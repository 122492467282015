import {Component, Input, OnInit} from '@angular/core';
import {SwiperConfigInterface, SwiperPaginationInterface} from 'ngx-swiper-wrapper';


@Component({
    selector: 'app-why-mahatta',
    templateUrl: './footer-whymahatta.component.html',
    styleUrls: ['./footer-whymahatta.component.scss']
})
export class FooterWhymahattaComponent implements OnInit {
    @Input('color') color: string;
    public config: SwiperConfigInterface = {};
    @Input('subtext') subtext: boolean = false;

    private pagination: SwiperPaginationInterface = {
        el: '.swiper-pagination',
        clickable: true
    };

    constructor() {
    }

    ngOnInit() {
        this.subtext = false;
    }

    getBackground() {
        let bgImage = {};
        if (this.color == 'pink') {
            bgImage = {
                background: this.color == 'pink' ? '#fbf8f8' : '#ffffff',
                marginTop: '20px'
            };
        }
        return bgImage;
    }
}
