import { CustomizedProduct } from "./customized_product.model";

export class WishlistItem {
  id?: number = 0;
  wishlist_id: number;
  customized_product_id: number;
  user_id: number;

  on_sale_id: number;

  image_id: number;
  image_filename: string;
  image_caption: string;
  image_photographer: string;

  created_at: string;
  updated_at: string;
  priceDetail: any;
  pricing_detail: any;

  customizedProduct: CustomizedProduct = new CustomizedProduct();

  public constructor() {
  }
}
