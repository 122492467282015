import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {AuthenticationService} from '../../authentication.service';
import {WallpaperRequest} from '../models/wallpaper_request.model';
import {Wallpaper} from '../models/wallpaper_master.model';
import {Order} from '../models/order.model';
import {PredefinedLayoutDetail} from '../models/art_set_master.model';
import {CollageSet} from '../models/collage_set.model';

@Injectable({
    providedIn: 'root'
})
export abstract class BaseAPIService {
    public apiUrlAuth = '';
    public apiUrlUnauth = '';

    // public http: HttpClient;
    public apiEndpointToken: string;

    constructor(public http: HttpClient, public authenticationService: AuthenticationService) {
        this.apiUrlAuth = environment.apiUrlauth;
        this.apiUrlUnauth = environment.apiUrlUnauth;
    }

    init(apiEndpointToken: string) {
        this.apiEndpointToken = apiEndpointToken;
    }

    setApiEndpointToken(apiEndpointToken: string) {
        this.apiEndpointToken = apiEndpointToken;
    }

    filter<T>(filter = '[]', orderBy = '[]', pageNumber = 0, pageSize = 10, apipath = '', type = '', apiAuth = 'unauth'): Observable<T[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('filter', filter);
        if (orderBy || orderBy != '') {
            httpParams = httpParams.append('orderBy', orderBy);
        }
        httpParams = httpParams.append('pageNumber', pageNumber.toString());
        if (pageSize || pageSize > 0) {
            httpParams = httpParams.append('pageSize', pageSize.toString());
        }
        httpParams = httpParams.append('type', type);
        if (apipath == '') {
            apipath = this.apiEndpointToken;
        }
        let authPath = this.apiUrlUnauth;
        if (apiAuth == 'auth') {
            authPath = this.apiUrlAuth;
        }
        return this.http.get<T[]>(authPath + '/' + apipath, {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('filter', []))
            );
    }

    find<T>(id, apipath = '', apiAuth = 'unauth'): Observable<T> {
        if (apipath == '') {
            apipath = this.apiEndpointToken;
        }
        let authPath = this.apiUrlUnauth;
        if (apiAuth == 'auth') {
            authPath = this.apiUrlAuth;
        }
        const httpParams = new HttpParams();
        return this.http.get<T>(authPath + '/' + apipath + '/' + id, {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('find', null))
            );
    }

    add<T>(element: T, apiUrl = ''): Observable<T> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };
        // apiUrl != '' ? apiUrl :
        return this.http.post<T>(this.apiUrlAuth + '/' + this.apiEndpointToken, element, httpOptions)
            .pipe(
                map(res => {
                    return res;
                })
            );
    }

    addFormData<T>(element: T, apiUrl = ''): Observable<T> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
            })
        };

        return this.http.post<T>(this.apiUrlAuth + '/' + apiUrl != '' ? apiUrl : this.apiEndpointToken, element, httpOptions)
            .pipe(
                map(res => {
                    return res;
                })
            );
    }

    update<T>(element: T): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<T>(this.apiUrlAuth + '/' + this.apiEndpointToken, element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getT', element))
            );
    }


    updateStatus<T>(id: number, status: number, type = 'status'): Observable<any> {
        const element: any = {
            id,
            status,
            form: this.apiEndpointToken,
            type
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<T>(this.apiUrlAuth + '/status_change', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getT', element))
            );
    }

    delete(ids = '[]'): Observable<any> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('ids', ids);
        return this.http.delete<any>(this.apiUrlAuth + '/' + this.apiEndpointToken, {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('deleteT', null))
            );
    }


    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    protected handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }


    calculateCustomWallpaperPrice(element: any) {

        let skipLoader=true;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            }),
            params: new HttpParams().append('skipLoader', skipLoader + '')
        };

       
        return this.http.post<WallpaperRequest>(this.apiUrlUnauth + '/calculateCustomWallpaperPrice?', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getWallpaperRequestService', element))
            );
            
    }


    getWallpaperByGemini(queryparam: string, apiEndToken: string, skipLoader = false): Observable<Wallpaper[]> {
        let params = new HttpParams();
        if (skipLoader) {
            params = params.append('skipLoader', skipLoader + '');
        }

        return this.http.get<any[]>(this.apiUrlUnauth + '/getRecommendedWallpapersByStyles?' + queryparam, {params: params})
        .pipe(
            map(res => res),
            catchError(this.handleError('filter', []))
        );
    }


    getWallpaperBySolr(queryparam: string, apiEndToken: string, skipLoader = false): Observable<Wallpaper[]> {
        let params = new HttpParams();
        if (skipLoader) {
            params = params.append('skipLoader', skipLoader + '');
            // queryparam += "&skipLoader=" + skipLoader;
        }
        return this.http.get<Wallpaper[]>(environment.wallpaperSearch + '/search_wallpaper.php?' + queryparam, {params: params})
            .pipe(
                map(res => res),
                catchError(this.handleError('filter', []))
            );
    }


    checkPincode(pincode: string) {
        return this.http.get<any>(this.apiUrlUnauth + '/checkInstallationPincode?pincode=' + pincode)
            .pipe(
                map(res => res),
                catchError(this.handleError('checkInstallationPincode', []))
            );
    }

    updatePrice(): Observable<Wallpaper[]> {

        return this.http.get<Wallpaper[]>(this.apiUrlUnauth + '/updatePriceAllWallpaper', {})
            .pipe(
                map(res => res),
                catchError(this.handleError('filter', []))
            );
    }

    fetchMasters(models: string, filters = '[]', apiEndpointToken = 'masters'): Observable<any> {
        let httpParams = new HttpParams();
        return this.http.get<any>(this.apiUrlUnauth + '/' + apiEndpointToken + '?models=' + models + '&filters=' + filters, {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('getMasters', null))
            );
    }

    removeImage(id: number, type: string, form: string): Observable<any> {
        const element: any = {
            id,
            type,
            form
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<PredefinedLayoutDetail>(this.apiUrlAuth + '/remove_image', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('removeImage', element))
            );
    }

    makeOrderPayment(element, type): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };
        let apth = '/makeOrderOfflinePayment';
        if (type == 'Online') {
            apth = '/makeOrderPayment';
        }
        return this.http.put<Order>(this.apiUrlAuth + apth, element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('makeOrderPayment', element))
            );
    }

    getCollageSetBySolr(queryparam: string, apiEndToken: string, skipLoader = false): Observable<CollageSet[]> {
        let params = new HttpParams();
        if (skipLoader) {
            params = params.append('skipLoader', skipLoader + '');
            // queryparam += "&skipLoader=" + skipLoader;
        }
        return this.http.get<CollageSet[]>(environment.wallpaperSearch + '/search_collage_set.php?' + queryparam, {params: params})
            .pipe(
                map(res => res),
                catchError(this.handleError('filter', []))
            );
    }

    getOnSaleBySolr(queryparam: string, apiEndToken: string, skipLoader = false): Observable<Wallpaper[]> {
        let params = new HttpParams();
        if (skipLoader) {
            params = params.append('skipLoader', skipLoader + '');
            // queryparam += "&skipLoader=" + skipLoader;
        }
        return this.http.get<Wallpaper[]>(environment.wallpaperSearch + '/search_on_sale.php?' + queryparam, {params: params})
            .pipe(
                map(res => res),
                catchError(this.handleError('filter', []))
            );
    }
}
