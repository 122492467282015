import {Component, OnInit, Input} from '@angular/core';
import {SwiperConfigInterface, SwiperPaginationInterface} from 'ngx-swiper-wrapper';


@Component({
    selector: 'app-layout-carousel',
    templateUrl: './layout-carousel.component.html',
    styleUrls: ['./layout-carousel.component.scss']
})
export class LayoutCarouselComponent implements OnInit {
    @Input('slides') slides: Array<any> = [];

    public config: SwiperConfigInterface = {};
    public noOfImages = 10;
    private pagination: SwiperPaginationInterface = {
        el: '.swiper-pagination',
        clickable: true
    };

    constructor() {
    }

    ngOnInit() {
        // if (window.innerWidth < 960) {
        //   this.noOfImages = 3;
        // }
        if (window.innerWidth < 1280) {
            this.noOfImages = 10;
        }
        if (window.innerWidth < 575) {
            this.noOfImages = 5;
        }
    }

    ngAfterViewInit() {
        // @ts-ignore
        this.config = {
            slidesPerView: this.noOfImages,
            spaceBetween: 5,
            keyboard: true,
            navigation: true,
            pagination: this.pagination,
            grabCursor: true,
            loop: false,
            preloadImages: false,
            lazy: true,
            autoplay: {
                delay: 6000,
                disableOnInteraction: false
            },
            speed: 500,
            effect: 'slide'

        };

    }

}
