import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {OrderDataSource} from '../../../../admin/services/order.datasource';
import {FormControl, FormGroup} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {OrderDetailOldModel} from '../../../../admin/models/order.model';
import {AuthenticationService} from '../../../../authentication.service';
import {OrderService} from '../../../../admin/services/order.service';
import {BehaviorSubject, of} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icSearch from '@iconify/icons-ic/twotone-search';
import icAdd from '@iconify/icons-ic/twotone-add';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz';
import icFolder from '@iconify/icons-ic/twotone-folder';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ArtSetPreviewDialogComponent} from "../../../../shared/art-set-preview-dialog/art-set-preview-dialog.component";

@Component({
    selector: 'app-order-detail-dialog',
    templateUrl: './order-detail-dialog.component.html',
    styleUrls: ['./order-detail-dialog.component.scss']
})
export class OrderDetailDialogComponent implements OnInit {
    pageSize = 10;
    pageSizeOptions: number[] = [5, 10, 20, 50];

    dataSource: OrderDataSource;
    searchCtrl = new FormControl();
    icEdit = icEdit;
    icSearch = icSearch;
    icDelete = icDelete;
    icAdd = icAdd;
    icFilterList = icFilterList;
    icMoreHoriz = icMoreHoriz;
    icFolder = icFolder;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    public orderDetail: OrderDetailOldModel;
    public searchForm: FormGroup;
    private loadingSubject;
    public loading$;

    constructor(private authenticationService: AuthenticationService,
                private orderService: OrderService,
                private dialog: MatDialog,
                public dialogRef: MatDialogRef<OrderDetailDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
        this.loading$ = this.loadingSubject.asObservable();
    }

    ngOnInit() {
        this.getData();
    }

    getData() {
        this.loadingSubject.next(true);
        const orderId = this.data.order_id;
        // const orderId = "MA1611234055671";
        // const orderId = "MA1606197576301";
        this.dataSource = new OrderDataSource(this.orderService);
        this.orderService.orderDetailOld(orderId)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(
                res => {
                    this.orderDetail = res['orderData'];
                    console.log(this.orderDetail);

                }
            );
    }


    onDismiss(): void {
        this.dialogRef.close(false);
    }

    previewDialog() {
        const dialogRef = this.dialog.open(ArtSetPreviewDialogComponent, {
            data: {
                // customizedProduct: product
            },
            width: '60vw',
            height: '50vh',
            disableClose: true,
            autoFocus: false
        });
    }
}
