import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {emailValidator} from '../../theme/utils/app-validators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Location} from '@angular/common';
import {BehaviorSubject, Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {ContactService} from '../../admin/services/contact.service';
import {Contact} from '../../admin/models/contact.model';


@Component({
    selector: 'app-query-form',
    templateUrl: './query-form.component.html',
    styleUrls: ['./query-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QueryFormComponent implements OnInit {
    contactForm: FormGroup;

    private loadingSubject: BehaviorSubject<boolean>;
    public loading$: Observable<boolean>;

    constructor(
        public formBuilder: FormBuilder,
        private location: Location,
        public router: Router,
        private route: ActivatedRoute,
        public snackBar: MatSnackBar,
        public snackbar: MatSnackBar,
        public contactService: ContactService
    ) {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
        this.loading$ = this.loadingSubject.asObservable();
    }

    ngOnInit() {
        this.contactForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, emailValidator])],
            name: ['', Validators.compose([Validators.required])],
            contact: ['', Validators.compose([Validators.required])],
            requirement: ['', Validators.compose([Validators.required])],
            subject: ['Contact us Query'],
            page_url: ['']
        });
    }


    public onSubmit(): void {
        if (this.contactForm.invalid || this.contactForm.pristine) {
            return;
        }
        this.loadingSubject.next(true);
        this.contactForm.disable();

        let element: Contact = new Contact();
        element = Object.assign({}, element, this.contactForm.value);
        element.page_url = this.router.url;

        this.contactService.add(element)
            .pipe(
                // catchError(() => of([])),
                finalize(() => {
                    this.loadingSubject.next(false);
                    this.contactForm.enable();
                    
                })
            )
            .subscribe(
                res => {
                    this.snackbar.open('Thank you for contacting us. Our agent will contact you within 24 hours. ', 'Ok', {
                        duration: 10000,
                        verticalPosition: 'top',
                        panelClass: ['success']
                    });                },
                err => {
                    console.log('error: ', err);
                    // this.errorSubject.next(error);
                    // this.loadingSubject.next(false);
                    this.snackbar.open('There is some error', 'Ok', {
                        duration: 10000,
                        verticalPosition: 'top',
                        panelClass: ['error']
                    });
                });
    }

}
