import {Component, ElementRef, EventEmitter, HostListener, Inject, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
// import {ForgotPasswordComponent} from '../../shared/forgot-password/forgot-password.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

import {environment} from '../../../environments/environment';

import {jsPDF} from 'jspdf';
import {ArtSetPreviewDialogComponent} from '../art-set-preview-dialog/art-set-preview-dialog.component';

@Component({
    selector: 'app-order-details-dialog',
    templateUrl: './order-details-dialog.component.html',
    styleUrls: ['./order-details-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OrderDetailsDialogComponent implements OnInit {
    @Output() onClose = new EventEmitter<any>(true);

    private loadingSubject: BehaviorSubject<boolean>;
    public loading$: Observable<boolean>;

    private _orderDetailsHtml: SafeHtml;
    public get orderDetailsHtml() {
        return this._orderDetailsHtml;
    }


    private _apiUrl = '';
    public get apiUrl() {
        return this._apiUrl;
    }

    private countSubject: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
    public count$: Observable<number> = this.countSubject.asObservable();

    constructor(
        public formBuilder: FormBuilder,
        public router: Router,
        private route: ActivatedRoute,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<OrderDetailsDialogComponent>,
        private matSnackBar: MatSnackBar,
        private http: HttpClient,
        private sanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: { order_id: number }
    ) {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
        this.loading$ = this.loadingSubject.asObservable();
        this._apiUrl = environment.apiUrlUnauth;
    }

    ngOnInit() {
        let invoiceUrl = environment.apiUrlauth + '/orderDetails?order_id=' + this.data.order_id;
        this.http.get(invoiceUrl, {responseType: 'text'}).subscribe(res => {
            this._orderDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(res);
        });
    }

    @ViewChild('orderDetailsHtmlContent') orderDetailsHtmlContent: ElementRef;

    public onExport() {
        console.log('ICTSoft', this.orderDetailsHtmlContent.nativeElement);

        let doc = new jsPDF('landscape');
        doc.html(this.orderDetailsHtmlContent.nativeElement, {
            callback: function(doc) {
                doc.save();
            }
        });

        // doc.addHTML(this._orderDetailsHtml, function() {
        //    doc.save("invoice.pdf");
        // });
    }

    public close(): void {
        this.dialogRef.close();
    }

    @HostListener('window:preview.order', ['$event'])
    previewOrder(event): void {
        console.log(event.detail);
        let customizedProduct = event.detail;
        // const dialogRef = this.dialog.open(ArtSetPreviewDialogComponent, {
        //     data: {
        //         customizedProduct : event.detail
        //     },
        //     minHeight: '50vh',
        //     // width: '100%',
        //     // disableClose: true,
        //     autoFocus: false
        // });

        const dialogRef = this.dialog.open(ArtSetPreviewDialogComponent, {
            data: {
                customizedProduct: event.detail
            },
            width: !(customizedProduct.layout_id > 0 || customizedProduct.predefine_layout_id > 0 || customizedProduct.collage_layout_id > 0) ? 'auto' : '700px',
            height: '70vh',
            disableClose: true,
            autoFocus: false
        });

        // const close = dialogRef.componentInstance.onClose.subscribe(() => {
        //     dialogRef.close();
        // });

    }
}
