import {Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {emailValidator} from '../../theme/utils/app-validators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Location} from '@angular/common';
import {BehaviorSubject, Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {ContactService} from '../../admin/services/contact.service';
import {Contact} from '../../admin/models/contact.model';
import {CustomizedProduct} from '../../admin/models/customized_product.model';
import {WallpaperRequest} from '../../admin/models/wallpaper_request.model';


@Component({
    selector: 'app-wallpaper-dialog',
    templateUrl: './wallpaper-dialog.component.html',
    styleUrls: ['./wallpaper-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WallpaperDialogComponent implements OnInit {
    @Output() onClose = new EventEmitter<any>(true);

    contactForm: FormGroup;

    private loadingSubject: BehaviorSubject<boolean>;
    public loading$: Observable<boolean>;

    constructor(
        public formBuilder: FormBuilder,
        private location: Location,
        public router: Router,
        private route: ActivatedRoute,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<WallpaperDialogComponent>,
        public snackbar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: {wallpaper: any}) {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
        this.loading$ = this.loadingSubject.asObservable();
    }

    ngOnInit() {

    }



    public close(): void {
        this.dialogRef.close();
    }
}
