import {Component, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {AppService} from '../../app.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TopbarNavbarService} from '../../topbar-navbar.service';
import {Order} from 'src/app/admin/models/order.model';

@Component({
    selector: 'app-order-history',
    templateUrl: './order-history.component.html',
    styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {
    @ViewChild('sidenav', {static: true}) sidenav: any;
    public sidenavOpen = true;

    private _order: Order;
    private sub: any;
    public session_id: any;
    public user_id: any;

    public get order() {
        return this._order;
    }

    constructor(
        public appService: AppService,
        @Inject(PLATFORM_ID) private platformId: Object,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public topbarNavbarService: TopbarNavbarService,
    ) {
        this.topbarNavbarService.setMenuType(0);
        this.topbarNavbarService.setHeaderType(1);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
    }

    ngOnInit() {
        this.sidenavOpen = false;
        this.sub = this.activatedRoute.params.subscribe(params => {
            this.session_id = params.session_id;
            this.user_id = params.user_id;
        });
    }

    public onOrder($event) {
        this._order = $event;
        this.sidenav.toggle();
    }
}
