import {Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
// import {ForgotPasswordComponent} from '../../shared/forgot-password/forgot-password.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AuthenticationService} from 'src/app/admin/services/authentication.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {messages} from 'src/environments/environment';
import {Wishlist} from 'src/app/admin/models/wishlist.model';
import {WishlistService} from 'src/app/admin/services/wishlist.service';


@Component({
    selector: 'app-wishlist-quick-dialog',
    templateUrl: './wishlist-quick-dialog.component.html',
    styleUrls: ['./wishlist-quick-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WishlistQuickDialogComponent implements OnInit {
    @Output() onClose = new EventEmitter<any>(true);

    form: FormGroup;

    private loadingSubject: BehaviorSubject<boolean>;
    public loading$: Observable<boolean>;

    public wishlists: Wishlist[] = [];

    constructor(
        public formBuilder: FormBuilder,
        public router: Router,
        private route: ActivatedRoute,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<WishlistQuickDialogComponent>,
        public wishlistService: WishlistService,
        private authenticationService: AuthenticationService,
        private matSnackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: { customer_address_id: number, list: number }
    ) {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
        this.loading$ = this.loadingSubject.asObservable();
    }

    private _wishlist_id: number = 0;
    public get wishlist_id() {
        return this._wishlist_id;
    }

    public set wishlist_id(_wishlist_id) {
        this._wishlist_id = _wishlist_id;
    }

    onWishlist() {
        this.onClose.emit({wishlist_id: this._wishlist_id});
        // console.log("ICTSoft", this._wishlist_id);
    }

    private _hideAddGallery: boolean = true;
    public get hideAddGallery() {
        return this._hideAddGallery;
    }

    public set hideAddGallery(_hideAddGallery) {
        this._hideAddGallery = _hideAddGallery;
    }

    onAddGallery() {
        this._hideAddGallery = false;
    }

    ngOnInit() {
        const filter = [];
        filter.push(['user_id', '=', this.authenticationService.currentUserValue.id]);

        this.wishlistService.filter(JSON.stringify(filter), '[]', 0, -1).subscribe(res => {
            this.wishlists = res['data'];
            this.loadingSubject.next(false);
        });

        this.form = this.formBuilder.group({
            id: 0,
            name: ['', Validators.required],
            user_id: [this.authenticationService.currentUserValue.id],
        });

        console.log(this.data);
        if (this.data.list == 0) {
            this._hideAddGallery = false;
        }
    }


    public onSubmit() {
        if (this.form.valid) {
            let element: Wishlist = new Wishlist();
            element = Object.assign({}, element, this.form.value);
            this.create(element);
        }
    }

    create(element) {
        this.loadingSubject.next(true);
        this.form.disable();
        this.wishlistService.add(element)
            .pipe(
                finalize(() => {
                    this.loadingSubject.next(false);
                    this.form.enable();
                })
            )
            .subscribe(
                res => {
                    console.log('ICTSoft', res['data']);
                    console.log('ICTSoft', res['data'].id);
                    this.loadingSubject.next(false);
                    this.matSnackBar.open('Record has been successfully added!', 'x', {
                        duration: 2000,
                        verticalPosition: 'top',
                        panelClass: ['success']
                    });
                    // this.dialogRef.close();
                    this.onClose.emit({wishlist_id: res['data'].id});
                },
                err => {
                    // this.form.enable();
                    // for (let key of Object.keys(err.errors)) {
                    //   const formControl = this.form.get(key);
                    //   if (formControl) {
                    //     // activate the error message
                    //     let errorMsg = this.i18nSelectPipe.transform(err.errors[key], this.messages)
                    //     setTimeout(() => {
                    //       formControl.setErrors({
                    //         serverError: errorMsg
                    //       });
                    //     });
                    //   }
                    // }
                }
            );
    }

    private messages = messages;

    get message() {
        return this.message;
    }

    public close(): void {
        this.dialogRef.close();
    }
}
