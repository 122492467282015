export class SpaceType {
    id = 0;
    public name: string;
    public status: boolean;

    public constructor() {
        // this.source_category = new SourceCategory();
    }
}

export class Space {
    id = 0;
    public name: string;
    public space_type_id: Array<number>;
    public sub_cat_slug: number;
    public status: boolean;
    public space1: string;
    public space2: string;
    public space3: string;
    public space4: string;
    public space5: string;
    public space6: string;
    public space_wallpaper_front1: string;
    public space_wallpaper_front2: string;
    public space_wallpaper_front3: string;
    public space_wallpaper_front4: string;
    public space_wallpaper_front5: string;
    public space_wallpaper_front6: string;
    public space_wallpaper_side1: string;
    public space_wallpaper_side2: string;
    public space_wallpaper_side3: string;
    public space_wallpaper_side4: string;
    public space_wallpaper_side5: string;
    public space_wallpaper_side6: string;
    public activated: boolean;

    constructor() {
        this.activated = false;
    }
}

export class DecorStyle {
    id = 0;
    public name: string;
    public space_id: Array<number>;
    public status: boolean;

    constructor() {
    }
}

export class ContentType {
    id = 0;
    public name: string;
    public decor_style_id: Array<number>;
    public status: boolean;

    constructor() {
    }
}

export class SubcontentType {
    id = 0;
    public name: string;
    public content_id: Array<number>;
    public status: boolean;

    constructor() {
    }
}

export class Theme {
    id = 0;
    public name: string;
    public slug: string;
    public status: boolean;
    public activated: boolean;

    constructor() {
        this.activated = false;
    }
}

export class Sizes {
    id = 0;
    public name: string;
    public status: boolean;
    public isChecked: number = 1;
    public width: number = 0;
    public height: number = 0;

    constructor() {
    }
}

export class ArtsetLayout {
    id = 0;
    public name: string;
    public no_of_frames: number;
    public sizes_id: string;
    public size_name: string;
    public image: string;

    public constructor() {
        // this.source_category = new SourceCategory();
    }
}

export class ArtsetLayoutDetail {
    id = 0;
    public art_set_layout_id: number;
    public size_id: number;
    public frame_detail: any;
    public final_size: string;
    public status: boolean;
    public start_index_x: number;
    public start_index_y: number;
    public start_index_x_mobile: number;
    public start_index_y_mobile: number;

    public constructor() {
        // this.source_category = new SourceCategory();
    }
}

export class PredefinedLayoutDetail {
    id = 0;
    public art_set_layout_id: number;
    public art_set_layout_detail_id: number;
    public size: number;
    public frame_detail: any;
    public final_size: string;
    public final_price: number;
    public status: boolean;
    public show_layout: boolean;
    public product_type: any;
    public space_type: number;
    public space: any;
    public decor_style: number;
    public content_type: number;
    public theme: number;
    public keyword: string;
    public caption: string;
    public image: string;
    public image1: string;
    public image2: string;
    public image3: string;
    public default_space_image: string;
    public art_set_layout: ArtsetLayout;
    public start_index_x: number;
    public start_index_y: number;
    public start_index_x_mobile: number;
    public start_index_y_mobile: number;
    public productType: any;
    public wishlist_item_id: number;
    public space1: string;
    public space2: string;
    public space3: string;
    public frame: any;
    public images_arr: any;
    public frame_image_arr: any;

    public constructor() {
        // this.source_category = new SourceCategory();
    }
}

export class ImageDetail {
    imageId: string;
    width = 3;
    height = 5;
    shape = 'square';
    top: number;
    left: number;
    text: string = '';
    initialX: number;
    initialY: number;
    backgroundImage: string;
    image_class: string;
    no_of_images = 1;
    public backgroundImages: any;

    public constructor() {
    }
}


export class FrameDetail {
    id = 0;
    public name: string;
    public width = 3;
    public height = 5;
    public top = 0.0;
    public left = 0.0;
    public topEnd = 0.0;
    public leftEnd = 0.0;
    public initialX = 0.0;
    public initialY = 0.0;
    public frameBorderWidth = 0.0;
    public frameBorderImage = '';
    public frame_id = 0;
    public frame_name = '';
    public mount_size_1 = 0.0;
    public mount_id_1 = 0.0;
    public mount_size_2 = 0.0;
    public mount_id_2 = 0.0;
    public mount_theme_id = 0.0;
    public mount_size_3 = 0.0;
    public mount_id_3 = 0.0;
    public glass_id = 0.0;
    public paper_id = 0.0;
    public wrap_id = 0.0;
    public price = 0.0;
    public priceDetail: any;
    public image_id = '235518';
    public image_type = 'wall_art';

    public image_class;
    public quality_grade = 'Platinum';
    public backgroundImage = 'https://static.mahattaart.com/400x400/media/EVRM_HISL044_EC870_H.JPG';
    public backgroundImages: any;
    public mount1background = '';
    public mount2background = '';
    public mount3background = '';
    public mountThemebackground = '';
    public images: ImageDetail[] = [];
    public isOuter = false;
    public is_active = true;
    public wrap_item_id = '';
    public flip = 0;
    public rotation = 0;
    public isImageUpload = 0;
    public text: any;
    public no_of_images: number;
    public no_of_images_uploaded = 0;
    public shape: string;
    public quotes: any;
    public image: any;

    public constructor(name: string, top: number, width: number, height: number, inch: number, type: string, noOfImages: number) {
        this.name = name;
        this.width = width;
        this.height = height;
        this.text = '';
        this.no_of_images = 1;
        if (type == 'top') {
            this.top = Number(top);
            this.topEnd = Number((top / inch) + height);
            this.leftEnd = Number(width);
            this.initialY = Number(top);
        } else {
            this.left = Number(top);
            this.leftEnd = Number((top / inch) + width);
            this.topEnd = Number(height);
            this.initialX = Number(top);
        }
        for (let i = 0; i < noOfImages; i++) {
            const image = new ImageDetail();
            image.left = (i * 3) * 2 * inch;
            image.top = 0;
            image.initialX = image.left;
            image.initialY = 0;
            image.text = '';
            this.images.push(image);
        }

        this.is_active = true;
    }
}


export class CollageLayoutDetail {
    id = 0;
    public size: number;
    public collage_type: any;
    public frame_detail: any;
    public final_size: string;
    public product_type: any;
    public no_of_frames: number;
    public final_price: number;
    public discount: number;
    public start_index_x: number;
    public start_index_y: number;
    public start_index_x_mobile: number;
    public start_index_y_mobile: number;
    public status: boolean;
    public show_layout: boolean;
    public keyword: string;
    public caption: string;
    public slug: string;
    public description: string;
    public image: string;
    public image1: string;
    public wishlist_item_id = 0;
    public frame_style_id = 0;
    public min_color = '';
    public max_color = '';
    public frame: any;
    public images_arr: any;

    public constructor() {
        // this.source_category = new SourceCategory();
    }
}

export class SpaceCategory {
    id = 0;
    public name: string;
    public status: boolean;

    public constructor() {
        // this.source_category = new SourceCategory();
    }
}

export class Color {
    id = 0;
    public name: string;
    public slug: string;
    public status: boolean;
    public activated: boolean;

    constructor() {
        this.activated = false;
    }
}
