import {Component, HostListener, Inject, PLATFORM_ID, Renderer2} from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AppSettings, Settings} from './app.settings';
import {filter} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {CartService} from './admin/services/cart.service';
import {AuthenticationService} from './admin/services/authentication.service';
import {MatDialog} from '@angular/material/dialog';
import {Meta} from '@angular/platform-browser';
import {SEOService} from './seo.service';
import {SettingService} from './admin/services/setting.service';
import {SignUpDialogComponent} from './shared/sign-up-dialog/sign-up-dialog.component';
import {SchemaService} from './schema.service';
import {Subject} from 'rxjs';
import {GtmService} from './gtm.service';

declare let trackEvent: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    constructor(public appSettings: AppSettings, public router: Router,
                @Inject(PLATFORM_ID) private platformId: Object,
                // private gtmService: GoogleTagManagerService,
                private cartService: CartService,
                private authenticationService: AuthenticationService,
                private dialog: MatDialog,
                private metaService: Meta,
                public activatedRoute: ActivatedRoute, public seoService: SEOService,
                public settingService: SettingService,
                public _render2: Renderer2,
                private schemaService: SchemaService,
                private gtmService: GtmService,
                @Inject(DOCUMENT) private _document: Document) {
        this.settings = this.appSettings.settings;
        this.setTimeout();
        this.userInactive.subscribe(() => this.gtmService.gtmExitPage('inactivity'));
    }

    loading = false;
    public settings: Settings;

    userActivity;
    userInactive: Subject<any> = new Subject();

    // customEvent() {
    //
    //     // push GTM data layer with a custom event
    //     const gtmTag = {
    //         event: 'button-click',
    //         data: 'my-custom-event',
    //     };
    //     this.gtmService.pushTag(gtmTag);
    //
    //     alert('this is a custom event');
    // }
    //

    getDifference(date1, date2) {
        const diffInMs = Math.abs(date2 - date1);
        return diffInMs / (1000 * 60);
    }

    ngOnInit() {
        this.updateUtmLink();
        this.schemaService.renderer = this._render2;
        if (environment.environmentName == 'prod') {
            const navigationEndEvents = this.router.events.pipe(
                filter(event => event instanceof NavigationEnd)
            );
            navigationEndEvents.subscribe((item: NavigationEnd) => {

                // const gtmTag = {
                //     event: 'page',
                //     pageName: item.url
                // };
                // this.gtmService.pushTag(gtmTag);
            });
        }

        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd))
            .subscribe((event) => {
                // console.log('print route');
                // console.log(this.router.url);
                if (!this.router.url.includes('/admin/')) {
                    this.settingService.getChild(this.router.url).subscribe(data => {
                        data = data['data'];
                        if (data) {
                            this.seoService.updateTitle(data.title);
                            this.seoService.updateData('title', data.title);
                            this.seoService.updateData('description', data.description);
                            this.seoService.updateData('keywords', data.keyword);
                            if (environment.environmentName == 'prod') {
                                this.seoService.updateData('robots', data.robots ? data.robots : 'follow,index');
                            } else {
                                this.seoService.updateData('robots', data.robots ? data.robots : 'nofollow,noindex');
                            }
                            this.seoService.updateDataOG('og:url', data.ogUrl ? data.ogUrl : this.router.url);
                            this.seoService.updateDataOG('og:title', data.ogTitle);
                            this.seoService.updateDataOG('og:description', data.ogDesc);
                            this.seoService.updateDataOG('og:image:secure_url', data.ogImage);
                            this.seoService.updateDataOG('og:site_name', data.ogSite);
                            this.seoService.updateDataOG('og:type', data.ogType);
                            this.seoService.updateDataOG('og:locale', data.ogLocale);
                            if (data.canonical) {
                                this.seoService.updateCanonicalUrl(this.router.url);
                            } else {
                                this.seoService.removeCanonicalUrl();
                            }
                            if (data.schema) {
                                this.schemaService.addSchema(data.schema, 0);
                            } else {
                                this.schemaService.addSchema('{}', 1);
                            }
                        } else {
                            this.seoService.removeCanonicalUrl();
                        }
                        // else {
                        //     this.clearTags();
                        // }
                    });

                } else {
                    this.clearTags();
                }
            });

        // this.showSignUpPopup();

    }

    showSignUpPopup() {
        setTimeout(() => {
            const date = localStorage.getItem('lastShown');
            const date1: Date = new Date();
            let min = 0;
            if (date) {
                const startDate: string = JSON.parse(date);
                const date2: Date = new Date(startDate);
                min = this.getDifference(date1, date2);
            }
            if (!this.authenticationService.currentUserValue && (min == 0 || min >= 2)) {
                localStorage.setItem('lastShown', JSON.stringify(date1));
                if (this.dialog.openDialogs.length == 0) {
                    const dialogRef = this.dialog.open(SignUpDialogComponent, {
                        panelClass: 'dialog-container-no-padding',
                        data: {},
                        disableClose: true,
                        autoFocus: false
                    });
                    const close = dialogRef.componentInstance.onClose.subscribe(() => {
                        dialogRef.close();
                    });
                }
            }
        }, 10000);

    }


    ngAfterViewInit() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (isPlatformBrowser(this.platformId)) {
                    window.scrollTo(0, 0);
                }
                console.log('in route navigation end');
            }
        });
        this.cartService.loadCart();
    }

    clearTags() {
        this.seoService.updateTitle('');
        this.seoService.updateData('description', '');
        this.seoService.updateData('keyword', '');
        this.seoService.updateData('robots', 'follow,index');
        this.seoService.updateDataOG('og:url', this.router.url);
        this.seoService.updateDataOG('og:title', '');
        this.seoService.updateDataOG('og:description', '');
        this.seoService.updateDataOG('og:image:secure_url', '');
        this.seoService.updateDataOG('og:site_name', '');
        this.seoService.updateDataOG('og:type', '');
        this.seoService.updateDataOG('og:locale', '');
        this.seoService.removeCanonicalUrl();
    }

    private updateUtmLink() {
        this.activatedRoute.queryParams.subscribe(params => {
            const utmSource = params['utm_source'];
            if (utmSource && utmSource != '') {
                localStorage.setItem('utmLink', this.router.url);
            }
        });
        this.gtmService.gtmEntryPage();
    }


    setTimeout() {
        this.userActivity = setTimeout(() => this.userInactive.next(undefined), 1200000);
    }

    @HostListener('window:mousemove') refreshUserState() {
        clearTimeout(this.userActivity);
        this.setTimeout();
    }

    @HostListener('window:unload', ['$event'])
    unloadHandler(event) {
        this.gtmService.gtmExitPage('close');
    }

}
