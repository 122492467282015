import {Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AuthenticationService} from 'src/app/admin/services/authentication.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {messages} from 'src/environments/environment';
import {TestimonialService} from 'src/app/admin/services/testimonial.service';
import {Testimonial} from 'src/app/admin/models/testimonial.model';


@Component({
    selector: 'app-testimonial-dialog',
    templateUrl: './testimonial-dialog.component.html',
    styleUrls: ['./testimonial-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TestimonialDialogComponent implements OnInit {


    constructor(
        public formBuilder: FormBuilder,
        public router: Router,
        private route: ActivatedRoute,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<TestimonialDialogComponent>,
        public testimonialService: TestimonialService,
        private authenticationService: AuthenticationService,
        private matSnackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: { order_item_id: number, product_id: number, order_id: number, order_offline_id: number }
    ) {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
        this.loading$ = this.loadingSubject.asObservable();

    }

    get message() {
        return this.message;
    }

    @Output() onClose = new EventEmitter<any>(true);

    form: FormGroup;

    private loadingSubject: BehaviorSubject<boolean>;
    public loading$: Observable<boolean>;

    public image_1;
    public image_1_path = './../../assets/images/placeholder.png';
    public image_2;
    public image_2_path = './../../assets/images/placeholder.png';
    public image_3;
    public image_3_path = './../../assets/images/placeholder.png';


    private messages = messages;
    public rating: number = 4;

    ngOnInit() {
        this.form = this.formBuilder.group({
            id: 0,
            review: ['', Validators.required],
            rating: [4, Validators.required],
            order_item_id: [this.data.order_item_id],
            product_id: [this.data.product_id],
            order_id: [this.data.order_id],
            order_offline_id: [this.data.order_offline_id],
            status: [0],
            user_id: [this.authenticationService.currentUserValue.id],
        });

        if (this.data.order_item_id || this.data.order_id || this.data.order_offline_id) {
            this.loadingSubject.next(true);
            this.form.disable();

            const filter = [];
            const orderBy = [];

            if (this.data.order_item_id) {
                filter.push(['order_item_id', '=', this.data.order_item_id]);
            } else if (this.data.order_id) {
                filter.push(['order_id', '=', this.data.order_id]);
            } else if (this.data.order_offline_id) {
                filter.push(['order_offline_id', '=', this.data.order_offline_id]);
            }

            this.testimonialService.filter(JSON.stringify(filter), JSON.stringify(orderBy), 0, -1).subscribe(res => {
                if (res['meta'].recordsFiltered > 0) {
                    this.form.patchValue(res['data'][0]);
                    this.rating = res['data'][0].rating;
                    if (res['data'][0]?.image_1) {
                        this.image_1_path = res['data'][0].image_1;
                    }
                    if (res['data'][0]?.image_2) {
                        this.image_2_path = res['data'][0].image_2;
                    }
                    if (res['data'][0]?.image_3) {
                        this.image_3_path = res['data'][0].image_3;
                    }

                }
                this.loadingSubject.next(false);
                this.form.enable();
            });
        }
    }


    public onSubmit() {
        if (this.form.valid) {
            let element: Testimonial = new Testimonial();
            element = Object.assign({}, element, this.form.value);
            // element.rating = this.rating;
            element.image_1 = this.image_1;
            element.image_2 = this.image_2;
            element.image_3 = this.image_3;


            if (element.id === 0) {
                this.create(element);
            } else {
                this.update(element);
            }
        }
    }

    create(element) {
        this.loadingSubject.next(true);
        this.form.disable();
        this.testimonialService.add(element)
            .pipe(
                finalize(() => {
                    this.loadingSubject.next(false);
                    this.form.enable();
                })
            )
            .subscribe(
                res => {
                    this.loadingSubject.next(false);
                    this.matSnackBar.open('Record has been successfully added!', 'x', {
                        duration: 2000,
                        verticalPosition: 'top',
                        panelClass: ['success']
                    });
                    // this.dialogRef.close();
                    this.onClose.emit();
                },
                err => {
                    // this.form.enable();
                    // for (let key of Object.keys(err.errors)) {
                    //   const formControl = this.form.get(key);
                    //   if (formControl) {
                    //     // activate the error message
                    //     let errorMsg = this.i18nSelectPipe.transform(err.errors[key], this.messages)
                    //     setTimeout(() => {
                    //       formControl.setErrors({
                    //         serverError: errorMsg
                    //       });
                    //     });
                    //   }
                    // }
                }
            );
    }

    update(element) {
        this.loadingSubject.next(true);
        this.form.disable();
        this.testimonialService.update(element)
            .pipe(
                finalize(() => {
                    this.loadingSubject.next(false);
                    this.form.enable();
                })
            )
            .subscribe(
                res => {
                    this.loadingSubject.next(false);
                    this.form.enable();
                    this.matSnackBar.open('Record has been successfully updated!', 'x', {
                        duration: 2000,
                        verticalPosition: 'top',
                        panelClass: ['success']
                    });
                    // this.dialogRef.close();
                    this.onClose.emit();
                },
                err => {
                    // this.form.enable();
                    // for (let key of Object.keys(err.errors)) {
                    //   const formControl = this.form.get(key);
                    //   if (formControl) {
                    //     // activate the error message
                    //     let errorMsg = this.i18nSelectPipe.transform(err.errors[key], this.messages)
                    //     setTimeout(() => {
                    //       formControl.setErrors({
                    //         serverError: errorMsg
                    //       });
                    //     });
                    //   }
                    // }
                }
            );
    }

    public close(): void {
        this.dialogRef.close();
    }

    onSelectMedia(event, type: string) { // called each time file input changes
        if (event.target.files && event.target.files[0]) {
            // this.mediaProductForm.markAsDirty();
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            if (type == 'image_1') {
                reader.onload = (event) => { // called once readAsDataURL is completed
                    this.image_1_path = reader.result.toString(); //add source to image
                    this.image_1 = reader.result;
                };
            }
            if (type == 'image_2') {
                reader.onload = (event) => { // called once readAsDataURL is completed
                    this.image_2_path = reader.result.toString(); //add source to image
                    this.image_2 = reader.result;
                };
            }
            if (type == 'image_3') {
                reader.onload = (event) => { // called once readAsDataURL is completed
                    this.image_3_path = reader.result.toString(); //add source to image
                    this.image_3 = reader.result;
                };
            }
        }
    }

}
