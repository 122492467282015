import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomizedProduct } from 'src/app/admin/models/customized_product.model';

@Component({
  selector: 'app-product-details-dialog',
  templateUrl: './product-details-dialog.component.html',
  styleUrls: ['./product-details-dialog.component.scss']
})
export class ProductDetailsDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ProductDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {customizedProduct: CustomizedProduct}
  ) {
  }

  public getImageName(customizedProduct: CustomizedProduct) {
    let image_name: string = customizedProduct.customizedProductItems[0].image_detail.image_path.split("/").pop();
    return image_name;
  }

  public isFrame(customizedProduct: CustomizedProduct) : boolean {
    switch(customizedProduct.product_type_id) {
      case 2:
      case 5:
      case 8:
        return true;
    }
    return false;
  }

  public isCanvas(customizedProduct: CustomizedProduct) : boolean {
    switch(customizedProduct.product_type_id) {
      case 1:
      case 4:
        return true;
    }
    return false;
  }

  public get customizedProduct() {
    return this.data.customizedProduct;
  }

  ngOnInit(): void {
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  public close(): void {
    this.dialogRef.close();
  }

}
