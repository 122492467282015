import { Pipe, PipeTransform } from '@angular/core';
import { isString } from 'util';

// https://github.com/fknop/angular-pipes/blob/master/src/string/split.pipe.ts
@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {
  transform(input: any, separator: string = ' ', limit?: number): any {
    if (!isString(input)) {
      return input;
    }

    return input.split(separator, limit);
  }
}
