// import { CartDetail } from "./cart_detail.model";
import {CustomizedProductItem} from './customized_product_item.model';
import {Product} from './product.model';
import {Wallpaper} from './wallpaper_master.model';

export class CustomizedProduct {
    id?: number = 0;
    customer_id: number;
    layout_id: number;
    wallpaper_id: number;
    space_decor_id: number;
    predefine_layout_id: number;
    collage_layout_id: number;
    no_of_product_types: number;
    product_id: number;
    product_type_id: number;
    url: string;
    quantity: number = 1;
    on_sale_id?: number = 0;
    // cartDetails: CartDetail[];
    customizedProductItems: CustomizedProductItem[];
    product: Product = new Product();
    pricing_detail: any;
    product_type: any;
    frame_detail: any;
    on_sale: any;
    collage_layout_detail: any;
    predefined_layout_detail: any;
    gift_wrap: number;
    gift_message: string;
    additional_remark: string;
    installation_pincode: string;
    filter: string;
    isInstall: boolean;
    wallpaper: Wallpaper = new Wallpaper();
    wallpaperSizeArr :any;
    public constructor() {
    }
}
