import {State} from "./state.model";

export class City {
  id: number;
  name: string;

  state_id: number;
  state: State;
  created_at: string;
  updated_at: string;

  public constructor() {
    this.state = new State();
  }
}
