import { Role } from './role.model';

export class User {
  id?: number = 0;
  name: string;
  email: string;
  role_id?: number = 0;
  role: Role;

  password: string;
  token?: string;

  public constructor() {
    this.role = new Role();
  }
}
