import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit {
  messages: { sender: string, text: string, botImage?: string, userImage?: string, typing?: boolean }[] = [];
  userMessage: string = '';
  botImage: string = 'images/assets/nur.png'; // Replace with the actual path to your bot image
  userImage: string = 'images/assets/user.png'; // Replace with the actual path to your user image
  isBotActive: boolean = true;
  voicesLoaded: boolean = false;
  clarificationQueue: { name: string, question: string }[] = [];
  isMuted: boolean = false;

  constructor(private http: HttpClient,
    public dialogRef: MatDialogRef<ChatbotComponent>, // Inject MatDialogRef
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  @Output() onClose = new EventEmitter<any>(true);
  
  ngOnInit() {
    this.isBotActive = true;
    this.loadVoices().then(() => {
      this.voicesLoaded = true;
      this.welcomeUser();
    });
  }

  welcomeUser() {
    setTimeout(() => {
      this.messages.push({ sender: 'bot', text: '', botImage: this.botImage, typing: true });
      setTimeout(() => {
        this.typewriterEffect("Welcome to the Mahatta Art Platform.", () => {
          this.speakText("Welcome to the Mahatta Art Platform.");
          setTimeout(() => {
            this.messages.push({ sender: 'bot', text: '', botImage: this.botImage, typing: true });
            setTimeout(() => {
              this.typewriterEffect("Hello, I am Nur, your AI assistant for planning, resourcing and tracking your creative stories?", () => {
                this.speakText("Hello, I am Nur, your AI assistant. How can I help?");
                this.isBotActive = false;
              });
            }, 1000);
          }, 500);
        });
      }, 1000);
    }, 500);
  }

  sendMessage() {
    this.isBotActive = true; 
    if (!this.userMessage.trim()) return;

    this.messages.push({ sender: 'user', text: this.userMessage, userImage: this.userImage });
    this.messages.push({ sender: 'bot', text: '', botImage: this.botImage, typing: true });

    if (this.clarificationQueue.length > 0) {
      this.processClarifications();
      return;
    }

    this.http.post<{ clarifications: { name: string, question: string }[] }>('https://devapi.studioip.in/v1/clarification', { content: this.userMessage + ' ' + "List clarifications as {clarifications:[{hint:'', question:''}]}", model: 'gpt-4o-mini'}).subscribe(
      (data) => {
        if (data.clarifications && data.clarifications.length > 0) {
          this.clarificationQueue = data.clarifications;
          this.processClarifications();
        } else {
          this.handleBotResponse("I'm not sure how to respond to that.");
        }
      },
      (error) => {
        console.error('API request failed, using mock response', error);
        const mockResponses = [
          "Hello! How can I assist you today?",
          "That's an interesting question!",
          "Can you please elaborate?",
          "I'm here to help!",
          "Let me think... Okay, here's my answer."
        ];
        const aiResponse = mockResponses[Math.floor(Math.random() * mockResponses.length)];
        this.handleBotResponse(aiResponse);
      }
    );

    this.userMessage=null;

  }

  processClarifications() {
    this.userMessage = ''; 
    if (this.clarificationQueue.length > 0) {
      const clarification = this.clarificationQueue.shift();
      if (clarification) {
        this.handleBotResponse(clarification.question);
      }
    } else {
      this.isBotActive = false;
    }
  }

  handleBotResponse(aiResponse: string) {
    const botMessage = this.messages.find(m => m.typing);
    if (botMessage) botMessage.text = '';
  
    setTimeout(() => {
      this.speakText(aiResponse);
      this.typewriterEffect(aiResponse, () => {
        this.isBotActive = false;
      });
    }, 1000);
  }

  typewriterEffect(text: string, callback?: () => void) {
    let index = 0;
    let typedText = '';
    const botMessage = this.messages.find(m => m.typing);
    
    if (!botMessage) return;
    botMessage.typing = false;
    botMessage.text = '';
    botMessage.typing = false;

    const interval = setInterval(() => {
      typedText += text[index];
      botMessage.text = typedText;
      index++;

      if (index === text.length) {
        clearInterval(interval);
        botMessage.typing = false;
        this.isBotActive = false; 
        if (callback) callback();
      }
    }, 50);
  }

  toggleMute() {
    this.isMuted = !this.isMuted;
  }
  
  loadVoices(): Promise<void> { return new Promise((resolve) => { 
    const voices = window.speechSynthesis.getVoices(); 
    if (voices.length !== 0) 
      { resolve(); } 
    else 
      { window.speechSynthesis.onvoiceschanged = () => { resolve(); }; } }); 
  }

  speakText(text: string) {
    if (this.isMuted) return;
    const speech = new SpeechSynthesisUtterance(text);
    speech.lang = 'en-US';
    speech.rate = 1;
    speech.pitch = 1.2; 
    
    const voices = window.speechSynthesis.getVoices();
    const femaleVoice = voices.find(voice => voice.lang === 'en-GB' && (voice.name.includes('Female') || voice.name.includes('Google UK English Female')));
    if (femaleVoice) {
      speech.voice = femaleVoice;
    } else {
      const fallbackVoice = voices.find(voice => voice.name.includes('Female'));
      if (fallbackVoice) {
        speech.voice = fallbackVoice;
      }
    }
    
    window.speechSynthesis.speak(speech);
  }

  closeChatbot() {
    this.dialogRef.close(); // Close the MatDialog
  }
  


}
