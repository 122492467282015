import {Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
// import {ForgotPasswordComponent} from '../../shared/forgot-password/forgot-password.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, Observable} from 'rxjs';
import {ContentType} from '../../admin/models/art_set_master.model';
import {OrderService} from '../../admin/services/order.service';
import {AppService} from '../../app.service';
import {Order} from '../../admin/models/order.model';
import {AuthenticationService} from '../../admin/services/authentication.service';
import {User} from '../../admin/models/user.model';


@Component({
    selector: 'app-preview-dialog',
    templateUrl: './preview-dialog.component.html',
    styleUrls: ['./preview-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PreviewDialogComponent implements OnInit {

    @Output() onClose = new EventEmitter<any>(true);
    @Output() onReset = new EventEmitter<any>(true);
    form: FormGroup;

    private loadingSubject: BehaviorSubject<boolean>;
    public loading$: Observable<boolean>;
    public statuses = [];
    public couriers = [];
    public currentUser: User;

    constructor(
        public formBuilder: FormBuilder,
        public router: Router,
        private route: ActivatedRoute,
        public dialogRef: MatDialogRef<PreviewDialogComponent>,
        public matSnackBar: MatSnackBar,
        private orderService: OrderService,
        private appService: AppService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private authenticationService: AuthenticationService
    ) {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
        this.loading$ = this.loadingSubject.asObservable();
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit() {
    }

    public close(): void {
        this.dialogRef.close();
    }
}
