import { CustomizedProduct } from "./customized_product.model";

export class Cart {
  id?: number = 0;
  isCartUpdated:0;
  cart_id: number;
  customized_product_id: number;
  customer_id: number;
  session_id: string;
  message:string;
  customizedProduct: CustomizedProduct = new CustomizedProduct();
  // frame_id: number;
  // frame_rate: number;
  // frame_size: number;
  // mount_id_1: number;
  // mount_rate_1: number;
  // mount_size_1: number;
  // mount_id_2: number;
  // mount_rate_2: number;
  // mount_size_2: number;
  // mount_id_3: number;
  // mount_rate_3: number;
  // mount_size_3: number;
  // paper_id: number;
  // print_rate: number;
  // canvas_id: number;
  // canvas_rate: number;
  // glass_id: number;
  // glass_rate: number;
  // image_detail: string[];

  public constructor() {
  }
}
