import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {emailValidator} from '../../theme/utils/app-validators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthenticationService} from 'src/app/admin/services/authentication.service';
import {I18nSelectPipe} from '@angular/common';
import {BehaviorSubject, forkJoin, Observable, of, Subscription, timer} from 'rxjs';
import {catchError, finalize, take} from 'rxjs/operators';
import {environment, messages} from 'src/environments/environment';
// import {Gender} from '../../admin/models/gender.model';
// import {Region} from '../../admin/models/region.model';
// import {CustomerType} from '../../admin/models/customer_type.model';
import {Profession} from '../../admin/models/profession.model';
import {Designation} from '../../admin/models/designation.model';
import {Requirement} from '../../admin/models/requirement.model';
import {Purpose} from '../../admin/models/purpose.model';
import {City} from '../../admin/models/city.model';
import {User} from '../../admin/models/user.model';
import {CustomerService} from '../../admin/services/customer.service';
// import {GenderService} from '../../admin/services/gender.service';
import {DesignationService} from '../../admin/services/designation.service';
import {CityService} from '../../admin/services/city.service';
// import {CustomerTypeService} from '../../admin/services/customer_type.service';
import {ProfessionService} from '../../admin/services/profession.service';
// import {RegionService} from '../../admin/services/region.service';
import {RequirementService} from '../../admin/services/requirement.service';
import {PurposeService} from '../../admin/services/purpose.service';
import {UserService} from '../../admin/services/user.service';
import {Customer} from '../../admin/models/customer.model';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
// import {GoogleLoginProvider, SocialAuthService} from 'angularx-social-login';
import {AppService} from '../../app.service';
import {CartService} from '../../admin/services/cart.service';
import {WishlistItemService} from '../../admin/services/wishlist_item.service';
import {SignInDialogComponent} from '../sign-in-dialog/sign-in-dialog.component';
import {Media} from '../../admin/models/media.model';
import {GtmService} from '../../gtm.service';

declare let gtag_report_conversion: Function;


@Component({
    selector: 'app-sign-up-dialog',
    templateUrl: './sign-up-dialog.component.html',
    styleUrls: ['./sign-up-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SignUpDialogComponent implements OnInit {
    @Output() onClose = new EventEmitter<any>(true);

    private loadingSubject: BehaviorSubject<boolean>;
    public loading$: Observable<boolean>;

    public form: FormGroup;
    public professions: Profession[];
    public designations: Designation[];
    public requirements: Requirement[];
    public purposes: Purpose[];

    private cityLoadingSubject: BehaviorSubject<boolean>;
    public cityLoading$: Observable<boolean>;
    public cities: City[] = [];
    public employees: User[] = [];
    public managers: User[] = [];
    private ipAddress: string;
    setting: Media;
    countDown: Subscription;
    counter = 0;
    tick = 1000;

    constructor(
        // public formBuilder: FormBuilder,
        // public router:Router,
        private route: ActivatedRoute,
        public snackbar: MatSnackBar,
        private authenticationService: AuthenticationService,
        private i18nSelectPipe: I18nSelectPipe,
        public customerService: CustomerService,
        public designationService: DesignationService,
        public cityService: CityService,
        public professionService: ProfessionService,
        public requirementService: RequirementService,
        public gtmService: GtmService,
        public userService: UserService,
        public formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private matSnackBar: MatSnackBar,
        public dialogRef: MatDialogRef<SignUpDialogComponent>,
        private router: Router,
        private appService: AppService,
        public cartService: CartService, public wishlistItemService: WishlistItemService,
        public dialog: MatDialog
    ) {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
        this.loading$ = this.loadingSubject.asObservable();

        this.cityLoadingSubject = new BehaviorSubject<boolean>(false);
        this.cityLoading$ = this.cityLoadingSubject.asObservable();
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            id: 0,
            email: ['', Validators.compose([Validators.required, emailValidator])],
            password: [''],
            firstname: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z \-\']+')])],
            lastname: [''],
            gender_id: [''],
            city: [''],
            address: [''],
            zipcode: ['', Validators.compose([Validators.pattern('^(\\d{4}|\\d{6})$')]) ],
            customer_type_id: ['9'],
            region_id: ['1'],
            contact: ['', Validators.compose([Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern('^[0-9]*$')])],

            requirement_id: ['', Validators.required],
            purpose_id: [''],
            otp: ['', Validators.required],
            company_name: [''],
            profession_id: [''],
            designation_id: [''],
            registered_from: ['Online'],
            lead_gen_from: ['Direct'],
            job_description: [''],
            client_handled_by: ['0'],
            created_by: ['1'],
            relationship_manager: ['0'],
            status: true
        });

        // this.form
        //     .get('city')
        //     .valueChanges
        //     .pipe(
        //         debounceTime(300),
        //         tap(() => this.cityLoadingSubject.next(true)),
        //         switchMap(value => this.cityService.findCities(value)
        //             .pipe(
        //                 finalize(() => this.cityLoadingSubject.next(false)),
        //             )
        //         )
        //     )
        //     .subscribe(res => {
        //         this.cities = res['data'];
        //     });

        // let _genderService = this.genderService.filter('[]', '[]',
        //     0, -1);
        // let _regionService = this.regionService.filter('[]', '[]',
        //     0, -1);
        let _requirementService = this.requirementService.filter('[]', '[]',
            0, -1);
        // let _purposeService = this.purposeService.filter('[]', '[]',
        //     0, -1);
        // // let _customerTypeService = this.customerTypeService.filter('[]', '[]',
        // //     0, -1);
        // let _professionService = this.professionService.filter('[]', '[]',
        //     0, -1);
        // let _designationService = this.designationService.filter('[]', '[]',
        //     0, -1);

        // const employeeFilter = [];
        // const managerFilter = [];
        //
        // employeeFilter.push(['role_id', '=', 3]);
        // managerFilter.push(['role_id', '=', 5]);

        // let _employeeUserService = this.userService.filter(JSON.stringify(employeeFilter), '[]',
        //     0, -1);
        // let _managerUserService = this.userService.filter(JSON.stringify(managerFilter), '[]',
        //     0, -1);

        this.loadingSubject.next(true);
        this.form.disable();
        // , _purposeService, _professionService, _designationService, _employeeUserService, _managerUserService
        forkJoin(_requirementService)
            .pipe(
                catchError(() => of([])),
                finalize(() => {
                })
            )
            .subscribe((res) => {

                this.requirements = res[0]['data'];
                // this.purposes = res[1]['data'];
                // this.professions = res[2]['data'];
                // this.designations = res[3]['data'];
                // this.employees = res[4]['data'];
                // this.managers = res[5]['data'];

                this.activatedRoute.params.subscribe(params => {
                    if (params.id && params.id > 0) {
                        this.customerService.find(params.id).subscribe((res: any) => {
                            let data = res['data'];
                            this.form.patchValue(data);

                            this.loadingSubject.next(false);
                            this.form.enable();
                        });
                    } else {
                        this.loadingSubject.next(false);
                        this.form.enable();
                    }
                });

            });
        this.setting = this.appService.Data.setting?.registrationOffer[0];
    }

    public onSubmit() {
        console.log(this.form.valid);
        if (this.form.valid) {
            let element: Customer = new Customer();
            this.form.value.city_id = this.form.value.city && this.form.value.city.id > 0 ? this.form.value.city.id : 0;
            if (this.form.value.purpose_id != 3) {
                this.form.value.customer_type_id = 1;
            } else {
                this.form.value.customer_type_id = 2;
            }
            element = Object.assign({}, element, this.form.value);
            delete element.city;
            element.session_id = this.cartService.getSessionId();
            element.utm_link = this.authenticationService.getUtmLink();
            this.create(element);
        }
    }

    create(element) {
        this.loadingSubject.next(true);
        element.lead_referrer_url = document.referrer;
        this.form.disable();
        this.customerService.add(element)
            .pipe(
                finalize(() => {
                    this.loadingSubject.next(false);
                    this.form.enable();
                })
            )
            .subscribe(
                res => {
                    const customer: Customer = res;

                    const requirementId = Number(this.form.value['requirement_id']);
                    const product: any = this.requirements?.find(item => item.id === requirementId);
                    
                    let data={
                        "id":customer.user?.id,
                        "name":this.form.value['firstname'],
                        "email":this.form.value['email'],
                        "phone_no":this.form.value['contact'],
                        "looking_for":product?.name
                    }

                    this.gtmService.signUpGTMCart('sign_up',data);
                    // this.form.get('id').setValue(customer.id);
                    this.loadingSubject.next(false);
                    this.matSnackBar.open('Thank you for registering. Your registration has been received, thanks!!', 'x', {
                        duration: 2000,
                        verticalPosition: 'top',
                        panelClass: ['success']
                    });
                    // console.log(customer.user);
                    this.authenticationService.registerLogin(customer.user);
                    this.cartService.refreshCount();
                    this.wishlistItemService.refreshCount();
                    this.dialogRef.close();
                    if (environment.production) {
                        // gtag_report_conversion();
                        const product = this.requirements.filter(item=> item.id == this.form.value['requirement_id']);
                        this.gtmService.gtmFormSubmission(this.form.value['firstname'] , this.form.value['email'],
                            this.form.value['contact'], 'signup-form', product);
                    }

                    if (this.router.url == '/sign-up') {
                        this.router.navigate(['/cart-view', 2]);
                    }
                },
                err => {
                    for (let key of Object.keys(err.errors)) {
                        const formControl = this.form.get(key);
                        if (formControl) {
                            // activate the error message
                            let errorMsg = this.i18nSelectPipe.transform(err.errors[key], this.messages);
                            setTimeout(() => {
                                formControl.setErrors({
                                    serverError: errorMsg
                                });
                            });
                        }
                    }

                }
            );
    }


    displayFn(city: City) {
        if (city && city.name) {
            return city.name + ' (' + city.state.name + ', ' + city.state.country.name + ')';
        } else {
            return '';
        }
    }

    getErrors(ctrl) {
        return Object.keys(ctrl.errors);
    }


    private messages = messages;

    get message() {
        return this.message;
    }


    public close(): void {
        this.dialogRef.close();
    }

    getIP() {
        this.appService.getIPAddress().subscribe((res: any) => {
            this.ipAddress = res.ip;
        });
    }

    // signInWithGoogle(): void {
    //     this.loadingSubject.next(true);
    //     this.form.disable();
    //     this.OAuth.signIn(GoogleLoginProvider.PROVIDER_ID).then(socialusers => {
    //         this.getIP();
    //
    //         this.authenticationService.googleSignIn(socialusers['firstName'], socialusers['lastName'], socialusers['authToken'],
    //             socialusers['profileUrl'], this.ipAddress, this.cartService.getSessionId())
    //             .pipe(
    //                 // catchError(() => of([])),
    //                 finalize(() => {
    //                     this.loadingSubject.next(false);
    //                     this.form.enable();
    //                 })
    //             )
    //             .subscribe(
    //                 res => {
    //
    //                     this.onClose.emit();
    //                     this.cartService.refreshCount();
    //                     this.wishlistItemService.refreshCount();
    //                 },
    //                 err => {
    //                     console.log('error: ', err);
    //                     // this.errorSubject.next(error);
    //                     // this.loadingSubject.next(false);
    //                     this.snackbar.open(this.i18nSelectPipe.transform(err, this.messages), 'Ok', {
    //                         duration: 10000,
    //                         verticalPosition: 'top',
    //                         panelClass: ['error']
    //                     });
    //                 });
    //
    //     });
    // }


    public login(): void {
        this.dialogRef.close();
        // this.router.navigate(['/sign-up']);
        const dialogRef = this.dialog.open(SignInDialogComponent, {
            panelClass: 'sign-up-dialog-container',
            direction: 'ltr'
        });

    }


    sendOtp() {
        this.loadingSubject.next(true);
        const element = {mobile_no: this.form.controls.contact.value, type: 'register'};
        this.form.disable();
        this.appService.generateOtp(element)
            .pipe(
                finalize(() => {
                    this.loadingSubject.next(false);
                    this.form.enable();
                })
            )
            .subscribe(
                res => {
                    this.loadingSubject.next(false);
                    this.matSnackBar.open('OTP sent successfully on mobile!!', 'x', {
                        duration: 2000,
                        verticalPosition: 'top',
                        panelClass: ['success']
                    });
                    this.counter = 30;
                    this.countDown = timer(0, this.tick)
                        .pipe(take(this.counter))
                        .subscribe(() => {
                            --this.counter;
                            // console.log(this.counter);
                            if (this.counter == 0) {
                                this.countDown.unsubscribe();
                            }
                        });
                },
                err => {

                    console.log('error: ', err);
                    // this.errorSubject.next(error);
                    // this.loadingSubject.next(false);
                    this.snackbar.open('Mobile no. already exist', 'Ok', {
                        duration: 10000,
                        verticalPosition: 'top',
                        panelClass: ['error']
                    });
                });
    }

    transform(value: number): string {
        const minutes: number = Math.floor(value / 60);
        return (
            ('00' + Math.floor(value - minutes * 60)).slice(-2)
        );
    }
}
