import {BrowserModule, Meta} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgxSpinnerModule} from 'ngx-spinner';
import {AgmCoreModule} from '@agm/core';

import {OverlayContainer, Overlay} from '@angular/cdk/overlay';
import {MAT_MENU_SCROLL_STRATEGY} from '@angular/material/menu';
import {CustomOverlayContainer} from './theme/utils/custom-overlay-container';
import {menuScrollStrategy} from './theme/utils/scroll-strategy';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {SharedModule} from './shared/shared.module';
import {AppRoutingModule} from './app.routing';
import {AppComponent} from './app.component';
import {PagesComponent} from './pages/pages.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {TopMenuComponent} from './theme/components/top-menu/top-menu.component';
import {MenuComponent} from './theme/components/menu/menu.component';
import {SidenavMenuComponent} from './theme/components/sidenav-menu/sidenav-menu.component';
import {BreadcrumbComponent} from './theme/components/breadcrumb/breadcrumb.component';

import {AppSettings} from './app.settings';
import {AppService} from './app.service';
import {TopbarNavbarService} from './topbar-navbar.service';
import {AppInterceptor} from './theme/utils/app-interceptor';
import {OptionsComponent} from './theme/components/options/options.component';
import {FooterComponent} from './theme/components/footer/footer.component';
import {JwtInterceptor} from './jwt.interceptor';
import {CommonModule, I18nSelectPipe} from '@angular/common';
import {ErrorPageComponent} from './pages/error-page/error-page.component';
// import {
//     GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule,
// } from 'angularx-social-login';
// import {GoogleTagManagerModule} from 'angular-google-tag-manager';
import {MatBadgeModule} from '@angular/material/badge';
import {ShareService} from 'ngx-sharebuttons';
import {ShareButtonsModule} from 'ngx-sharebuttons/buttons';
import {ShareIconsModule} from 'ngx-sharebuttons/icons';
import {NgxImageZoomModule} from 'ngx-image-zoom';
// import {NgxJsonLdModule} from '@ngx-lite/json-ld';
import {DragDropModule} from '@angular/cdk/drag-drop';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { ImageCropperModule } from 'ngx-image-cropper';
import {GoogleTagManagerModule} from 'angular-google-tag-manager';
import {ExportService} from "./export.service";
import { ChatbotComponent } from './shared/chatbot/chatbot.component';
import { ChangeRoomComponent } from './pages/wallpaper-customization/change-room/change-room.component';
import { SpacesComponent } from './pages/wallpaper-customization/spaces/spaces.component';

@NgModule({
    imports: [
        BrowserModule.withServerTransition({appId: 'my-app'}),
        BrowserAnimationsModule,
        HttpClientModule,
        NgxSpinnerModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyA1rF9bttCxRmsNdZYjW7FzIoyrul5jb-s'
        }),
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        AppRoutingModule,
        CommonModule,
        // SocialLoginModule,
        // GoogleTagManagerModule.forRoot({
        //     // id: 'GTM-PXV8FW5',
        //     id: 'GTM-5DZ3TTQ',
        //     // gtm_auth: YOUR_GTM_AUTH,
        //     // gtm_preview: YOUR_GTM_ENV
        // }),
        MatBadgeModule,
        ShareButtonsModule.withConfig({
            debug: false
        }),
        ShareIconsModule,
        NgxImageZoomModule,
        // NgxJsonLdModule,
        DragDropModule
        // NgbModule

    ],
    declarations: [
        AppComponent,
        PagesComponent,
        NotFoundComponent,
        ErrorPageComponent,
        TopMenuComponent,
        MenuComponent,
        SidenavMenuComponent,
        BreadcrumbComponent,
        OptionsComponent,
        FooterComponent,
        ChatbotComponent,
        ChangeRoomComponent,
        SpacesComponent
    ],
    providers: [
        // { provide: 'googleTagManagerCSPNonce', useValue: 'CSP-NONCE' },
        // {
        //     // '1091183752752-2phimdsshdnkqldfnaj3p5hbq434smnq.apps.googleusercontent.com'
        //     provide: 'SocialAuthServiceConfig',
        //     useValue: {
        //         autoLogin: false,
        //         providers: [
        //             {
        //                 id: GoogleLoginProvider.PROVIDER_ID,
        //                 provider: new GoogleLoginProvider(
        //                     '682112098424-ejkuh875fte3hl7hm4588vcp08vb77ad.apps.googleusercontent.com'
        //                 )
        //             }
        //         ]
        //     } as SocialAuthServiceConfig,
        // },
        AppSettings,
        AppService,
        TopbarNavbarService,
        I18nSelectPipe,
        ShareService,
        {provide: OverlayContainer, useClass: CustomOverlayContainer},
        {provide: MAT_MENU_SCROLL_STRATEGY, useFactory: menuScrollStrategy, deps: [Overlay]},
        {provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        Meta,
        ExportService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
