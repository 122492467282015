import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Cart} from '../models/cart.model';
import {environment} from '../../../environments/environment';
import {AuthenticationService} from './authentication.service';
import {CustomizedProduct} from '../models/customized_product.model';
import {v4 as uuidv4} from 'uuid';
import {CustomerService} from "./customer.service";


@Injectable({
    providedIn: 'root'
})
export class CartService {
    private apiUrlAuth = '';
    private apiUrlUnAuth = '';

    private countSubject: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
    private cashPointSubject: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
    public count$: Observable<number> = this.countSubject.asObservable();
    public cashPoint$: Observable<number> = this.cashPointSubject.asObservable();
    public cartList: Cart[] = [];

    public getSessionId() {
        const data = localStorage.getItem('McartSession');
        if (data != null) {
            return data;
        } else {
            return '';
        }
    }

    private generateRandomId() {
        let uuid = uuidv4();
        return uuid;
    }

    public addToCartGuest(element: Cart): Observable<Cart> {
        // const data = localStorage.getItem('Mcart');
        // if(data!=null) {
        //     this.cartList = JSON.parse(data);
        // }
        // this.cartList.push(cart);
        // localStorage.setItem('Mcart', JSON.stringify(this.cartList));
        // this.refreshCount();

        console.log('in cartservice');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        const url = this.apiUrlUnAuth + '/cart_guest';
        const data = localStorage.getItem('McartSession');
        if (data != null) {
            element.session_id = localStorage.getItem('McartSession');
        } else {
            localStorage.setItem('McartSession', this.generateRandomId());
            element.session_id = localStorage.getItem('McartSession');
        }
        return this.http.post<Cart>(url, element, httpOptions)
            .pipe(
                map(res => {
                    this.refreshCount();
                    return res;
                }),
                catchError(this.handleError('getCartGuest', element))
            );
    }

    // public removeFromCart(index) {
    //     this.cartList.splice(index, 1);
    //     localStorage.setItem('Mcart', JSON.stringify(this.cartList));
    //     this.refreshCount();
    // }

    public loadCart() {
        // const data = localStorage.getItem('Mcart');
        // this.cartList = JSON.parse(data);
        // if (this.cartList == null) {
        //     this.cartList = [];
        // }
        this.refreshCount();
        this.getCustomerCashPoints();
    }

    getCustomerCashPoints() {
        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            this.customerService.getCashPoint().subscribe((res: any) => {
                this.cashPointSubject.next(res['data']);

            });
        }else{
            this.cashPointSubject.next(0);

        }
    }

    constructor(private http: HttpClient, public authenticationService: AuthenticationService, public customerService: CustomerService) {
        this.apiUrlAuth = environment.apiUrlauth;
        this.apiUrlUnAuth = environment.apiUrlUnauth;
    }

    public refreshCount() {
        const filter = [];
        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            filter.push(['customer_id', '=', this.authenticationService.currentUserValue.id]);
        } else {
            // this.countSubject.next(this.cartList.length);
            filter.push(['session_id', '=', this.getSessionId()]);
        }
        this.filter(JSON.stringify(filter), '[]', 0, -1).subscribe(res => {
        });
    }

    public resetCount() {
        this.cartList = [];
        this.countSubject.next(0);
    }

    filter(filter = '[]', orderBy = '[]', pageNumber = 0, pageSize = 10, purchaseType: string = 'Inventory'): Observable<Cart[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('filter', filter);
        httpParams = httpParams.append('purchaseType', purchaseType);
        if (orderBy || orderBy != '') {
            httpParams = httpParams.append('orderBy', orderBy);
        }
        httpParams = httpParams.append('pageNumber', pageNumber.toString());
        if (pageSize || pageSize > 0) {
            httpParams = httpParams.append('pageSize', pageSize.toString());
        }

        let $url = this.apiUrlUnAuth;
        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            $url = this.apiUrlAuth;
        }
        return this.http.get<Cart[]>($url + '/cart', {
            params: httpParams
        })
            .pipe(
                map(res => {
                    this.countSubject.next(res['data'].length);

                    return res;
                }),
                catchError(this.handleError('filter', []))
            );
    }

    find(id = 0): Observable<Cart> {
        let httpParams = new HttpParams();
        return this.http.get<Cart>(this.apiUrlAuth + '/cart/' + id, {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('find', null))
            );
    }

    findShare(id = ''): Observable<Cart> {
        let httpParams = new HttpParams();
        return this.http.get<Cart>(this.apiUrlUnAuth + '/sharelink/' + id, {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('findshare', null))
            );
    }


    getProductPrice(product_id = 0, product_type = 0, paper_id = 0, frame_id = 0, mount_id_1 = 0, mount_id_2 = 0, mount_id_3 = 0, mount_size_1 = 0, mount_size_2 = 0, mount_size_3 = 0, glass_id = 0, image_size = '', quality_grade = '', table_frame = 0): Observable<any> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('product_id', product_id.toString());
        httpParams = httpParams.append('product_type', product_type.toString());
        httpParams = httpParams.append('paper_id', paper_id.toString());
        httpParams = httpParams.append('frame_id', frame_id.toString());
        httpParams = httpParams.append('mount_id_1', mount_id_1.toString());
        httpParams = httpParams.append('mount_id_2', mount_id_2.toString());
        httpParams = httpParams.append('mount_id_3', mount_id_3.toString());
        httpParams = httpParams.append('mount_size_1', mount_size_1.toString());
        httpParams = httpParams.append('mount_size_2', mount_size_2.toString());
        httpParams = httpParams.append('mount_size_3', mount_size_3.toString());
        httpParams = httpParams.append('glass_id', glass_id.toString());
        httpParams = httpParams.append('image_size', image_size.toString());
        httpParams = httpParams.append('quality_grade', quality_grade.toString());
        httpParams = httpParams.append('is_table_frame', table_frame.toString());

        return this.http.get<any>(this.apiUrlUnAuth + '/product_price', {
            params: httpParams
        })
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError('filter', []))
            );
    }

    calculatePrice(element: Cart): Observable<any[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.post<any>(this.apiUrlAuth + '/calculatePrice', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('calculatePrice', element))
            );
    }


    add(element: Cart): Observable<Cart> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.post<Cart>(this.apiUrlAuth + '/cart', element, httpOptions)
            .pipe(
                map(res => {
                    this.refreshCount();
                    return res;
                }),
                catchError(this.handleError('getCart', element))
            );
    }

    share(element: Cart): Observable<Cart> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.post<Cart>(this.apiUrlUnAuth + '/share', element, httpOptions)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError('getCart', element))
            );
    }

    update(element: Cart): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<Cart>(this.apiUrlAuth + '/cart', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getCart', element))
            );
    }

    updateCustomizedProductQuantity(customized_product_id: number, quantity: number, promo_id: number = 0): Observable<CustomizedProduct> {
        const element: any = {
            customized_product_id: customized_product_id,
            quantity: quantity,
            promo_id: promo_id,
            session_id: this.getSessionId()
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        let $url = this.apiUrlUnAuth;
        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            $url = this.apiUrlAuth;
        }
        return this.http.put<CustomizedProduct>($url + '/updateCustomizedProductQuantity', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('updateCustomizedProductQuantity', element))
            );
    }

    updateGiftWrap(customized_product_id: number, gift_wrap: number): Observable<CustomizedProduct> {
        const element: any = {
            customized_product_id: customized_product_id,
            gift_wrap: gift_wrap,
            session_id: this.getSessionId()
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        let $url = this.apiUrlUnAuth;
        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            $url = this.apiUrlAuth;
        }
        return this.http.put<CustomizedProduct>($url + '/updateGiftWrap', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('updateGiftWrap', element))
            );
    }

    updateInstallation(customized_product_id: number, pincode: string): Observable<CustomizedProduct> {
        const element: any = {
            customized_product_id: customized_product_id,
            installation_pincode: pincode,
            session_id: this.getSessionId()
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        let $url = this.apiUrlUnAuth;
        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            $url = this.apiUrlAuth;
        }
        return this.http.put<CustomizedProduct>($url + '/updateInstallation', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('updateInstallation', element))
            );
    }

    updateGiftMessage(customized_product_id: number, gift_message: string): Observable<CustomizedProduct> {
        const element: any = {
            customized_product_id: customized_product_id,
            gift_message: gift_message,
            session_id: this.getSessionId()
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        let $url = this.apiUrlUnAuth;
        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            $url = this.apiUrlAuth;
        }
        return this.http.put<CustomizedProduct>($url + '/updateGiftMessage', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('updateGiftMessage', element))
            );
    }


    addOnSaleItemToCart(on_sale_id: number, customer_id: number, image_path: string = '', width = 1, height = 1, installation_pincode = 0): Observable<Cart> {
        const element: any = {
            on_sale_id: on_sale_id,
            customer_id: customer_id,
            image_path,
            width,
            height,
            installation_pincode
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.post<Cart>(this.apiUrlAuth + '/addOnSaleItemToCart', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('addOnSaleItemToCart', element))
            );
    }

    fillSaleItemToCart(on_sale_id: number, image_path: string = '', width = 1, height = 1, installation_pincode = 0): Observable<Cart> {
        const element: any = {
            on_sale_id: on_sale_id,
            image_path,
            width,
            height,
            installation_pincode
        };
        const data = localStorage.getItem('McartSession');
        if (data != null) {
            element.session_id = localStorage.getItem('McartSession');
        } else {
            localStorage.setItem('McartSession', this.generateRandomId());
            element.session_id = localStorage.getItem('McartSession');
        }
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.post<Cart>(this.apiUrlUnAuth + '/fillSaleItemToCart', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('fillSaleItemToCart', element))
            );
    }

    updateStatus(id: number, status: number): Observable<any> {
        const element: any = {
            id: id,
            status: status,
            form: 'cart'
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<Cart>(this.apiUrlAuth + '/status_change', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getBasicPaint', element))
            );
    }

    delete(ids = '[]'): Observable<any> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('ids', ids);
        return this.http.delete<any>(this.apiUrlAuth + '/cart', {
            params: httpParams
        })
            .pipe(
                map(res => {
                    this.refreshCount();
                    return res;
                }),
                catchError(this.handleError('deleteCart', null))
            );
    }

    deleteGuest(ids = '[]', session_id: string): Observable<any> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('ids', ids);
        httpParams = httpParams.append('session_id', session_id);
        return this.http.delete<any>(this.apiUrlUnAuth + '/cart-guest', {
            params: httpParams
        })
            .pipe(
                map(res => {
                    this.refreshCount();
                    return res;
                }),
                catchError(this.handleError('deleteCart', null))
            );
    }


    productView(element: Cart): Observable<Cart> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.post<Cart>(this.apiUrlUnAuth + '/product_click', element, httpOptions)
            .pipe(
                map(res => {
                    this.refreshCount();
                    return res;
                }),
                catchError(this.handleError('getCart', element))
            );
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

}
