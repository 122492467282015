import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {User} from '../models/user.model';
import {environment} from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl = '';

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrlUnauth;
  }

  filter(filter = '[]', orderBy = '[]', pageNumber = 0, pageSize = 10): Observable<User[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('filter', filter);
    if (orderBy || orderBy != '') {
      httpParams = httpParams.append('orderBy', orderBy);
    }
    httpParams = httpParams.append('pageNumber', pageNumber.toString());
    if (pageSize || pageSize > 0) {
      httpParams = httpParams.append('pageSize', pageSize.toString());
    }
    return this.http.get<User[]>(this.apiUrl + '/user', {
        params: httpParams
      })
      .pipe(
        map(res =>  res),
        catchError(this.handleError('filter', []))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
