import {Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {emailValidator} from '../../theme/utils/app-validators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AuthenticationService} from 'src/app/admin/services/authentication.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {debounceTime, finalize, switchMap, tap} from 'rxjs/operators';
import {messages} from 'src/environments/environment';
// import {CustomerRequireMatch} from 'src/app/admin/customers/customer-create-update/customer-create-update.component';
import {CityService} from 'src/app/admin/services/city.service';
import {City} from 'src/app/admin/models/city.model';
import {CustomerAddressService} from 'src/app/admin/services/customer_address.service';
import {CustomerAddress} from 'src/app/admin/models/customer_address.model';

export function CustomerRequireMatch(control: AbstractControl) {
    const selection: any = control.value;
    if (typeof selection === 'string') {
        return {incorrect: true};
    }
    return null;
}


@Component({
    selector: 'app-customer-address-dialog',
    templateUrl: './customer-address-dialog.component.html',
    styleUrls: ['./customer-address-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CustomerAddressDialogComponent implements OnInit {
    @Output() onClose = new EventEmitter<any>(true);

    form: FormGroup;

    private loadingSubject: BehaviorSubject<boolean>;
    public loading$: Observable<boolean>;

    private cityLoadingSubject: BehaviorSubject<boolean>;
    public cityLoading$: Observable<boolean>;

    public cities: City[] = [];


    constructor(
        public formBuilder: FormBuilder,
        public router: Router,
        private route: ActivatedRoute,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<CustomerAddressDialogComponent>,
        public cityService: CityService,
        public customerAddressService: CustomerAddressService,
        public authenticationService: AuthenticationService,
        private matSnackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: { customer_address_id: number, user_id: number }
    ) {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
        this.loading$ = this.loadingSubject.asObservable();

        this.cityLoadingSubject = new BehaviorSubject<boolean>(false);
        this.cityLoading$ = this.cityLoadingSubject.asObservable();
    }

    ngOnInit() {
        // Validators.compose([emailValidator])
        this.form = this.formBuilder.group({
            id: 0,
            fullname: ['', Validators.required],
            mobile: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
            email: [''],
            address: ['', Validators.required],
            zipcode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
            city: ['', [Validators.required, CustomerRequireMatch]],
            company_name: [''],
            gst: [''],
            pan: [''],
            otp: ['', this.authenticationService.currentUserValue?.id > 0 ? [] : [Validators.required]],
        });

        this.form
            .get('city')
            .valueChanges
            .pipe(
                debounceTime(300),
                tap(() => this.cityLoadingSubject.next(true)),
                switchMap(value => this.cityService.findCities(value)
                    .pipe(
                        finalize(() => this.cityLoadingSubject.next(false)),
                    )
                )
            )
            .subscribe(res => {
                this.cities = res['data'];
            });

        if (this.data.customer_address_id > 0) {
            this.loadingSubject.next(true);
            this.form.disable();

            this.customerAddressService.find(this.data.customer_address_id).subscribe((res: any) => {
                let data = res['data'];
                this.form.patchValue(data);

                this.loadingSubject.next(false);
                this.form.enable();
            });
        }

    }

    public onSubmit() {
console.log(this.form.valid);
        if (this.form.valid) {
            let element: CustomerAddress = new CustomerAddress();
            this.form.value.city_id = this.form.value.city && this.form.value.city.id > 0 ? this.form.value.city.id : 0;
            if (this.data.user_id) {
                this.form.value.user_id = this.data.user_id;
            } else if (this.authenticationService.currentUserValue?.id) {
                this.form.value.user_id = this.authenticationService.currentUserValue.id;
            } else {
                this.form.value.user_id = 0;
            }
            element = Object.assign({}, element, this.form.value);

            if (element.id === 0) {
                this.create(element);
            } else {
                this.update(element);
            }
        }
    }

    create(element) {
        this.loadingSubject.next(true);
        this.form.disable();
        // if (this.authenticationService.currentUserValue?.id > 0) {
            this.customerAddressService.add(element)
                .pipe(
                    finalize(() => {
                        this.loadingSubject.next(false);
                        this.form.enable();
                    })
                )
                .subscribe(
                    res => {
                        this.loadingSubject.next(false);
                        this.matSnackBar.open('Record has been successfully added!', 'x', {
                            duration: 2000,
                            verticalPosition: 'top',
                            panelClass: ['success']
                        });
                        // this.dialogRef.close();
                        this.onClose.emit();
                    },
                    err => {
                    }
                );
        // } else {
        //
        // }
    }

    update(element) {
        this.loadingSubject.next(true);
        this.form.disable();
        this.customerAddressService.update(element)
            .pipe(
                finalize(() => {
                    this.loadingSubject.next(false);
                    this.form.enable();
                })
            )
            .subscribe(
                res => {
                    this.loadingSubject.next(false);
                    this.form.enable();
                    this.matSnackBar.open('Record has been successfully updated!', 'x', {
                        duration: 2000,
                        verticalPosition: 'top',
                        panelClass: ['success']
                    });
                    // this.dialogRef.close();
                    this.onClose.emit();
                },
                err => {
                    // this.form.enable();
                    // for (let key of Object.keys(err.errors)) {
                    //   const formControl = this.form.get(key);
                    //   if (formControl) {
                    //     // activate the error message
                    //     let errorMsg = this.i18nSelectPipe.transform(err.errors[key], this.messages)
                    //     setTimeout(() => {
                    //       formControl.setErrors({
                    //         serverError: errorMsg
                    //       });
                    //     });
                    //   }
                    // }
                }
            );
    }


    displayFn(city: City) {
        if (city && city.name) {
            return city.name + ' (' + city.state.name + ', ' + city.state.country.name + ')';
        } else {
            return '';
        }
    }

    private messages = messages;

    get message() {
        return this.message;
    }

    public close(): void {
        this.dialogRef.close();
    }

    public sendOtp() {
        let element: CustomerAddress = new CustomerAddress();
        this.form.value.city_id = this.form.value.city && this.form.value.city.id > 0 ? this.form.value.city.id : 0;
        if (this.data.user_id) {
            this.form.value.user_id = this.data.user_id;
        } else if (this.authenticationService.currentUserValue?.id) {
            this.form.value.user_id = this.authenticationService.currentUserValue.id;
        } else {
            this.form.value.user_id = 0;
        }
        element = Object.assign({}, element, this.form.value);

        this.customerAddressService.sendOtp(element)
            .pipe(
                finalize(() => {
                    this.loadingSubject.next(false);
                    this.form.enable();
                })
            )
            .subscribe(
                res => {
                    this.loadingSubject.next(false);
                    this.form.enable();

                },
                err => {
                }
            );
    }
}
