import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {Setting} from '../models/setting.model';
import { Media } from '../models/media.model';
import {environment} from '../../../environments/environment';
import {SeoMeta} from '../../app.models';
@Injectable({
  providedIn: 'root'
})
export class SettingService {
  private apiUrlAuth = '';
  private apiUrlUnAuth = '';

  constructor(private http: HttpClient) {
    this.apiUrlAuth = environment.apiUrlauth;
    this.apiUrlUnAuth = environment.apiUrlUnauth;
  }

  getSettings(): Observable<Setting[]> {
    let httpParams = new HttpParams();
    return this.http.get<Setting[]>(this.apiUrlUnAuth + '/setting', {
        params: httpParams
      })
      .pipe(
        map(res =>  res),
        catchError(this.handleError('filter', []))
      );
  }


  filter(filter = '[]', orderBy = '[]', pageNumber = 0, pageSize = 10): Observable<Setting[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('filter', filter);
    if (orderBy || orderBy != '') {
      httpParams = httpParams.append('orderBy', orderBy);
    }
    httpParams = httpParams.append('pageNumber', pageNumber.toString());
    if (pageSize || pageSize > 0) {
      httpParams = httpParams.append('pageSize', pageSize.toString());
    }
    return this.http.get<Setting[]>(this.apiUrlUnAuth + '/setting', {
        params: httpParams
      })
      .pipe(
        map(res =>  res),
        catchError(this.handleError('filter', []))
      );
  }

  find(id = 0): Observable<Setting> {
    let httpParams = new HttpParams();
    return this.http.get<Setting>(this.apiUrlUnAuth + '/setting/' + id, {
        params: httpParams
      })
      .pipe(
        map(res => res),
        catchError(this.handleError('find', null))
      );
  }

  getChild(route: string): Observable<SeoMeta> {
    route = route.toString().replace(/\//g, "_");
    console.log(route);
    return this.http.get<SeoMeta[]>(this.apiUrlUnAuth + '/meta/' + route).pipe(
        map(res => res),
        catchError(this.handleError('find', null))
    );
  }

  add(element: Setting): Observable<Setting> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Accept: 'application/json, text/plain'
      })
    };

    return this.http.post<Setting>(this.apiUrlAuth + '/setting', element, httpOptions)
      .pipe(
        map(res => res),
        catchError(this.handleError('getSetting', element))
      );
  }

  update(element: Setting): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Accept: 'application/json, text/plain'
      })
    };

    return this.http.put<Setting>(this.apiUrlAuth + '/setting', element, httpOptions)
      .pipe(
        map(res => res),
        catchError(this.handleError('getSetting', element))
      );
  }

  updateMedia(element: Media): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Accept: 'application/json, text/plain'
      })
    };

    return this.http.put<Setting>(this.apiUrlAuth + '/setting_media', element, httpOptions)
      .pipe(
        map(res => res),
        catchError(this.handleError('updateSetting', element))
      );
  }

  delete(ids = '[]'): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ids', ids);
    return this.http.delete<any>(this.apiUrlAuth + '/setting', {
        params: httpParams
      })
      .pipe(
        map(res =>  res),
        catchError(this.handleError('deleteSetting', null))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getHomeSettings(): Observable<Setting[]> {
    let httpParams = new HttpParams();
    return this.http.get<Setting[]>(this.apiUrlUnAuth + '/home-setting', {
      params: httpParams
    })
        .pipe(
            map(res =>  res),
            catchError(this.handleError('filter', []))
        );
  }
}
