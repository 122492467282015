import {Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
// import {ForgotPasswordComponent} from '../../shared/forgot-password/forgot-password.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, Observable} from 'rxjs';
import {ContentType} from '../../models/art_set_master.model';
import {OrderService} from '../../services/order.service';
import {AppService} from '../../../app.service';
import {Order} from '../../models/order.model';


@Component({
    selector: 'app-status-dialog',
    templateUrl: './payment-mark-status-offline-dialog.component.html',
    styleUrls: ['./payment-mark-status-offline-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PaymentMarkStatusOfflineDialogComponent implements OnInit {

    @Output() onClose = new EventEmitter<any>(true);
    @Output() onReset = new EventEmitter<any>(true);
    form: FormGroup;

    private loadingSubject: BehaviorSubject<boolean>;
    public loading$: Observable<boolean>;
    public statuses = [];
    public paymentModes = [];

    constructor(
        public formBuilder: FormBuilder,
        public router: Router,
        private route: ActivatedRoute,
        public dialogRef: MatDialogRef<PaymentMarkStatusOfflineDialogComponent>,
        public matSnackBar: MatSnackBar,
        private orderService: OrderService,
        private appService: AppService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
        this.loading$ = this.loadingSubject.asObservable();
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            payment_status: ['', Validators.required],
            payment_comment: [''],
            payment_mode: ['']
        });
        this.statuses = [
            {id: 2, name: 'Credit'},
            {id: 0, name: 'Prepaid'},
            {id: 1, name: 'Postpaid'}
        ];

        this.paymentModes = [
            {slug: 'online', name: 'Online'},
            {slug: 'offline', name: 'Offline'},
        ];


        if (this.data.order) {
            this.form.patchValue(this.data.order);
            this.form.get('payment_status').setValue(this.data.order.payment_status);
            this.form.get('payment_comment').setValue(this.data.order.payment_comment);
        }
    }

    public onSubmit() {
        // console.log(this.form.value.status);
        if (this.form.valid) {
            let element: ContentType = new ContentType();
            element = Object.assign({}, element, this.form.value);
            this.updatePayment(element);
        }
    }

    updatePayment(element) {
        this.loadingSubject.next(true);
        this.form.disable();
        this.orderService.updatePaymentStatus(this.data.order.id, this.form.value['payment_status'], this.form.value['payment_comment'])
            .subscribe(
                res => {
                    const order: Order = res['data'];
                    this.loadingSubject.next(false);
                    this.onClose.emit();
                    this.form.disable();
                    this.matSnackBar.open('Status updated succesfully!', 'x', {
                        duration: 2000,
                        verticalPosition: 'top',
                        panelClass: ['success']
                    });
                });
    }

    public close(): void {
        this.dialogRef.close();
    }
}
