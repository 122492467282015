import {Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
// import {ForgotPasswordComponent} from '../../shared/forgot-password/forgot-password.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AuthenticationService} from 'src/app/admin/services/authentication.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {OrderService} from '../../services/order.service';
import {User} from '../../models/user.model';
import {Order} from '../../models/order.model';


@Component({
    selector: 'app-tracking-dialog',
    templateUrl: './tracking-dialog.component.html',
    styleUrls: ['./tracking-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TrackingDialogComponent implements OnInit {
    shippingServices = [];
    selectedService: any = {};

    constructor(
        public router: Router,
        private route: ActivatedRoute,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<TrackingDialogComponent>,
        public orderService: OrderService,
        public authenticationService: AuthenticationService,
        private matSnackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: { order: Order, order_type: string }
    ) {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
        this.loading$ = this.loadingSubject.asObservable();
        this.currentUser = authenticationService.currentUserValue;

    }

    get message() {
        return this.message;
    }

    @Output() onClose = new EventEmitter<any>(true);
    @Output() onReset = new EventEmitter<any>(true);

    private loadingSubject: BehaviorSubject<boolean>;
    public loading$: Observable<boolean>;
    currentUser: User;
    trackingHistory = [];
    awb_number:any="";

    ngOnInit() {
        if (this.data.order) {
            this.loadingSubject.next(true);


            this.orderService.trackShipment(this.data.order.id , this.data.order_type).subscribe((res: any) => {
                if (res.message) {
                    this.matSnackBar.open(res.message, 'x', {
                        duration: 2000,
                        verticalPosition: 'top',
                        panelClass: ['error']
                    });
                } else {
                    this.trackingHistory = res.data.data.history;
                    this.awb_number = res.data.data?.awb_number;                    
                }

                this.loadingSubject.next(false);
            });
        }

    }

    public close(): void {
        this.dialogRef.close();
    }

}
