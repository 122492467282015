// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export * from './lang/en/messages';

export const environment = {
        production: false,
        environmentName: 'mirror',
        apiUrlUnauth: 'http://localhost:8000/api/unauth',
        apiUrlauth: 'http://localhost:8000/api/auth',
        frontUrl: 'http://localhost:4200',
        payu: 'https://sboxcheckout-static.citruspay.com/bolt/run/bolt.min.js',
        cdn: 'https://content.mahattaart.com/',
        wallpaperSearch: 'https://betaapi.indiapicture.in/wallsnart-new/',
        inch: 8,
        sampleSize: '8\'\'X11\'\'',
        // offer: '🎁 Buy 1 Get 1 Free',
        flat_discount: '0',
        wallpaper_flat_discount: '0.15',
        space_decor_flat_discount: '0',
        canvas_flat_discount: '0.15',
        art_set_flat_discount: '0.20',
        collage_set_flat_discount: '0.15',
        featured_art: '0.20',
        featured_frame: '0.15',
        offer: '',
        cartGuestEnable: 1,
        shippingFree: [1, 2, 3, 4, 5, 9, 10, 11, 12],
        payuUrl: 'https://test.payu.in/_payment',
        payu_salt: 'UkojH5TS',
        payu_key: 'oZ7oo9',
        contributorUrl : 'http://localhost/indiapicture_api/contributor-profile',

//
// apiUrlUnauth: 'https://mahattaart.com/api/api/unauth',
// apiUrlauth:  'https://mahattaart.com/api/api/auth',
// frontUrl:  'https://mahattaart.com',
// payu: 'https://checkout-static.citruspay.com/bolt/run/bolt.min.js',
// cdn: 'https://content.mahattaart.com/',
// inch: 8
    }

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
