import { Component, ViewEncapsulation, OnInit, Inject, Output, EventEmitter} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import {ForgotPasswordComponent} from '../../shared/forgot-password/forgot-password.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/admin/services/authentication.service';
import { I18nSelectPipe } from '@angular/common';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, debounceTime, tap, switchMap, catchError } from 'rxjs/operators';
import { messages } from 'src/environments/environment';
import { CustomerRequireMatch } from 'src/app/admin/customers/customer-create-update/customer-create-update.component';
import { CityService } from 'src/app/admin/services/city.service';
import { City } from 'src/app/admin/models/city.model';
import { CustomerAddressService } from 'src/app/admin/services/customer_address.service';
import { CustomerAddress } from 'src/app/admin/models/customer_address.model';
import { Wishlist } from 'src/app/admin/models/wishlist.model';
import { WishlistService } from 'src/app/admin/services/wishlist.service';
import Swal from 'sweetalert2';
import { OrderService } from 'src/app/admin/services/order.service';


@Component({
  selector: 'app-cancel-order-dialog',
  templateUrl: './cancel-order-dialog.component.html',
  styleUrls: ['./cancel-order-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CancelOrderDialogComponent implements OnInit {
  @Output() onClose = new EventEmitter<any>(true);

  form: FormGroup;

  private loadingSubject: BehaviorSubject<boolean>;
  public loading$: Observable<boolean>;

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CancelOrderDialogComponent>,
    public wishlistService: WishlistService,
    private authenticationService: AuthenticationService,
    private matSnackBar: MatSnackBar,
    private orderService: OrderService,
    @Inject(MAT_DIALOG_DATA) public data: {order_id: number}
  ) {
    this.loadingSubject = new BehaviorSubject<boolean>(false);
    this.loading$ = this.loadingSubject.asObservable();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      id: 0,
      cancel_reason: ['', Validators.required],
    });

  }


  public onSubmit() {
    if (this.form.valid) {
      let order_id = this.data.order_id;
      let cancel_reason = this.form.value.cancel_reason;


      const self = this;
      Swal.fire({
          title: 'Are you sure?',
          text: '',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          preConfirm() {
              return new Promise((resolve) => {
                  self.orderService.orderCancel(order_id, cancel_reason)
                      .pipe(
                          catchError(() => of([])),
                          finalize(() => resolve())
                      )
                      .subscribe(
                          res => {
                          });
              });
          }
      }).then((result) => {
          if (result.value) {
              this.onClose.emit();
              Swal.fire(
                  'Order Canceled!',
                  'Order has been canceled.',
                  'success'
              );
          }
      });

    }
  }

  // create(element) {
  //   this.loadingSubject.next(true);
  //   this.form.disable();
  //   this.wishlistService.add(element)
  //       .pipe(
  //           finalize(() => {
  //             this.loadingSubject.next(false);
  //             this.form.enable();
  //           })
  //       )
  //       .subscribe(
  //           res => {
  //             console.log("ICTSoft", res["data"]);
  //             console.log("ICTSoft", res["data"].id);
  //             this.loadingSubject.next(false);
  //             this.matSnackBar.open('Record has been successfully added!', 'x', {
  //               duration: 2000,
  //               verticalPosition: 'top',
  //               panelClass: ['success']
  //             });
  //             // this.dialogRef.close();
  //             this.onClose.emit({wishlist_id: res["data"].id});
  //           },
  //           err => {
  //             // this.form.enable();
  //             // for (let key of Object.keys(err.errors)) {
  //             //   const formControl = this.form.get(key);
  //             //   if (formControl) {
  //             //     // activate the error message
  //             //     let errorMsg = this.i18nSelectPipe.transform(err.errors[key], this.messages)
  //             //     setTimeout(() => {
  //             //       formControl.setErrors({
  //             //         serverError: errorMsg
  //             //       });
  //             //     });
  //             //   }
  //             // }
  //           }
  //         );
  // }

  public close(): void {
    this.dialogRef.close();
  }
}
