import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Order, OrderDetailOldModel, OrderOldModel} from '../models/order.model';
import {environment} from '../../../environments/environment';
import {AuthenticationService} from "../../authentication.service";
import {Customer} from '../models/customer.model';

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    private apiUrl = '';
    private apiUnauthUrl = '';

    constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
        this.apiUrl = environment.apiUrlauth;
        this.apiUnauthUrl = environment.apiUrlUnauth;
    }

    filter(filter = '[]', orderBy = '[]', pageNumber = 0, pageSize = 10, type = 'list'): Observable<Order[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('type', type);
        httpParams = httpParams.append('filter', filter);
        if (orderBy || orderBy != '') {
            httpParams = httpParams.append('orderBy', orderBy);
        }
        httpParams = httpParams.append('pageNumber', pageNumber.toString());
        if (pageSize || pageSize > 0) {
            httpParams = httpParams.append('pageSize', pageSize.toString());
        }
        let $url = this.apiUnauthUrl;
        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            $url = this.apiUrl;
        }

        return this.http.get<Order[]>($url + '/order', {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('filter', []))
            );
    }

    filterOffline(filter = '[]', orderBy = '[]', pageNumber = 0, pageSize = 10, type = 'list'): Observable<Order[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('type', type);
        httpParams = httpParams.append('filter', filter);
        if (orderBy || orderBy != '') {
            httpParams = httpParams.append('orderBy', orderBy);
        }
        httpParams = httpParams.append('pageNumber', pageNumber.toString());
        if (pageSize || pageSize > 0) {
            httpParams = httpParams.append('pageSize', pageSize.toString());
        }
        return this.http.get<Order[]>(this.apiUrl + '/order_offline', {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('filter', []))
            );
    }

    exportInvoiceOffline(filter = '[]', orderBy = '[]', pageNumber = 0, pageSize = 10): Observable<Order[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('filter', filter);
        if (orderBy || orderBy != '') {
            httpParams = httpParams.append('orderBy', orderBy);
        }
        return this.http.get<Order[]>(this.apiUrl + '/export_invoice_offline', {
            params: httpParams
        })
            .pipe(
                map(res =>  res),
                catchError(this.handleError('filter', []))
            );
    }


    exportInvoiceOfflineDetail(filter = '[]', orderBy = '[]', pageNumber = 0, pageSize = 10): Observable<Order[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('filter', filter);
        if (orderBy || orderBy != '') {
            httpParams = httpParams.append('orderBy', orderBy);
        }
        return this.http.get<Order[]>(this.apiUrl + '/export_invoice_offline_detail', {
            params: httpParams
        })
            .pipe(
                map(res =>  res),
                catchError(this.handleError('filter', []))
            );
    }

    find(id = 0): Observable<Order> {
        let httpParams = new HttpParams();
        return this.http.get<Order>(this.apiUrl + '/order/' + id, {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('find', null))
            );
    }

    add(element: Order): Observable<Order> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.post<Order>(this.apiUrl + '/order', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getOrder', element))
            );
    }


    addPickup(element: Order): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.post<Order>(this.apiUrl + '/createPickupRequest', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('order_id', element))
            );
    }



    update(element: Order): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<Order>(this.apiUrl + '/order', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getOrder', element))
            );
    }

    orderCancel(id: number, cancel_reason: string = ''): Observable<any> {
        const element: any = {
            order_id: id,
            cancel_reason: cancel_reason
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<Order>(this.apiUrl + '/orderCancel', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('order_id', element))
            );
    }

    //Called before Payment Gateway...
    generateOrder(customer_id: number, promo_id: number, shipping_method_id: number, billing_address_id: number, shipping_address_id: number,
                  gift_wrap: number, gift_message: string, is_guest: number, purchase_type: string, payment_method: string,
                  credit_amt_used: number, cash_point_used:number, customer_discount: number, utm_link: string): Observable<any> {
        const element: any = {
            customer_id: customer_id,
            promo_id: promo_id,
            shipping_method_id: shipping_method_id,
            billing_address_id: billing_address_id,
            shipping_address_id: shipping_address_id,
            gift_wrap: gift_wrap,
            gift_message: gift_message,
            purchase_type: purchase_type,
            payment_method: payment_method,
            credit_amt_used: credit_amt_used,
            cash_point_used: cash_point_used,
            customer_discount,
            utm_link

        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };
        const url = is_guest == 0 ? this.apiUrl : this.apiUnauthUrl;
        return this.http.put<Order>(url + '/generateOrder', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('generateOrder', element))
            );
    }

    updateStatus(id: number, status: number, courier: string, trackingId: string, cancelReason: string, delieveryPerson: string): Observable<any> {
        const element: any = {
            id: id,
            status: status,
            courier: courier,
            trackingId: trackingId,
            cancelReason,
            delieveryPerson
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<Order>(this.apiUrl + '/order_status', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getBasicPaint', element))
            );
    }

 updateOfflineStatus(id: number, status: number, courier: string, trackingId: string, cancelReason: string): Observable<any> {
        const element: any = {
            id: id,
            status: status,
            courier: courier,
            trackingId: trackingId,
            cancelReason: cancelReason
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<Order>(this.apiUrl + '/order_cancel_status', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getBasicPaint', element))
            );
    }

    updateOfflinePaymentStatus(id: number, payment_status: number, payment_comment: string): Observable<any> {
        const element: any = {
            id,
            payment_status,
            payment_comment
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<Order>(this.apiUrl + '/order_offline_payment_status', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getBasicPaint', element))
            );
    }

    updatePaymentStatus(id: number, payment_status: number, payment_comment: string): Observable<any> {
        const element: any = {
            id,
            payment_status,
            payment_comment
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<Order>(this.apiUrl + '/order_payment_status', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getBasicPaint', element))
            );
    }

    updateProductionCost(id: number, production_cost: number): Observable<any> {
        const element: any = {
            id,
            production_cost
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<Order>(this.apiUrl + '/order_production_cost', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getBasicPaint', element))
            );
    }

    updateOfflineUAM(id: number,  uam_no: string): Observable<any> {
        const element: any = {
            id,
            uam_no
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<Order>(this.apiUrl + '/order_offline_uam', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('updateuam', element))
            );
    }


    delete(ids = '[]'): Observable<any> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('ids', ids);
        return this.http.delete<any>(this.apiUrl + '/order', {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('deleteOrder', null))
            );
    }

    deleteOfflineOrderItem(id = 0): Observable<any> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('id', id+'');
        return this.http.delete<any>(this.apiUrl + '/order_offline', {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('deleteOrderOffline', null))
            );
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    filterOld(customerId: string): Observable<OrderOldModel[]> {
        return this.http.get<OrderOldModel[]>('https://mahattaart.com/backapi/api/user-orders/' + customerId)
            .pipe(
                map(res => res),
                catchError(this.handleError('filter', []))
            );
    }

    orderDetailOld(orderId: string): Observable<OrderDetailOldModel> {
        return this.http.get<OrderDetailOldModel>('https://mahattaart.com/backapi/api/user-orders-details/' + orderId)
            .pipe(
                map(res => res),
                catchError(
                    this.handleError('filter', null)
                )
            );
    }

    generateOrderOffline(id:number, order_id:string, customer_id: number, customer_type: string, order_type: string, invoice_type: string, payment_type: string,
                         payment_comment:string, credit_date: string,
                         billing_address_id: number, shipping_address_id: number, dataArray: string, file_1, po_no, po_date, order_approval, approval_reason
    ): Observable<any> {
        const element: any = {
            id,
            order_id,
            customer_id: customer_id,
            billing_address_id: billing_address_id,
            shipping_address_id: shipping_address_id,
            customer_type,
            order_type,
            invoice_type,
            payment_type,
            credit_date,
            dataArray,
            payment_comment,
            file_1,
            po_no,
            po_date,
            order_approval,
            approval_reason

        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<Order>(this.apiUrl + '/generateOrderOffline', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('generateOrderOffline', element))
            );
    }


    uploadOrderOffline(id:number, file_1): Observable<any> {
        const element: any = {
            id,
            file_1

        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<Order>(this.apiUrl + '/uploadOfflineOrderData', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('uploadOfflineOrderData', element))
            );
    }


    findOffline(id = 0): Observable<Order> {
        let httpParams = new HttpParams();
        return this.http.get<Order>(this.apiUrl + '/order_offline/' + id, {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('findOffline', null))
            );
    }

    sendCompletePurchaseEmail(id = 0): Observable<Order> {
        let httpParams = new HttpParams();
        return this.http.get<Order>(this.apiUrl + '/send_complete_purchase_mail/' + id, {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('findOffline', null))
            );
    }

    sendCompletePurchaseEmailOffline(id = 0): Observable<Order> {
        let httpParams = new HttpParams();
        return this.http.get<Order>(this.apiUrl + '/send_complete_purchase_mail_offline/' + id, {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('findOffline', null))
            );
    }

    markEditable(id: number): Observable<any> {
        const element: any = {
            id: id,
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<Order>(this.apiUrl + '/editable_status', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('order', element))
            );
    }

    markPaymentEnable(id: number): Observable<any> {
        const element: any = {
            id: id,
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<Order>(this.apiUrl + '/enable_payment_link', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('order', element))
            );
    }

    export(filter = '[]', orderBy = '[]', pageNumber = 0, pageSize = 10): Observable<Customer[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('filter', filter);
        if (orderBy || orderBy != '') {
            httpParams = httpParams.append('orderBy', orderBy);
        }
        return this.http.get<Customer[]>(this.apiUrl +'/export_orders', {
            params: httpParams
        })
            .pipe(
                map(res =>  res),
                catchError(this.handleError('filter', []))
            );
    }

    updateAddress(element: Order): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<Order>(this.apiUrl + '/update_address', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getOrder', element))
            );
    }

    updateRetailerReceivedStatus(id: any, order_received_status: any, received_quantity: any) {
        const element: any = {
            id: id,
            order_received_status,
            received_quantity
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<Order>(this.apiUrl + '/retailer_received_status', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getBasicPaint', element))
            );
    }

    paymentHook(order_id: number): Observable<any>
    {
        const element: any = {
            productinfo: order_id
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };
        return this.http.post<Order>(this.apiUnauthUrl + '/paymentHook', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('paymentHook', element))
            );
    }



    findByOrderId(id = ""): Observable<Order> {
        let httpParams = new HttpParams();
        return this.http.get<Order>(this.apiUnauthUrl + '/orderById/' + id, {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('find', null))
            );
    }


    /* Shipping */
    shippingRates(element: Order): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<Order>(this.apiUrl + '/shipping_rates', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getOrder', element))
            );
    }

    createShipment(element: Order): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<Order>(this.apiUrl + '/create_shipment', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getOrder', element))
            );
    }


    printLabel(id = 0, order_type = 'online'): Observable<Order> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('order_type', order_type);

        return this.http.get<Order>(this.apiUrl + '/print_label/' + id, {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('find', null))
            );
    }


    printManifest(id = 0, order_type = 'online'): Observable<Order> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('order_type', order_type);

        return this.http.get<Order>(this.apiUrl + '/print_manifest/' + id, {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('find', null))
            );
    }

    trackShipment(id, order_type = 'online'): Observable<Order> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('order_type', order_type);

        return this.http.get<Order>(this.apiUrl + '/track_shipment/' + id, {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('track_shipment', null))
            );
    }

    cancelShipment(id,order_type = 'online'): Observable<Order> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('order_type', order_type);

        return this.http.get<Order>(this.apiUrl + '/cancel_shipment/' + id, {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('cancel_shipment', null))
            );
    }

    trackShipmentCustomer(element: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<Order>(this.apiUrl + '/track_shipment_customer', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('track_shipment_customer', element))
            );
    }
}
