import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import {environment} from '../../../environments/environment';
import {WallArtCategory} from '../../app.models';
import {Order} from '../models/order.model';

@Injectable({
  providedIn: 'root'
})
export class WallArtCategoryService {
  private apiUrlAuth = '';
  private apiUrlUnAuth = '';

  constructor(private http: HttpClient) {
    this.apiUrlAuth = environment.apiUrlauth;
    this.apiUrlUnAuth = environment.apiUrlUnauth;
  }

  filter(filter = '[]', orderBy = '[]', pageNumber = 0, pageSize = 10): Observable<WallArtCategory[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('filter', filter);
    if (orderBy || orderBy != '') {
      httpParams = httpParams.append('orderBy', orderBy);
    }
    httpParams = httpParams.append('pageNumber', pageNumber.toString());
    if (pageSize || pageSize > 0) {
      httpParams = httpParams.append('pageSize', pageSize.toString());
    }
    return this.http.get<WallArtCategory[]>(this.apiUrlUnAuth +'/wallart_category', {
        params: httpParams
      })
      .pipe(
        map(res =>  res),
        catchError(this.handleError('filter', []))
      );
  }

  find(id = 0): Observable<WallArtCategory> {
    const httpParams = new HttpParams();
    return this.http.get<WallArtCategory>(this.apiUrlUnAuth +'/wallart_category/' + id, {
        params: httpParams
      })
      .pipe(
        map(res => res),
        catchError(this.handleError('find', null))
      );
  }

  add(element: WallArtCategory): Observable<WallArtCategory> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Accept: 'application/json, text/plain'
      })
    };

    return this.http.post<WallArtCategory>(this.apiUrlUnAuth +'/wallart_category', element, httpOptions)
      .pipe(
        map(res => res),
        catchError(this.handleError('getWallArtCategoryService', element))
      );
  }

  update(element: WallArtCategory): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Accept: 'application/json, text/plain'
      })
    };

    return this.http.put<WallArtCategory>(this.apiUrlAuth +'/wallart_category', element, httpOptions)
      .pipe(
        map(res => res),
        catchError(this.handleError('getWallArtCategoryService', element))
      );
  }

  delete(ids = '[]'): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ids', ids);
    return this.http.delete<any>(this.apiUrlAuth +'/wallart_category', {
        params: httpParams
      })
      .pipe(
        map(res =>  res),
        catchError(this.handleError('deleteWallArtCategoryService', null))
      );
  }

  updateStatus(id: number, status: number): Observable<any> {
    const element: any = {
      id: id,
      status: status
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Accept: 'application/json, text/plain'
      })
    };

    return this.http.put<WallArtCategory>(this.apiUrlAuth +'/wallart_category_quotation', element, httpOptions)
        .pipe(
            map(res => res),
            catchError(this.handleError('getWallArtCategory', element))
        );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  uploadThumbnail(id:number, file_1): Observable<any> {
    const element: any = {
      id,
      file_1

    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json, text/plain'
      })
    };

    return this.http.put<Order>(this.apiUrlAuth + '/wallart-category-thumbnail', element, httpOptions)
        .pipe(
            map(res => res),
            catchError(this.handleError('uploadWallArtCategoryThumbnail', element))
        );
  }


}
