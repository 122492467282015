import { Paper } from "./paper.model";
import { Mount } from "./mount.model";
import { Frame } from "./frame.model";
import { Glass } from "./glass.model";
import { CanvasWrap } from "./canvas_wrap.model";

export class CustomizedProductItem {
  id?: number = 0;
  customized_product_id: number;
  frame_id: number;
  frame_rate: number;
  // frame_size: number;
  mount_id_1: number;
  mount_rate_1: number;
  mount_size_1: number;
  mount_id_2: number;
  mount_rate_2: number;
  mount_size_2: number;
  mount_id_3: number;
  mount_rate_3: number;
  mount_size_3: number;
  mount_type: string;
  product_category_id: number;
  paper_id: number;
  print_rate: number;
  image_size: string;

  canvas_wrap_id: number;
  canvas_rate: number;
  glass_id: number;
  glass_rate: number;

  final_size: string;
  price: number;
  discount: number;

  image_detail: any;
  frame_detail: any;

  paper: Paper = new Paper();
  mount_1: Mount;
  mount_2: Mount;
  mount_3: Mount;
  frame: Frame;
  glass: Glass;
  canvas_wrap: CanvasWrap;
  is_table_frame :number = 0;
  public constructor() {
  }
}
