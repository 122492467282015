import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwiperConfigInterface, SwiperDirective, SwiperPaginationInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-change-room',
  templateUrl: './change-room.component.html',
  styleUrls: ['./change-room.component.scss'],
})
export class ChangeRoomComponent implements OnInit {
  slides: Array<any> = [];
  sectionCount = 1;
  shownavigation = true;
  @ViewChild(SwiperDirective) swiperView: SwiperDirective;
  rooms: any[] = [];
  selectedRoomId: string | null = null;

  public bannerLeftMd = 70;
  public bannerLeftSm = 60;
  public containerPadding = '';
  public bannerRightShow = true;
  public isMobile = false;
  public config: SwiperConfigInterface = {};
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };
  height = '600px';
  mobileHeight = '280px';

  constructor(private http: HttpClient,private router: Router,
    private route: ActivatedRoute) {

    this.http.get('../../../../assets/config.json').subscribe((data: any) => {
      
      const roomFromRoute = this.route.snapshot.paramMap.get('space');

      console.log("space",roomFromRoute);

      
      this.rooms = Object.values(data).filter((env: any) => {
        return env.space.split(',').map(s => s.trim().toLowerCase()).includes(roomFromRoute.toLowerCase().trim());
      });

      const savedRoomId = localStorage.getItem('selectedRoom');
      if (savedRoomId) {
        this.selectedRoomId = savedRoomId;
      }

    });

  }


  ngOnInit() {
    if (this.sectionCount === 1 || this.sectionCount === 3) {
      this.bannerLeftMd = this.bannerLeftSm = 100;
      this.bannerRightShow = false;

    }
    if (this.sectionCount === 3) {
      this.containerPadding = 'padding: 16px 2px 2px 2px !important;';
    }

    if (window.innerWidth <= 575) {
      this.isMobile = true;
    }

  }

  selectRoom(roomId: string): void {
    this.selectedRoomId = roomId;
    localStorage.setItem('selectedRoom', roomId);
  }

  get selectedRoomName(): string | undefined {
    return this.rooms.find(r => r.key === this.selectedRoomId)?.name;
  }


  navigateToWallpaper(wallaper_id: string) {
    this.router.navigate(['/wallpaper', wallaper_id]);
  }

  ngAfterViewInit() {
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: true,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false
      },
      speed: 500
    };


  }

  ngDestroy() {
  }

}
