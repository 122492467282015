import {Component, Input, OnInit} from '@angular/core';
import {SwiperConfigInterface, SwiperPaginationInterface} from 'ngx-swiper-wrapper';


@Component({
    selector: 'app-image-carousel',
    templateUrl: './image-carousel.component.html',
    styleUrls: ['./image-carousel.component.scss']
})
export class ImageCarouselComponent implements OnInit {
    @Input('slides') slides: Array<any> = [];
    @Input('type') type: string;

    public config: SwiperConfigInterface = {};
    public noOfImages = 5;
    private pagination: SwiperPaginationInterface = {
        el: '.swiper-pagination',
        clickable: true
    };

    constructor() {
    }

    ngOnInit() {
        if (this.type == undefined) {
            this.type = '';
        }
        if (window.innerWidth < 960) {
            this.noOfImages = 3;
        }
        if (window.innerWidth < 1280) {
            this.noOfImages = 5;
        }
        if (window.innerWidth < 575) {
            this.noOfImages = 2;
        }
    }

    ngAfterViewInit() {
        this.config = {
            slidesPerView: this.noOfImages,
            spaceBetween: 30,
            keyboard: true,
            navigation: true,
            pagination: this.pagination,
            grabCursor: true,
            loop: true,
            preloadImages: true,
            lazy: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            speed: 500,
            effect: 'slide'
        };
    }

}
