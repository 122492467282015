import {Component, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {AppService} from '../../app.service';
// import {Product} from '../../app.models';
import {Location} from '@angular/common';
import {Frame} from 'src/app/admin/models/frame.model';
import {Mount} from 'src/app/admin/models/mount.model';
import {Glass} from '../../admin/models/glass.model';
import {MountService} from 'src/app/admin/services/mount.service';
import {FrameStyleService} from 'src/app/admin/services/frame_style.service';
import {FrameService} from 'src/app/admin/services/frame.service';
import {FrameStyle} from 'src/app/admin/models/frame_style.model';
import {ActivatedRoute} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import {ProductCategory} from 'src/app/admin/models/product_category.model';
import {ProductCategoryService} from 'src/app/admin/services/product_category.service';
import {PaperService} from 'src/app/admin/services/paper.service';
import {Paper} from 'src/app/admin/models/paper.model';
import {MatDialog} from '@angular/material/dialog';
import Swal from 'sweetalert2';
import {TopbarNavbarService} from '../../topbar-navbar.service';
import {CartService} from 'src/app/admin/services/cart.service';
import {Cart} from 'src/app/admin/models/cart.model';
import {catchError, finalize} from 'rxjs/operators';
import {ProductType} from 'src/app/admin/models/product_type.model';
import {ProductTypeService} from 'src/app/admin/services/product_type.service';
import {Product} from 'src/app/admin/models/product.model';
import {SignInDialogComponent} from 'src/app/shared/sign-in-dialog/sign-in-dialog.component';
import {AuthenticationService} from 'src/app/admin/services/authentication.service';
import {GlassService} from 'src/app/admin/services/glass.service';
import {CanvasWrapService} from 'src/app/admin/services/canvas_wrap.service';
import {CanvasWrap} from 'src/app/admin/models/canvas_wrap.model';
import {CustomizedProduct} from 'src/app/admin/models/customized_product.model';
import {CustomizedProductItem} from 'src/app/admin/models/customized_product_item.model';
import {WishlistQuickDialogComponent} from 'src/app/shared/wishlist-quick-dialog/wishlist-quick-dialog.component';
import {WishlistItem} from 'src/app/admin/models/wishlist_item.model';
import {WishlistItemService} from 'src/app/admin/services/wishlist_item.service';
import {of} from 'rxjs';


@Component({
    selector: 'app-customization',
    templateUrl: './customization.component.html',
    styleUrls: ['./customization.component.scss']
})
export class CustomizationComponent implements OnInit {

    constructor(
        public appService: AppService,
        public cartService: CartService,
        public wishlistItemService: WishlistItemService,
        public mountService: MountService,
        public frameStyleService: FrameStyleService,
        public frameService: FrameService,
        public paperService: PaperService,
        public productTypeService: ProductTypeService,
        public productCategoryService: ProductCategoryService,
        public glassService: GlassService,
        public canvasWrapService: CanvasWrapService,
        private location: Location,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private topbarNavbarService: TopbarNavbarService,
        private authenticationService: AuthenticationService,
        // private changeDetectorRef: ChangeDetectorRef,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
    }

    public get sidenavOpen(): boolean {
        return this._sidenavOpen;
    }

    public get price() {
        return this._price;
    }

    public set price(_price) {
        this._price = _price;
    }

    public get image_path() {
        return this._image_path;
    }

    public set image_path(_image_path) {
        this._image_path = _image_path;
    }

    public get my_photos() {
        return this._my_photos;
    }

    public set my_photos(_my_photos) {
        this._my_photos = _my_photos;
    }

    public get product() {
        return this._product;
    }

    public set product(_product) {
        this._product = _product;
    }

    public get max_h() {
        return this.max_h;
    }

    public set max_h(_max_h) {
        this._max_h = _max_h;
    }

    public get max_w() {
        return this.max_w;
    }

    public set max_w(_max_w) {
        this._max_w = _max_w;
    }

    public get wall_color() {
        return this._wall_color;
    }

    public set wall_color(_wall_color) {
        this._wall_color = _wall_color;
        this.updateUrl();
    }

    public get frame_style_id() {
        return this._frame_style_id;
    }

    public set frame_style_id(_frame_style_id) {
        this._frame_style_id = _frame_style_id;
    }

    public get frameStyles() {
        return this._frameStyles;
    }

    public get frameColors() {
        return this._frameColors;
    }

    public get frameColor() {
        return this._frameColor;
    }

    public set frameColor(_frameColor) {
        this._frameColor = _frameColor;
    }

    public get frameWidths() {
        return this._frameWidths;
    }

    public get frameWidth() {
        return this._frameWidth;
    }

    public set frameWidth(_frameWidth) {
        this._frameWidth = _frameWidth;
    }

    public get frameSize() {
        if (this.frame) {
            return this.frame.width_in + ' X ' + this.frame.width_in;
        } else {
            return '4 X 4';
        }
    }

    public get mountSize() {
        let w = 0;
        if (this.mount1) {
            w += this.mat1Width;
        }
        if (this.mount2) {
            w += this.mat2Width;
        }
        if (this.mount3) {
            w += this.mat3Width;
        }
        return w + ' X ' + w;
    }

    public get frameCustomizationSelectedIndex(): number {
        return this._frameCustomizationSelectedIndex;
    }

    public get canvasWraps() {
        return this._canvasWraps;
    }

    public set canvasWraps(_canvasWraps) {
        this._canvasWraps = _canvasWraps;
    }

    public get canvasWrap() {
        return this._canvasWrap;
    }

    public set canvasWrap(_canvasWrap) {
        this._canvasWrap = _canvasWrap;
    }

    public get productTypes(): ProductType[] {
        return this._productTypes;
    }

    public get productType(): ProductType {
        return this._productType;
    }

    public get productSubType(): string {
        return this._productSubType;
    }

    public get printType() {
        return this._printType;
    }

    public set printType(_printType) {
        this._printType = _printType;
    }

    public get productCategories() {
        return this._productCategories;
    }

    public get printSurface(): Paper {
        return this._printSurface;
    }

    public set printSurface(_printSurface: Paper) {
        this._printSurface = _printSurface;
    }

    public get papers() {
        return this._papers;
    }

    public get printSizes() {
        return this._printSizes;
    }

    public set printSizes(_printSizes) {
        this._printSizes = _printSizes;
    }

    public get printSize() {
        return this._printSize + '';
    }

    public set printSize(_printSize) {
        console.log('ICTSoft', '_printSize: ' + _printSize);
        const arr = _printSize.split('X');
        if (arr && arr.length == 2) {
            this._printSizeCustomWidth = +arr[0].trim();
            this._printSizeCustomHeight = +arr[1].trim();
        }
        this._printSize = _printSize;
    }

    public get printSizeCustomWidth() {
        return this._printSizeCustomWidth;
    }

    public set printSizeCustomWidth(_printSizeCustomWidth) {
        this._printSizeCustomWidth = _printSizeCustomWidth;
    }

    public get printSizeCustomHeight() {
        return this._printSizeCustomHeight;
    }

    public set printSizeCustomHeight(_printSizeCustomHeight) {
        this._printSizeCustomHeight = _printSizeCustomHeight;
    }


    public get printSizeText() {
        return this._printSizeCustomWidth + ' X ' + this._printSizeCustomHeight;

        // if(this._printSize && this._printSize !== "") {
        //   var arr = this._printSize.split('X');
        //   var w = +arr[0].trim();
        //   var h = +arr[1].trim();
        //
        //   return w + ' X ' + h;
        // }
        // else {
        //   return '0 x 0';
        // }
    }

    public get printSizeW() {
        return this._printSizeW;
    }

    public get printSizeH() {
        return this._printSizeH;
    }

    public get printSizeWRoom() {
        return this._printSizeWRoom;
    }

    public get printSizeHRoom() {
        return this._printSizeHRoom;
    }

    public get matWidths() {
        return this._matWidths;
    }

    public get mat1Width() {
        return this._mat1Width;
    }

    public set mat1Width(_mat1Width) {
        this._mat1Width = _mat1Width;
    }

    public get mat1Padding() {
        return this._mat1Padding;
    }

    public get mat2Width() {
        return this._mat2Width;
    }

    public set mat2Width(_mat2Width) {
        this._mat2Width = _mat2Width;
    }

    public get mat2Padding() {
        return this._mat2Padding;
    }

    public get mat3Width() {
        return this._mat3Width;
    }

    public set mat3Width(_mat3Width) {
        this._mat3Width = _mat3Width;
    }

    public get mat3Padding() {
        return this._mat3Padding;
    }

    public get matColors() {
        return this._matColors;
    }

    public get mat1Color() {
        return this._mat1Color + '';
    }

    public set mat1Color(_mat1Color) {
        this._mat1Color = _mat1Color;
    }

    public get mat2Color() {
        return this._mat2Color + '';
    }

    public set mat2Color(_mat2Color) {
        this._mat2Color = _mat2Color;
    }

    public get mat3Color() {
        return this._mat3Color + '';
    }

    public set mat3Color(_mat3Color) {
        this._mat3Color = _mat3Color;
    }

    /* </Mat Width */


    /* <Canvas> */
    // private _canvas_type = 'museum';
    // public get canvas_type() {
    //     return this._canvas_type;
    // }
    //
    // public set canvas_type(_canvas_type) {
    //     this._canvas_type = _canvas_type;
    // }
    /* </Canvas> */

    public get finalSize() {
        let width = 0;
        let height = 0;
        if (this._printSize && this._printSize !== '') {
            const arr = this._printSize.split('X');
            const w = +arr[0].trim();
            const h = +arr[1].trim();

            if (this.frame) {
                width += w;
                height += h;

                width += this.frame.width_in * 2;
                height += this.frame.width_in * 2;

                if (this.mount1) {
                    width += this.mat1Width * 2;
                    height += this.mat1Width * 2;
                }
                if (this.mount2) {
                    width += this.mat3Width * 2;
                    height += this.mat3Width * 2;
                }
                if (this.mount3) {
                    width += this.mat3Width * 2;
                    height += this.mat3Width * 2;
                }
            }
        }
        return width + ' X ' + height;
    }

    public get url() {
        return this._url;
    }

    public set url(_url) {
        this._url = _url;
    }

    public get params() {
        return this._params;
    }

    public get customizedProductItem() {
        return this._customizedProductItem;
    }

    public set customizedProductItem(_customizedProductItem) {
        this._customizedProductItem = _customizedProductItem;
    }

    public get customizedProduct() {
        return this._customizedProduct;
    }

    public set customizedProduct(_customizedProduct) {
        this._customizedProduct = _customizedProduct;
    }

    public get cart() {
        return this._cart;
    }

    public set cart(_cart) {
        this._cart = _cart;
    }

    public get wishlist_item() {
        return this._wishlist_item;
    }

    public set wishlist_item(_wishlist_item) {
        this._wishlist_item = _wishlist_item;
    }

    @ViewChild('sidenav', {static: true}) sidenav: any;

    public _sidenavOpen = true;

    private _price = 0;

    private _image_path = '../../assets/images/mahattaart/product_icons/upload_photos.jpg';

    private _my_photos = [];

    private _product: Product;

    private _max_h;

    private _max_w;

    private _image_caption;
    private _image_photographer;


    private _wall_color = '#fff';

    // public wallArtProduct: Product;
    // public products: Array<Product> = [];

    public frame: Frame;
    public frames: Frame[];

    public _frame_style_id = 0;

    public _frameStyles: FrameStyle[];

    private _frameColors: string[];

    private _frameColor = '';

    private _frameWidths: string[];

    private _frameWidth = 0;


    public glass: Glass;
    public glasses: Glass[];

    public selectedMat = 0;

    public mount1: Mount;
    public mount2: Mount;
    public mount3: Mount;
    // public mounts: Mount[];
    public mounts1: Mount[];
    public mounts2: Mount[];
    public mounts3: Mount[];

    public frameBorderWidth = 0;
    public frameBorderImage = '';

    // public mount1Padding = 0;
    public mount1background = '';

    // public mount2Padding = 0;
    public mount2background = '';

    // public mount3Padding = 0;
    public mount3background = '';

    private _frameCustomizationSelectedIndex = 0;

    private _canvasWraps: CanvasWrap[];

    private _canvasWrap: CanvasWrap;


    private _productTypes: ProductType[];


    private _productType: ProductType = new ProductType();

    private _productSubType = 'main';

    /* <Print Type */
    private _printType: ProductCategory;

    private _productCategories: ProductCategory[];

    /* </Print Type */

    /* <Print Surface */
    private _printSurface: Paper;

    private _papers: Paper[];

    /* </Print Surface */

    /* <Print Size */
    private _printSizes = [];

    // private _printSize = '4.5X7';
    private _printSize = '';

    private _printSizeCustomWidth = 0;

    private _printSizeCustomHeight = 0;


    private _printSizeW = 'initial';

    private _printSizeH = '218px';

    private _printSizeWRoom = 'initial';

    private _printSizeHRoom = '109px';

    /* </Print Size */


    /* <Mat Width */
    private _matWidths: string[];

    private _mat1Width = 0.5;

    private _mat1Padding = '0';

    private _mat2Width = 0.5;

    private _mat2Padding = '0';

    private _mat3Width = 0.5;

    private _mat3Padding = '0';

    private _matColors: string[];

    private _mat1Color = '';

    private _mat2Color = '';

    private _mat3Color = '';

    private _url: string;

    private _params:any;

    private _customizedProductItem: CustomizedProductItem = new CustomizedProductItem();

    private _customizedProduct: CustomizedProduct = new CustomizedProduct();


    private _cart: Cart = new Cart();

    private _wishlist_item: WishlistItem = new WishlistItem();

    public getPrintSizes() {
        this.appService.getPrintSizes(this._product.slug, this._max_h, this._max_w, this._image_path, (this._max_h > this._max_w ? 0 : 1), this._productType).subscribe(res => {
            this._printSizes = res['data'];
            if (this._printSizes && this._printSizes.length > 0) {
                this.printSize = this._printSizes[0];
            }

            if (this._params.image_size !== undefined && this._params.image_size !== '') {
                const self = this;
                const filterArray = this._printSizes.filter(function(el) {
                    return el == self._params.image_size;
                });
                if (filterArray[0]) {
                    // this._printSize = filterArray[0];
                    this.printSize = filterArray[0];
                }
            }
        });
    }


    public onPrintSizeCustomWidth() {
        const arr1 = this._printSizes[0].split('X');
        const xa = +arr1[0].trim();
        const ya = +arr1[1].trim();

        const arr2 = this._printSizes[1].split('X');
        const xb = +arr2[0].trim();
        const yb = +arr2[1].trim();

        // Last Size
        const arr3 = this._printSizes[this._printSizes.length - 1].split('X');
        const xc = +arr3[0].trim();
        const yc = +arr3[1].trim();

        let x = this._printSizeCustomWidth;
        let y = 0;      // ?

        if (x < xa) {
            x = xa;
        }

        if (x > xc) {
            x = xc;
        }


        const size_fraction_x = +(x - Math.floor(x)).toFixed(2);
        x = size_fraction_x <= 0.4 ? Math.floor(x) : (size_fraction_x >= 0.41 && size_fraction_x <= 0.7 ? Math.floor(x) + 0.5 : Math.ceil(x));
        console.log('ICTSoft', size_fraction_x + ' ' + x + ' ' + this._printSizeCustomWidth);

        y = ((x - xa) * (yb - ya) / (xb - xa)) + ya;

        const size_fraction_y = +(y - Math.floor(y)).toFixed(2);
        y = size_fraction_y <= 0.4 ? Math.floor(y) : (size_fraction_y >= 0.41 && size_fraction_y <= 0.7 ? Math.floor(y) + 0.5 : Math.ceil(y));

        // this.changeDetectorRef.detectChanges()
        this._printSizeCustomWidth = x;
        this._printSizeCustomHeight = y;
        // this.changeDetectorRef.markForCheck();
        // this.printSize = x + "X" + y;
        this.onPrintSize();
    }

    public onPrintSizeCustomHeight() {
        const arr1 = this._printSizes[0].split('X');
        const xa = +arr1[0].trim();
        const ya = +arr1[1].trim();

        const arr2 = this._printSizes[1].split('X');
        const xb = +arr2[0].trim();
        const yb = +arr2[1].trim();

        // Last Size
        const arr3 = this._printSizes[this._printSizes.length - 1].split('X');
        const xc = +arr3[0].trim();
        const yc = +arr3[1].trim();

        let x = 0;      // ?
        let y = this._printSizeCustomHeight;

        if (y < ya) {
            y = ya;
        }

        if (y > yc) {
            y = yc;
        }

        const size_fraction_y = +(y - Math.floor(y)).toFixed(2);
        y = size_fraction_y <= 0.4 ? Math.floor(y) : (size_fraction_y >= 0.41 && size_fraction_y <= 0.7 ? Math.floor(y) + 0.5 : Math.ceil(y));

        x = ((xb - xa) * (y - ya) / (yb - ya)) + xa;

        const size_fraction = +(x - Math.floor(x)).toFixed(2);
        x = size_fraction <= 0.4 ? Math.floor(x) : (size_fraction >= 0.41 && size_fraction <= 0.7 ? Math.floor(x) + 0.5 : Math.ceil(x));

        this._printSizeCustomWidth = x;
        this._printSizeCustomHeight = y;
    }

    public onPrintSize() {
        console.log('ICTSoft', (this.printSize == ''));

        // var arr = this.printSize.split('X');
        // var w = +arr[0].trim();
        // var h = +arr[1].trim();

        const w = this._printSizeCustomWidth;
        const h = this._printSizeCustomHeight;

        // Portrait
        // if(this.product.type == "portrait") {
        this._printSizeW = 'initial';
        this._printSizeH = (3 * (h - 1) + 200) + 'px';
        this._printSizeWRoom = 'initial';
        this._printSizeHRoom = ((3 * (h - 1) + 200) / 2) + 'px';
        // }

        // Landscape
        // if(this.product.type == "landscape") {
        // this._printSizeW = (3 * (w - 1) + 200) + "px";
        // this._printSizeH = "initial";
        // this._printSizeWRoom = ((3 * (w - 1) + 200)/2) + "px";
        // this._printSizeHRoom = "initial";
        // }

        this.updateUrl();
    }

    public onPrintType() {
        this.getPapers();
    }

    public onPrintSurface() {
        this.updateUrl();
    }

    public onMatWidth(selectedMat: number) {
        switch (selectedMat) {
            case 1:
                if (this.mount1) {
                    this._mat1Padding = (+this.mat1Width * 10) + '';
                }
                break;
            case 2:
                if (this.mount2) {
                    this._mat2Padding = (+this.mat2Width * 10) + '';
                }
                break;
            case 3:
                if (this.mount3) {
                    this._mat3Padding = (+this.mat3Width * 10) + '';
                }
                break;
        }
        this.updateUrl();
    }

    public onMatColor(selectedMat: number) {
        switch (selectedMat) {
            case 1:
                this.getMounts1();
                break;
            case 2:
                this.getMounts2();
                break;
            case 3:
                this.getMounts3();
                break;
        }
    }

    public updateUrl() {
        const baseURL = 'product-customization';

        let params = new HttpParams()
            .set('product', encodeURIComponent(this._params.product))
            .set('product_type', encodeURIComponent(this._productType.slug))
            .set('frame_code', encodeURIComponent(this.frame ? this.frame.frame_code : ''))
            .set('mount1_code', encodeURIComponent(this.mount1 ? this.mount1.code : ''))
            .set('mount2_code', encodeURIComponent(this.mount2 ? this.mount2.code : ''))
            .set('mount3_code', encodeURIComponent(this.mount3 ? this.mount3.code : ''))
            .set('mount1_size', encodeURIComponent(this._mat1Width))
            .set('mount2_size', encodeURIComponent(this._mat2Width))
            .set('print_type', encodeURIComponent(this._printType ? this._printType.slug : ''))
            .set('mount3_size', encodeURIComponent(this._mat3Width))
            .set('print_surface', encodeURIComponent(this._printSurface ? this._printSurface.id : ''))
            .set('image_size', encodeURIComponent(this._printSize))
            .set('glass_type', encodeURIComponent(this.glass ? this.glass.code : ''))
            .set('canvas_type', encodeURIComponent(this.canvasWrap ? this.canvasWrap.slug : ''))
            .set('cart_id', encodeURIComponent(this._cart ? this._cart.id : 0))
            .set('wishlist_item_id', encodeURIComponent(this._wishlist_item ? this._wishlist_item.id : 0))
            .set('image_id', encodeURIComponent(this._params.image_id));
        // .set('wall_color', encodeURIComponent(this._wall_color));

        let fullURL = `${baseURL}?${params.toString()}`;

        // If 0 then Remove cart_id or wishlist_item_id from url
        if (this._cart && this._cart.id > 0) {
            this._url = fullURL;
        } else {
            params = params.delete('cart_id');
            // params = params.delete("wishlist_item");
            this._url = `${baseURL}?${params.toString()}`;
            fullURL = this._url;
        }
        if (this._wishlist_item && this._wishlist_item.id > 0) {
            this._url = fullURL;
        } else {
            // params = params.delete("cart_id");
            params = params.delete('wishlist_item_id');
            this._url = `${baseURL}?${params.toString()}`;
            fullURL = this._url;
        }


        this.location.replaceState(fullURL);
        this.refreshCustomizedProductItem();
        this._calculatePrice();
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            // this._params = params;
            // this._params.product = 'wall_art';
            // this._params.productType = 'frame';
            // if (params['product']) {
            //     this._product = decodeURIComponent(params['product']);
            // }
            // if (params['wall_color']) {
            //     this._wall_color = decodeURIComponent(params['wall_color']);
            // }
        });


        this._sidenavOpen = false;

        this.init();
    }

    public init() {
        const filter = [];
        filter.push(['product.slug', '=', 'wall_art']);

        this.productTypeService.filter(JSON.stringify(filter), '[]', 0, -1).subscribe(res => {
            this._productTypes = res['data'];
            this._product = this._productTypes[0].product;

            // iterate for slug...
            // this._productType = this._productTypes[1];
            const self = this;
            const filterArray = this._productTypes.filter(function(el) {
                return el.slug == 'Canvas';
            });
            if (filterArray && filterArray[0]) {
                this._productType = filterArray[0];
            }


            // this.updateUrl();

            // if (this._params.product == 'wall_art') {
                // ToDo: get wall-art image from api...
                this.getWallArtProduct();
                this.topbarNavbarService.setMenuType(1);
            this.topbarNavbarService.setHeaderType(1);
            // }

            this.getProductCategories();
            // this.getPapers();
            this.getFrameStyles();
            this.getFrameWidths();
            this.getFrameColors();
            this.getFrames();
            this.getMatWidths();
            this.getMatColors();
            this.getMounts1();
            this.getMounts2();
            this.getMounts3();
            this.getGlasses();
            this.getCanvasWraps();

            if (this._params.mount1_code !== undefined && this._params.mount1_code !== '') {
                this.getMountByCode(this._params.mount1_code, 1);
                if (this._params.mount1_size && this._params.mount1_size !== '') {
                    this._mat1Width = +this._params.mount1_size;
                }
            }
            if (this._params.mount2_code !== undefined && this._params.mount2_code !== '') {
                this.getMountByCode(this._params.mount2_code, 2);
                if (this._params.mount2_size && this._params.mount2_size !== '') {
                    this._mat2Width = +this._params.mount2_size;
                }
            }
            if (this._params.mount3_code !== undefined && this._params.mount3_code !== '') {
                this.getMountByCode(this._params.mount3_code, 3);
                if (this._params.mount3_size && this._params.mount3_size !== '') {
                    this._mat3Width = +this._params.mount3_size;
                }
            }
            if (this._params.glass_type !== undefined && this._params.glass_type !== '') {
                this.getGlassByCode(this._params.glass_type);
            }
            if (this._params.canvas_type !== undefined && this._params.canvas_type !== '') {
                this.getCanvasWrapByCode(this._params.canvas_type);
            }
            if (this._params.cart_id !== undefined && this._params.cart_id > 0) {
                this.getCartById(this._params.cart_id);
            }
            if (this._params.wishlist_item_id !== undefined && this._params.wishlist_item_id > 0) {
                this.getWishlistItemById(this._params.wishlist_item_id);
            }


        });
    }


    onAddPhoto($event) {
    }

    onRemovePhoto($event, i) {
        $event.stopPropagation();
        if (this._my_photos[i] == this._image_path) {
            Swal.fire('You can\'t delete current active image.', '', 'error');
        } else {
            const self = this;
            Swal.fire({
                title: 'Are you sure to delete image?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                showLoaderOnConfirm: true,
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this._my_photos.splice(i, 1);
                    localStorage.setItem('my_photos', JSON.stringify(this._my_photos));
                }
            });
        }
    }

    onPhoto($event, i) {
        $event.stopPropagation();
        this._image_path = this._my_photos[i];
        this.getPrintSizes();
    }

    onFrame(frame: Frame) {
        this.frame = frame;
        // this.frameBorderWidth = 20;

        this.frameBorderWidth = this.frame.width_in * 10;
        if (this.frame.width_in <= 1) {
            this.frameBorderWidth += 5;
        }

        this.frameBorderImage = 'url(\'' + this.frame.whole_frame_image + '\') 50 stretch stretch';

        this.updateUrl();
    }

    onGlasses(glass: Glass) {
        this.glass = glass;
        this.updateUrl();
    }

    onCanvasWrap(canvasWrap: CanvasWrap) {
        this.canvasWrap = canvasWrap;
        this.updateUrl();
    }

    // onCanvasType(_canvas_type) {
    //     this._canvas_type = _canvas_type;
    // }


    onMatSelect(selectedMat: number) {
        switch (selectedMat) {
            case 2:
                if (!(this.mount1 && this.mount1.id > 0)) {
                    return;
                }
                break;
            case 3:
                if (!(this.mount2 && this.mount2.id > 0)) {
                    return;
                }
                break;
        }
        this.selectedMat = selectedMat;
    }

    onMat(mount: Mount, selectedMat: number) {
        switch (selectedMat) {
            case 1:
                this.mount1 = mount;

                // this.mount1Padding = 5;
                this._mat1Padding = (+this.mat1Width * 10) + '';
                this.mount1background = 'rgba(0, 0, 0, 0) url(\'' + this.mount1.image_1 + '\') repeat scroll 0% 0%';
                break;
            case 2:
                this.mount2 = mount;

                // this.mount2Padding = 5;
                this._mat2Padding = (+this.mat2Width * 10) + '';
                this.mount2background = 'rgba(0, 0, 0, 0) url(\'' + this.mount2.image_1 + '\') repeat scroll 0% 0%';
                break;
            case 3:
                this.mount3 = mount;

                // this.mount3Padding = 5;
                this._mat3Padding = (+this.mat3Width * 10) + '';
                this.mount3background = 'rgba(0, 0, 0, 0) url(\'' + this.mount3.image_1 + '\') repeat scroll 0% 0%';
                break;
        }
        this.updateUrl();
    }

    removeMount($event, mountType: number) {
        $event.stopPropagation();
        switch (mountType) {
            case 1:
                this.mount1 = null;
                // this.mount1Padding = 0;
                this._mat1Padding = '0';
                this.mount1background = '';
                break;
            case 2:
                this.mount2 = null;
                // this.mount2Padding = 0;
                this._mat2Padding = '0';
                this.mount2background = '';
                break;
            case 3:
                this.mount3 = null;
                // this.mount3Padding = 0;
                this._mat3Padding = '0';
                this.mount3background = '';
                break;
        }
        this.selectedMat = mountType - 1;
    }

    onProduct(_productType: ProductType) {
        this._productType = _productType;
        this._productSubType = 'main';
        this.getPapers();
        this.getPrintSizes();
        this.updateUrl();
    }

    // public product: Product;
    // public getAllProducts(){
    //   this.appService.getProducts('featured').subscribe(data => {
    //     this.products = data;
    //     // for show more product
    //     for (let index = 0; index < 3; index++) {
    //       this.products = this.products.concat(this.products);
    //     }
    //     this.wallArtProduct = this.products[0];
    //   });
    // }

    public getWallArtProduct() {

        // this.appService.getWallArtImage(this._params.image_id).subscribe(res => {
        //     this._image_path = res['data'].image_path;
        //     this._max_h = res['data'].image_original_width;
        //     this._max_w = res['data'].image_original_height;
        //     this._image_caption = res['data'].image_caption;
        //     this._image_photographer = res['data'].image_photographer;
        //
        //     this.getPrintSizes();
        // });

    }

    public getFrames() {
        const filter = [];
        filter.push(['status', '=', 1]);

        if (this._frame_style_id > 0) {
            filter.push(['frame_style_id', '=', this._frame_style_id]);
        }
        if (this._frameWidth > 0) {
            filter.push(['width_in', '=', this._frameWidth]);
        }
        if (this._frameColor != '') {
            filter.push(['color', '=', this._frameColor]);
        }

        this.frameService.filter(JSON.stringify(filter), '[]', 0, -1).subscribe(res => {
            this.frames = res['data'];

            if (res['meta'].recordsFiltered > 0 && !this.frame) {
                if (this._params.frame_code && this._params.frame_code !== '') {
                    // If parameter contains frame_code, then fetch and set current frame by frame_code
                    this.getFrameByFrameCode(this._params.frame_code);
                } else {
                    this.frame = this.frames[0];
                    this.frameBorderWidth = this.frame.width_in * 10 + 10;
                    this.frameBorderImage = 'url(\'' + this.frame.whole_frame_image + '\') 50 stretch stretch';
                    this.updateUrl();
                }
            }

        });

        // this.appService.getFrames().subscribe(data => {
        //   this.frames = data;
        //
        //   this.frame = this.frames[0];
        //   this.frameBorderWidth = this.frame.width_in * 10;
        //   this.frameBorderImage = 'url(\'' + this.frame.whole_frame_image + '\') 50 stretch stretch';
        // });
    }

    public getFrameByFrameCode(frame_code: string) {
        const filter = [];
        filter.push(['frame_code', '=', frame_code]);

        this.frameService.filter(JSON.stringify(filter), '[]', 0, -1).subscribe(res => {
            this.frame = res['data'][0];

            if (this.frame) {
                this.frameBorderWidth = this.frame.width_in * 10 + 10;
                this.frameBorderImage = 'url(\'' + this.frame.whole_frame_image + '\') 50 stretch stretch';
                this.updateUrl();
            }

        });
    }


    public onFrameFilter() {
        this.getFrames();
    }

    public getProductCategories() {
        const filter = [];
        filter.push(['status', '=', 1]);
        filter.push(['product_id', '=', 1]);

        this.productCategoryService.filter(JSON.stringify(filter), '[]', 0, -1).subscribe(res => {
            this._productCategories = res['data'];

            // if (res['meta'].recordsFiltered > 0 && !this._printType) {
            //     if(this._params["print_type"] && this._params["print_type"] !== '') {
            //       let filterArray = this._productCategories.filter(function(el) {
            //           return el.slug == this._params["print_type"];
            //       });
            //       this._printType = filterArray[0];
            //       this.getPapers();
            //       this.updateUrl();
            //     }
            //     else {
            //       this._printType = this._productCategories[0];
            //       this.getPapers();
            //       this.updateUrl();
            //     }
            // }


            if (this._params.print_type && this._params.print_type !== '') {
                const self = this;
                const filterArray = this._productCategories.filter(function(el) {
                    return el.slug == self._params.print_type;
                });
                this._printType = filterArray[0];
                this.getPapers();
                this.updateUrl();
            } else {
                this._printType = this._productCategories[0];
                this.getPapers();
                this.updateUrl();
            }

        });
    }

    public getPapers() {
        const filter = [];
        filter.push(['status', '=', 1]);
        filter.push(['category_id', '=', this._printType.id]);

        filter.push(['product_type_ids', this._productType.id]);

        // ToDo: Check following parameters in API...
        // if (this.productType == 'framing') {
        //     filter.push(['product_type', 1]);
        // }
        // if (this.productType == 'canvas') {
        //     filter.push(['product_type', 2]);
        // }
        // if (this.productType == 'print') {
        //     filter.push(['product_type', 3]);
        // }

        this.paperService.filter(JSON.stringify(filter), '[]', 0, -1).subscribe(res => {
            this._papers = res['data'];
            // this._printSurface = this._papers[0];

            if (this._params.print_surface && this._params.print_surface !== '') {
                const self = this;
                const filterArray = this._papers.filter(function(el) {
                    return el.id == +self._params.print_surface;
                });
                this._printSurface = filterArray[0];
                this.updateUrl();
            } else {
                this._printSurface = this._papers[0];
                this.updateUrl();
            }
        });
    }

    public getFrameStyles() {
        const filter = [];
        // filter.push(['status', '=', 1]);

        this.frameStyleService.filter(JSON.stringify(filter), '[]', 0, -1).subscribe(res => {
            this._frameStyles = res['data'];
        });
    }

    public getFrameColors() {
        this.frameService.getFrameColors().subscribe(res => {
            this._frameColors = res['data'];
        });
    }

    public getFrameWidths() {
        this.frameService.getFrameWidths().subscribe(res => {
            this._frameWidths = res['data'];
        });
    }

    public getGlassByCode(code: string) {
        const filter = [];
        filter.push(['code', '=', code]);

        this.glassService.filter(JSON.stringify(filter), '[]', 0, -1).subscribe(res => {
            this.glass = res['data'][0];
        });
    }

    public getCanvasWrapByCode(slug: string) {
        const filter = [];
        filter.push(['slug', '=', slug]);

        this.canvasWrapService.filter(JSON.stringify(filter), '[]', 0, -1).subscribe(res => {
            this._canvasWrap = res['data'][0];
        });
    }

    public getCartById(id: number) {
        this.cartService.find(id).subscribe(res => {
            this._cart = res['data'];
            this._customizedProduct = this._cart.customizedProduct;
            this._customizedProductItem = this._customizedProduct.customizedProductItems[0];
        });
    }

    public getWishlistItemById(id: number) {
        this.wishlistItemService.find(id).subscribe(res => {
            this._wishlist_item = res['data'];
            this._customizedProduct = this._wishlist_item.customizedProduct;
            this._customizedProductItem = this._customizedProduct.customizedProductItems[0];
        });
    }

    public getMountByCode(mount_code: string, selectedMat: number) {
        const filter = [];
        filter.push(['code', '=', mount_code]);
        let mount: Mount;

        this.mountService.filter(JSON.stringify(filter), '[]', 0, -1).subscribe(res => {
            mount = res['data'][0];
            // switch (selectedMat) {
            //     case 1:
            //         mount = res['data'][0];
            //         break;
            //     case 2:
            //         mount = res['data'][0];
            //         break;
            //     case 3:
            //         mount = res['data'][0];
            //         break;
            // }
            this.onMat(mount, selectedMat);
        });
    }


    public getMounts1() {
        const filter = [];
        filter.push(['status', '=', 1]);

        if (this._mat1Color != '') {
            filter.push(['name', '=', this._mat1Color]);
        }
        this.mountService.filter(JSON.stringify(filter), '[]', 0, -1).subscribe(res => {
            this.mounts1 = res['data'];
        });
    }

    public getMounts2() {
        const filter = [];
        filter.push(['status', '=', 1]);

        if (this._mat2Color != '') {
            filter.push(['name', '=', this._mat2Color]);
        }
        this.mountService.filter(JSON.stringify(filter), '[]', 0, -1).subscribe(res => {
            this.mounts2 = res['data'];
        });
    }

    public getMounts3() {
        const filter = [];
        filter.push(['status', '=', 1]);

        if (this._mat3Color != '') {
            filter.push(['name', '=', this._mat3Color]);
        }
        this.mountService.filter(JSON.stringify(filter), '[]', 0, -1).subscribe(res => {
            this.mounts3 = res['data'];
        });
    }

    public getMatColors() {
        this.mountService.getMatColors().subscribe(res => {
            this._matColors = res['data'];
        });
    }

    public getMatWidths() {
        this.mountService.getMatWidths().subscribe(res => {
            this._matWidths = res['data'];
        });
    }

    public getGlasses() {
        const filter = [];
        filter.push(['status', '=', 1]);

        this.glassService.filter(JSON.stringify(filter), '[]', 0, -1).subscribe(res => {
            this.glasses = res['data'];
            this.glass = this.glasses[0];
        });

        // this.appService.getGlasses().subscribe(data => {
        //     this.glasses = data;
        //     this.glass = this.glasses[0];
        //
        //     // this.mount = this.mounts[0];
        //     // this.frameBorderWidth = 20;
        //     // this.frameBorderImage = "url('" + this.frame.whole_frame_image + "') 50 stretch stretch";
        // });
    }

    public getCanvasWraps() {
        const filter = [];
        filter.push(['status', '=', 1]);

        this.canvasWrapService.filter(JSON.stringify(filter), '[]', 0, -1).subscribe(res => {
            this.canvasWraps = res['data'];
            this.canvasWrap = this.canvasWraps[0];
        });
    }

    public onFrameCustomization() {
        if (this.productType.slug == 'framing') {
            this._frameCustomizationSelectedIndex = 3;
        }

        // if (this.productType == 'framing') {
        //     this._frameCustomizationSelectedIndex = 0;
        // }
        this.sidenav.toggle();
    }

    public onMatCustomization() {
        if (this.productType.slug == 'framing') {
            this._frameCustomizationSelectedIndex = 1;
        }

        // if (this.productType == 'framing') {
        //     this._frameCustomizationSelectedIndex = 1;
        // }
        this.sidenav.toggle();
    }

    public onGlassCustomization() {
        if (this.productType.slug == 'framing') {
            this._frameCustomizationSelectedIndex = 2;
        }

        // if (this.productType == 'framing') {
        //     this._frameCustomizationSelectedIndex = 2;
        // }
        this.sidenav.toggle();
    }

    public onPrintSizeCustomization() {
        if (this.productType.slug == 'framing') {
            this._frameCustomizationSelectedIndex = 0;
            // this._frameCustomizationSelectedIndex = 3;
        }
        if (this.productType.slug == 'canvas') {
            this._frameCustomizationSelectedIndex = 0;
        }
        this._frameCustomizationSelectedIndex = 0;

        // if (this.productType == 'framing') {
        //     this._frameCustomizationSelectedIndex = 3;
        // }
        // if (this.productType == 'canvas') {
        //     this._frameCustomizationSelectedIndex = 1;
        // }
        this.sidenav.toggle();
    }

    // public onCanvasTypeCustomization() {
    //     if (this.productType.slug == 'canvas') {
    //         this._frameCustomizationSelectedIndex = 1;
    //     }
    //
    //     // if (this.productType == 'canvas') {
    //     //     this._frameCustomizationSelectedIndex = 0;
    //     // }
    //     this.sidenav.toggle();
    // }

    public onCanvasWrapCustomization() {
        if (this.productType.slug == 'canvas') {
            this._frameCustomizationSelectedIndex = 1;
        }

        this.sidenav.toggle();
    }


    public onProductSubType(productSubType: string) {
        this._productSubType = productSubType;
    }

    private refreshCustomizedProductItem() {
        this._customizedProductItem.frame_id = this.frame ? this.frame.id : 0;
        this._customizedProductItem.frame_rate = this.frame ? this.frame.length_rate_ft : 0;

        this._customizedProductItem.mount_id_1 = this.mount1 ? this.mount1.id : 0;
        this._customizedProductItem.mount_rate_1 = this.mount1 ? this.mount1.mount_rate : 0;
        this._customizedProductItem.mount_size_1 = this.mount1 ? this.mat1Width : 0;

        this._customizedProductItem.mount_id_2 = this.mount2 ? this.mount2.id : 0;
        this._customizedProductItem.mount_rate_2 = this.mount2 ? this.mount2.mount_rate : 0;
        this._customizedProductItem.mount_size_2 = this.mount2 ? this.mat2Width : 0;

        this._customizedProductItem.mount_id_3 = this.mount3 ? this.mount3.id : 0;
        this._customizedProductItem.mount_rate_3 = this.mount3 ? this.mount3.mount_rate : 0;
        this._customizedProductItem.mount_size_3 = this.mount3 ? this.mat3Width : 0;

        this._customizedProductItem.product_category_id = this._printType ? this._printType.id : 0;
        this._customizedProductItem.paper_id = this._printSurface ? this._printSurface.id : 0;        // printSurface => paper
        this._customizedProductItem.print_rate = 0;
        this._customizedProductItem.image_size = this._printSize;        // image_size => Print Size

        this._customizedProductItem.canvas_wrap_id = this._canvasWrap ? this._canvasWrap.id : 0;
        this._customizedProductItem.canvas_rate = 0;
        this._customizedProductItem.glass_id = this.glass ? this.glass.id : 0;
        this._customizedProductItem.glass_rate = 0;      // ToDo: get Glass Rate dynamically

        this._customizedProductItem.final_size = this.finalSize;
        this._customizedProductItem.price = this._price;

        const image_detail: any = {};
        image_detail.image_id = this._params.image_id;
        image_detail.image_path = this._image_path;
        image_detail.image_caption = this._image_caption;
        image_detail.image_photographer = this._image_photographer;
        image_detail.size = this._printSize;
        image_detail.xposition = 0;
        image_detail.yposition = 0;

        this._customizedProductItem.image_detail = image_detail;

        const customizedProductItems: CustomizedProductItem[] = [];
        customizedProductItems.push(this._customizedProductItem);
        this._customizedProduct.customizedProductItems = customizedProductItems;
    }

    private refreshCustomizedProduct(type: string) {
        // let cartDetail = new CartDetail();

        this._customizedProduct.customer_id = (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) ? this.authenticationService.currentUserValue.id : 0;
        this._customizedProduct.layout_id = 0;
        this._customizedProduct.predefine_layout_id = 0;
        this._customizedProduct.collage_layout_id = 0;
        this._customizedProduct.no_of_product_types = 1;
        this._customizedProduct.product_id = this._product.id;
        this._customizedProduct.product_type_id = this._productType.id;
        this._customizedProduct.url = this._url;
        console.log('ICTSoft', 'this._url: ' + this._url);

        if (type == 'cart') {
            this._cart.customizedProduct = this._customizedProduct;
        }
        if (type == 'wishlist_item') {
            console.log('ICTSoft wishlist_item', this._wishlist_item);
            this._wishlist_item.customizedProduct = this._customizedProduct;
        }


        // let customizedProducts: CustomizedProduct[] = [];
        // customizedProducts.push(this._customizedProduct);
        // this._cart.customizedProducts = customizedProducts;
    }

    public onAddToWishList() {
        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            const dialogRef = this.dialog.open(WishlistQuickDialogComponent, {
                data: {
                    customer_address_id: 0
                },
                disableClose: true,
                autoFocus: false
            });
            const close = dialogRef.componentInstance.onClose.subscribe(data => {
                // console.log("ICTSoft onclose", data.wishlist_id);
                dialogRef.close();
                this._wishlist_item.wishlist_id = data.wishlist_id;

                if (this._cart && this._cart.id > 0) {
                    this.moveCartToWishlistItem(data.wishlist_id);
                } else {
                    this.addToWishlist();
                }

            });
        } else {
            const dialogRef = this.dialog.open(SignInDialogComponent, {
                panelClass: 'sign-up-dialog-container',
                data: {
                    // foil,
                },
                disableClose: true,
                autoFocus: false

            });
            const close = dialogRef.componentInstance.onClose.subscribe(() => {
                dialogRef.close();
            });
        }
    }

    public onRemoveFromWishList() {
        const ids = [];
        ids.push(this.wishlist_item.id);
        const self = this;
        Swal.fire({
            title: 'Are you sure to remove item from wishlist??',
            text: 'You won\'t be able to revert this!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, remove it!',
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            preConfirm() {
                return new Promise((resolve) => {
                    self.wishlistItemService.delete(JSON.stringify(ids))
                        .pipe(
                            catchError(() => of([])),
                            finalize(() => resolve())
                        )
                        .subscribe(
                            res => {
                            });
                });
            }
        }).then((result) => {
            if (result.value) {
                self.wishlist_item = new WishlistItem();
                self.updateUrl();
                // this.getCarts();
                // Swal.fire(
                //     'Deleted!',
                //     'Cart item has been removed.',
                //     'success'
                // );
            }
        });

    }

    private addToWishlist() {
        this.refreshCustomizedProduct('wishlist_item');
        if (this._wishlist_item.id == 0) {
            this.wishlistItemService.add(this._wishlist_item)
                .pipe(
                    finalize(() => {
                        this.updateUrl();
                        // this.loadingSubject.next(false);
                        // this.form.enable();
                    })
                )
                .subscribe(
                    res => {
                        console.log('ICTSoft', res);
                        this._wishlist_item = res['data'];
                        this._customizedProduct = this._wishlist_item.customizedProduct;
                        this._customizedProductItem = this._customizedProduct.customizedProductItems[0];
                    });
        } else {
            this.wishlistItemService.update(this._wishlist_item)
                .pipe(
                    finalize(() => {
                        this.updateUrl();
                    })
                )
                .subscribe(
                    res => {
                        console.log('ICTSoft', res);
                        this._wishlist_item = res['data'];
                    });
        }

    }

    public onAddToCart() {

        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            if (this.wishlist_item && this.wishlist_item.id > 0) {
                this.moveWishlistItemToCart();
            } else {
                this.addToCart();
            }
        } else {
            const dialogRef = this.dialog.open(SignInDialogComponent, {
                panelClass: 'sign-up-dialog-container',
                data: {
                    // foil,
                },
                disableClose: true,
                autoFocus: false

            });
            const close = dialogRef.componentInstance.onClose.subscribe(() => {
                dialogRef.close();
            });
        }

    }

    private addToCart() {
        // this.appService.Data.totalCartCount++;

        this.refreshCustomizedProduct('cart');
        console.log('ICTSoft', this._cart);
        if (this._cart.id == 0) {
            this.cartService.add(this._cart)
                .pipe(
                    finalize(() => {
                        this.updateUrl();
                        // this.loadingSubject.next(false);
                        // this.form.enable();
                    })
                )
                .subscribe(
                    res => {
                        console.log('ICTSoft', res);
                        this._cart = res['data'];
                        this._customizedProduct = this._cart.customizedProduct;
                        this._customizedProductItem = this._customizedProduct.customizedProductItems[0];
                    });
        } else {
            this.cartService.update(this._cart)
                .pipe(
                    finalize(() => {
                        this.updateUrl();
                        // this.loadingSubject.next(false);
                        // this.form.enable();
                    })
                )
                .subscribe(
                    res => {
                        console.log('ICTSoft', res);
                        this._cart = res['data'];
                    });
        }
    }

    public moveWishlistItemToCart() {
        const wishlist_item_id = this._wishlist_item.id;
        this._wishlist_item = new WishlistItem();
        this._cart = new Cart();
        this.updateUrl();
        this.appService.moveWishlistItemToCart(wishlist_item_id, this._url)
            .pipe(
                finalize(() => {
                    this.updateUrl();
                    // this.loadingSubject.next(false);
                    // this.form.enable();
                })
            )
            .subscribe(
                res => {
                    console.log('ICTSoft', res);
                    this._cart = res['data'];
                    // this._wishlist_item = new WishlistItem();
                    // this.updateUrl();
                });

    }

    public moveCartToWishlistItem(wishlist_id: number) {
        const cart_id = this._cart.id;
        this._cart = new Cart();
        this._wishlist_item = new WishlistItem();
        this.updateUrl();
        this.appService.moveCartToWishlistItem(cart_id, wishlist_id, this._url)
            .pipe(
                finalize(() => {
                    this.updateUrl();
                    // this.loadingSubject.next(false);
                    // this.form.enable();
                })
            )
            .subscribe(
                res => {
                    console.log('ICTSoft', res);
                    this._wishlist_item = res['data'];
                    // this._wishlist_item = new WishlistItem();
                    // this.updateUrl();
                });

    }

    public addToCartOld1() {

        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            this.appService.Data.totalCartCount++;
            // let cart: Cart = new Cart();
            // let cartDetail = new CartDetail();
            //
            // cart.customer_id = 1;
            // cart.layout_id = 0;
            // cart.predefine_layout_id = 0;
            // cart.collage_layout_id = 0;
            // cart.no_of_product_types = 1;
            // cart.product_id = this._product.id;
            // cart.product_type_id = this._productType.id;
            // cart.url = this._url;
            //
            // cartDetail.cart_id = 0;
            // cartDetail.frame_id = this.frame.id;
            // cartDetail.frame_rate = this.frame.length_rate_ft;
            // cartDetail.frame_size = this.frame.length_size_ft;
            // cartDetail.mount_id_1 = this.mount1 ? this.mount1.id : 0;
            // cartDetail.mount_rate_1 = this.mount1 ? this.mount1.mount_rate : 0;
            // cartDetail.mount_size_1 = this.mount1 ? this.mat1Width : 0;
            //
            // cartDetail.mount_id_2 = this.mount2 ? this.mount2.id : 0;
            // cartDetail.mount_rate_2 = this.mount2 ? this.mount2.mount_rate : 0;
            // cartDetail.mount_size_2 = this.mount2 ? this.mat2Width : 0;
            //
            // cartDetail.mount_id_3 = this.mount3 ? this.mount3.id : 0;
            // cartDetail.mount_rate_3 = this.mount3 ? this.mount3.mount_rate : 0;
            // cartDetail.mount_size_3 = this.mount3 ? this.mat3Width : 0;
            //
            // cartDetail.paper_id = this._printSurface.id;
            // cartDetail.print_rate = 0;
            // cartDetail.canvas_id = 0;       //ToDo: get Canvase Id dynamically
            // cartDetail.canvas_rate = 0;
            // cartDetail.glass_id = 0;        //ToDo: get Glass Id dynamically
            // cartDetail.glass_rate = 0;      //ToDo: get Glass Rate dynamically
            // cartDetail.image_detail = [this.printSize];
            //
            // let cartDetails: CartDetail[] = [];
            // cartDetails.push(cartDetail);
            // cart.cartDetails = cartDetails;

            this.refreshCustomizedProduct('cart');
            console.log('ICTSoft', this._cart);
            if (this._cart.id == 0) {
                this.cartService.add(this._cart)
                    .pipe(
                        finalize(() => {
                            this.updateUrl();
                            // this.loadingSubject.next(false);
                            // this.form.enable();
                        })
                    )
                    .subscribe(
                        res => {
                            console.log('ICTSoft', res);
                            this._cart = res['data'];
                            this._customizedProduct = this._cart.customizedProduct;
                            this._customizedProductItem = this._customizedProduct.customizedProductItems[0];

                            // const productPricing: ProductPricing = res["data"];
                            // this.loadingSubject.next(false);
                            // this.onClose.emit();
                            // this.onReset.emit();
                            // this.matSnackBar.open('Record has been successfully added!', 'x', {
                            //   duration: 2000,
                            //   verticalPosition: 'top',
                            //   panelClass: ['success']
                            // });
                        });
            } else {
                this.cartService.update(this._cart)
                    .pipe(
                        finalize(() => {
                            this.updateUrl();
                            // this.loadingSubject.next(false);
                            // this.form.enable();
                        })
                    )
                    .subscribe(
                        res => {
                            console.log('ICTSoft', res);
                            this._cart = res['data'];
                            // const productPricing: ProductPricing = res["data"];
                            // this.loadingSubject.next(false);
                            // this.onClose.emit();
                            // this.onReset.emit();
                            // this.matSnackBar.open('Record has been successfully added!', 'x', {
                            //   duration: 2000,
                            //   verticalPosition: 'top',
                            //   panelClass: ['success']
                            // });
                        });
            }
        } else {
            const dialogRef = this.dialog.open(SignInDialogComponent, {
                panelClass: 'sign-up-dialog-container',
                data: {
                    // foil,
                },
                disableClose: true,
                autoFocus: false

            });
            const close = dialogRef.componentInstance.onClose.subscribe(() => {
                dialogRef.close();
            });
        }

    }

    private _calculatePrice() {
        // this.refreshCart();
        this.cartService.calculatePrice(this._cart)
            .pipe(
                finalize(() => {
                    // this.loadingSubject.next(false);
                    // this.form.enable();
                })
            )
            .subscribe(
                res => {
                    this._price = res['data'].price;
                    // console.log("ICTSoft", res["data"].price);
                });
    }

}
