import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import { City} from '../models/city.model';

@Injectable({
  providedIn: 'root'
})
export class CityService {
  private apiUrl = '';

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrlUnauth;
  }

  findCities(value = '', orderBy = '', pageNumber = 0, pageSize = 0): Observable<City[]> {
    var httpParams = new HttpParams();
    if (typeof value === 'string') {
      httpParams = httpParams.append('filter', JSON.stringify([["search_key", value]]));
      if(orderBy || orderBy != '') {
        httpParams = httpParams.append('orderBy', orderBy);
      }
      httpParams = httpParams.append('pageNumber', pageNumber.toString());
      if(pageSize || pageSize > 0) {
        httpParams = httpParams.append('pageSize', pageSize.toString());
      }
      return this.http.get<City[]>(this.apiUrl + "/city", {
          params: httpParams
        })
        .pipe(
          map(res =>  res),
          catchError(this.handleError('getCities', []))
        );
    }
    else {
      let cities: City[] = []
      cities.push(value);
      return of(cities);
    }
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
