import {Component, Input, OnInit} from '@angular/core';
import {SwiperConfigInterface, SwiperPaginationInterface} from 'ngx-swiper-wrapper';
import {Testimonial} from '../../admin/models/testimonial.model';


@Component({
    selector: 'app-text-carousel',
    templateUrl: './text-carousel.component.html',
    styleUrls: ['./text-carousel.component.scss']
})
export class TextCarouselComponent implements OnInit {
    @Input('slides') slides: Array<any> = [];

    public config: SwiperConfigInterface = {};
    private pagination: SwiperPaginationInterface = {
        el: '.swiper-pagination',
        clickable: true
    };

    constructor() {
    }

    ngOnInit() {
    }


    ngAfterViewInit() {
        // this.config = {
        //     slidesPerView: 2,
        //     spaceBetween: 30,
        //     keyboard: true,
        //     navigation: false,
        //     pagination: this.pagination,
        //     grabCursor: true,
        //     loop: false,
        //     preloadImages: false,
        //     lazy: true,
        //     autoplay: {
        //         delay: 6000,
        //         disableOnInteraction: false
        //     },
        //     speed: 500,
        //     effect: 'slide'
        // };

        this.config = {
            slidesPerView: 1,
            spaceBetween: 0,
            keyboard: true,
            navigation: true,
            pagination: this.pagination,
            grabCursor: true,
            loop: false,
            preloadImages: false,
            lazy: true,
            autoplay: {
                delay: 6000,
                disableOnInteraction: false
            },
            speed: 500
        };
    }

}
