export class Category {
    constructor(public id: number,
                public name: string,
                public hasSubCategory: boolean,
                public parentId: number,
                public product_id: number,
                public subproduct: number,
                public price_type: string,
                public product_type: number,
                public status: boolean) {
    }
}

export class ProductPricing {
    constructor(public id: number,
                public price_type: string,
                public rate: number,
                public company_margin_rate: number,
                public mrp_rate: number,
                public discount_rate: number,
                public max_display_rate: number,
                public company_margin_price: number = 0,
                public mrp_price: number = 0,
                public discount_price: number = 0,
                public max_display_price: number = 0,
                public discount_per = 0
    ) {
    }
}

export class Product {
    constructor(public id: number,
                public name: string,
                public images: Array<any>,
                public oldPrice: number,
                public newPrice: number,
                public discount: number,
                public ratingsCount: number,
                public ratingsValue: number,
                public description: string,
                public availibilityCount: number,
                public cartCount: number,
                public color: Array<string>,
                public size: Array<string>,
                public weight: number,
                public categoryId: number) {
    }
}


export class ProductImage {
    constructor(public id: number,
                public title: string,
                public category_name: string,
                public image_id: string,
                public image_photographer: string,
                public image_filename: string,
                public image_caption: string,
                public image_category_id: string,
                public image_collection_id: string,
                public image_quality_grade: string,
                public wishlist_item_id: number) {
    }
}

export class ProductList {
    constructor(public total: number,
                public category_name: string,
                public cat_slug: string,
                public data: Array<ProductImage>,
                public results: Array<ProductImage>,
                public facet_field: Array<any>,
                public price: any) {

    }

}

export class User {
    constructor(public id: number,
                public customers_id: string,
                public customers_email_address: string,
                public customers_password: string,
                public token: string,
                public role_id: number) {
    }
}

export class WallArtCategory {
    constructor(public id: number,
                public cat_slug: string,
                public sub_cat_slug: string,
                public title: string,
                public image: string,
                public image2: string,
                public image3: string,
                public collection_id: number,
                public indexed_images: number,
                public status: boolean) {
    }
}

export class Role {
    constructor(public id: number,
                public name: string) {
    }
}

export class SeoMeta {
    constructor(
        public id: number,
        public page_url: string,
        public title: string,
        public description: string,
        public keyword: string,
        public robots: string,
        public canonical: string,
        public ogTitle: string,
        public ogUrl: string,
        public ogDesc: string,
        public ogImage: string,
        public ogType: string,
        public ogSite: string,
        public ogLocale: string,
        public schema: string
    ) {
    }
}

export class RetailerDiscount {
    constructor(public id: number,
                public name: string,
                public product_type_id: number,
                public discount: number = 0,
                public extra_discount: number = 0,
                public commission: number = 0
    ) {
    }
}
