import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CustomizedProduct} from 'src/app/admin/models/customized_product.model';
import {Params} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-customized-product-details-dialog',
    templateUrl: './customized-product-details-dialog.component.html',
    styleUrls: ['./customized-product-details-dialog.component.scss']
})
export class CustomizedProductDetailsDialogComponent implements OnInit {

    public inch = 8;

    constructor(
        public dialogRef: MatDialogRef<CustomizedProductDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { type: string, customizedProduct: CustomizedProduct, pricingDetails: Array<any>, wallpaperSize: string }
    ) {
    }

    public getImageName(customizedProduct: CustomizedProduct, index = 0) {
        let image_name: string = customizedProduct.customizedProductItems[index].image_detail.image_path.split('/').pop();

        // if (customizedProduct?.collage_layout_detail?.collage_type?.is_single_frame == 1) {
        //     image_name = '';
        //     for (let i = 0; i < customizedProduct.frame_detail[index].images.length; i++) {
        //         image_name += customizedProduct.frame_detail[index].images[i].backgroundImage.split('/').pop();
        //
        //         if (customizedProduct.frame_detail[index].images.length > 0) {
        //             image_name += ',';
        //         }
        //     }
        // }


        return image_name;
    }

    public isFrame(customizedProduct: CustomizedProduct): boolean {
        switch (customizedProduct.product_type_id) {
            case 2:
            case 5:
            case 8:
            case 1:
                return true;
        }
        return false;
    }

    public isCanvas(customizedProduct: CustomizedProduct): boolean {
        switch (customizedProduct.product_type_id) {
            case 1:
            case 4:
                return true;
        }
        return false;
    }

    public get customizedProduct() {
        return this.data.customizedProduct;
    }

    public get pricingDetails() {
        const price = this.data.pricingDetails;
        if (!price['installation_amount']) {
            price['installation_amount'] = 0;
        }
        if (!price['installation_tax']) {
            price['installation_tax'] = 0;
        }
        return price;
    }

    /*
      cart, order-details, product-customization
    */
    public get type() {
        return this.data.type;
    }

    public canvasWrapType: string;

    ngOnInit(): void {
        this.initQueryParams();
        if (this.params['product_type'] == 'canvas') {
            this.canvasWrapType = this.params['canvas_wrap_item'].split('-')[1];
        }
    }


    public params: Params = [];
    sampleSize = environment.sampleSize;

    public initQueryParams() {
        let self = this;
        let url = this.data.customizedProduct.url;
        let urlParams = url.split('?')[1].split('&');
        urlParams.forEach(function(param) {
            let paramKeyValue = param.split('=');
            self.params[paramKeyValue[0]] = paramKeyValue[1];
        });
    }


    onConfirm(): void {
        this.dialogRef.close(true);
    }

    onDismiss(): void {
        this.dialogRef.close(false);
    }

    public close(): void {
        this.dialogRef.close();
    }

}
