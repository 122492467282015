import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {PredefinedLayoutDetail} from '../models/art_set_master.model';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PredefinedLayoutDetailService {
    private apiUrlAuth = '';
    private apiUrlUnAuth = '';

    constructor(private http: HttpClient) {
        this.apiUrlAuth = environment.apiUrlauth;
        this.apiUrlUnAuth = environment.apiUrlUnauth;
    }

    filter(filter = '[]', orderBy = '[]', pageNumber = 0, pageSize = 10): Observable<PredefinedLayoutDetail[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('filter', filter);
        if (orderBy || orderBy != '') {
            httpParams = httpParams.append('orderBy', orderBy);
        }
        httpParams = httpParams.append('pageNumber', pageNumber.toString());
        if (pageSize || pageSize > 0) {
            httpParams = httpParams.append('pageSize', pageSize.toString());
        }
        return this.http.get<PredefinedLayoutDetail[]>(this.apiUrlUnAuth + '/predefined_layout_detail', {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('filter', []))
            );
    }

    find(id = 0, size: 0): Observable<PredefinedLayoutDetail> {
        let httpParams = new HttpParams();
        return this.http.get<PredefinedLayoutDetail>(this.apiUrlUnAuth + '/predefined_layout_detail/' + id + '/' + size, {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('find', null))
            );
    }


    findById(id = 0): Observable<PredefinedLayoutDetail> {
        let httpParams = new HttpParams();
        return this.http.get<PredefinedLayoutDetail>(this.apiUrlUnAuth + '/predefined_layout_detail/' + id, {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('find', null))
            );
    }

    add(element: PredefinedLayoutDetail): Observable<PredefinedLayoutDetail> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.post<PredefinedLayoutDetail>(this.apiUrlAuth + '/predefined_layout_detail', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getPredefinedLayoutDetail', element))
            );
    }

    update(element: PredefinedLayoutDetail): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<PredefinedLayoutDetail>(this.apiUrlAuth + '/predefined_layout_detail', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getPredefinedLayoutDetail', element))
            );
    }

    updateStatus(id: number, status: number): Observable<any> {
        const element: any = {
            id: id,
            status: status,
            form: 'predefined_layout_detail'
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<PredefinedLayoutDetail>(this.apiUrlAuth + '/status_change', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getBasicPaint', element))
            );
    }

    delete(ids = '[]'): Observable<any> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('ids', ids);
        return this.http.delete<any>(this.apiUrlAuth + '/predefined_layout_detail', {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('deletePredefinedLayoutDetail', null))
            );
    }

    updatePrice(): Observable<PredefinedLayoutDetail[]> {

        return this.http.get<PredefinedLayoutDetail[]>(this.apiUrlUnAuth + '/updatePriceAllPredefinedLayout', {})
            .pipe(
                map(res => res),
                catchError(this.handleError('filter', []))
            );
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
