import {Component, Input, OnInit} from '@angular/core';
import {CustomizedProduct} from 'src/app/admin/models/customized_product.model';
import {Params} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ArtSetPreviewDialogComponent} from '../art-set-preview-dialog/art-set-preview-dialog.component';

@Component({
    selector: 'app-product-preview',
    templateUrl: './product-preview.component.html',
    styleUrls: ['./product-preview.component.scss']
})
export class ProductPreviewComponent implements OnInit {
    @Input('customizedProduct') customizedProduct: CustomizedProduct;
    @Input('size') size: string;    //Small, Normal (Default)
    @Input('type') type: string;    //Small, Normal (Default)
    inch = 8;
    public canvasWrapSideBackground = '';
    params: Params = [];

    constructor(private dialog: MatDialog) {
    }

    private _imageWidth = 100;

    public get imageWidth() {
        return this._imageWidth;
    }

    private _imageHeight = 100;

    public get imageHeight() {
        return this._imageHeight;
    }

    ngOnInit() {
        if (window.innerWidth < 650) {
            this._imageWidth = 100;
            this._imageHeight = 100;
        }
        if (this.size === 'small') {
            this._imageWidth = 80;
            this._imageHeight = 60;
        }
        if (this.size === 'large') {
            this._imageWidth = 300;
            this._imageHeight = 300;
        }
        if (this.type === 'wishlist') {
            this._imageWidth = 250;
            this._imageHeight = 250;
        }
        if (this.customizedProduct.product_type.slug === 'canvas') {
            this.canvasWrapSideBackground = this.customizedProduct.customizedProductItems[0].image_detail.image_path;
        }

        this.getQueryParams(this.customizedProduct.url);
    }

    public getQueryParams(url) {
        const self = this;
        const urlParams = url.split('?')[1].split('&');
        urlParams.forEach(function(param) {
            const paramKeyValue = param.split('=');
            self.params[paramKeyValue[0]] = paramKeyValue[1];
        });
    }

    previewDialog() {
        const dialogRef = this.dialog.open(ArtSetPreviewDialogComponent, {
            data: {
                customizedProduct: this.customizedProduct
            },
            width: !(this.customizedProduct.layout_id > 0 || this.customizedProduct.predefine_layout_id > 0 || this.customizedProduct.collage_layout_id > 0) ? 'auto' : '700px',
            height: '50vh',
            disableClose: true,
            autoFocus: false
        });
    }

}
