export class DesignType {
    id = 0;
    public name: string;
    public status: boolean;

    public constructor() {
        // this.source_category = new SourceCategory();
    }
}

export class WallpaperTheme {
    id = 0;
    public name: string;
    public status: boolean;

    public constructor() {
        // this.source_category = new SourceCategory();
    }
}

export class WallpaperCollection {
    id = 0;
    public name: string;
    public quality_grade: string;
    public status: boolean;

    public constructor() {
        // this.source_category = new SourceCategory();
    }
}

export class Tags {
    id = 0;
    public name: string;
    public categories: string;
    public status: boolean;

    public constructor() {
        // this.source_category = new SourceCategory();
    }
}

export class Wallpaper {
    id = 0;
    wallpaper_id: string;
    slug: string;
    name;
    keyword;
    caption;
    type;
    roll_width;
    roll_height;
    roll_size;
    total_no_of_rolls: 0;
    design_size: string;
    collection: any;
    design_replicates: false;
    design_variance_x: 0;
    design_variance_y: 0;
    design_variance_overall: 0;
    space_id;
    decor_style_id;
    design_type_id;
    collection_id;
    area_covered: 0;
    standard_size1: 0;
    standard_size2: 0;
    standard_size3: 0;
    standard_size4: 0;
    standard_size5: 0;
    aspect_ratio: 0;
    orientation: 0;
    default_paper: any;
    tags: any;
    colors_arr: WallpaperColor[];
    price: number;
    status: number;
    list_thumbnail: string;
    thumbnail: string;
    hover_thumbnail: string;
    wishlist_item_id: number;
    // recommended: false;
    // status: false;
    // max_color;
    // min_color;
    // highres;
    // texture;
    // object;
    // space1;
    // space2;
    // pattern;
    max_color: string;
    min_color: string;
    space1: string;
    space2: string;
    space3: string;
    space_side1: string;
    space_side2: string;
    space_side3: string;
    object: string;
    pattern: string;
    default_rolls: number;
    default_size: string;
    is_add_text: number;
    thumbnail_space_id: number = 0;
    thumbnail_space1_id: number = 0;
    thumbnail_space2_id: number = 0;

    public constructor() {
        // this.source_category = new SourceCategory();
    }
}

export class WallpaperColor {
    list_thumbnail: string;
    space_thumbnail: string;


    public constructor(public  id: number,
                       public wallpaper_id: number,
                       public wallpaper_no: string,
                       public recommended: boolean,
                       public status: boolean,
                       public max_color: string,
                       public min_color: string,
                       public highres: string,
                       public texture: string,
                       public object: string,
                       public space1: string,
                       public space2: string,
                       public pattern: string,
                       public highres_path: string,
                       public texture_path: string,
                       public object_path: string,
                       public space1_path: string,
                       public space2_path: string,
                       public pattern_path: string,
                       public thumbnail_space_id: number,
                       public thumbnail_space1_id: number,
                       public thumbnail_space2_id: number) {

    }


}
