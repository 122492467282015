import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {OrderService} from './order.service';
import {Order} from '../models/order.model';
import {Meta} from '../models/meta.model';

export class OrderDataSource implements DataSource<Order> {

    private orderSubject = new BehaviorSubject<Order[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();

    // public recordsTotal: number;
    // public recordsFiltered: number;
    // public pageSize: number;
    // public pageSizeOptions: number[];

    public data: Order[] = [];
    public meta: Meta = new Meta();

    constructor(private orderService: OrderService) {
    }

    getData(
        filter: string,
        orderBy: string,
        pageIndex: number,
        pageSize: number,
        pageSizeOptions: number[]
    ) {

        this.loadingSubject.next(true);

        this.orderService.filter(filter, orderBy, pageIndex, pageSize)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(
                res => {
                    this.data = res['data'];
                    this.meta = res['meta'];
                    this.meta.pageSizeOptions = pageSizeOptions;
                    this.orderSubject.next(res['data']);

                    // this.recordsTotal = res["meta"].recordsTotal;
                    // this.recordsFiltered = res["meta"].recordsFiltered;
                    // this.pageSize = res["meta"].pageSize;
                    // this.pageSizeOptions = pageSizeOptions;
//                this.pageSizeOptions = this.pageSizeOptions ? this.pageSizeOptions : Array.of(res["pageSizeOptions"].json());
                });
    }

    getDataOffline(
        filter: string,
        orderBy: string,
        pageIndex: number,
        pageSize: number,
        pageSizeOptions: number[]
    ) {

        this.loadingSubject.next(true);

        this.orderService.filterOffline(filter, orderBy, pageIndex, pageSize)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(
                res => {
                    this.data = res['data'];
                    this.meta = res['meta'];
                    this.meta.pageSizeOptions = pageSizeOptions;
                    this.orderSubject.next(res['data']);

                    // this.recordsTotal = res["meta"].recordsTotal;
                    // this.recordsFiltered = res["meta"].recordsFiltered;
                    // this.pageSize = res["meta"].pageSize;
                    // this.pageSizeOptions = pageSizeOptions;
//                this.pageSizeOptions = this.pageSizeOptions ? this.pageSizeOptions : Array.of(res["pageSizeOptions"].json());
                });
    }

    connect(collectionViewer: CollectionViewer): Observable<Order[]> {
        return this.orderSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.orderSubject.complete();
        this.loadingSubject.complete();
    }

    getDataOlder(
        customerId: string,
        pageSizeOptions: number[]
    ) {

        this.loadingSubject.next(true);

        this.orderService.filterOld(customerId)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(
                res => {
                    this.data = res['orderData'];

                    this.meta.pageNumber = 1;
                    this.meta.pageSize = 1000;
                    this.meta.recordsTotal = res['totalOrder'];
                    this.meta.recordsFiltered = res['totalOrder'];
                    this.meta.pageSizeOptions = pageSizeOptions;
                    this.orderSubject.next(res['data']);

                    // this.recordsTotal = res["meta"].recordsTotal;
                    // this.recordsFiltered = res["meta"].recordsFiltered;
                    // this.pageSize = res["meta"].pageSize;
                    // this.pageSizeOptions = pageSizeOptions;
//                this.pageSizeOptions = this.pageSizeOptions ? this.pageSizeOptions : Array.of(res["pageSizeOptions"].json());
                });
    }


}
