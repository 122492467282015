import {Inject, Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class SEOService {
    constructor(private title: Title, private meta: Meta, @Inject(DOCUMENT) private doc) {
    }

    //
    // createLinkForCanonicalURL(data: string) {
    //     if (data === undefined) {
    //     } else {
    //         let link: HTMLLinkElement = this.doc.createElement('link');
    //         link.setAttribute('rel', 'canonical');
    //         this.doc.head.appendChild(link);
    //         link.setAttribute('href', this.doc.URL);
    //     }
    // }

    updateCanonicalUrl(url: string) {
        const head = this.doc.getElementsByTagName('head')[0];
        let element: HTMLLinkElement = this.doc.querySelector(`link[rel='canonical']`) || null;
        if (element == null) {
            element = this.doc.createElement('link') as HTMLLinkElement;
            head.appendChild(element);
        }
        element.setAttribute('rel', 'canonical');
        element.setAttribute('href', url);
    }

    removeCanonicalUrl() {
        const head = this.doc.getElementsByTagName('head')[0];
        let element: HTMLLinkElement = this.doc.querySelector(`link[rel='canonical']`) || null;
        if (element != null) {
            element.remove();
        }
    }


    updateTitle(title: string) {
        if (title) {
            this.title.setTitle(title);
        } else {
            this.title.setTitle('Mahattaart');
        }
    }

    updateOgUrl(url: string) {
        this.meta.updateTag({name: 'og:url', content: url});
    }


    updateData(name: string, data: string) {
        if (data) {
            if (data === undefined) {
                this.meta.updateTag({name, content: ''});
            } else {
                this.meta.updateTag({name, content: data});
            }
        } else {
            console.log('in else');
            this.meta.removeTag('name=' + name);
        }
    }

    updateDataOG(name: string, data: string) {
        // if (data) {
        //     if (data === undefined) {
        //         this.meta.updateTag({property: name, content: ''});
        //     } else {
        //         this.meta.updateTag({property: name, content: data});
        //     }
        // } else {
        //     this.meta.removeTag('property=' + name );
        // }
        const attributeSelector = `name="${name}"`;
        this.meta.removeTag(attributeSelector);

        if (data) {
            const tag = {name, content: data};
            this.meta.addTag((tag) as any, false);
        }

    }
}
