import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Frame} from "../../admin/models/frame.model";
import {Mount} from "../../admin/models/mount.model";
import {CanvasWrap} from "../../admin/models/canvas_wrap.model";
import {Glass} from "../../admin/models/glass.model";
import {CustomizedProductItem} from "../../admin/models/customized_product_item.model";
import {finalize} from "rxjs/operators";
import {Paper} from "../../admin/models/paper.model";
import {CartService} from "../../admin/services/cart.service";
import {AuthenticationService} from "../../authentication.service";
import {Cart} from "../../admin/models/cart.model";
import {Product} from "../../admin/models/product.model";
import {ProductType} from "../../admin/models/product_type.model";
import {ProductTypeService} from "../../admin/services/product_type.service";
import {CustomizedProduct} from "../../admin/models/customized_product.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {CartLoginAthentiacationService} from "../../cart-login-athentiacation.service";

@Component({
    selector: 'app-book-consultation',
    templateUrl: './book-consultation.component.html',
    styleUrls: ['./book-consultation.component.scss']
})
export class BookConsultationComponent implements OnInit {
    customizedProduct: CustomizedProduct = {} as CustomizedProduct;
    customizedProductItem = {} as CustomizedProductItem;
    private product: Product;
    private productType: ProductType;
    @Input('wallpaperId') wallpaperId=0;
    @Input('path') path='';

    constructor(private router: Router, private cartService: CartService,
                private authenticationService: AuthenticationService,
                private productTypeService: ProductTypeService,
                private snackbar: MatSnackBar, public dialog: MatDialog,
                private cartLoginAthentiacationService: CartLoginAthentiacationService) {
    }

    ngOnInit(): void {
        this.getProductType();
    }

    private cart: Cart = new Cart();

    getProductType() {
        const filter = [];
        filter.push(['product_type.id', "=", 24]);

        const order = [];
        order.push(['product_type.id', 'desc']);
        this.productTypeService.filter(JSON.stringify(filter), JSON.stringify(order)).subscribe(res => {
            this.productType = res['data'][0];
            this.product = this.productType.product;
        });
    }

    private refreshCustomizedProductItem() {
        this.customizedProduct.product = this.product;
        this.customizedProduct.product_type = this.productType;
        this.customizedProduct.product_type_id = this.productType.id;

        this.customizedProductItem.frame = {} as Frame;
        this.customizedProductItem.frame_id = 0;
        this.customizedProductItem.frame_rate = 0;

        this.customizedProductItem.mount_1 = {} as Mount;
        this.customizedProductItem.mount_id_1 = 0;
        this.customizedProductItem.mount_rate_1 = 0;
        this.customizedProductItem.mount_size_1 = 0;

        this.customizedProductItem.mount_2 = {} as Mount;
        this.customizedProductItem.mount_id_2 = 0;
        this.customizedProductItem.mount_rate_2 = 0;
        this.customizedProductItem.mount_size_2 = 0;

        this.customizedProductItem.mount_3 = {} as Mount;
        this.customizedProductItem.mount_id_3 = 0;
        this.customizedProductItem.mount_rate_3 = 0;
        this.customizedProductItem.mount_size_3 = 0;

        this.customizedProductItem.product_category_id = 0;
        this.customizedProductItem.paper = {} as Paper;
        this.customizedProductItem.paper_id = 0;        //printSurface => paper
        this.customizedProductItem.print_rate = 0;
        this.customizedProductItem.image_size = "0X0";        //image_size => Print Size

        this.customizedProductItem.canvas_wrap = {} as CanvasWrap;
        this.customizedProductItem.canvas_wrap_id = 0;
        this.customizedProductItem.canvas_rate = 0;
        this.customizedProductItem.glass = {} as Glass;
        this.customizedProductItem.glass_id = 0;
        this.customizedProductItem.glass_rate = 0;
        this.customizedProductItem.is_table_frame = 0;

        this.customizedProductItem.final_size = "0X0";
        this.customizedProductItem.price = 1000;

        let image_detail: any = {};
        image_detail['image_id'] = "Consultation";
        image_detail['image_path'] = this.path;
        image_detail['image_caption'] = "Above Mentioned Items are Totally Free of Cost, These 1000/- will adjusted at the time of order.  (Applicable on Wallpapers Order above 3500/-)";
        image_detail['image_photographer'] = "Consultation";
        image_detail['size'] = "0X0";
        image_detail['xposition'] = 0;
        image_detail['yposition'] = 0;
        image_detail['quality_grade'] = '';

        this.customizedProductItem.image_detail = image_detail;

        const customizedProductItems: CustomizedProductItem[] = [];
        customizedProductItems.push(this.customizedProductItem);
        this.customizedProduct.customizedProductItems = customizedProductItems;
    }

    private refreshCustomizedProduct() {
        // let cartDetail = new CartDetail();

        this.customizedProduct.customer_id = (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) ? this.authenticationService.currentUserValue.id : 0;
        this.customizedProduct.layout_id = 0;
        this.customizedProduct.wallpaper_id = this.wallpaperId;
        this.customizedProduct.predefine_layout_id = 0;
        this.customizedProduct.collage_layout_id = 0;
        this.customizedProduct.no_of_product_types = 1;
        this.customizedProduct.product_id = this.product.id;
        this.customizedProduct.product_type_id = this.productType.id;
        this.customizedProduct.url = 'consultation?product=consultation&product_type=wallpaper_consultation';
        this.customizedProduct.quantity = 1;
        this.customizedProduct.installation_pincode = '';
        this.cart.customizedProduct = this.customizedProduct;
    }


    private addToCart() {
        // this.appService.Data.totalCartCount++;

        this.refreshCustomizedProduct();
        this.refreshCustomizedProductItem();
        // if (this.cart.id == 0) {

        this.cartService.add(this.cart)
            .pipe(
                finalize(() => {
                    this.router.navigate(['/cart-view']);
                })
            )
            .subscribe(
                res => {
                    this.cart = res['data'];
                    this.customizedProduct = this.cart.customizedProduct;
                    this.customizedProductItem = this.customizedProduct.customizedProductItems[0];
                });

    }

    onAddToCart() {
        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {

            this.addToCart();

            this.snackbar.open('Consultation added to cart successfully!', '×', {
                panelClass: 'success',
                verticalPosition: 'top',
                duration: 3000
            });

        } else {

            const dialogRef = this.cartLoginAthentiacationService.checkIsRegisteredUser();
            const close = dialogRef.componentInstance.onClose.subscribe(() => {
                dialogRef.close();
                if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {

                    this.addToCart();
                    this.snackbar.open('Consultation added to cart successfully!', '×', {
                        panelClass: 'success',
                        verticalPosition: 'top',
                        duration: 3000
                    });
                }
            });

        }
    }
}
