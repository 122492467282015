import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SwiperConfigInterface, SwiperDirective, SwiperPaginationInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-spaces',
  templateUrl: './spaces.component.html',
  styleUrls: ['./spaces.component.scss']
})
export class SpacesComponent implements OnInit {
  slides: Array<any> = [];
  sectionCount = 1;
  shownavigation = true;
  @ViewChild(SwiperDirective) swiperView: SwiperDirective;

  public bannerLeftMd = 70;
  public bannerLeftSm = 60;
  public containerPadding = '';
  public bannerRightShow = true;
  public isMobile = false;
  public config: SwiperConfigInterface = {};
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };
  height = '600px';
  mobileHeight = '280px';

  constructor() {

    this.slides = [
      {
        image: ".../../.../../assets/Mockup/1/wallpaper.jpg",
        mobile_image: ".../../.../../assets/Mockup/1/wallpaper.jpg",
        name: "BEDROOMS",
        width: "10",
        height: "12",
        link:"Bedroom"
      },
      {
        image: ".../../.../../assets/Mockup/3/wallpaper.jpg",
        mobile_image: ".../../.../../assets/Mockup/3/wallpaper.jpg",
        name: "LIVING ROOMS",
        width: "10",
        height: "12",
        link:"Living Room"
      },
      {
        image: ".../../.../../assets/Mockup/10/wallpaper.jpg",
        mobile_image: ".../../.../../assets/Mockup/10/wallpaper.jpg",
        name: "KID'S ROOMS",
        width: "10",
        height: "12",
        link:"Kids Room"
      },
      {
        image: ".../../.../../assets/Mockup/8/wallpaper.jpg",
        mobile_image: ".../../.../../assets/Mockup/8/wallpaper.jpg",
        name: "STUDIES ROOMS",
        width: "10",
        height: "12",
        link:"Study Room"
      },
    ];

  }


  ngOnInit() {
    if (this.sectionCount === 1 || this.sectionCount === 3) {
      this.bannerLeftMd = this.bannerLeftSm = 100;
      this.bannerRightShow = false;

    }
    if (this.sectionCount === 3) {
      this.containerPadding = 'padding: 16px 2px 2px 2px !important;';
    }

    if (window.innerWidth <= 575) {
      this.isMobile = true;
    }

  }

  ngAfterViewInit() {
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: true,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false
      },
      speed: 500
    };


  }

  ngDestroy() {
  }

}
