import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppService} from './app.service';
import {CartService} from './admin/services/cart.service';
import {SignInDialogComponent} from './shared/sign-in-dialog/sign-in-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {finalize} from 'rxjs/operators';
import {AuthenticationService} from './admin/services/authentication.service';
import {Cart} from './admin/models/cart.model';
import {WishlistItem} from './admin/models/wishlist_item.model';
import {WishlistQuickDialogComponent} from './shared/wishlist-quick-dialog/wishlist-quick-dialog.component';
import {WishlistItemService} from './admin/services/wishlist_item.service';
import {SignUpDialogComponent} from './shared/sign-up-dialog/sign-up-dialog.component';
import {Router} from '@angular/router';
import {GtmService} from './gtm.service';

declare let trackEvent: Function;

@Injectable({providedIn: 'root'})
export class CartLoginAthentiacationService {
    cart: any;
    wishlist_item: any;

    constructor(public http: HttpClient, public appService: AppService,
                private cartService: CartService, public authenticationService: AuthenticationService,
                public gtmService: GtmService,
                private wishlistItemService: WishlistItemService,
                public dialog: MatDialog, private snackbar: MatSnackBar, private router: Router) {

    }

    checkIsRegisteredUser() {
        let dialogRef;
        const register = localStorage.getItem('is_registered_user');
        if (register && register == '1') {
            dialogRef = this.dialog.open(SignInDialogComponent, {
                panelClass: 'sign-up-dialog-container',
                data: {},
                disableClose: true,
                autoFocus: false
            });
        } else {
            dialogRef = this.dialog.open(SignUpDialogComponent, {
                // panelClass: 'sign-up-dialog-container',
                panelClass: 'dialog-container-no-padding',
                data: {},
                disableClose: true,
                autoFocus: false
            });
        }
        return dialogRef;
    }

    validateAddToCart(cart, wishlist_item, isUpdate = 0, url, isEnableGuest = 0) {

        return new Promise(resolve => {
            if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
                this.snackbar.open('Product added to cart successfully!', '×', {
                    panelClass: 'success',
                    verticalPosition: 'top',
                    duration: 3000
                });
                if (wishlist_item && wishlist_item.id > 0) {
                    return resolve(this.moveWishlistItemToCart(wishlist_item, url));
                } else {
                    return resolve(this.addToCart(cart, isUpdate));
                }
            } else {
                if (environment.cartGuestEnable == 1 || isEnableGuest == 1) {
                    this.snackbar.open('Product added to cart successfully!', '×', {
                        panelClass: 'success',
                        verticalPosition: 'top',
                        duration: 3000
                    });

                    return resolve(this.addToCartGuest(cart));
                } else {

                    const dialogRef = this.checkIsRegisteredUser();

                    const close = dialogRef.componentInstance.onClose.subscribe(() => {
                        dialogRef.close();

                        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
                            this.snackbar.open('Product added to cart successfully!', '×', {
                                panelClass: 'success',
                                verticalPosition: 'top',
                                duration: 3000
                            });
                            cart.customizedProduct.customer_id = this.authenticationService.currentUserValue.id;
                            return resolve(this.addToCart(cart, 0));
                        } else {
                            // return new Promise(resolve => {
                            resolve(null);
                            // });
                        }
                    });
                }
            }
        });
    }

    validateAddToWishList(cart, wishlist_item, url) {
        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            const dialogRef = this.dialog.open(WishlistQuickDialogComponent, {
                data: {
                    customer_address_id: 0
                },
                disableClose: true,
                autoFocus: false
            });
            return new Promise(resolve => {
                dialogRef.componentInstance.onClose.subscribe(data => {
                    dialogRef.close();
                    wishlist_item.wishlist_id = data.wishlist_id;
                    if (this.cart && this.cart.id > 0) {
                        resolve(this.moveCartToWishlistItem(cart, data.wishlist_id, url));
                    } else {
                        resolve(this.addToWishlist(wishlist_item));
                    }
                });
            });
        } else {
            const dialogRef = this.checkIsRegisteredUser();
            const close = dialogRef.componentInstance.onClose.subscribe(() => {
                dialogRef.close();
                return new Promise(resolve => {
                    resolve(null);
                });
            });
        }
    }


    private addToCartGuest(cart) {
        return new Promise(resolve => {
            this.cartService.addToCartGuest(cart)
                .pipe(
                    finalize(() => {
                        resolve(this.cart);
                    })
                )
                .subscribe(
                    res => {
                        this.cart = res['data'];
                        this.gtmService.gtmCart('customer', 0, 'add_to_cart');
                    });
        });
    }


    private addToCart(cart, isUpdate: number) {
        return new Promise(resolve => {
            if (isUpdate == 0) {
                this.cartService.add(cart)
                    .pipe(
                        finalize(() => {
                            resolve(this.cart);
                        })
                    )
                    .subscribe(
                        res => {
                            this.cart = res['data'];
                        });
            } else {
                this.cartService.update(this.cart)
                    .pipe(
                        finalize(() => {
                            resolve(this.cart);
                        })
                    )
                    .subscribe(
                        res => {
                            this.cart = res['data'];
                            this.gtmService.gtmCart('customer', 0, 'add_to_cart');
                        });
            }
        });
    }

    public moveWishlistItemToCart(wishlist_item, url) {
        const wishlist_item_id = wishlist_item.id;
        this.wishlist_item = new WishlistItem();
        this.cart = new Cart();
        return new Promise(resolve => {

            this.appService.moveWishlistItemToCart(wishlist_item_id, url)
                .pipe(
                    finalize(() => {
                        resolve(this.cart);
                    })
                )
                .subscribe(
                    res => {
                        this.cart = res['data'];
                        this.gtmService.gtmCart('customer', 0, 'add_to_cart');
                    });
        });

    }


    private addToWishlist(wishlist_item) {
        return new Promise(resolve => {

            if (wishlist_item.id == 0) {
                this.wishlistItemService.add(wishlist_item)
                    .pipe(
                        finalize(() => {
                            resolve(this.wishlist_item);
                        })
                    )
                    .subscribe(
                        res => {
                            this.wishlist_item = res['data'];
                        });
            } else {
                this.wishlistItemService.update(wishlist_item)
                    .pipe(
                        finalize(() => {
                            resolve(this.wishlist_item);
                        })
                    )
                    .subscribe(
                        res => {
                            this.wishlist_item = res['data'];
                            this.snackbar.open('Product added to wishlist successfully!', '×', {
                                panelClass: 'success',
                                verticalPosition: 'top',
                                duration: 3000
                            });
                        });
            }
        });
    }

    public moveCartToWishlistItem(cart, wishlist_id: number, url) {
        const cart_id = this.cart.id;
        this.cart = new Cart();
        return new Promise(resolve => {
            this.appService.moveCartToWishlistItem(cart_id, wishlist_id, url)
                .pipe(
                    finalize(() => {
                        resolve(this.wishlist_item);
                    })
                )
                .subscribe(
                    res => {
                        this.wishlist_item = res['data'];
                        this.snackbar.open('Product added to wishlist successfully!', '×', {
                            panelClass: 'success',
                            verticalPosition: 'top',
                            duration: 3000
                        });
                    });
        });
    }
}
