export class OrderPayment {
    id: number;
    invoice_id: number;
    customer_id: string;
    received_date: string;
    payment_mode: string;
    cheque_date: string;
    cheque_no: string;
    transaction_no: string;
    amount: number;
    remarks: string;

    public constructor() {
    }
}
