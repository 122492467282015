import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import {Router, RouterStateSnapshot} from '@angular/router';

import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/admin/services/authentication.service';
import { CartService } from 'src/app/admin/services/cart.service';
import { WishlistItemService } from 'src/app/admin/services/wishlist_item.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

/*
    constructor( private spinner: NgxSpinnerService) {}

    intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.spinner.show();

        return next.handle(req).pipe(map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.spinner.hide();
            }
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            const started = Date.now();
            const elapsed = Date.now() - started;
            console.log(`Request for ${req.urlWithParams} failed after ${elapsed} ms.`);
           // debugger;
            return throwError(error);
          })
        );

    }
*/

    private requestCount = 0;
    constructor(
      private spinner: NgxSpinnerService,
      public cartService: CartService,
      public wishlistItemService: WishlistItemService,
      private authenticationService: AuthenticationService,
      private router: Router
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log(req.params.get('skipLoader'));
        if(!(req.method == "GET" && req.url.endsWith("/city")) && !(req.params && req.params.get('skipLoader')  &&  req.params.get('skipLoader') === 'true')) {
          this.requestCount++;
          this.spinner.show();
        }
        return next.handle(req).pipe(map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              if(this.requestCount <= 0) {
                this.spinner.hide();
              }
            }
            return event;
          }),
          catchError((httpErrorResponse: HttpErrorResponse) => {
            const started = Date.now();
            const elapsed = Date.now() - started;
            console.log(`Request for ${req.urlWithParams} failed after ${elapsed} ms.`);
             // debugger;

             if (httpErrorResponse.status === 401) {
                 if(httpErrorResponse.error.error == "Unauthorized") {
                   return throwError(httpErrorResponse.error.error);
                 }
                 else {
                   // auto logout if 401 response returned from api
                   let role_id = this.authenticationService.currentUserValue.role_id;
                   this.authenticationService.logout();
                   this.cartService.resetCount();
                   this.wishlistItemService.resetCount();

                   if(role_id == 7) {
                       this.router.navigate(['/access-denied'], { queryParams: {returnUrl: this.router.url}, queryParamsHandling: 'merge' });
                   }
                   else {
                     this.router.navigate(['/admin']);
                   }
                   // return of(null);
                   // location.reload(true);
                 }
             }

             if (httpErrorResponse.status === 422) {
               return throwError(httpErrorResponse.error);
             }

             const error = httpErrorResponse.error.error || httpErrorResponse.statusText;

            return throwError(error);
          }),
          finalize(() => {
              if(!(req.method == "GET" && req.url.endsWith("/city"))) {
                this.requestCount--;
                if(this.requestCount <= 0) {
                  this.spinner.hide();
                }
              }
          }),
        );
    }


}
