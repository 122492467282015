import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/admin/services/authentication.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

  public error_title: String = "404";
  public error_msg: String = "Opps, it seems that this page does not exist.";

  constructor(
    public router:Router,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    // unauthorized
    if(this.router.routerState.snapshot.url.includes("/unauthorized")){
      this.error_title = "Access Denied!";
      this.error_msg = "Opps, it seems that you are not authorized to view this page.";
    }
  }

  public goHome(): void {
    let user = this.authenticationService.currentUserValue;

    if(user.role_id > 1 && user.role_id <= 6){
      this.router.navigate(['/admin']);
    }
    else{
      this.router.navigate(['/']);
    }
  }

}
