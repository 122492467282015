import { User } from "./user.model";
import { Product } from "./product.model";

export class Testimonial {
  id?: number = 0;
  review: string;
  rating: number;
  order_item_id: number;
  product_id: number;
  user_id: number;
  image_1: string;
  image_2: string;
  image_3: string;
  status: string;
  showmore: number;

  user: User;
  product: Product;

  public constructor() {
    this.user = new User();
    this.product = new Product();
  }
}
