import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {finalize} from 'rxjs/operators';
import {Media} from '../../admin/models/media.model';
import {SettingService} from '../../admin/services/setting.service';
import {ChangeEvent} from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
    selector: 'app-media',
    templateUrl: './media.component.html',
    styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {
    @Input() title: string;
    @Input() index: number;
    @Input() type: string;
    @Input() media: Media;

    @Input() hideSubTitle: boolean;
    @Input() hideTitle: boolean;
    @Input() showEditor: boolean;
    @Input() hideDescrition: boolean;
    @Input() hideImage: boolean;
    @Input() showPDF: boolean;
    @Input() showMobileImage: boolean;
    @Input() hideButtonTitle: boolean;
    @Input() hideButtonLink: boolean;
    @Input() hidePublished: boolean;
    @Input() showType: boolean;
    @Input() showTitleColor: boolean;
    @Input() showSubtitleColor: boolean;
    @Input() hideAltText: boolean;
    @Input() showPosition: boolean;
    @Input() showTopHeading: boolean;

    public editorConfig = {
        toolbar: {
            items: [
                'heading', '|',
                'fontfamily', 'fontsize', '|',
                'alignment', '|',
                'fontColor', 'fontBackgroundColor', '|',
                'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                'link', '|',
                'outdent', 'indent', '|',
                'bulletedList', 'numberedList', 'todoList', '|',
                'code', 'codeBlock', '|',
                'insertTable', '|',
                'uploadImage', 'blockQuote', '|',
                'undo', 'redo'
            ],
            shouldNotGroupWhenFull: false
        }
    };

    private loadingSubject: BehaviorSubject<boolean>;
    public loading$: Observable<boolean>;
    public form: FormGroup;
    public Editor = ClassicEditor;

    public image;
    public image_path = './../../../assets/images/placeholder.png';

    public mobile_image;
    public mobile_image_path = './../../../assets/images/placeholder.png';

    public pdf;
    public pdf_path = './../../../assets/images/placeholder.png';


    constructor(
        public settingService: SettingService,
        public formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private matSnackBar: MatSnackBar,
        private router: Router
    ) {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
        this.loading$ = this.loadingSubject.asObservable();

        // ClassicEditor.builtinPlugins = [Font];
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            index: this.index,
            type: this.type,
            title: [this.media?.title],
            subtitle: [this.media?.subtitle],
            description: [this.media?.description],
            button_title: [this.media?.button_title],
            button_link: [this.media?.pdf],
            published: [this.media?.published == 1, Validators.required],
            text_type: [this.media?.text_type == 1, Validators.required],
            position_hor: this.media?.position_hor,
            position_ver: this.media?.position_ver,
            alt_text: this.media?.alt_text
        });

        if (this.media?.image) {
            this.image_path = this.media?.image;
        }
        if (this.media?.mobile_image) {
            this.mobile_image_path = this.media?.mobile_image;
        }
        if (this.media?.pdf) {
            this.pdf_path = this.media?.pdf;
        }
    }

    public onSubmit() {
        if (this.form.valid) {
            let element: Media = new Media();
            element = Object.assign({}, element, this.form.value);
            element.image = this.image;
            element.pdf = this.pdf;
            element.mobile_image = this.mobile_image;

            this.update(element);
        }
    }

    update(element) {
        this.loadingSubject.next(true);
        this.form.disable();
        this.settingService.updateMedia(element)
            .pipe(
                finalize(() => {
                    this.loadingSubject.next(false);
                    this.form.enable();
                })
            )
            .subscribe(
                res => {
                    this.loadingSubject.next(false);
                    this.matSnackBar.open('Record has been successfully updated!', 'x', {
                        duration: 2000,
                        verticalPosition: 'top',
                        panelClass: ['success']
                    });
                });
    }

    onSelectMedia(event) { // called each time file input changes
        if (event.target.files && event.target.files[0]) {
            // this.mediaProductForm.markAsDirty();
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            reader.onload = (event) => { // called once readAsDataURL is completed
                this.image_path = reader.result.toString(); //add source to image
                this.image = reader.result;
            };
        }
    }

    onSelectPDFMedia(event) { // called each time file input changes
        if (event.target.files && event.target.files[0]) {
            // this.mediaProductForm.markAsDirty();
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            reader.onload = (event) => { // called once readAsDataURL is completed
                this.pdf_path = reader.result.toString(); //add source to image
                this.pdf = reader.result;
            };
        }
    }

    onSelectMobileMedia(event) { // called each time file input changes
        if (event.target.files && event.target.files[0]) {
            // this.mediaProductForm.markAsDirty();
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            reader.onload = (event) => { // called once readAsDataURL is completed
                this.mobile_image_path = reader.result.toString(); //add source to image
                this.mobile_image = reader.result;
            };
        }
    }

    getErrors(ctrl) {
        return Object.keys(ctrl.errors);
    }

    public onChange({editor}: ChangeEvent, $type) {
        console.log('in');
        const data = editor.getData();
        this.form.get($type).setValue(data);
    }

}
