import {Component, EventEmitter, NgZone, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {emailValidator} from '../../theme/utils/app-validators';
// import {ForgotPasswordComponent} from '../../shared/forgot-password/forgot-password.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AuthenticationService} from 'src/app/admin/services/authentication.service';
import {I18nSelectPipe, Location} from '@angular/common';
import {BehaviorSubject, Observable, Subscription, timer} from 'rxjs';
import {finalize, take} from 'rxjs/operators';
import {messages} from 'src/environments/environment';
import {CartService} from 'src/app/admin/services/cart.service';
import {WishlistItemService} from 'src/app/admin/services/wishlist_item.service';
// import {GoogleLoginProvider, SocialAuthService} from 'angularx-social-login';
import {ForgotPasswordComponent} from '../forgot-password/forgot-password.component';
import {AppService} from '../../app.service';
import {SignUpDialogComponent} from '../sign-up-dialog/sign-up-dialog.component';
import {Media} from '../../admin/models/media.model';
import { GtmService } from 'src/app/gtm.service';

declare var google: any;


@Component({
    selector: 'app-sign-in-dialog',
    templateUrl: './sign-in-dialog.component.html',
    styleUrls: ['./sign-in-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SignInDialogComponent implements OnInit {
    @Output() onClose = new EventEmitter<any>(true);

    loginForm: FormGroup;

    private loadingSubject: BehaviorSubject<boolean>;
    public loading$: Observable<boolean>;
    returnUrl: string;
    setting: Media;
    countDown: Subscription;
    counter = 0;
    tick = 1000;

    res:any;
    // auth2: any;
    // @ViewChild('loginRef', { static: true }) loginElement!: ElementRef;

    constructor(
        private ngZone: NgZone,

        public formBuilder: FormBuilder,
        private location: Location,
        public router: Router,
        private route: ActivatedRoute,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<SignInDialogComponent>,
        public snackbar: MatSnackBar,
        private authenticationService: AuthenticationService,
        private appService: AppService,
        private i18nSelectPipe: I18nSelectPipe,
        public cartService: CartService, public wishlistItemService: WishlistItemService,
        public matSnackBar: MatSnackBar,
        private gtmService:GtmService
    ) {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
        this.loading$ = this.loadingSubject.asObservable();
    }

    ngOnInit() {
        // this.googleAuthSDK();
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.compose([emailValidator])],
            password: [''],
            mobile_no: ['', Validators.compose([Validators.maxLength(10), Validators.minLength(10)])],
            otp: ['', Validators.compose([Validators.maxLength(6), Validators.minLength(6)])]
        });

        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
        this.setting = this.appService.Data.setting.registrationOffer[1];

    }

    // ngAfterViewInit(): void {
    //     var self = this;
    //     google.accounts.id.initialize({
    //         client_id: "682112098424-ejkuh875fte3hl7hm4588vcp08vb77ad.apps.googleusercontent.com",
    //         callback: (response: any)  => this.ngZone.run(() => {
    //             this.handleGoogleSignIn(response);
    //         })
    //     });
    //     google.accounts.id.renderButton(
    //         document.getElementById("buttonDiv"),
    //         { theme: 'outline', size: 'large' }
    //         // { size: "large", type: "icon", shape: "pill" }  // customization attributes
    //     );
    // }

    // handleGoogleSignIn(response: any) {
    //     let base64Url = response.credential.split('.')[1];
    //     let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    //     let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    //         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    //     }).join(''));
    //     console.log(JSON.parse(jsonPayload));
    //     jsonPayload = JSON.parse(jsonPayload);
    //     this.onGoogleLogin(jsonPayload['name'], jsonPayload['family'], jsonPayload['jti'],
    //         jsonPayload['picture'], jsonPayload['email']);
    // }

    // callLogin() {
    //
    //     this.auth2.attachClickHandler(this.loginElement.nativeElement, {},
    //         (googleAuthUser: any) => {
    //
    //             //Print profile details in the console logs
    //
    //             let profile = googleAuthUser.getBasicProfile();
    //             console.log('Token || ' + googleAuthUser.getAuthResponse().id_token);
    //             console.log('ID: ' + profile.getId());
    //             console.log('Name: ' + profile.getName());
    //             console.log('Image URL: ' + profile.getImageUrl());
    //             console.log('Email: ' + profile.getEmail());
    //
    //         }, (error: any) => {
    //             alert(JSON.stringify(error, undefined, 2));
    //         });
    //
    // }
    //
    // googleAuthSDK() {
    //
    //     (<any>window)['googleSDKLoaded'] = () => {
    //         (<any>window)['gapi'].load('auth2', () => {
    //             this.auth2 = (<any>window)['gapi'].auth2.getAuthInstance({
    //                 client_id: "804440394324-ho03559bchen2l8jtgedmlb20dhpfg04.apps.googleusercontent.com",
    //                 plugin_name:'login',
    //                 cookiepolicy: 'single_host_origin',
    //                 scope: 'profile email'
    //             });
    //             this.callLogin();
    //         });
    //     }
    //
    //     (function (d, s, id) {
    //         var js, fjs = d.getElementsByTagName(s)[0];
    //         if (d.getElementById(id)) { return; }
    //         js = d.createElement('script');
    //         js.id = id;
    //         js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
    //         fjs?.parentNode?.insertBefore(js, fjs);
    //     }(document, 'script', 'google-jssdk'));
    // }

    private messages = messages;
    private ipAddress = '';

    get message() {
        return this.message;
    }

    getIP() {
        this.appService.getIPAddress().subscribe((res: any) => {
            this.ipAddress = res.ip;
        });
    }

    // signInWithGoogle(): void {
    //     this.loadingSubject.next(true);
    //     this.loginForm.disable();
    //     this.OAuth.signIn(GoogleLoginProvider.PROVIDER_ID).then(socialusers => {
    //         this.onGoogleLogin(socialusers['firstName'], socialusers['lastName'], socialusers['jit'],
    //             socialusers['profileUrl'], socialusers['email']);
    //     });
    // }

    public onGoogleLogin(firstname, lastname, token, profileUrl, email = '') {
        const self = this;
        self.loadingSubject.next(true);
        // this.getIP();
        this.authenticationService.googleSignIn(firstname, lastname, token, profileUrl, this.ipAddress, this.cartService.getSessionId(), email)
            .pipe(
                // catchError(() => of([])),
                finalize(() => {
                    self.loadingSubject.next(false);
                    self.loginForm.enable();
                })
            )
            .subscribe(
                res => {
                    // if(res.role_id == 7){
                    //   this.router.navigate(['/']);
                    // }
                    // else {
                    //   this.router.navigate(['/admin']);
                    // }
                    self.dialogRef.close();

                    if (this.returnUrl != '') {
                        self.router.navigate([this.returnUrl]);
                    }
                    self.cartService.refreshCount();
                    self.wishlistItemService.refreshCount();
                    this.snackbar.open('Login Successfully', 'Ok', {
                        duration: 10000,
                        verticalPosition: 'top',
                        panelClass: ['success']
                    });
                },
                err => {
                    console.log('error: ', err);
                    // this.errorSubject.next(error);
                    // this.loadingSubject.next(false);
                    this.snackbar.open(this.i18nSelectPipe.transform(err, this.messages), 'Ok', {
                        duration: 10000,
                        verticalPosition: 'top',
                        panelClass: ['error']
                    });
                });
    }

    public onLogin(): void {
        if (this.loginForm.invalid || this.loginForm.pristine) {
            return;
        }

        if (this.loginForm.value.email != '' && this.loginForm.value.password != '') {
            this.loadingSubject.next(true);
            this.loginForm.disable();
            this.loginWithEmail();
        } else if (this.loginForm.value.mobile != '' && this.loginForm.value.otp != '') {

            this.loadingSubject.next(true);
            this.loginForm.disable();
            this.loginWithOTP();

        } else {
            this.snackbar.open('Please fill the required Information', 'Ok', {
                duration: 10000,
                verticalPosition: 'top',
                panelClass: ['error']
            });
        }


        // this.router.navigate(['/']);
        // this.snackbar.open('Lucky you! Looks like you didn\'t need a password or email address! For a real application we provide validators to prevent this. ;)', 'LOL THANKS', {
        //   duration: 10000
        // });
    }

    public loginSuccess() {

        const finObject: any = {};

        const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');

        this.gtmService.signInGTMCart('login',currentUser?.id);

        console.log("calling");
        // if(res.role_id == 7){
        //   this.router.navigate(['/account/orders']);
        if (this.returnUrl != '') {

          
            this.router.navigate([this.returnUrl]);
        }
        // }
        // else {
        //   this.router.navigate(['/admin']);
        // }
        this.onClose.emit();

        this.cartService.refreshCount();
        this.wishlistItemService.refreshCount();
        if (this.location.path().includes('access-denied')) {
            this.router.navigate(['/']);
        }
        // this.onReset.emit();
    }

    public loginWithOTP() {
        this.authenticationService.loginWithOtp(this.loginForm.value.mobile_no, this.loginForm.value.otp, this.cartService.getSessionId())
            .pipe(
                // catchError(() => of([])),
                finalize(() => {
                    this.loadingSubject.next(false);
                    this.loginForm.enable();
                })
            )
            .subscribe(
                res => {
                    this.loginSuccess();
                },
                err => {
                    console.log('error: ', err);
                    // this.errorSubject.next(error);
                    // this.loadingSubject.next(false);
                    this.snackbar.open('Your OTP is incorrect or expired', 'Ok', {
                        duration: 10000,
                        verticalPosition: 'top',
                        panelClass: ['error']
                    });
                });
    }


    public loginWithEmail() {
        this.authenticationService.login(this.loginForm.value.email, this.loginForm.value.password, this.cartService.getSessionId())
            .pipe(
                // catchError(() => of([])),
                finalize(() => {
                    this.loadingSubject.next(false);
                    this.loginForm.enable();
                })
            )
            .subscribe(
                res => {
                    this.res=res?.id;
                    this.loginSuccess();
                },
                err => {
                    console.log('error: ', err);
                    // this.errorSubject.next(error);
                    // this.loadingSubject.next(false);
                    this.snackbar.open(this.i18nSelectPipe.transform(err, this.messages), 'Ok', {
                        duration: 10000,
                        verticalPosition: 'top',
                        panelClass: ['error']
                    });
                });
    }

    public onLoginFormSubmit(values: Object): void {
        if (this.loginForm.valid) {
            // this.authService.login(values.email, values.password).then(resolve => {
            //     if (resolve) {
            //         this.dialogRef.close();
            //         this.snackBar.open('User login to account successfully', '×', {
            //             panelClass: 'success',
            //             verticalPosition: 'top',
            //             duration: 3000
            //         });
            //     } else {
            //         this.snackBar.open('Incorrect login id/pass', '×', {
            //             panelClass: 'error',
            //             verticalPosition: 'top',
            //             duration: 3000
            //         });
            //     }
            // });
        }
    }

    public Register(): void {
        this.dialogRef.close();
        // this.router.navigate(['/sign-up']);
        const dialogRef = this.dialog.open(SignUpDialogComponent, {
            panelClass: 'dialog-container-no-padding',
            direction: 'ltr'
        });
    }

    public openDialog() {
        const dialogRef = this.dialog.open(ForgotPasswordComponent, {
            panelClass: 'app-forgot-password',
            direction: 'ltr'
        });
    }

    public close(): void {
        this.dialogRef.close();
    }


    sendOtp() {
        this.loadingSubject.next(true);
        const element = {mobile_no: this.loginForm.controls.mobile_no.value, type: 'login'};
        this.loginForm.disable();
        this.appService.generateOtp(element)
            .pipe(
                finalize(() => {
                    this.loadingSubject.next(false);
                    this.loginForm.enable();
                })
            )
            .subscribe(
                res => {
                    this.loadingSubject.next(false);
                    this.matSnackBar.open('OTP sent successfully on mobile!!', 'x', {
                        duration: 2000,
                        verticalPosition: 'top',
                        panelClass: ['success']
                    });
                    this.counter = 30;
                    this.countDown = timer(0, this.tick)
                        .pipe(take(this.counter))
                        .subscribe(() => {
                            --this.counter;
                            // console.log(this.counter);
                            if (this.counter == 0) {
                                this.countDown.unsubscribe();
                            }
                        });
                },
                err => {

                    console.log('error: ', err);
                    // this.errorSubject.next(error);
                    // this.loadingSubject.next(false);
                    this.snackbar.open('Mobile no. does not exist', 'Ok', {
                        duration: 10000,
                        verticalPosition: 'top',
                        panelClass: ['error']
                    });
                });
    }

    transform(value: number): string {
        const minutes: number = Math.floor(value / 60);
        return (
            ('00' + Math.floor(value - minutes * 60)).slice(-2)
        );
    }
}
