export class Product {
  id: number;
  name: string;
  slug?: String;
  image?: String;

  created_at?: string;
  updated_at?: string;

  public constructor() {
  }
}
