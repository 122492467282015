import {Injectable} from '@angular/core';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import {Workbook} from 'exceljs';
import {saveAs} from 'file-saver';

@Injectable()
export class ExportService {

    async exportToExcel(data: any[], fileName: string) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('My Sheet');
        worksheet.properties.defaultRowHeight = 100;

        // Add headers
        const headers = Object.keys(data[0]);
        headers.push("Image");
        worksheet.addRow(headers);

        // Add data
        let index = 0;
        // data = data.splice(0,5);
        for (const item of data) {
            const row: any = [];
            row.push('');

            headers.forEach((header) => {
                item['Image']=[];
                row.push(item[header]);
            });
            worksheet.addRow(row);

            // console.log(item['list_thumbnail']);
            //
            // const imageUrl = item['list_thumbnail'];
            // const splitted = item['list_thumbnail'].split(".");
            // const extName = splitted[splitted.length - 1];
            // fetch(imageUrl)
            //     .then((r) => {
            //
            //         const imageId2 = workbook.addImage({
            //             buffer: r.arrayBuffer(),
            //             extension: extName,
            //         });
            //
            //         worksheet.addImage(imageId2, {
            //             tl: {col: 0, row: index++},
            //             ext: {width: 100, height: 100},
            //         });
            //     }).catch(console.error);

            if(item['list_thumbnail']!='') {
                const result = await this.getBase64ImageFromUrl(item['list_thumbnail']);
                // const splitted = item['list_thumbnail'].split(".");
                // const extName = splitted[splitted.length - 1];

                if(result) {
                    const imageId2 = workbook.addImage({
                        base64: result,
                        extension: "jpg",
                    });
                    worksheet.addImage(imageId2, {
                        tl: {col: 0, row: ++index},
                        ext: {width: 150, height: 100},
                        editAs: 'oneCell'
                    });
                }
            }

            // worksheet.addImage(imageId2, {
            //     tl: {col: 0, row: index++},
            //     ext: {width: 100, height: 100},
            // });
        }

        worksheet.columns.forEach(function (column, i) {
            let maxLength = 0;
            column["eachCell"]({ includeEmpty: true }, function (cell) {
                var columnLength = cell.value ? cell.value.toString().length : 10;
                if (columnLength > maxLength ) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength < 10 ? 10 : maxLength;
        });
        worksheet.columns[0].width = 22;


        // worksheet.getColumn(1).width = 15;
        // worksheet.getColumn(2).width = 20;


        // Generate Excel file
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            saveAs(blob, `${fileName}.xlsx`);
        });

        // await workbook.xlsx.writeFile('export.xlsx');

    }

    async getBase64ImageFromUrl(imageUrl) {
        // console.log(imageUrl);
        // imageUrl = "https://content.mahattaart.com/wallpaper/list_thumbnail_MAWP-P-279.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAZZYIAYRDNPNLC56Z%2F20241214%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20241214T110153Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1200&X-Amz-Signature=920bac789cdebb93e689e51b3f0bbf7cf475e786335840a3375f488b8543bfae";
        try {

            const res = await fetch(imageUrl);
            const blob = await res.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.addEventListener("load", function() {
                    resolve(reader.result);
                }, false);

                reader.onerror = () => {
                    return reject(this);
                };
                reader.readAsDataURL(blob);
            });
        } catch (e) {
            console.log(e);
        }
    }
}
