import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SwiperConfigInterface, SwiperDirective, SwiperPaginationInterface} from 'ngx-swiper-wrapper';

@Component({
    selector: 'app-main-carousel',
    templateUrl: './main-carousel.component.html',
    styleUrls: ['./main-carousel.component.scss']
})
export class MainCarouselComponent implements OnInit {
    @Input('slides') slides: Array<any> = [];
    @Input('section') sectionCount = 1;
    @Input('shownavigation') shownavigation = true;
    @ViewChild(SwiperDirective) swiperView: SwiperDirective;

    public bannerLeftMd = 70;
    public bannerLeftSm = 60;
    public containerPadding = '';
    public bannerRightShow = true;
    public isMobile = false;
    public config: SwiperConfigInterface = {};
    private pagination: SwiperPaginationInterface = {
        el: '.swiper-pagination',
        clickable: true
    };
    @Input('height') height = '400px';
    @Input('mobileHeight') mobileHeight = '280px';

    constructor() {

    }


    ngOnInit() {
        if (this.sectionCount === 1 || this.sectionCount === 3) {
            this.bannerLeftMd = this.bannerLeftSm = 100;
            this.bannerRightShow = false;

        }
        if (this.sectionCount === 3) {
            this.containerPadding = 'padding: 16px 2px 2px 2px !important;';
        }

        if (window.innerWidth <= 575) {
            this.isMobile = true;
        }

    }

    //
    // ngAfterViewInit() {
    //     this.config = {
    //         effect: 'slide',
    //         slidesPerView: 1,
    //         // navigation: {
    //         //     nextEl: '.swiper-button-next',
    //         //     prevEl: '.swiper-button-prev',
    //         // },
    //         pagination: {
    //             el: '.swiper-pagination',
    //             clickable: true
    //         },
    //         autoplay: {
    //             delay: 6000,
    //             disableOnInteraction: false
    //         },
    //     };
    // }

    ngAfterViewInit() {
        this.config = {
            slidesPerView: 1,
            spaceBetween: 0,
            keyboard: true,
            navigation: true,
            pagination: this.pagination,
            grabCursor: true,
            loop: false,
            preloadImages: false,
            lazy: true,
            autoplay: {
                delay: 6000,
                disableOnInteraction: false
            },
            speed: 500
        };


    }

    ngDestroy() {
    }

}
