import {Component, Inject, Input, OnInit} from '@angular/core';
import {SwiperConfigInterface, SwiperPaginationInterface} from 'ngx-swiper-wrapper';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';


@Component({
    selector: 'app-terms-service-installation',
    templateUrl: './terms-service-installation.component.html',
    styleUrls: ['./terms-service-installation.component.scss']
})
export class TermsServiceInstallationComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<TermsServiceInstallationComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private router: Router) {
        dialogRef.disableClose = true;

    }


    ngOnInit() {
    }

    onDismiss()
    {
        this.dialogRef.close(false);
    }

}
