export class Wishlist {
  id?: number = 0;
  name: string;
  user_id: number;

  created_at: string;
  updated_at: string;


  public constructor() {
  }
}
