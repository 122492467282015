import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AppSettings, Settings} from '../app.settings';
import {AppService} from '../app.service';
import {Category} from '../app.models';
import {SidenavMenuService} from '../theme/components/sidenav-menu/sidenav-menu.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TopbarNavbarService} from '../topbar-navbar.service';
import {MatDialog} from '@angular/material/dialog';
import {SettingService} from '../admin/services/setting.service';
import {Setting} from '../admin/models/setting.model';
import {SwiperConfigInterface, SwiperPaginationInterface} from 'ngx-swiper-wrapper';
import {GtmService} from '../gtm.service';
import { ChatbotComponent } from '../shared/chatbot/chatbot.component';

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.scss'],
    providers: [SidenavMenuService]

})
export class PagesComponent implements OnInit {
    public showBackToTop = false;
    public categories: Category[];
    public category: Category;
    public sidenavMenuItems: Array<any>;
    @ViewChild('sidenav', {static: true}) sidenav: any;
    searchForm: FormGroup;
    setting: Setting;
    @ViewChild('tabGroup')
    tabGroup;
    public settings: Settings;
    private isDragging = false;
    displayType = 'none';
    topOfferSlides: string[];
    topOfferSlidesMobile: string[];
    public config: SwiperConfigInterface = {};
    private pagination: SwiperPaginationInterface = {
        el: '.swiper-pagination',
        clickable: true
    };

    isChatbotVisible: boolean = false;

    toggleChatbot() {
        const dialogRef = this.dialog.open(ChatbotComponent, {
            width: '450px',
            maxHeight: '400px',
            position: { bottom: '20px', right: '20px' }, // Fixed bottom-right position
            disableClose: true,
            autoFocus: false,
            panelClass: 'chatbot-dialog-container' // Custom CSS class
          });
    const close = dialogRef.componentInstance.onClose.subscribe(() => {
        dialogRef.close();
    });
    }

    constructor(public appSettings: AppSettings,
                public appService: AppService,
                public sidenavMenuService: SidenavMenuService,
                public router: Router,
                public topbarNavbarService: TopbarNavbarService,
                public formBuilder: FormBuilder,
                private dialog: MatDialog,
                private settingService: SettingService,
                private gtmService: GtmService
    ) {
        this.settings = this.appSettings.settings;
        this.getSettings();
    }

    ngOnInit() {
        this.searchForm = this.formBuilder.group({
            searchText: ['', Validators.required],
        });
        // this.getCategories();
        // this.showOffer();
        this.sidenavMenuItems = this.sidenavMenuService.getSidenavMenuItems();
        setTimeout(() => {                           // <<<---using ()=> syntax
            this.displayType = 'block';
        }, 2000);
        setTimeout(() => {                           // <<<---using ()=> syntax
            this.displayType = 'none';
        }, 6000);
    }

    public getSettings() {
        if (this.appService.Data.setting) {
            this.setting = this.appService.Data.setting;
            this.topOfferSlides = this.setting?.topOffer[0].title.split(',');
            this.topOfferSlidesMobile = this.setting?.topOffer[0].mobile_title.split(',');
        } else {
            this.settingService.getSettings()
                .subscribe((res) => {
                    this.setting = res['data'];
                    this.appService.Data.setting = this.setting;
                    this.topOfferSlides = this.setting?.topOffer[0].title.split(',');
                    this.topOfferSlidesMobile = this.setting?.topOffer[0].mobile_title.split(',');

                });
        }

        this.isShowOffer = true;
    }

    // public getCategories() {
    //     this.appService.getCategories().subscribe(data => {
    //         this.categories = data;
    //         this.category = data[0];
    //         this.appService.Data.categories = data;
    //     });
    // }

    public changeCategory(event) {
        if (event.target) {
            this.category = this.categories.filter(category => category.name == event.target.innerText)[0];
        }
        if (window.innerWidth < 960) {
            this.stopClickPropagate(event);
        }
    }

    public remove(product) {
        const index: number = this.appService.Data.cartList.indexOf(product);
        if (index !== -1) {
            this.appService.Data.cartList.splice(index, 1);
            this.appService.Data.totalPrice = this.appService.Data.totalPrice - product.newPrice * product.cartCount;
            this.appService.Data.totalCartCount = this.appService.Data.totalCartCount - product.cartCount;
            this.appService.resetProductCartCount(product);
        }
    }

    public clear() {
        this.appService.Data.cartList.forEach(product => {
            this.appService.resetProductCartCount(product);
        });
        this.appService.Data.cartList.length = 0;
        this.appService.Data.totalPrice = 0;
        this.appService.Data.totalCartCount = 0;
    }


    public changeTheme(theme) {
        this.settings.theme = theme;
    }

    public stopClickPropagate(event: any) {
        event.stopPropagation();
        event.preventDefault();
    }

    public onSubmit($event) {
        $event.preventDefault();
        console.log(this.router.url.split('?')[0]);
        const url = this.router.url.split('?')[0];
        this.gtmService.gtmSearch(this.searchForm.value['searchText']);

        if (url == '/wallpaper-customization' || url == '/wallpaper-listing' || url == '/wallpaper') {
            this.router.navigate(['/wallpaper-listing'], {queryParams: {searchtext: this.searchForm.value['searchText']}});
        } else {
            this.router.navigate(['/product-search', this.searchForm.value['searchText']]);
        }

    }

    public openContactForm() {
        this.router.navigate(['/sign-up']);

        // const dialogRef = this.dialog.open(ContactDialogComponent, {
        //     panelClass: 'dialog-container-no-padding',
        //     data: {},
        //     // width: '50vw',
        //     // maxWidth: '90vw',
        //     disableClose: true,
        //     autoFocus: false
        // });
        // const close = dialogRef.componentInstance.onClose.subscribe(() => {
        //     dialogRef.close();
        // });
    }

    public scrollToTop() {
        const scrollDuration = 200;
        const scrollStep = -window.pageYOffset / (scrollDuration / 20);
        const scrollInterval = setInterval(() => {
            if (window.pageYOffset != 0) {
                window.scrollBy(0, scrollStep);
            } else {
                clearInterval(scrollInterval);
            }
        }, 10);
        if (window.innerWidth <= 768) {
            setTimeout(() => {
                window.scrollTo(0, 0);
            });
        }
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll($event) {
        ($event.target.documentElement.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;
    }

    ngAfterViewInit() {
        this.config = {
            slidesPerView: 1,
            spaceBetween: 0,
            keyboard: true,
            navigation: true,
            pagination: this.pagination,
            grabCursor: true,
            loop: false,
            preloadImages: false,
            lazy: true,
            autoplay: {
                delay: 6000,
                disableOnInteraction: false
            },
            speed: 500
        };

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.sidenav.close();
            }
        });
        this.sidenavMenuService.expandActiveSubMenu(this.sidenavMenuService.getSidenavMenuItems());

    }

    public closeSubMenus() {
        if (window.innerWidth < 960) {
            this.sidenavMenuService.closeAllSubMenus();
        }
    }

    public isShowOffer: boolean = true;

    public showOffer() {
        const date = localStorage.getItem('lastShown');
        const date1: Date = new Date();
        let hr = 0;
        if (date) {
            const startDate: string = JSON.parse(date);
            const date2: Date = new Date(startDate);
            hr = this.getDifference(date1, date2);
        }
        console.log(hr);
        if (hr == 0 || hr > 2) {
            this.isShowOffer = true;
        } else {
            this.isShowOffer = false;
        }
    }

    getDifference(date1, date2) {
        const diffInMs = Math.abs(date2 - date1);
        return diffInMs / (1000 * 60 * 60);
    }

    public hideTopOffer() {
        const date1: Date = new Date();
        localStorage.setItem('shoOffer', JSON.stringify(date1));
        this.isShowOffer = false;
    }

    scrollTabs(event) {
        const children = this.tabGroup._tabHeader._elementRef.nativeElement.children;

        // get the tabGroup pagination buttons
        const back = children[0];
        const forward = children[2];

        // depending on scroll direction click forward or back
        if (event.deltaY > 0) {
            forward.click();
        } else {
            back.click();
        }
    }

    dragEnd($event: any, url) {
        console.log('i m in');
        if (!this.isDragging) {
            window.open(url, '_blank');
        } else {
            this.isDragging = false;
        }

    }

    dragStart() {
        this.isDragging = true;
    }
}
