import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CustomizedProduct} from 'src/app/admin/models/customized_product.model';
import {Params} from '@angular/router';

@Component({
    selector: 'app-art-set-preview-dialog-dialog',
    templateUrl: './art-set-preview-dialog.component.html',
    styleUrls: ['./art-set-preview-dialog.component.scss']
})
export class ArtSetPreviewDialogComponent implements OnInit {
    public inch = 8;
    params: Params = [];

    constructor(
        public dialogRef: MatDialogRef<ArtSetPreviewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { customizedProduct: CustomizedProduct },
        private dialog: MatDialog
    ) {
    }


    public isFrame(customizedProduct: CustomizedProduct): boolean {
        switch (customizedProduct.product_type_id) {
            case 2:
            case 5:
            case 8:
                return true;
        }
        return false;
    }

    public isCanvas(customizedProduct: CustomizedProduct): boolean {
        switch (customizedProduct.product_type_id) {
            case 1:
            case 4:
                return true;
        }
        return false;
    }

    public get customizedProduct() {
        return this.data.customizedProduct;
    }

    private _imageWidth = 300;

    public get imageWidth() {
        return this._imageWidth;
    }

    private _imageHeight = 300;
    size: string = 'large';

    public get imageHeight() {
        return this._imageHeight;
    }

    ngOnInit() {
        if (window.innerWidth < 650) {
            this._imageWidth = 100;
            this._imageHeight = 100;
        }
        this.getQueryParams(this.customizedProduct.url);
    }

    public getQueryParams(url) {
        const self = this;
        const urlParams = url.split('?')[1].split('&');
        urlParams.forEach(function(param) {
            const paramKeyValue = param.split('=');
            self.params[paramKeyValue[0]] = paramKeyValue[1];
        });
    }

    onConfirm(): void {
        this.dialogRef.close(true);
    }

    onDismiss(): void {
        this.dialogRef.close(false);
    }

    public close(): void {
        this.dialogRef.close();
    }


    getCanvasBackground() {
        let background = '#000';
        if (this.params['canvas_type'] == 'gallery') {
            if (this.params['canvas_wrap_item'] == 'gallery-mirror' || this.params['canvas_wrap_item'] == 'gallery-blur') {
                background = 'url("' + this.customizedProduct.customizedProductItems[0].image_detail.image_path + '")';
            } else if (this.params['canvas_wrap_item'] == 'gallery-color') {
                background = '#' + this.params['canvas_wrap_color'];
            }
        }
        return background;
    }
}
