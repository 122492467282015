import { Product } from "./product.model";

export class ProductType {
  id: number;
  name: string;
  slug: string;
  product_id: number;
  image: string;

  product: Product;
  tax_rate: number;

  created_at: string;
  updated_at: string;

  public constructor() {
  }
}
