import {Country} from "./country.model";

export class State {
  id: number;
  name: string;
  country_id: number;
  country: Country;
  created_at: string;
  updated_at: string;

  public constructor() {
    this.country = new Country();
  }
}
