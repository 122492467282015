import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-footer-social-links',
    templateUrl: './footer-social-links.component.html',
    styleUrls: ['./footer-social-links.component.scss']
})
export class FooterSocialLinksComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }


}
