import { DiscountType } from './discount_type.model';

export class Promo {
  id?: number = 0;
  name: string;
  start_at: string;
  expires_at: string;
  discount_type_id: number;
  discount_amount: number;
  description: string;
  code: string;
  uses: number;
  min_cart_value: number;

  created_at: string;
  updated_at: string;

  discount_type: DiscountType;

  public product_type_ids: number[];
  public status: boolean;

  public constructor() {
    this.discount_type = new DiscountType();

    // this.name = "Test";
    // this.no_of_free_image = 10;
    // this.discount_type_id = 1;
    // this.discount_amount = 100;
    // this.description = "Loream Ipsum!";
    // this.code = "xxx";
    // this.uses = 100;
    // this.max_uses = 1000;
    // this.max_uses_user = 100;
  }
}
