export class Media {
  index?: number = 0;
  type: string;

  title: string;
  mobile_title: string;
  subtitle: string;
  description: string;
  image: string;
  pdf: string;
  mobile_image: string;
  button_title: string;
  button_link: string;
  published: number;
  text_type: number;
  position_hor: string;
  position_ver: string;
  alt_text: string;
  top_heading: string;

  public constructor() {
  }
}
