export class Contact {
    id?: number = 0;
    public name: string;
    public email: string;
    public contact: string;
    public requirement: string;
    public subject: string;
    public page_url: string;
    public remark: string;

    public constructor() {
    }
}
