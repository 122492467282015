import {Component, Input, OnInit} from '@angular/core';
import {SwiperConfigInterface, SwiperPaginationInterface} from 'ngx-swiper-wrapper';
import {Testimonial} from '../../admin/models/testimonial.model';


@Component({
    selector: 'app-testimonial-carousel',
    templateUrl: './testimonial-carousel.component.html',
    styleUrls: ['./testimonial-carousel.component.scss']
})
export class TestimonialCarouselComponent implements OnInit {
    @Input('slides') slides: Array<any> = [];

    public config: SwiperConfigInterface = {};
    public noOfImages = 5;
    public showmore = 0;
    public ellipsisChar = 120;
    private pagination: SwiperPaginationInterface = {
        el: '.swiper-pagination',
        clickable: true
    };

    constructor() {
    }

    ngOnInit() {
        if (window.innerWidth < 960) {
          this.ellipsisChar = 70;
        }
        if (window.innerWidth < 1280) {
            this.noOfImages = 5;
        }
        if (window.innerWidth < 575) {
            this.noOfImages = 1;
        }
    }

    public changeTextStatus(testimonial: Testimonial, status: number) {
        testimonial.showmore = status;
    }

    ngAfterViewInit() {
        this.config = {
            slidesPerView: this.noOfImages,
            spaceBetween: 15,
            keyboard: true,
            navigation: true,
            // pagination: this.pagination,
            grabCursor: true,
            loop: false,
            preloadImages: false,
            lazy: true,
            autoplay: {
                delay: 6000,
                disableOnInteraction: false
            },
            speed: 500,
            effect: 'slide'
        };
    }

}
