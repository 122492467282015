import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {emailValidator} from '../../theme/utils/app-validators';

import {BehaviorSubject, Observable} from 'rxjs';
import {AppService} from '../../app.service';
import {WallpaperRequest} from '../../admin/models/wallpaper_request.model';
import {finalize} from 'rxjs/operators';
import {WallpaperRequestService} from '../../admin/services/wallpaper_request.service';
import {environment} from '../../../environments/environment';
import {GtmService} from '../../gtm.service';

@Component({
    selector: 'app-wall-paper-request',
    templateUrl: './wall-paper-request.html',
    styleUrls: ['./wall-paper-request.component.scss']
})
export class WallPaperRequestComponent implements OnInit {
    @Input('section') section = 100;
    private loadingSubject: BehaviorSubject<boolean>;
    public loading$: Observable<boolean>;
    requestDemoForm: FormGroup;
    @Input('wallpaper') wallpaper: any;
    @Input('url') url = '';
    @Input('size') size = '';
    @Output() submitEvent = new EventEmitter<number>();

    // tslint:disable-next-line:max-line-length
    constructor(public formBuilder: FormBuilder, public router: Router, public snackBar: MatSnackBar,
                public appService: AppService,
                private wallpaperRequestService: WallpaperRequestService,
                private gtmService: GtmService) {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
        this.loading$ = this.loadingSubject.asObservable();
    }


    ngOnInit() {
        this.requestDemoForm = this.formBuilder.group({
            name: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
            email: ['', Validators.compose([Validators.required, emailValidator])],
            contact: ['', Validators.required],
            city: ['', Validators.required],
            agree: [''],
            wallpaper_name: [this.wallpaper ? (this.size ?  this.wallpaper.wallpaper_id  : this.wallpaper.title) : ''],
            url: [this.url],
            size: [this.size],
        });

    }

    public updateSelect() {

    }

    // public onFormSubmit(values: any) {
    //     if (this.requestDemoForm.valid) {
    //         this.appService.addWallPaperRequest(values["fullname"],values["email"],values["contact"],
    //            values["room"]).subscribe(data => {
    //             this.snackBar.open('Thank you for your Request!\n' +
    //                 'Our Client Relationship Manager will contact you in 24 hours.                                                                                                                                                 ', '×', {
    //                 panelClass: 'success',
    //                 verticalPosition: 'top',
    //                 duration: 3000
    //             });
    //             // this.requestDemoForm.reset();
    //         });
    //     }
    // }

    public onFormSubmit(): void {
        if (this.requestDemoForm.invalid || this.requestDemoForm.pristine) {
            return;
        }

        this.loadingSubject.next(true);
        this.requestDemoForm.disable();

        let element: WallpaperRequest = new WallpaperRequest();
        element = Object.assign({}, element, this.requestDemoForm.value);
        element.agree = this.requestDemoForm.value.agree  ?  this.requestDemoForm.value.agree : false;
        this.wallpaperRequestService.add(element)
            .pipe(
                // catchError(() => of([])),
                finalize(() => {
                    this.loadingSubject.next(false);
                    this.requestDemoForm.enable();
                    this.submitEvent.emit();
                })
            )
            .subscribe(
                res => {
                    this.snackBar.open('Thank you for contacting us. Our agent will contact you within 24 hours. ', 'Ok', {
                        duration: 10000,
                        verticalPosition: 'top',
                        panelClass: ['success']
                    });
                    if (environment.production) {
                        this.gtmService.gtmFormSubmission(this.requestDemoForm.value['name'], this.requestDemoForm.value['email'],
                            this.requestDemoForm.value['contact'], 'wallpaper-request', this.wallpaper?.wallpaper_id);
                    }
                },
                err => {
                    console.log('error: ', err);
                    // this.errorSubject.next(error);
                    // this.loadingSubject.next(false);
                    this.snackBar.open('There is some error', 'Ok', {
                        duration: 10000,
                        verticalPosition: 'top',
                        panelClass: ['error']
                    });
                });
    }

}
