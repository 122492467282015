import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {WishlistItem} from 'src/app/admin/models/wishlist_item.model';
import {AuthenticationService} from 'src/app/admin/services/authentication.service';
import {Params} from '@angular/router';
import {AppService} from 'src/app/app.service';
import {catchError, finalize} from 'rxjs/operators';
import {of} from 'rxjs';
import Swal from 'sweetalert2';
import {PredefinedLayoutDetail} from "../../admin/models/art_set_master.model";
import {PredefinedLayoutDetailService} from "../../admin/services/predefined_layout_detail.service";
import {ProductImage} from "../../app.models";

@Component({
    selector: 'app-predefined-item-carousel',
    templateUrl: './predefined-item-carousel.component.html',
    styleUrls: ['./predefined-item-carousel.component.scss']
})
export class PredefinedItemCarouselComponent implements OnInit {
    @Output() changed = new EventEmitter();
    @Input('predefinedLayoutDetail') predefinedLayoutDetail: PredefinedLayoutDetail;
    public config: SwiperConfigInterface = {};
    @Input('predefinedLayouts') predefinedLayouts: PredefinedLayoutDetail[] = [];
    @Input('showPrice') showPrice = true;


    constructor(
        public appService: AppService,
        private predefinedLayoutDetailService: PredefinedLayoutDetailService,
        private authenticationService: AuthenticationService,
    ) {
    }

    ngOnInit() {
        if(this.predefinedLayouts.length ==0) {
            this.getPredefinedLayouts();
        }
    }

    public refresh() {
        this.getPredefinedLayouts();
    }


    public getPredefinedLayouts() {
            const filter = [];
            // filter.push(['caption', '=', this.predefinedLayoutDetail.caption]);
            filter.push(['theme', '=', this.predefinedLayoutDetail.theme]);
            filter.push(['id', '!=', this.predefinedLayoutDetail.id]);
            filter.push(['status', '=', 1]);
            this.predefinedLayoutDetailService.filter(JSON.stringify(filter), '[]', 0, -1).subscribe(res => {
                this.predefinedLayouts = res['data'];
            });
    }

    ngAfterViewInit() {
        this.config = {
            observer: true,
            slidesPerView: 4,
            spaceBetween: 16,
            keyboard: true,
            navigation: true,
            pagination: false,
            grabCursor: true,
            loop: true,
            preloadImages: false,
            lazy: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },

            breakpoints: {
                480: {
                    slidesPerView: 2
                },
                740: {
                    slidesPerView: 2,
                },
                960: {
                    slidesPerView: 3,
                },
                1280: {
                    slidesPerView: 4,
                },
                1500: {
                    slidesPerView: 4,
                }
            }
        };
    }


}
