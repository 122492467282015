import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PagesComponent} from './pages/pages.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {AuthGuard} from './auth.guard';
import {ErrorPageComponent} from './pages/error-page/error-page.component';
import { ChangeRoomComponent } from './pages/wallpaper-customization/change-room/change-room.component';
import { SpacesComponent } from './pages/wallpaper-customization/spaces/spaces.component';

export const routes: Routes = [
    {
        path: '',
        component: PagesComponent, children: [
            // {path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)},
            // {path: 'home-new', loadChildren: () => import('./pages/home-new/home-new.module').then(m => m.HomeNewModule)},
            {path: '', loadChildren: () => import('./pages/home-new-2/home-new-2.module').then(m => m.HomeNew2Module)},
            // {
            //     path: 'frame-photos-old',
            //     loadChildren: () => import('./pages/frame-your-photos/frame-your-photos.module').then(m => m.FrameYourPhotosModule)
            // },
            {
                path: 'frame-photos',
                // tslint:disable-next-line:max-line-length
                loadChildren: () => import('./pages/frame-your-photos-new/frame-your-photos-new.module').then(m => m.FrameYourPhotosNewModule)
            },

            {
                path: 'wallpaper',
                loadChildren: () => import('./pages/wall-paper-new/wall-paper-new.module').then(m => m.WallPaperNewModule)
            },

            {
                path: 'wall-art',
                loadChildren: () => import('./pages/wall-art-new/wall-art-new.module').then(m => m.WallArtNewModule)
            },
            {
                path: 'categories/:name',
                loadChildren: () => import('./pages/wall-art-collectiom/categories.module').then(m => m.CategoriesModule)
            },
            {
                path: 'wall-art/:category/:name',
                loadChildren: () => import('./pages/wall-art-collectiom/categories.module').then(m => m.CategoriesModule)
            },
            {
                path: 'product/:category/:name',
                loadChildren: () => import('./pages/wall-art-product/wall-art-product.module').then(m => m.WallArtProductModule)
            },
            {
                path: 'wall-art/:categoryType/:category/:name',
                loadChildren: () => import('./pages/wall-art-product/wall-art-product.module').then(m => m.WallArtProductModule)
            },
            {
                path: 'product-all',
                loadChildren: () => import('./pages/wall-art-product/wall-art-product.module').then(m => m.WallArtProductModule)
            },
            {
                path: 'product-search/:name',
                loadChildren: () => import('./pages/wall-art-product/wall-art-product.module').then(m => m.WallArtProductModule)
            },
            {
                path: 'product-search/art-by-type/:name',
                loadChildren: () => import('./pages/wall-art-product/wall-art-product.module').then(m => m.WallArtProductModule)
            },
            // {
            //     path: 'product-customization-old',
            //     // tslint:disable-next-line:max-line-length
            //     loadChildren: () => import('./pages/product-customization/product-customization.module').then(m => m.ProductCustomizationModule)
            // },
            {
                path: 'product-customization',
                // tslint:disable-next-line:max-line-length
                loadChildren: () => import('./pages/product-customization-new/product-customization-new.module').then(m => m.ProductCustomizationNewModule)
            },
            {
                path: 'account',
                loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule),
                data: {breadcrumb: 'Account Settings'},
                canActivate: [AuthGuard]
            },
            // {
            //     path: 'compare',
            //     loadChildren: () => import('./pages/compare/compare.module').then(m => m.CompareModule),
            //     data: {breadcrumb: 'Compare'}
            // },
            {
                path: 'wishlist',
                loadChildren: () => import('./pages/wishlist/wishlist.module').then(m => m.WishlistModule),
                data: {breadcrumb: 'Wishlist'}
            },
            // {
            //     path: 'cart',
            //     loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule),
            //     data: {breadcrumb: 'Cart'}
            // },
            {
                path: 'cart-view',
                loadChildren: () => import('./pages/cart-view/cart-view.module').then(m => m.CartViewModule),
                data: {breadcrumb: 'Cart'}
                // canActivate: [AuthGuard]
            },
            {
                path: 'wishlist-view',
                loadChildren: () => import('./pages/wishlist-view/wishlist-view.module').then(m => m.WishlistViewModule),
                data: {breadcrumb: 'Wishlist'},
                canActivate: [AuthGuard]
            },
            {
                path: 'on-sale',
                loadChildren: () => import('./pages/on-sale/on-sale.module').then(m => m.OnSaleModule),
                data: {breadcrumb: 'On-Sale'}
            },
            {
                path: 'product-detail',
                loadChildren: () => import('./pages/on-sale-detail/on-sale-detail.module').then(m => m.OnSaleDetailModule),
                data: {breadcrumb: 'Product Detail'}
            },
            {
                path: 'featured-frame/:caption',
                loadChildren: () => import('./pages/on-sale-detail/on-sale-detail.module').then(m => m.OnSaleDetailModule),
                data: {breadcrumb: 'Featured Frame'}
            },
            {
                path: 'featured-art/:caption',
                loadChildren: () => import('./pages/on-sale-detail/on-sale-detail.module').then(m => m.OnSaleDetailModule),
                data: {breadcrumb: 'Featured Frame'}
            },
            {
                path: 'on-sale/:caption',
                loadChildren: () => import('./pages/on-sale-detail/on-sale-detail.module').then(m => m.OnSaleDetailModule),
                data: {breadcrumb: 'Featured Frame'}
            },
            // {
            //     path: 'checkout',
            //     loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule),
            //     data: {breadcrumb: 'Checkout'}
            // },
            {
                path: 'contact',
                loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule),
                data: {breadcrumb: 'Contact'}
            },
            //
            {
                path: 'sign-up',
                loadChildren: () => import('./pages/sign-up/sign-up.module').then(m => m.SignUpModule),
                data: {breadcrumb: 'Sign Up '}
            },
            // {
            //     path: 'brands',
            //     loadChildren: () => import('./pages/brands/brands.module').then(m => m.BrandsModule),
            //     data: {breadcrumb: 'Brands'}
            // },
            // {
            //     path: 'products',
            //     loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule),
            //     data: {breadcrumb: 'All Products'}
            // },
            {
                path: 'art-for-business',
                loadChildren: () => import('./pages/art-for-business/art-for-business.module').then(m => m.ArtForBusinessModule),
                data: {breadcrumb: 'Art For Business'}
            },
            {
                path: 'printing-partner',
                loadChildren: () => import('./pages/printing-partner/printing-partner.module').then(m => m.PrintingPartnerModule),
                data: {breadcrumb: 'Printing Partner'}
            },
            {
                path: 'about',
                loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule),
                data: {breadcrumb: 'About Mahattaart'}
            },
            {
                path: 'find-your-art',
                loadChildren: () => import('./pages/find-your-art/find-your-art.module').then(m => m.FindYourArtModule),
                data: {breadcrumb: 'Find Your Art'}
            },
            {
                path: 'partner',
                loadChildren: () => import('./pages/partner/partner.module').then(m => m.PartnerModule),
                data: {breadcrumb: 'Partner'}
            },
            {
                path: 'faq',
                loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule),
                data: {breadcrumb: 'FAQ'}
            },
            {
                path: 'ordering',
                loadChildren: () => import('./pages/ordering/ordering.module').then(m => m.OrderingModule),
                data: {breadcrumb: 'Ordering'}
            },
            {
                path: 'shipping',
                loadChildren: () => import('./pages/shipping/shipping.module').then(m => m.ShippingModule),
                data: {breadcrumb: 'Shipping'}
            },
            {
                path: 'return-policy',
                loadChildren: () => import('./pages/return-policy/return-policy.module').then(m => m.ReturnPolicyModule),
                data: {breadcrumb: 'Return Policy'}
            },
            {
                path: 'privacy-policy',
                loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
                data: {breadcrumb: 'Privacy Policy'}
            },
            {
                path: 'terms-of-use',
                loadChildren: () => import('./pages/terms-of-use/terms-of-use.module').then(m => m.TermsOfUseModule),
                data: {breadcrumb: 'Terms Of Use'}
            },
            {
                path: 'career',
                loadChildren: () => import('./pages/career/career.module').then(m => m.CareerModule),
                data: {breadcrumb: 'Career'}
            },
            {
                path: 'offer',
                loadChildren: () => import('./pages/offer/offer.module').then(m => m.OfferModule),
                data: {breadcrumb: 'Offers'}
            },
            {
                path: 'change-password',
                loadChildren: () => import('./pages/change-password/change-password.module').then(m => m.ChangePasswordModule),
                data: {breadcrumb: 'Change password'}
            },

            {
                path: 'access-denied',
                loadChildren: () => import('./pages/access-denied/access-denied.module').then(m => m.AccessDeniedModule),
                data: {breadcrumb: 'Access Denied'}
            },
            {
                path: 'thank-you',
                loadChildren: () => import('./pages/thank-you/thank-you.module').then(m => m.ThankYouModule),
                data: {breadcrumb: 'Thank You'}
            },
            {
                path: 'art-for-business/thank-you',
                loadChildren: () => import('./pages/thank-you-artset/thank-you-artset.module').then(m => m.ThankYouArtsetModule),
                data: {breadcrumb: 'Thank You'}
            },
            {
                path: 'collage-set',
                loadChildren: () => import('./pages/collage-set/collage-set.module').then(m => m.CollageSetModule)
            },
            {
                path: 'art-set',
                loadChildren: () => import('./pages/art-set/art-set.module').then(m => m.ArtSetModule)
            },
            {
                path: 'orders',
                loadChildren: () => import('./pages/order-history/order-history.module').then(m => m.OrderHistoryModule)
            },


            {
                path: 'salemarket',
                loadChildren: () => import('./pages/salemarket/salemarket.module').then(m => m.SalemarketModule)
            },
            

            {
                path: 'featured-art',
                loadChildren: () => import('./pages/on-sale/on-sale.module').then(m => m.OnSaleModule),
                data: {breadcrumb: 'Featured Art'}
            },
            {
                path: 'popular-art',
                loadChildren: () => import('./pages/on-sale/on-sale.module').then(m => m.OnSaleModule),
                data: {breadcrumb: 'Featured Art'}
            },
            {
                path: 'featured-frames',
                loadChildren: () => import('./pages/on-sale/on-sale.module').then(m => m.OnSaleModule),
                data: {breadcrumb: 'Featured Frames'}
            },
            {
                path: 'featured-wallpapers',
                loadChildren: () => import('./pages/on-sale/on-sale.module').then(m => m.OnSaleModule),
                data: {breadcrumb: 'Featured Wallpapers'}
            },
            {
                path: 'collage-set/:caption',
                loadChildren: () => import('./pages/art-set-customization/art-set-customization.module').then(m => m.ArtSetCustomizationModule)
            },
            {
                path: 'art-set-customization',
                // tslint:disable-next-line:max-line-length
                loadChildren: () => import('./pages/art-set-customization/art-set-customization.module').then(m => m.ArtSetCustomizationModule)
            },
            {
                path: 'share',
                loadChildren: () => import('./pages/share/share.module').then(m => m.ShareModule),
                data: {breadcrumb: 'Share'}
            },
            {
                path: 'wallpaper-listing',
                loadChildren: () => import('./pages/wallpaper-listing/wallpaper-listing.module').then(m => m.WallpaperListingModule)
            },
            {
                path: 'wallpaper/:type/:category',
                loadChildren: () => import('./pages/wallpaper-listing/wallpaper-listing.module').then(m => m.WallpaperListingModule)
            },
            {
                path: 'wallpaper-search',
                loadChildren: () => import('./pages/wallpaper-listing/wallpaper-listing.module').then(m => m.WallpaperListingModule)
            },
            {
                path: 'wallpaper-customization',
                // tslint:disable-next-line:max-line-length
                loadChildren: () => import('./pages/wallpaper-customization/wallpaper-customization.module').then(m => m.WallpaperCustomizationModule)
            },
            {
                path: 'wallpaper/:id',
                loadChildren: () => import('./pages/wallpaper-customization/wallpaper-customization.module').then(m => m.WallpaperCustomizationModule)
            },
            
            {
                path: 'wallpaper/custom-wallpaper',
                loadChildren: () => import('./pages/wallpaper-customization/wallpaper-customization.module').then(m => m.WallpaperCustomizationModule)
            },
            {
                path: 'shop-by-industry',
                loadChildren: () => import('./pages/b2b/b2b-landing/b2b-landing.module').then(m => m.B2bLandingModule)
            },
            {
                path: 'b2b-subcategory',
                loadChildren: () => import('./pages/b2b/b2b-subcategory/b2b-subcategory.module').then(m => m.B2bSubcategoryModule)
            },
            {
                path: 'space-decor',
                loadChildren: () => import('./pages/space-decor/space-decor.module').then(m => m.SpaceDecorModule)
            },
            {
                path: 'space-decor-listing',
                loadChildren: () => import('./pages/space-decor-listing/space-decor-listing.module').then(m => m.SpaceDecorListingModule)
            },
            {
                path: 'space-decor-customization/:collection/:slug/:variant',
                // tslint:disable-next-line:max-line-length
                loadChildren: () => import('./pages/space-decor-customization/space-decor-customization.module').then(m => m.SpaceDecorCustomizationModule)
            },
            {
                path: 'contributor',
                loadChildren: () => import('./pages/contributor-profile/contributor-profile.module').then(m => m.ContributorProfileModule),
                data: {breadcrumb: 'Contributor'}
            },
            {path: 'change-room/:space', component: ChangeRoomComponent},
            {path: 'spaces', component: SpacesComponent},
        ]
    },
    {
        path: 'sign-in',
        loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule),
        data: {breadcrumb: 'Sign In '}
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module')
            .then(m => m.AdminModule),
        canActivate: [AuthGuard],
        data: {roles: [1, 2, 3, 4, 5, 6, 8, 9, 11, 12]}
    },
    {path: 'error-page', component: ErrorPageComponent},
    {path: 'unauthorized', component: ErrorPageComponent},
    {path: '**', component: NotFoundComponent}
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            // preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
            initialNavigation: 'enabled' // for one load page, without reload
            // useHash: true
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
