import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BaseAPIService} from '../../services/base_api.service';
import {CurrencyPipe, DatePipe} from '@angular/common';
import {OrderPayment} from "../../models/order_payment.model";

@Component({
    selector: 'app-invoice-payment-dialog',
    templateUrl: './invoice-payment-dialog.component.html',
    styleUrls: ['./invoice-payment-dialog.component.scss'],
    providers: [
        DatePipe,
        CurrencyPipe
    ]
})
export class InvoicePaymentDialogComponent implements OnInit {
    public form: FormGroup;
    private loadingSubject: BehaviorSubject<boolean>;
    public loading$: Observable<boolean>;
    @Output() onClose = new EventEmitter<any>(true);
    @Output() onReset = new EventEmitter<any>(true);
    public orderOffline: any;
    private orderPaymentAmount = 0;

    constructor(public dialogRef: MatDialogRef<InvoicePaymentDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public fb: FormBuilder, private matSnackBar: MatSnackBar,
                public baseAPIService: BaseAPIService,
                private datePipe: DatePipe) {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
        this.loading$ = this.loadingSubject.asObservable();
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            id: 0,
            received_date: ['', Validators.compose([Validators.required])],
            mode: ['', Validators.compose([Validators.required])],
            cheque_date: '',
            cheque_no: '',
            txnid: '',
            amount: [0, Validators.compose([Validators.required])],
            remarks: '',
            invoice_id: '',
            order_id: '',
            customer_id: '',
            customer_name: '',
            invoice_date: '',
            grand_total: 0,
            total_received: 0,
            pending_amt: 0

        });

        this.getInvoiceData();

    }

    private getInvoiceData() {
        this.baseAPIService.apiEndpointToken = this.data.type == 'Online' ? 'order' : 'order_offline';
        if (this.data.orderPayment) {
            this.baseAPIService.find((this.data.order.id)).subscribe((res: any) => {
                this.data.order = res['data'];
                this.setFormData();
                this.data.orderPayment.received_date = this.datePipe.transform(this.data.orderPayment.received_date, 'yyyy-MM-dd');
                this.data.invoicePayment.cheque_date = this.datePipe.transform(this.data.orderPayment.cheque_date, 'yyyy-MM-dd');
                this.orderPaymentAmount = this.data.orderPayment.amount;
                this.form.patchValue(this.data.orderPayment);
            });
        } else {
            this.orderPaymentAmount = 0;
            this.setFormData();
        }

        // });
    }

    private setFormData() {
        console.log(this.data.order);
        if (this.data.type == 'Online') {
            this.data.order.total_received = this.data.order.grand_total - this.data.order.amt_pending;
        }
        this.data.order.pending_amt = this.data.order.grand_total - this.data.order.total_received;
        this.form.patchValue(this.data.order);
        this.form.get('id').setValue(0);
        this.form.get('customer_id').setValue(this.data.order.customer.customer_id);
        this.form.get('customer_name').setValue(this.data.order.customer.firstname + ' ' + this.data.order.customer.lastname);
        this.form.get('amount').setValue(0);
    }

    public onSubmit() {
        if (this.form.valid) {
            let element: OrderPayment = new OrderPayment();
            element = Object.assign({}, element, this.form.value);
            this.create(element);
        }
    }

    create(element) {
        this.loadingSubject.next(true);
        this.form.disable();
        this.baseAPIService.makeOrderPayment(element, this.data.type)
            .subscribe(
                res => {
                    if (res['data']) {
                        const orderPayment: OrderPayment = res['data'];
                        this.form.get('id').setValue(orderPayment.id);
                        this.loadingSubject.next(false);
                        this.onClose.emit();
                        this.onReset.emit();
                        this.matSnackBar.open('Record has been successfully added!', 'x', {
                            duration: 2000,
                            verticalPosition: 'top',
                            panelClass: ['success']
                        });
                        this.dialogRef.close(this.form.value);
                    } else if (res['error']) {
                        this.loadingSubject.next(false);
                        this.form.enable();
                        this.matSnackBar.open(res['error'], 'x', {
                            duration: 2000,
                            verticalPosition: 'top',
                            panelClass: ['error']
                        });

                    } else {
                        this.loadingSubject.next(false);
                        this.form.enable();
                        this.matSnackBar.open('There is some error', 'x', {
                            duration: 2000,
                            verticalPosition: 'top',
                            panelClass: ['error']
                        });

                    }
                });
    }

    getErrors(ctrl) {
        return Object.keys(ctrl.errors);
    }
}
