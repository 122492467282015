export class Meta {
  pageNumber: number;
  pageSize: number;
  recordsTotal: number;
  recordsFiltered: number;
  pageSizeOptions: number[];
  message: string;
  totalAmt: number;

  public constructor() {
  }
}
