import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Cart } from 'src/app/admin/models/cart.model';

@Component({
  selector: 'app-cart-details-dialog',
  templateUrl: './cart-details-dialog.component.html',
  styleUrls: ['./cart-details-dialog.component.scss']
})
export class CartDetailsDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CartDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {cart: Cart}
  ) {
  }

  public getImageName(cart: Cart) {
    let image_name: string = cart.customizedProduct.customizedProductItems[0].image_detail.image_path.split("/").pop();
    return image_name;
  }

  public isFrame(cart: Cart) : boolean {
    switch(cart.customizedProduct.product_type_id) {
      case 2:
      case 5:
      case 8:
        return true;
    }
    return false;
  }

  public isCanvas(cart: Cart) : boolean {
    switch(cart.customizedProduct.product_type_id) {
      case 1:
      case 4:
        return true;
    }
    return false;
  }

  public get cart() {
    return this.data.cart;
  }

  ngOnInit(): void {
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  public close(): void {
    this.dialogRef.close();
  }

}
