import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ShippingMethod} from '../models/shipping_method.model';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ShippingMethodService {
    private shippingMethodSubject: BehaviorSubject<ShippingMethod> = new BehaviorSubject<ShippingMethod>(new ShippingMethod());
    public shippingMethod$: Observable<ShippingMethod> = this.shippingMethodSubject.asObservable();

    private apiUrlAuth = '';
    private apiUrlUnAuth = '';

    constructor(private http: HttpClient) {
        this.apiUrlAuth = environment.apiUrlauth;
        this.apiUrlUnAuth = environment.apiUrlUnauth;
    }

    public initByPincode(pincode: string) {
        const filter = [];
        filter.push(['pincode', '=', pincode]);

        this.filter(JSON.stringify(filter), '[]', 0, -1).subscribe(res => {
            this.shippingMethodSubject.next(res['data'][0]);
        });
    }

    public set shippingMethod(shippingMethod: ShippingMethod) {
        this.shippingMethodSubject.next(shippingMethod);
    }

    public get shippingMethod(): ShippingMethod {
        return this.shippingMethodSubject.value;
    }


    filter(filter = '[]', orderBy = '[]', pageNumber = 0, pageSize = 10): Observable<ShippingMethod[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('filter', filter);
        if (orderBy || orderBy != '') {
            httpParams = httpParams.append('orderBy', orderBy);
        }
        httpParams = httpParams.append('pageNumber', pageNumber.toString());
        if (pageSize || pageSize > 0) {
            httpParams = httpParams.append('pageSize', pageSize.toString());
        }
        return this.http.get<ShippingMethod[]>(this.apiUrlUnAuth + '/shipping_method', {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('filter', []))
            );
    }

    find(id = 0): Observable<ShippingMethod> {
        let httpParams = new HttpParams();
        return this.http.get<ShippingMethod>(this.apiUrlUnAuth + '/shipping_method/' + id, {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('find', null))
            );
    }

    add(element: ShippingMethod): Observable<ShippingMethod> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.post<ShippingMethod>(this.apiUrlAuth + '/shipping_method', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getShippingMethod', element))
            );
    }

    update(element: ShippingMethod): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<ShippingMethod>(this.apiUrlAuth + '/shipping_method', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getShippingMethod', element))
            );
    }

    delete(ids = '[]'): Observable<any> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('ids', ids);
        return this.http.delete<any>(this.apiUrlAuth + '/shipping_method', {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('deleteShippingMethod', null))
            );
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }


  getShippingAmt(filter = '[]', orderBy = '[]', pageNumber = 0, pageSize = 10, pincode: string): Observable<ShippingMethod[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('filter', filter);
        if (orderBy || orderBy != '') {
            httpParams = httpParams.append('orderBy', orderBy);
        }
        httpParams = httpParams.append('pageNumber', pageNumber.toString());
        if (pageSize || pageSize > 0) {
            httpParams = httpParams.append('pageSize', pageSize.toString());
        }
        httpParams = httpParams.append('pincode', pincode.toString());
        return this.http.get<ShippingMethod[]>(this.apiUrlUnAuth + '/shipping_amt', {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('filter', []))
            );
    }
}
