import {Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {emailValidator} from '../../../theme/utils/app-validators';
// import {ForgotPasswordComponent} from '../../shared/forgot-password/forgot-password.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AuthenticationService} from 'src/app/admin/services/authentication.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {messages} from 'src/environments/environment';
import {CityService} from 'src/app/admin/services/city.service';
import {City} from 'src/app/admin/models/city.model';
import {OrderService} from '../../services/order.service';
import {User} from '../../models/user.model';
import {Order} from '../../models/order.model';


@Component({
    selector: 'app-shipping-dialog',
    templateUrl: './shipping-dialog.component.html',
    styleUrls: ['./shipping-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ShippingDialogComponent implements OnInit {
    shippingServices = [];
    selectedService: any = {};

    constructor(
        public formBuilder: FormBuilder,
        public router: Router,
        private route: ActivatedRoute,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ShippingDialogComponent>,
        public cityService: CityService,
        public orderService: OrderService,
        public authenticationService: AuthenticationService,
        private matSnackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: { order: Order, 'order_type': string }
    ) {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
        this.loading$ = this.loadingSubject.asObservable();

        this.cityLoadingSubject = new BehaviorSubject<boolean>(false);
        this.cityLoading$ = this.cityLoadingSubject.asObservable();

        this.currentUser = authenticationService.currentUserValue;

    }

    get message() {
        return this.message;
    }

    @Output() onClose = new EventEmitter<any>(true);
    @Output() onReset = new EventEmitter<any>(true);


    shippingForm: FormGroup;

    private loadingSubject: BehaviorSubject<boolean>;
    public loading$: Observable<boolean>;

    private cityLoadingSubject: BehaviorSubject<boolean>;
    public cityLoading$: Observable<boolean>;

    public cities: City[] = [];
    currentUser: User;

    private messages = messages;

    ngOnInit() {

        this.shippingForm = this.formBuilder.group({
            id: 0,
            shipping_name: ['', Validators.required],
            shipping_mobile: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
            shipping_email: ['', Validators.compose([emailValidator])],
            shipping_address: ['', Validators.required],
            shipping_pincode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
            shipping_city: ['', [Validators.required]],
            weight: ['', [Validators.required]],
            height: ['', [Validators.required]],
            length: ['', [Validators.required]],
            breadth: ['', [Validators.required]],
            shipping_charges: [''],
            cod_charges: [''],
            discount: [''],
            tags: [''],            
            shipment_provider: ['', [Validators.required]],
            shipping_mode: ['', [Validators.required]],
            request_auto_pickup: [false, [Validators.required]],
            is_insurance: [false, [Validators.required]]
        });

        if (this.data.order) {
            this.loadingSubject.next(true);

            this.shippingForm.disable();

            if(this.data.order_type == 'offline')
            {
                this.orderService.findOffline(this.data.order.id).subscribe((res: any) => {
                    const data = res.data;
                    data.shipping_address = data.shipping_address_detail;
                    this.shippingForm.patchValue(data);

                    this.loadingSubject.next(false);
                    this.shippingForm.enable();
                });

            } else {
                this.orderService.find(this.data.order.id).subscribe((res: any) => {
                    const data = res.data;
                    this.shippingForm.patchValue(data);

                    this.loadingSubject.next(false);
                    this.shippingForm.enable();
                });
            }
        }

    }

    public onSubmit(type) {
        if (this.shippingForm.valid) {
            let element: any = {};
            element = Object.assign({}, element, this.shippingForm.value);
            element.order_id = this.data.order.id;
            element.order_type = this.data.order_type;

            console.log(type);
            if (type == 'fetch') {
                this.fetchRates(element);
            } else {
                element.service_detail = JSON.stringify(this.selectedService);
                this.update(element);

            }
        }
    }

    downloadBase64Pdf(base64String: string) {
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
      
        window.open(blobUrl, '_blank');
    }
      

    update(element) {
        this.loadingSubject.next(true);
        this.shippingForm.disable();


        this.orderService.createShipment(element)
            .pipe(
                finalize(() => {
                    this.loadingSubject.next(false);
                })
            )
            .subscribe(
                res => {
                    this.loadingSubject.next(false);
                    this.shippingForm.enable();

                    if(this.shippingForm.value.shipment_provider=="nimbuspost"){
                       window.open(res['data']['label'], '_blank');
                    }else{
                        this.downloadBase64Pdf(res['data']['label']);
                    }

                    this.matSnackBar.open('Record has been successfully updated!', 'x', {
                        duration: 2000,
                        verticalPosition: 'top',
                        panelClass: ['success']
                    });
                    this.dialogRef.close();
                    this.onClose.emit();
                },
                err => {

                    this.matSnackBar.open('Failed to Create Shipment!', 'x', {
                        duration: 2000,
                        verticalPosition: 'top',
                        panelClass: ['error']
                    });
                    this.dialogRef.close();
                    this.onClose.emit();
                    // this.form.enable();
                    // for (let key of Object.keys(err.errors)) {
                    //   const formControl = this.form.get(key);
                    //   if (formControl) {
                    //     // activate the error message
                    //     let errorMsg = this.i18nSelectPipe.transform(err.errors[key], this.messages)
                    //     setTimeout(() => {
                    //       formControl.setErrors({
                    //         serverError: errorMsg
                    //       });
                    //     });
                    //   }
                    // }
                }
            );
    }


    displayFn(city: City) {
        if (city && city.name) {
            return city.name + ' (' + city.state.name + ', ' + city.state.country.name + ')';
        } else {
            return '';
        }
    }

    public close(): void {
        this.dialogRef.close();
    }

    fetchRates(element) {
        this.orderService.shippingRates(element)
            .pipe(
                finalize(() => {
                    this.loadingSubject.next(false);
                })
            )
            .subscribe(
                res => {
                    this.loadingSubject.next(false);
                    this.shippingForm.enable();
                    this.shippingServices = res.data;

                    
                },
                err => {
                }
            );
    }

    onShippingService(shippingService: any) {
        this.selectedService = shippingService;
    }
}
