import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SwiperConfigInterface, SwiperPaginationInterface} from 'ngx-swiper-wrapper';
import {WallpaperDialogComponent} from '../wallpaper-dialog/wallpaper-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import { Router } from '@angular/router';


@Component({
    selector: 'app-wall-paper-carousel',
    templateUrl: './wall-paper-carousel.component.html',
    styleUrls: ['./wall-paper-carousel.component.scss']
})
export class WallPaperCarouselComponent implements OnInit {
    @Input('slides') slides: Array<any> = [];
    @Input('images') images: number = 4;
    @Input('pattern') pattern: any = 4;
    @Input() changeMockupEnv!: (data: any) => void;

    @Input('type') type: string = "new";
    @Input('text-align') textAlignment = "left";

    public config: SwiperConfigInterface = {};

    private pagination: SwiperPaginationInterface = {
        el: '.swiper-pagination',
        clickable: true
    };

    @Output() wishlistUpdated = new EventEmitter<void>(); // Event to notify parent

    constructor(private dialog: MatDialog,private router:Router,private cdRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.wishlistUpdated.emit(); // Trigger the event
    }


    ngAfterViewInit() {
        this.config = {
            observer: true,
            observeParents: true,
            slidesPerView: this.images, // Ensure this is set before use
            spaceBetween: 16,
            keyboard: true,
            navigation: true,
            pagination: false,
            grabCursor: true,
            loop: false, // Make sure this is false to respect `initialSlide`
            preloadImages: false,
            lazy: true,
            autoplay: false,
            initialSlide: 0, // Force first image
            breakpoints: {
              480: { slidesPerView: 2 },
              740: { slidesPerView: 2 },
              960: { slidesPerView: 3 },
              1280: { slidesPerView: this.images },
              1500: { slidesPerView: this.images }
            }
        };

        console.log("config",this.config)

    }

    forceChangeDetection() {
        console.log('Force change detection triggered');
        this.cdRef.detectChanges();
    }

    enquireNowDialog(wallpaper) {
        const dialogRef = this.dialog.open(WallpaperDialogComponent, {
            data: {
                wallpaper
            },
            // width: '50vw',
            // maxWidth: '90vw',
            disableClose: true,
            autoFocus: false
        });
        const close = dialogRef.componentInstance.onClose.subscribe(() => {
            dialogRef.close();
        });
    }

    callParentFunction(wallpaper:any) {
    
        if (typeof this.changeMockupEnv === 'function') {
          this.changeMockupEnv(wallpaper);
        } else {
          // Build the navigation URL
          const collectionSlug = wallpaper?.collection
            ? wallpaper?.collection.slug
            : wallpaper?.collection_slug;
          
          const route = `/wallpaper/${wallpaper?.wallpaper_id}`;
          
          console.warn("customFunction is not defined! Navigating to:", route);
          this.router.navigate([route]);
        }
      }

      ngOnChanges(changes: SimpleChanges) {
        if (changes['slides']) {
          console.log('Slides updated in child:', this.slides);
          // Trigger any necessary actions when `slides` is updated
        }
      }
    
}
