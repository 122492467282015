import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {MatHorizontalStepper, MatStepper} from '@angular/material/stepper';
import {Router} from '@angular/router';

@Component({
    selector: 'app-tracker',
    templateUrl: './tracker.component.html',
    styleUrls: ['./tracker.component.scss']
})
export class TrackerComponent implements OnInit {
    @Input('tracker') tracker: number = 1;
    @Input('selection') selection: string = '';
    @Input('customize') customize: string = '';
    @ViewChild('stepper') stepper: MatStepper;

    public isMobile = false;

    constructor(private router: Router) {
    }

    ngOnInit() {
        if (window.innerWidth <= 575) {
            this.isMobile = true;
        }
    }


    selectionChange($event: StepperSelectionEvent, stepper: MatHorizontalStepper) {
        console.log(stepper);
        if ($event.selectedIndex == 0) {
            this.router.navigate([this.selection]);
        }
        if ($event.selectedIndex == 1) {
            window.location.href = this.customize;
        }
    }
}
