import {Component, Input, OnInit} from '@angular/core';
import {SwiperConfigInterface, SwiperPaginationInterface} from 'ngx-swiper-wrapper';
import {OnSale} from '../../admin/models/on_sale.model';
import {catchError, finalize} from 'rxjs/operators';
import {AuthenticationService} from '../../admin/services/authentication.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CartService} from '../../admin/services/cart.service';
import {of} from 'rxjs';
import {WishlistItem} from '../../admin/models/wishlist_item.model';
import {WishlistItemService} from '../../admin/services/wishlist_item.service';
import Swal from 'sweetalert2';
import {WishlistQuickDialogComponent} from '../wishlist-quick-dialog/wishlist-quick-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../../environments/environment';
import {CartLoginAthentiacationService} from "../../cart-login-athentiacation.service";

@Component({
    selector: 'app-product-listing-carousel',
    templateUrl: './product-listing-carousel.component.html',
    styleUrls: ['./product-listing-carousel.component.scss']
})
export class ProductListingCarouselComponent implements OnInit {
    @Input('slides') slides: Array<OnSale> = [];
    @Input('showPrice') showPrice = false;
    @Input('showCaption') showCaption = false;
    @Input('isShowElevation') isShowElevation = true;

    public config1: SwiperConfigInterface = {};
    public noOfImages=4;

    pagination: SwiperPaginationInterface = {
        el: '.swiper-pagination2',
        clickable: true
    };
    public  offer: string;


    constructor(private authenticationService: AuthenticationService,
                private router: Router, private snackbar: MatSnackBar,
                private cartService: CartService,
                private cartLoginAthentiacationService: CartLoginAthentiacationService,
                private wishlistItemService: WishlistItemService,
                private dialog: MatDialog) {
        this.offer = environment.offer;

        console.log(this.slides);
    }

    ngOnInit() {
        // console.log(this.slides);
        if (window.innerWidth < 1280) {
            this.noOfImages = 4;
        }
        if (window.innerWidth < 575) {
            this.noOfImages = 2;
        }
    }


    ngAfterViewInit() {
        this.config1 = {
            slidesPerView: this.noOfImages,
            spaceBetween: 2,
            keyboard: true,
            navigation: true,
            // pagination: this.pagination,
            grabCursor: true,
            loop: true,
            preloadImages: true,
            lazy: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            speed: 500,
            effect: 'slide'
        };

    }

    public onAddToCart(onSale: OnSale) {
        let on_sale_id: number = onSale.id;
        let customer_id: number = 0;
            if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
                customer_id = this.authenticationService.currentUserValue.id;
                this.addToCart(on_sale_id, customer_id);
            } else {
                this.addToGuestCart(on_sale_id);
            }

    }


    private addToCart(on_sale_id: number, customer_id: number) {
        this.cartService.addOnSaleItemToCart(on_sale_id, customer_id)
            .pipe(
                finalize(() => {
                    this.router.navigate(['/cart-view']);
                })
            )
            .subscribe(
                res => {
                    this.snackbar.open('Product added to cart successfully!', '×', {
                        panelClass: 'success',
                        verticalPosition: 'top',
                        duration: 3000
                    });
                });
    }

    private addToGuestCart(on_sale_id: number) {
        this.cartService.fillSaleItemToCart(on_sale_id)
            .pipe(
                finalize(() => {
                    this.router.navigate(['/cart-view']);
                    // this.loadingSubject.next(false);
                    // this.form.enable();
                })
            )
            .subscribe(
                res => {
                    // let _cart =res;
                    this.cartService.addToCartGuest(res);
                    this.snackbar.open('Product added to cart successfully!', '×', {
                        panelClass: 'success',
                        verticalPosition: 'top',
                        duration: 3000
                    });
                    // let _customizedProduct = _cart.customizedProduct;
                    // let _customizedProductItem = _customizedProduct.customizedProductItems[0];
                });
    }



    public onRemoveFromWishList(wishlist_item_id) {
        const ids = [];
        ids.push(wishlist_item_id);
        const self = this;
        Swal.fire({
            title: 'Are you sure to remove item from wishlist??',
            text: 'You won\'t be able to revert this!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, remove it!',
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            preConfirm() {
                return new Promise((resolve) => {
                    self.wishlistItemService.delete(JSON.stringify(ids))
                        .pipe(
                            catchError(() => of([])),
                            finalize(() => resolve())
                        )
                        .subscribe(
                            res => {
                            });
                });
            }
        }).then((result) => {
        });

    }

    private addToWishlist(on_sale_id, wishlist_id) {
        let wishlist_item = new WishlistItem();
        wishlist_item.customized_product_id = 0;
        wishlist_item.on_sale_id = on_sale_id;
        wishlist_item.wishlist_id = wishlist_id;
        wishlist_item.image_id = 0;
        wishlist_item.image_filename = '';
        wishlist_item.image_caption = '';
        wishlist_item.image_photographer = '';
        wishlist_item.user_id = this.authenticationService.currentUserValue.id;

        this.wishlistItemService.add(wishlist_item)
            .pipe(
                finalize(() => {
                })
            )
            .subscribe(
                res => {
                });

    }

    public onAddToWishList(onSale: OnSale) {
        // if(onSale.product.slug!='wall_art' && onSale.product_type.slug != 'frame_only')
        // {
        //     this.snackbar.open('Please upload Image on detail Page', '×', {
        //         panelClass: 'error',
        //         verticalPosition: 'top',
        //         duration: 3000
        //     });
        // } else {
            if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
                const dialogRef = this.dialog.open(WishlistQuickDialogComponent, {
                    data: {
                        // customer_address_id: 0
                    },
                    disableClose: true,
                    autoFocus: false
                });
                const close = dialogRef.componentInstance.onClose.subscribe(data => {
                    // console.log("ICTSoft onclose", data.wishlist_id);
                    dialogRef.close();
                    this.addToWishlist(onSale.id, data.wishlist_id);

                });
            } else {
                const dialogRef = this.cartLoginAthentiacationService.checkIsRegisteredUser();
                const close = dialogRef.componentInstance.onClose.subscribe(() => {
                    dialogRef.close();
                });
            }
        // }
    }

}
