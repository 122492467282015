import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Category, Product, ProductList, User, WallArtCategory} from './app.models';
import {environment} from '../environments/environment';
import {Mount} from './admin/models/mount.model';
import {catchError, map} from 'rxjs/operators';
import {WishlistItem} from './admin/models/wishlist_item.model';
import {Cart} from './admin/models/cart.model';
import {AuthenticationService} from './admin/services/authentication.service';
import {CartService} from './admin/services/cart.service';
import {WishlistItemService} from './admin/services/wishlist_item.service';
import {DOCUMENT} from '@angular/common';
import {ArtForBusiness} from './admin/models/art_for_business.model';
import {sha512} from 'js-sha512';
import {Setting} from './admin/models/setting.model';
import {Customer} from './admin/models/customer.model';
import {ContributorData} from './admin/models/contributor';

declare var bolt: any;

export class Data {
    constructor(
        public categories: Category[],
        public compareList: Product[],
        public wishList: Product[],
        public cartList: Product[],
        public totalPrice: number,
        public totalCartCount: number,
        public setting: Setting
    ) {
    }
}

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public Data = new Data(
        [], // categories
        [], // compareList
        [],  // wishList
        [],  // cartList
        null, // totalPrice,
        0, // totalCartCount,
        null
    );

    public viewProductRole = [1, 2, 4, 5, 6, 8, 9];
    public addProductRole = [1, 2, 6, 8, 9];
    public editProductRole = [1, 2, 6, 8, 9];

    private backendUrlUnAuth = environment.apiUrlUnauth + '/';
    public backendUrlAUTH = environment.apiUrlauth;
    private contributorUrl = environment.contributorUrl;


    private apiUrlAuth = '';
    private apiUrlUnAuth = '';
    private url = '';
    public artTypes =
        [
            {id: 1, name: 'Painting', url: '/', activated: false},
            {id: 2, name: 'Photography', url: '/', activated: false},
            {id: 4, name: 'Illustrations', url: '/', activated: false},
            {id: 3, name: 'Posters', url: '/', activated: false},
        ];
    public typeOfFrames =
        [
            {id: 2, name: 'Wall Frame', url: '/', activated: false},
            {id: 1, name: 'Table Frame', url: '/', activated: false}
        ];

    constructor(
        public http: HttpClient,
        public snackBar: MatSnackBar,
        public cartService: CartService,
        public wishlistItemService: WishlistItemService,
        private authenticationService: AuthenticationService,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.apiUrlAuth = environment.apiUrlauth;
        this.apiUrlUnAuth = environment.apiUrlUnauth;
        this.backendUrlUnAuth = environment.apiUrlUnauth + '/';
        this.backendUrlAUTH = environment.apiUrlauth;

        if (document.location.hostname != 'localhost') {
            this.url = document.location.protocol + '//' + document.location.hostname + '/assets/data/';
        } else {
            this.url = environment.frontUrl + '/assets/data/';
        }
    }

    checkAccessPermissionProduct(type) {
        let isEnable = false;
        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            if (type == 'view' && this.viewProductRole.filter(item => item == this.authenticationService.currentUserValue.role_id).length > 0) {
                isEnable = true;
            } else if (type == 'add' && this.addProductRole.filter(item => item == this.authenticationService.currentUserValue.role_id).length > 0) {
                isEnable = true;
            } else if (type == 'edit' && this.editProductRole.filter(item => item == this.authenticationService.currentUserValue.role_id).length > 0) {
                isEnable = true;
            }
        }
        return isEnable;
    }

    moveWishlistItemToCart(wishlist_item_id: number, url: string): Observable<WishlistItem> {
        const element: any = {
            wishlist_item_id: wishlist_item_id,
            url: url
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.post<WishlistItem>(this.apiUrlAuth + '/moveWishlistItemToCart', element, httpOptions)
            .pipe(
                map(res => {
                    this.cartService.refreshCount();
                    this.wishlistItemService.refreshCount();
                    return res;
                }),
                catchError(this.handleError('moveWishlistItemToCart', element))
            );
    }

    moveCartToWishlistItem(cart_id: number, wishlist_id: number, url: string): Observable<Cart> {
        const element: any = {
            cart_id: cart_id,
            wishlist_id: wishlist_id,
            url: url
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.post<Cart>(this.apiUrlAuth + '/moveCartToWishlistItem', element, httpOptions)
            .pipe(
                map(res => {
                    this.cartService.refreshCount();
                    this.wishlistItemService.refreshCount();
                    return res;
                }),
                catchError(this.handleError('moveCartToWishlistItem', element))
            );
    }


    getWallArtImage(image_id, more_products = 1): Observable<any> {
        let httpParams = new HttpParams();
        let user_id = (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) ? this.authenticationService.currentUserValue.id : 0;
        return this.http.get<any>(this.apiUrlUnAuth + '/wallart-search-by-image-id?image_id=' + image_id + '&user_id=' + user_id + '&more_products=' + more_products, {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('getWallArtImage', []))
            );
    }

    getPrintSizes(product, max_h, max_w, image_path, default_image, product_type): Observable<any[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('product', product);
        httpParams = httpParams.append('max_h', max_h);
        httpParams = httpParams.append('max_w', max_w);
        httpParams = httpParams.append('image_path', image_path);
        httpParams = httpParams.append('default_image', default_image);
        httpParams = httpParams.append('product_type', product_type);

        return this.http.get<string[]>(this.apiUrlUnAuth + '/printSizes', {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('printSizes', []))
            );
    }

    initProductCustomization(httpParams: HttpParams): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrlUnAuth + '/init-product-customization', {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('init-product-customization', []))
            );
    }

    initArtSetCustomization(httpParams: HttpParams): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrlUnAuth + '/init-artset-customization', {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('init-artset-customization', []))
            );
    }

    initWallpaperCustomization(httpParams: HttpParams): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrlUnAuth + '/init-wallpaper-customization', {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('init-wallpaper-customization', []))
            );
    }

    uploadMedia(element): Observable<Mount> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.post<Mount>(this.apiUrlUnAuth + '/uploadMedia', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getMount', element))
            );
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }


    public getCategories(): Observable<Category[]> {
        return this.http.get<Category[]>(this.url + 'categories.json');
    }

    public getCategoriesBanner(): Observable<any> {
        return this.http.get<any>(this.url + 'categories-banner.json');
    }

    public signIn(email, password): Observable<any> {
        return this.http.post<any>(this.backendUrlUnAuth + 'login', {email, password});
    }

    public register(name, email, phone, password): Observable<any> {
        return this.http.post<any>(this.backendUrlUnAuth + 'register', {name, email, phone, password});
    }

    // public updateProfile(firstname, lastname, email, company, com_type, job_desc, address, state, pincode, city, phone, fax, user_id): Observable<User> {
    //     return this.http.post<any>(this.backendUrl + 'auth/updateProfile', { firstname, lastname, email, company, com_type, job_desc, address, state, pincode, city, phone, fax, user_id});
    // }
    public getUserDetail(email): Observable<User> {
        return this.http.post<any>(this.backendUrlUnAuth + 'getUserDetail', {email});
    }

    public changePassword(email, newPassword, currentPassword, encryptedPass, isEncrypted): Observable<any> {
        // tslint:disable-next-line:max-line-length
        return this.http.post<any>(this.backendUrlUnAuth + 'changePassword', {
            email,
            newPassword,
            currentPassword,
            encryptedPass,
            isEncrypted
        });
    }


    public resetPassword(email, new_pass): Observable<any> {
        // tslint:disable-next-line:max-line-length
        return this.http.post<any>(this.backendUrlUnAuth + 'resetPasswordUpdate', {email, new_pass});
    }

    public forgotPassword(email: string) {
        return this.http.get<any>(this.backendUrlUnAuth + 'resetPassword?email=' + email);
    }


    public logout(): Observable<any> {
        return this.http.post<any>(this.backendUrlUnAuth + 'logout', {});
    }


    // public getFrames(): Observable<Frame[]> {
    //     return this.http.get<Frame[]>(this.url + 'frames.json');
    // }
    //
    // public getMounts(): Observable<Mount[]> {
    //     return this.http.get<Mount[]>(this.url + 'mounts.json');
    // }
    //
    // public getGlasses(): Observable<Glass[]> {
    //     return this.http.get<Glass[]>(this.url + 'glass.json');
    // }


    public getProducts(type): Observable<Product[]> {
        return this.http.get<Product[]>(this.url + type + '-products.json');
    }

    public getWallartProducts($sub_cat_slug, $color, $orientation, $art_type, $subject, $page, $per_page, $cat_slug, $contibutor_code, skipLoader = false): Observable<ProductList> {

        let params = new HttpParams();
        if (skipLoader) {
            params = params.append('skipLoader', skipLoader + '');
            // queryparam += "&skipLoader=" + skipLoader;
        }
        let user_id = (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) ? this.authenticationService.currentUserValue.id : 0;

        return this.http.get<ProductList>(this.backendUrlUnAuth + 'wallart-search-by-collection?cat_slug=' + $cat_slug + '&sub_cat_slug=' + $sub_cat_slug +
            '&color=' + $color + '&orientation=' + $orientation + '&art_type=' + $art_type + '&keyword=' + $subject + '&contributor_code=' + $contibutor_code + '&page=' + $page + '&per_page=' + $per_page + '&user_id=' + user_id, {params: params});
    }

    public searchWallartProducts($searchText, $color, $orientation, $art_type, $subject, $page, $per_page, $contibutor_code = ''): Observable<ProductList> {
        let user_id = (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) ? this.authenticationService.currentUserValue.id : 0;
        return this.http.get<ProductList>(this.backendUrlUnAuth + 'wallart-search-by-keyword?search_text=' + $searchText +
            '&color=' + $color + '&orientation=' + $orientation + '&art_type=' + $art_type + '&keyword=' + $subject + '&contributor_code=' + $contibutor_code + '&page=' + $page + '&per_page=' + $per_page + '&user_id=' + user_id);
    }

    public getProductById(id): Observable<Product> {
        return this.http.get<Product>(this.url + 'product-' + id + '.json');
    }

    public getBanners(): Observable<any[]> {
        return this.http.get<any[]>(this.url + 'banners.json');
    }

    /*
        public getPrice(frame: string, productType: number, product: number) {
            return this.http.get<any>(this.backendUrlUnAuth + 'getPriceDetailArtCollage?frame=' + frame +
                '&productType=' + productType + '&product=' + product);
        }
    */
    public getPrice(frame: string, productType: number, product: number, checkRestrictions: number, restrictionType: string, isFrontend: number) {
        return this.http.get<any>(this.backendUrlUnAuth + 'getPriceDetailArtCollage?frame=' + frame +
            '&productType=' + productType + '&product=' + product + '&checkRestriction=' + checkRestrictions +
            '&restrictionType=' + restrictionType + '&isFrontend=' + isFrontend);
    }

    public getArtSetTotal(frame: string, product_type: number, collage_layout: number, layout_id: number) {
        const element: any = {
            frame: frame,
            product_type: product_type,
            collage_layout: collage_layout,
            layout_id: layout_id,
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };
        return this.http.post<any>(this.backendUrlUnAuth + 'getArtSetTotal', element, httpOptions)
            .pipe(
                map(res => {
                    this.cartService.refreshCount();
                    this.wishlistItemService.refreshCount();
                    return res;
                }),
                catchError(this.handleError('getArtSetTotal', element))
            );
    }

    public getTotalPriceByFrame(frame: string, product_type: number, product: number) {
        return this.http.get<any>(this.backendUrlUnAuth + 'getPriceByFrame?frame=' + frame +
            '&product_type=' + product_type + '&product=' + product);
    }

    public checkRestrictionsArtCollage(frame: string, productType: number, product: number) {
        return this.http.get<any>(this.backendUrlUnAuth + 'checkRestrictionsArtCollage?frame=' + frame +
            '&productType=' + productType + '&product=' + product);
    }

    public addToCompare(product: Product) {
        let message, status;
        if (this.Data.compareList.filter(item => item.id == product.id)[0]) {
            message = 'The product ' + product.name + ' already added to comparison list.';
            status = 'error';
        } else {
            this.Data.compareList.push(product);
            message = 'The product ' + product.name + ' has been added to comparison list.';
            status = 'success';
        }
        this.snackBar.open(message, '×', {panelClass: [status], verticalPosition: 'top', duration: 3000});
    }

    public addToWishList(product: Product) {
        let message, status;
        if (this.Data.wishList.filter(item => item.id == product.id)[0]) {
            message = 'The product ' + product.name + ' already added to wish list.';
            status = 'error';
        } else {
            this.Data.wishList.push(product);
            message = 'The product has been added to wish list.';
            status = 'success';
        }
        this.snackBar.open(message, '×', {panelClass: [status], verticalPosition: 'top', duration: 3000});
    }

    public addToCart(product: Product) {
        let message, status;

        this.Data.totalPrice = null;
        this.Data.totalCartCount = null;

        // if (this.Data.cartList.filter(item => item.id == product.id)[0]) {
        //     const item = this.Data.cartList.filter(item => item.id == product.id)[0];
        //     item.cartCount = product.cartCount;
        // } else {
        // this.Data.cartList.push(product);
        // localStorage.setItem('Mcart', JSON.stringify(this.Data.cartList));
        // }
        // this.Data.cartList.forEach(product => {
        //         //     this.Data.totalPrice = this.Data.totalPrice + (product.cartCount * product.newPrice);
        //         //     this.Data.totalCartCount = this.Data.totalCartCount + product.cartCount;
        //         // });

        message = 'The product has been added to cart.';
        status = 'success';
        this.snackBar.open(message, '×', {panelClass: [status], verticalPosition: 'top', duration: 3000});
    }

    public resetProductCartCount(product: Product) {
        product.cartCount = 0;
        const compareProduct = this.Data.compareList.filter(item => item.id == product.id)[0];
        if (compareProduct) {
            compareProduct.cartCount = 0;
        }
        const wishProduct = this.Data.wishList.filter(item => item.id == product.id)[0];
        if (wishProduct) {
            wishProduct.cartCount = 0;
        }
    }

    public getBrands() {
        return [
            {title: 'The Bride At Argent', subtitle: 'By Claude Mone', image: 'assets/images/brands/mounte/brand1.png'},
            {title: 'The Bride At Argent', subtitle: 'By Claude Mone', image: 'assets/images/brands/mounte/brand2.png'},
            {title: 'The Bride At Argent', subtitle: 'By Claude Mone', image: 'assets/images/brands/mounte/brand3.png'},
            {title: 'The Bride At Argent', subtitle: 'By Claude Mone', image: 'assets/images/brands/mounte/brand4.png'},
            {title: 'The Bride At Argent', subtitle: 'By Claude Mone', image: 'assets/images/brands/mounte/brand5.png'},
            {title: 'The Bride At Argent', subtitle: 'By Claude Mone', image: 'assets/images/brands/mounte/brand6.png'},
            {title: 'The Bride At Argent', subtitle: 'By Claude Mone', image: 'assets/images/brands/mounte/brand7.png'},

        ];
    }

    public getWallArtCategories(catSlug: string, type: string) {
        return this.http.get<WallArtCategory[]>(this.backendUrlUnAuth + 'wallart-category-list?cat_slug=' + catSlug + '&type=' + type);
    }

    public getCountries() {
        return [
            {name: 'Afghanistan', code: 'AF'},
            {name: 'Aland Islands', code: 'AX'},
            {name: 'Albania', code: 'AL'},
            {name: 'Algeria', code: 'DZ'},
            {name: 'American Samoa', code: 'AS'},
            {name: 'AndorrA', code: 'AD'},
            {name: 'Angola', code: 'AO'},
            {name: 'Anguilla', code: 'AI'},
            {name: 'Antarctica', code: 'AQ'},
            {name: 'Antigua and Barbuda', code: 'AG'},
            {name: 'Argentina', code: 'AR'},
            {name: 'Armenia', code: 'AM'},
            {name: 'Aruba', code: 'AW'},
            {name: 'Australia', code: 'AU'},
            {name: 'Austria', code: 'AT'},
            {name: 'Azerbaijan', code: 'AZ'},
            {name: 'Bahamas', code: 'BS'},
            {name: 'Bahrain', code: 'BH'},
            {name: 'Bangladesh', code: 'BD'},
            {name: 'Barbados', code: 'BB'},
            {name: 'Belarus', code: 'BY'},
            {name: 'Belgium', code: 'BE'},
            {name: 'Belize', code: 'BZ'},
            {name: 'Benin', code: 'BJ'},
            {name: 'Bermuda', code: 'BM'},
            {name: 'Bhutan', code: 'BT'},
            {name: 'Bolivia', code: 'BO'},
            {name: 'Bosnia and Herzegovina', code: 'BA'},
            {name: 'Botswana', code: 'BW'},
            {name: 'Bouvet Island', code: 'BV'},
            {name: 'Brazil', code: 'BR'},
            {name: 'British Indian Ocean Territory', code: 'IO'},
            {name: 'Brunei Darussalam', code: 'BN'},
            {name: 'Bulgaria', code: 'BG'},
            {name: 'Burkina Faso', code: 'BF'},
            {name: 'Burundi', code: 'BI'},
            {name: 'Cambodia', code: 'KH'},
            {name: 'Cameroon', code: 'CM'},
            {name: 'Canada', code: 'CA'},
            {name: 'Cape Verde', code: 'CV'},
            {name: 'Cayman Islands', code: 'KY'},
            {name: 'Central African Republic', code: 'CF'},
            {name: 'Chad', code: 'TD'},
            {name: 'Chile', code: 'CL'},
            {name: 'China', code: 'CN'},
            {name: 'Christmas Island', code: 'CX'},
            {name: 'Cocos (Keeling) Islands', code: 'CC'},
            {name: 'Colombia', code: 'CO'},
            {name: 'Comoros', code: 'KM'},
            {name: 'Congo', code: 'CG'},
            {name: 'Congo, The Democratic Republic of the', code: 'CD'},
            {name: 'Cook Islands', code: 'CK'},
            {name: 'Costa Rica', code: 'CR'},
            {name: 'Cote D\'Ivoire', code: 'CI'},
            {name: 'Croatia', code: 'HR'},
            {name: 'Cuba', code: 'CU'},
            {name: 'Cyprus', code: 'CY'},
            {name: 'Czech Republic', code: 'CZ'},
            {name: 'Denmark', code: 'DK'},
            {name: 'Djibouti', code: 'DJ'},
            {name: 'Dominica', code: 'DM'},
            {name: 'Dominican Republic', code: 'DO'},
            {name: 'Ecuador', code: 'EC'},
            {name: 'Egypt', code: 'EG'},
            {name: 'El Salvador', code: 'SV'},
            {name: 'Equatorial Guinea', code: 'GQ'},
            {name: 'Eritrea', code: 'ER'},
            {name: 'Estonia', code: 'EE'},
            {name: 'Ethiopia', code: 'ET'},
            {name: 'Falkland Islands (Malvinas)', code: 'FK'},
            {name: 'Faroe Islands', code: 'FO'},
            {name: 'Fiji', code: 'FJ'},
            {name: 'Finland', code: 'FI'},
            {name: 'France', code: 'FR'},
            {name: 'French Guiana', code: 'GF'},
            {name: 'French Polynesia', code: 'PF'},
            {name: 'French Southern Territories', code: 'TF'},
            {name: 'Gabon', code: 'GA'},
            {name: 'Gambia', code: 'GM'},
            {name: 'Georgia', code: 'GE'},
            {name: 'Germany', code: 'DE'},
            {name: 'Ghana', code: 'GH'},
            {name: 'Gibraltar', code: 'GI'},
            {name: 'Greece', code: 'GR'},
            {name: 'Greenland', code: 'GL'},
            {name: 'Grenada', code: 'GD'},
            {name: 'Guadeloupe', code: 'GP'},
            {name: 'Guam', code: 'GU'},
            {name: 'Guatemala', code: 'GT'},
            {name: 'Guernsey', code: 'GG'},
            {name: 'Guinea', code: 'GN'},
            {name: 'Guinea-Bissau', code: 'GW'},
            {name: 'Guyana', code: 'GY'},
            {name: 'Haiti', code: 'HT'},
            {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
            {name: 'Holy See (Vatican City State)', code: 'VA'},
            {name: 'Honduras', code: 'HN'},
            {name: 'Hong Kong', code: 'HK'},
            {name: 'Hungary', code: 'HU'},
            {name: 'Iceland', code: 'IS'},
            {name: 'India', code: 'IN'},
            {name: 'Indonesia', code: 'ID'},
            {name: 'Iran, Islamic Republic Of', code: 'IR'},
            {name: 'Iraq', code: 'IQ'},
            {name: 'Ireland', code: 'IE'},
            {name: 'Isle of Man', code: 'IM'},
            {name: 'Israel', code: 'IL'},
            {name: 'Italy', code: 'IT'},
            {name: 'Jamaica', code: 'JM'},
            {name: 'Japan', code: 'JP'},
            {name: 'Jersey', code: 'JE'},
            {name: 'Jordan', code: 'JO'},
            {name: 'Kazakhstan', code: 'KZ'},
            {name: 'Kenya', code: 'KE'},
            {name: 'Kiribati', code: 'KI'},
            {name: 'Korea, Democratic People\'S Republic of', code: 'KP'},
            {name: 'Korea, Republic of', code: 'KR'},
            {name: 'Kuwait', code: 'KW'},
            {name: 'Kyrgyzstan', code: 'KG'},
            {name: 'Lao People\'S Democratic Republic', code: 'LA'},
            {name: 'Latvia', code: 'LV'},
            {name: 'Lebanon', code: 'LB'},
            {name: 'Lesotho', code: 'LS'},
            {name: 'Liberia', code: 'LR'},
            {name: 'Libyan Arab Jamahiriya', code: 'LY'},
            {name: 'Liechtenstein', code: 'LI'},
            {name: 'Lithuania', code: 'LT'},
            {name: 'Luxembourg', code: 'LU'},
            {name: 'Macao', code: 'MO'},
            {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'},
            {name: 'Madagascar', code: 'MG'},
            {name: 'Malawi', code: 'MW'},
            {name: 'Malaysia', code: 'MY'},
            {name: 'Maldives', code: 'MV'},
            {name: 'Mali', code: 'ML'},
            {name: 'Malta', code: 'MT'},
            {name: 'Marshall Islands', code: 'MH'},
            {name: 'Martinique', code: 'MQ'},
            {name: 'Mauritania', code: 'MR'},
            {name: 'Mauritius', code: 'MU'},
            {name: 'Mayotte', code: 'YT'},
            {name: 'Mexico', code: 'MX'},
            {name: 'Micronesia, Federated States of', code: 'FM'},
            {name: 'Moldova, Republic of', code: 'MD'},
            {name: 'Monaco', code: 'MC'},
            {name: 'Mongolia', code: 'MN'},
            {name: 'Montserrat', code: 'MS'},
            {name: 'Morocco', code: 'MA'},
            {name: 'Mozambique', code: 'MZ'},
            {name: 'Myanmar', code: 'MM'},
            {name: 'Namibia', code: 'NA'},
            {name: 'Nauru', code: 'NR'},
            {name: 'Nepal', code: 'NP'},
            {name: 'Netherlands', code: 'NL'},
            {name: 'Netherlands Antilles', code: 'AN'},
            {name: 'New Caledonia', code: 'NC'},
            {name: 'New Zealand', code: 'NZ'},
            {name: 'Nicaragua', code: 'NI'},
            {name: 'Niger', code: 'NE'},
            {name: 'Nigeria', code: 'NG'},
            {name: 'Niue', code: 'NU'},
            {name: 'Norfolk Island', code: 'NF'},
            {name: 'Northern Mariana Islands', code: 'MP'},
            {name: 'Norway', code: 'NO'},
            {name: 'Oman', code: 'OM'},
            {name: 'Pakistan', code: 'PK'},
            {name: 'Palau', code: 'PW'},
            {name: 'Palestinian Territory, Occupied', code: 'PS'},
            {name: 'Panama', code: 'PA'},
            {name: 'Papua New Guinea', code: 'PG'},
            {name: 'Paraguay', code: 'PY'},
            {name: 'Peru', code: 'PE'},
            {name: 'Philippines', code: 'PH'},
            {name: 'Pitcairn', code: 'PN'},
            {name: 'Poland', code: 'PL'},
            {name: 'Portugal', code: 'PT'},
            {name: 'Puerto Rico', code: 'PR'},
            {name: 'Qatar', code: 'QA'},
            {name: 'Reunion', code: 'RE'},
            {name: 'Romania', code: 'RO'},
            {name: 'Russian Federation', code: 'RU'},
            {name: 'RWANDA', code: 'RW'},
            {name: 'Saint Helena', code: 'SH'},
            {name: 'Saint Kitts and Nevis', code: 'KN'},
            {name: 'Saint Lucia', code: 'LC'},
            {name: 'Saint Pierre and Miquelon', code: 'PM'},
            {name: 'Saint Vincent and the Grenadines', code: 'VC'},
            {name: 'Samoa', code: 'WS'},
            {name: 'San Marino', code: 'SM'},
            {name: 'Sao Tome and Principe', code: 'ST'},
            {name: 'Saudi Arabia', code: 'SA'},
            {name: 'Senegal', code: 'SN'},
            {name: 'Serbia and Montenegro', code: 'CS'},
            {name: 'Seychelles', code: 'SC'},
            {name: 'Sierra Leone', code: 'SL'},
            {name: 'Singapore', code: 'SG'},
            {name: 'Slovakia', code: 'SK'},
            {name: 'Slovenia', code: 'SI'},
            {name: 'Solomon Islands', code: 'SB'},
            {name: 'Somalia', code: 'SO'},
            {name: 'South Africa', code: 'ZA'},
            {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
            {name: 'Spain', code: 'ES'},
            {name: 'Sri Lanka', code: 'LK'},
            {name: 'Sudan', code: 'SD'},
            {name: 'Suriname', code: 'SR'},
            {name: 'Svalbard and Jan Mayen', code: 'SJ'},
            {name: 'Swaziland', code: 'SZ'},
            {name: 'Sweden', code: 'SE'},
            {name: 'Switzerland', code: 'CH'},
            {name: 'Syrian Arab Republic', code: 'SY'},
            {name: 'Taiwan, Province of China', code: 'TW'},
            {name: 'Tajikistan', code: 'TJ'},
            {name: 'Tanzania, United Republic of', code: 'TZ'},
            {name: 'Thailand', code: 'TH'},
            {name: 'Timor-Leste', code: 'TL'},
            {name: 'Togo', code: 'TG'},
            {name: 'Tokelau', code: 'TK'},
            {name: 'Tonga', code: 'TO'},
            {name: 'Trinidad and Tobago', code: 'TT'},
            {name: 'Tunisia', code: 'TN'},
            {name: 'Turkey', code: 'TR'},
            {name: 'Turkmenistan', code: 'TM'},
            {name: 'Turks and Caicos Islands', code: 'TC'},
            {name: 'Tuvalu', code: 'TV'},
            {name: 'Uganda', code: 'UG'},
            {name: 'Ukraine', code: 'UA'},
            {name: 'United Arab Emirates', code: 'AE'},
            {name: 'United Kingdom', code: 'GB'},
            {name: 'United States', code: 'US'},
            {name: 'United States Minor Outlying Islands', code: 'UM'},
            {name: 'Uruguay', code: 'UY'},
            {name: 'Uzbekistan', code: 'UZ'},
            {name: 'Vanuatu', code: 'VU'},
            {name: 'Venezuela', code: 'VE'},
            {name: 'Viet Nam', code: 'VN'},
            {name: 'Virgin Islands, British', code: 'VG'},
            {name: 'Virgin Islands, U.S.', code: 'VI'},
            {name: 'Wallis and Futuna', code: 'WF'},
            {name: 'Western Sahara', code: 'EH'},
            {name: 'Yemen', code: 'YE'},
            {name: 'Zambia', code: 'ZM'},
            {name: 'Zimbabwe', code: 'ZW'}
        ];
    }

    public getMonths() {
        return [
            {value: '01', name: 'January'},
            {value: '02', name: 'February'},
            {value: '03', name: 'March'},
            {value: '04', name: 'April'},
            {value: '05', name: 'May'},
            {value: '06', name: 'June'},
            {value: '07', name: 'July'},
            {value: '08', name: 'August'},
            {value: '09', name: 'September'},
            {value: '10', name: 'October'},
            {value: '11', name: 'November'},
            {value: '12', name: 'December'}
        ];
    }


    public getCourier() {
        return [
            {value: 'fedex', name: 'Fedex'},
            {value: 'dtdc', name: 'DTDC'},
            {value: 'speed_post', name: 'Speed Post'},
            {value: 'dot_aot', name: 'DOT ZOT'},
            {value: 'delhivery', name: 'Delhivery'},
            {value: 'gati', name: 'Gati'},
            {value: 'bluedart', name: 'Bluedart'},
            {value: 'ecom_exp', name: 'Ecom exp'},
            {value: 'rapid_delivery', name: 'Rapid Delivery'},
            {value: 'wow_express', name: 'Wow express'},
            {value: 'xpressbees', name: 'Xpressbees'},
            {value: 'ekart_surface', name: 'Ekart Surface'},
            {value: 'ad_express', name: 'AD Express'},
            {value: 'rv_logistic', name: 'RV Logistic'},
            {value: 'amazon_shipping_company', name: 'Amazon shipping company'},
            {value: 'unique_5pl', name: 'Unique 5PL'},
            {value: 'kerry_indev', name: 'Kerry Indev'},
            {value: 'shadowfax', name: 'Shadowfax'},
            {value: 'uber_package', name: 'Uber Package'},
            {value: 'porter', name: 'Porter'},
            {value: 'self_pickup', name: 'Self-Pickup'},
            {value: 'mahattaart', name: 'Mahattaart'},
        ];
    }

    public getOrderStatus() {
        // {id: 16, name: 'Hold', role: [1, 2, 4, 5], hide: [5, 11, 12, 13, 14, 15]},
        // {id: 17, name: 'Hold by Production', role: [1, 2, 8], hide: [5, 11, 12, 13, 14, 15]},
        // {id: 18, name: 'Resume Order', role: [1, 2, 4, 5]},
        // {id: 19, name: 'Production Resumed', role: [1, 2, 8]}
        return [
            {id: 1, name: 'Order Received', role: [1, 2, 3, 4, 5, 6, 7, 8]},
            {id: 3, name: 'Cancel', role: [1, 2, 4, 5]},
            {id: 5, name: 'Confirmed', role: [1, 2, 4, 5]},
            {id: 6, name: 'Under Printing', role: [1, 2, 8]},
            {id: 7, name: 'Printing Done', role: [1, 2, 8]},
            {id: 8, name: 'Under Framing', role: [1, 2, 8]},
            {id: 9, name: 'Framing Done', role: [1, 2, 8]},
            {id: 10, name: 'Under Packaging', role: [1, 2, 8]},
            {id: 11, name: 'Packaging Done', role: [1, 2, 8]},
            {id: 12, name: 'Ready To Ship', role: [1, 2, 8]},
            {id: 13, name: 'Order Shipped', role: [1, 2, 8]},
            {id: 14, name: 'Order Completed', role: [1, 2, 8]},
            {id: 15, name: 'Cancel by Production', role: [1, 2, 8]}

        ];
    }

    public getShippingStatus() {
        return [{name: 'Pending'},
            {name: 'Out for pickup'},
            {name: 'Pickup done'},
            {name: 'In Transit'},
            {name: 'Reached at Destination'},
            {name: 'Out For Delivery'},
            {name: 'Delivered'},
            {name: 'Cancelled'}
        ];
    }


    public getYears() {
        return ['2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];
    }

    public getDeliveryMethods() {
        return [
            {value: 'free', name: 'Free Delivery', desc: '$0.00 / Delivery in 7 to 14 business Days'},
            {value: 'standard', name: 'Standard Delivery', desc: '$7.99 / Delivery in 5 to 7 business Days'},
            {value: 'express', name: 'Express Delivery', desc: '$29.99 / Delivery in 1 business Days'}
        ];
    }

    public getProductTypes() {
        return [
            {id: 1, name: 'Canvas', slug: 'canvas', path: 'assets/images/product/product_type/canvas.png', activated: false},
            {id: 2, name: 'Frame', slug: 'framing', path: 'assets/images/product/product_type/framed-art.png', activated: false}
        ];
    }


    sanitizeString(desc) {
        var itemDesc;
        if (desc) {
            itemDesc = desc.replace(/(\r\n|\n|\r|\s+|\t|&nbsp;)/gm, ' ');
            itemDesc = itemDesc.replace(/"/g, '""');
            itemDesc = itemDesc.replace(/ +(?= )/g, '');
            itemDesc = itemDesc.replace(/,/g, ';');

        } else {
            itemDesc = '';
        }
        return itemDesc;
    }

    public downloadFile(data: any) {
        // const replacer = (key, value) => (value === null ? '' : value.replace(/"/g, '""')); // specify how you want to handle null values here
        const replacer = (key, value) => (value === null ? '' : this.sanitizeString(value.toString()));
        const header = Object.keys(data[0]);
        const csv = data.map((row) =>
            header
                .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                .join(',')
        );
        csv.unshift(header.join(','));
        return csv.join('\r\n');
    }

    public getIPAddress() {
        return this.http.get('https://api.ipify.org/?format=json');
    }


    uploadData(fileToUpload: File, form: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };
        const endpoint = this.backendUrlAUTH + '/uploadData';
        const formData: FormData = new FormData();
        formData.append('File', fileToUpload, fileToUpload.name);
        formData.append('form', form);
        return this.http.post<any>(endpoint, formData).pipe(
            map(res => res),
            catchError(this.handleError('uploadData', formData))
        );
    }

    public getCollageSetDecor(): Observable<any> {
        return this.http.get<any>(this.url + 'collage-set-decor.json');
    }

    public getArtSetDecor(): Observable<any> {
        return this.http.get<any>(this.url + 'art-set-decor.json');
    }

    public contact(element: ArtForBusiness): Observable<ArtForBusiness> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.post<ArtForBusiness>(this.apiUrlAuth + '/contact', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('contact', element))
            );
    }

    public _launchBOLT(key, txnid, amount, firstname, email, phone, productinfo, udf5, surl, furl) {
        var re = /_/gi;
        firstname = firstname.replace(re, ' ');

        let salt = 'VvgCQAMtGV';
        let hash = key + '|' + txnid + '|' + amount + '|' + productinfo + '|' + firstname + '|' + email + '|||||' + udf5 + '||||||' + salt;
        hash = sha512.create().update(hash).hex();

        bolt.launch({
            key: key,
            txnid: txnid,
            hash: hash,
            amount: amount,
            firstname: firstname,
            email: email,
            phone: phone,
            productinfo: productinfo,
            udf5: udf5,
            surl: surl,
            furl: furl,
            mode: 'dropout'
        }, {
            responseHandler: function(BOLT) {
                console.log('ICTSoft', BOLT.response.txnStatus);
            },
            catchException: function(BOLT) {
                console.log(BOLT.message);
            }
        });
    }

    calculateDiff(orderDate) {
        const date = new Date(orderDate);
        const currentDate = new Date();

        const days = Math.floor((currentDate.getTime() - date.getTime()) / 1000 / 60 / 60 / 24);
        return days;
    }


    calculateDiffReverse(orderDate) {
        const date = new Date(orderDate + ' 23:59:55');
        const currentDate = new Date();

        const days = Math.floor((date.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);
        return days;
    }


    calculateDiffBetweenDates(startDate, endDate) {
        startDate = new Date(startDate);
        endDate = new Date(endDate);
        const days = Math.floor((endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24);
        return days;
    }

    public addWallPaperRequest(name: string, email: string, contact: string, room_type: string) {
        return this.http.post<any>(this.backendUrlUnAuth + 'wallpaper-request', {
            name,
            email,
            contact,
            room_type
        });
    }


    generateOtp(element: any): Observable<Customer> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.post<Customer>(this.apiUrlUnAuth + '/generateOtp', element, httpOptions)
            .pipe(
                map(res => {
                    return res['data'];
                })
            );

    }


    initSpaceDecorCustomization(httpParams: HttpParams): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrlUnAuth + '/init-space-decor-customization', {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('init-space-decor-customization', []))
            );
    }

    isNumeric(val: unknown): val is string | number {
        return (
            !isNaN(Number(Number.parseFloat(String(val)))) &&
            isFinite(Number(val))
        );
    }


    getCity(ip): Observable<any> {
        return this.http.get<any>('http://ip-api.com/json/' + ip);
    }

    public getContributorData(text): Observable<ContributorData[]> {
        return this.http.get<ContributorData[]>(this.contributorUrl + '/contributor-data.php?contributor_code=' + text);
    }

}
