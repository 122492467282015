import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {MatHorizontalStepper, MatStepper} from '@angular/material/stepper';
import {Router} from '@angular/router';

@Component({
    selector: 'app-p2f-product',
    templateUrl: './p2f-product.component.html',
    styleUrls: ['./p2f-product.component.scss']
})
export class P2fProductComponent implements OnInit {
    @Input('selection') selection: string = '';

    public isMobile = false;

    constructor(private router: Router) {
    }

    ngOnInit() {
        if (window.innerWidth <= 575) {
            this.isMobile = true;
        }
    }
}
