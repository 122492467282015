import {PaperPrice} from './paper_price.model';

export class Paper {
    id?: number = 0;
    public name: string;
    public display_name: string;

    public roll_price: number;
    public roll_height_mt: number;
    public roll_height_in: number;
    public roll_width_in: number;
    public cost_per_sq_in: number;
    public ink_cost: number;
    public spray_cost: number;
    public print_cost: number;

    public category_id: number;
    public vendor_id: number;

    public product_type_ids: number[];
    public status: boolean;
    public is_active: boolean;
    public wall_art_discount: string;
    public frame_discount: number;
    public price_arr: PaperPrice[];
    public dis: number;
    public print_quality: string

    public constructor() {
    }
}
