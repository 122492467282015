import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, Observable} from 'rxjs';
import { messages} from 'src/environments/environment';
import {TestimonialService} from 'src/app/admin/services/testimonial.service';
import {ShippingMethod} from 'src/app/admin/models/shipping_method.model';
import {ShippingMethodService} from 'src/app/admin/services/shipping_method.service';
import {environment} from '../../../environments/environment';


@Component({
    selector: 'app-check-pincode-dialog',
    templateUrl: './check-pincode-dialog.component.html',
    styleUrls: ['./check-pincode-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CheckPincodeDialogComponent implements OnInit {
    public get shippingMethods() {
        return this._shippingMethods;
    }

    public set shippingMethods(_shippingMethods) {
        this._shippingMethods = _shippingMethods;
    }
    public get shippingMethod() {
        return this._shippingMethod;
    }

    public set shippingMethod(_shippingMethod) {
        this._shippingMethod = _shippingMethod;
    }


    constructor(
        public formBuilder: FormBuilder,
        public router: Router,
        private route: ActivatedRoute,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<CheckPincodeDialogComponent>,
        public testimonialService: TestimonialService,
        private matSnackBar: MatSnackBar,
        private shippingMethodService: ShippingMethodService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
        this.loading$ = this.loadingSubject.asObservable();
    }

    get message() {
        return this.message;
    }
    @Output() onClose = new EventEmitter<any>(true);
    isShippingFree = false;
    form: FormGroup;

    private loadingSubject: BehaviorSubject<boolean>;
    public loading$: Observable<boolean>;

    private _shippingMethods: ShippingMethod[];


    private _shippingMethod: ShippingMethod;

    private messages = messages;

    ngOnInit() {
        this.form = this.formBuilder.group({
            pincode: ['', Validators.required],
        });

        this.getShippingMethodes('');

        if (environment.shippingFree.filter(item => item == this.data.product_type_id).length > 0) {
            this.isShippingFree = true;
        }
    }


    getShippingMethodes(pincode: string) {
        const filter = [];
        // filter.push(['user_id', this.authenticationService.currentUserValue.id]);
        filter.push(['pincode', pincode]);

        this.loadingSubject.next(true);
        this.shippingMethodService.filter(JSON.stringify(filter), '[]', 0, -1).subscribe(res => {
            this._shippingMethods = res['data'];
            this._shippingMethod = this._shippingMethods[0];
            this.shippingMethodService.shippingMethod = this._shippingMethod;
            this.loadingSubject.next(false);
        });
    }

    onShippingMethod(shippingMethod) {
        this._shippingMethod = shippingMethod;
        this.shippingMethodService.shippingMethod = this._shippingMethod;
    }

    public onSubmit() {
        if (this.form.valid) {
            console.log('ICTSoft', this.form.value);
            this.getShippingMethodes(this.form.value.pincode);
        }
    }

    public close(): void {
        this.dialogRef.close();
    }
}
