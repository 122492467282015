import {Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AppService} from '../../app.service';
import {OrderService} from '../../admin/services/order.service';
import {WallArtCategory} from '../../app.models';
import {WallArtCategoryService} from '../../admin/services/wallart-category.service';

// import {AuthenticationService} from '../../../authentication.service';

@Component({
    selector: 'app-reanme-dialog',
    templateUrl: './uploadcsv-new-diaog.component.html',
    styleUrls: ['./uploadcsv-new-diaog.component.scss']
})
export class UploadcsvNewDiaogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<UploadcsvNewDiaogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public fb: FormBuilder, private matSnackBar: MatSnackBar, private appService: AppService,
                private orderService: OrderService, private wallArtCategoryService: WallArtCategoryService) {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
        this.loading$ = this.loadingSubject.asObservable();
    }

    public form: FormGroup;
    public editForm: FormGroup;
    private loadingSubject: BehaviorSubject<boolean>;
    public loading$: Observable<boolean>;
    @Output() onClose = new EventEmitter<any>(true);
    @Output() onReset = new EventEmitter<any>(true);
    fileToUpload: any = null;
    @ViewChild('inputFile')
    fileInputVariable: ElementRef;
    format = '.csv';
    public file_1;
    public file_1_path = '';


    ngOnInit(): void {
        this.form = this.fb.group({});

        if (this.data.form == 'offline-order') {
            this.format = '';
        }
        if (this.data.form == 'wall_art_category_thumbnail') {
            this.format = '.jpg,.webp';
        }
    }


    onFileSelected(event) {
        this.fileToUpload = event.target.files[0];
    }

    onSelectMedia(event) { // called each time file input changes
        this.fileToUpload = event.target.files[0];
        if (event.target.files && event.target.files[0]) {
            // this.mediaProductForm.markAsDirty();
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            reader.onload = (event) => { // called once readAsDataURL is completed
                this.file_1_path = reader.result.toString();
                this.file_1 = reader.result;
            };
        }
    }


    public uploadCsv() {
        if (this.fileToUpload == null) {
            this.matSnackBar.open('Select File To Upload', 'x', {
                duration: 2000,
                verticalPosition: 'top',
                panelClass: ['success']
            });
        } else {
            this.form.disable();
            if (this.data.form == 'offline-order') {
                this.orderService.uploadOrderOffline(this.data.orderOffline.id, this.file_1).subscribe(data => {
                    this.form.enable();
                    this.fileToUpload = null;
                    // this.fileInputVariable.nativeElement.value = '';
                    this.onClose.emit();
                    this.onReset.emit();
                    this.matSnackBar.open('File Uploaded Successfully', 'x', {
                        duration: 2000,
                        verticalPosition: 'top',
                        panelClass: ['success']
                    });

                }, error => {
                    console.log(error);
                });
            }else if(this.data.form == 'wall_art_category_thumbnail'){
                this.wallArtCategoryService.uploadThumbnail(this.data.wallCategory.id, this.file_1).subscribe(data => {
                    this.form.enable();
                    this.fileToUpload = null;
                    // this.fileInputVariable.nativeElement.value = '';
                    this.onClose.emit();
                    this.onReset.emit();
                    this.matSnackBar.open('File Uploaded Successfully', 'x', {
                        duration: 2000,
                        verticalPosition: 'top',
                        panelClass: ['success']
                    });

                }, error => {
                    console.log(error);
                });
            } else {
                this.appService.uploadData(this.fileToUpload, this.data.form).subscribe(data => {
                    this.form.enable();
                    this.fileToUpload = null;
                    this.fileInputVariable.nativeElement.value = '';
                    this.onClose.emit();
                    this.onReset.emit();
                    this.matSnackBar.open('File Imported Successfully', 'x', {
                        duration: 2000,
                        verticalPosition: 'top',
                        panelClass: ['success']
                    });

                }, error => {
                    console.log(error);
                });
            }
        }
    }

    getErrors(ctrl) {
        return Object.keys(ctrl.errors);
    }

}
