import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Cart} from '../../admin/models/cart.model';
import {CartService} from '../../admin/services/cart.service';

@Component({
    selector: 'app-gift-message-dialog',
    templateUrl: './gift-message-dialog.component.html',
    styleUrls: ['./gift-message-dialog.component.scss']
})
export class GiftMessageDialogComponent implements OnInit {
    @Output() onClose = new EventEmitter<any>(true);
    public form: FormGroup;
    cart: Cart;

    constructor(public dialogRef: MatDialogRef<GiftMessageDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private cartService: CartService) {
    }

    ngOnInit(): void {

        this.form = this.formBuilder.group({
            gift_message: ['', Validators.required],
        });
        this.cart = this.data.cart;
        this.form.patchValue(this.data.cart.customizedProduct);
    }

    onSubmit(): void {
        this.cartService.updateGiftMessage(this.cart?.customizedProduct.id, this.form.value['gift_message']).subscribe(res => {
            this.onClose.emit(res);
        });

    }


}
