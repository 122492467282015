import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Cart} from 'src/app/admin/models/cart.model';
import {CartService} from 'src/app/admin/services/cart.service';
import Swal from 'sweetalert2';
import {catchError, finalize} from 'rxjs/operators';
import {of} from 'rxjs';
import {AuthenticationService} from 'src/app/admin/services/authentication.service';
import {AppService} from 'src/app/app.service';
import {WishlistQuickDialogComponent} from 'src/app/shared/wishlist-quick-dialog/wishlist-quick-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {HttpParams} from '@angular/common/http';
import {CartDetailsDialogComponent} from '../cart-details-dialog/cart-details-dialog.component';
import {Params} from '@angular/router';
import {CustomizedProductDetailsDialogComponent} from 'src/app/shared/customized-product-details-dialog/customized-product-details-dialog.component';
import {Promo} from 'src/app/admin/models/promo.model';
import {SignInDialogComponent} from '../../../shared/sign-in-dialog/sign-in-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TopbarNavbarService} from '../../../topbar-navbar.service';
import {GiftMessageDialogComponent} from '../../../shared/gift-message-dialog/gift-message-dialog.component';
import {environment} from '../../../../environments/environment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TermsServiceInstallationComponent} from '../../../shared/terms-service-installation/terms-service-installation.component';


@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
    @Output() cartAmountEvent = new EventEmitter<any>();
    @Output() purchaseTypeEvent = new EventEmitter<any>();
    @Output() shippingAmountEvent = new EventEmitter<any>();
    @Output() changed = new EventEmitter();
    @Input() section = 1;
    wallpaperSampleSize = environment.sampleSize;
    purchaseType = 'customer';

    public section1: number = 25;
    public section2: number = 25;
    public section3: number = 15;
    public section4: number = 25;

    public promo: Promo = new Promo();
    inch = 8;
    private _carts: Cart[];
    private product_type_name: any;
    private product_listing: number;
    private cartDiscounts: any;
    public cartDiscount: number = 0;
    public cartDiscountAmt: number = 0;
    private form: FormGroup;
    freeItem: any;
    isFreeItemApplicable = false;
    private wallpaper_areas: any = [];

    public get carts() {
        return this._carts;
    }

    public set carts(_carts) {
        this._carts = _carts;
    }

    constructor(
        private cartService: CartService,
        private appService: AppService,
        public authenticationService: AuthenticationService,
        private dialog: MatDialog,
        private snackbar: MatSnackBar,
        private topbarNavbarService: TopbarNavbarService,
        public fb: FormBuilder
    ) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            pincode: ['', Validators.required],
        });

        if (this.section == 2) {
            this.section1 = 20;
            this.section2 = 20;
            this.section3 = 18;
            this.section4 = 20;
        }
        this.getCarts();
        // this.getCartDiscount();
    }

    public refresh() {
        this.getCarts();
    }

    public applyPromo(promo: Promo) {
        this.promo = promo;
        this.getCarts();
        this.refreshCartAmount();
    }

    // public checkFreeItem(totalPayable = 0) {
    //     if (totalPayable < this.freeItem.min_cart_value) {
    //         this.isFreeItemApplicable = false;
    //     }
    // }

    // getCartDiscount() {
    //     this.promoService.filterCartDiscount('[]', '[]', 0, -1).subscribe(res => {
    //         this.cartDiscounts = res['data'];
    //     });
    // }


    getCarts() {
        const filter = [];
        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {

            filter.push(['customer_id', '=', this.authenticationService.currentUserValue.id]);
        } else {
            filter.push(['session_id', '=', this.cartService.getSessionId()]);
        }
        if (this.promo && this.promo.id > 0) {
            filter.push(['promo_id', '=', this.promo.id]);
        }
        this.cartService.filter(JSON.stringify(filter), '[]', 0, -1, this.purchaseType).subscribe(res => {
            this._carts = res['data'];
            this.freeItem = res['free'];
            this.isFreeItemApplicable = this.freeItem ? true : false;
            this.product_type_name = res['product_types'];
            this.product_listing = res['product_listing'];
            this.wallpaper_areas = res['wallpaper_areas'];
            if (this._carts.length > 0) {
                if (this._carts[this._carts.length - 1].customizedProduct.product_id == 1) {
                    this.topbarNavbarService.setWhatsAppType(1);
                }
            }
            this.refreshCartAmount();
        });
    }

    refreshCartAmount() {
        let total = 0;
        let total_with_tax = 0;
        let totalOfferQty = 0;
        let amount_taxable = 0;
        let discount = 0;
        let discount_with_tax = 0;
        let tax = 0;
        let gift_amount = 0;
        let gift_tax = 0;
        let installation_amount = 0;
        let installation_tax = 0;
        let amount_payable = 0;
        // let cart_discount_total = 0;
        let max_tax = 12;
        let product_type = [];
        let trackerSelection = '';
        let customizeUrl = '';
        this._carts.forEach((cart: Cart, index) => {
            if (cart.customizedProduct.on_sale_id > 0 && cart.customizedProduct.on_sale) {
                total += cart.customizedProduct.on_sale.list_price * cart.customizedProduct.quantity;
                total_with_tax += cart.customizedProduct.on_sale.list_price_tax * cart.customizedProduct.quantity;
                amount_taxable += cart.customizedProduct.on_sale.net_price * cart.customizedProduct.quantity;
                tax += cart.customizedProduct.on_sale.tax * cart.customizedProduct.quantity;
                amount_payable += cart.customizedProduct.on_sale.amount_payable * cart.customizedProduct.quantity;
                discount += cart.customizedProduct.on_sale.discount * cart.customizedProduct.quantity;
                discount_with_tax += cart.customizedProduct.on_sale.discount_with_tax * cart.customizedProduct.quantity;
                if (cart.customizedProduct.on_sale.tax_rate == 18) {
                    max_tax = 18;
                }

                gift_amount += cart.customizedProduct.on_sale.gift_wrap * cart.customizedProduct.quantity;
                gift_tax += cart.customizedProduct.on_sale.gift_tax * cart.customizedProduct.quantity;
                product_type.push(cart.customizedProduct.product_type_id);

                if (cart.customizedProduct.on_sale.installation_amount) {
                    installation_amount += cart.customizedProduct.on_sale.installation_amount;
                    installation_tax += cart.customizedProduct.on_sale.installation_tax;
                }

                if (trackerSelection == '' || customizeUrl == '') {
                    trackerSelection = this.getPageUrl(cart.customizedProduct.product_id);
                    customizeUrl = cart.customizedProduct.url;
                }
            } else {
                if (cart.customizedProduct.pricing_detail) {
                    total += cart.customizedProduct.pricing_detail['total'] * cart.customizedProduct.quantity;
                    total_with_tax += cart.customizedProduct.pricing_detail['total_with_tax'] * cart.customizedProduct.quantity;
                    // cart_discount_total += cart.customizedProduct.pricing_detail['total'] * cart.customizedProduct.quantity;
                    discount += cart.customizedProduct.pricing_detail['discount'] * cart.customizedProduct.quantity;
                    discount_with_tax += cart.customizedProduct.pricing_detail['discount_with_tax'] * cart.customizedProduct.quantity;

                    amount_taxable += (cart.customizedProduct.pricing_detail['amount_taxable'] - cart.customizedProduct.pricing_detail['gift_wrap']) * cart.customizedProduct.quantity;
                    tax += cart.customizedProduct.pricing_detail['tax'] * cart.customizedProduct.quantity;
                    amount_payable += cart.customizedProduct.pricing_detail['amount_payable'] * cart.customizedProduct.quantity;
                    if (cart.customizedProduct.pricing_detail['tax_rate'] == 18) {
                        max_tax = 18;
                    }
                }

                gift_amount += cart.customizedProduct.pricing_detail['gift_wrap'] * cart.customizedProduct.quantity;
                gift_tax += cart.customizedProduct.pricing_detail['gift_tax'] * cart.customizedProduct.quantity;

                if (cart.customizedProduct.pricing_detail['installation_amount']) {
                    installation_amount += cart.customizedProduct.pricing_detail['installation_amount'];
                    installation_tax += cart.customizedProduct.pricing_detail['installation_tax'];
                }
                product_type.push(cart.customizedProduct.product_type_id);
                if (trackerSelection == '' || customizeUrl == '') {
                    trackerSelection = this.getPageUrl(cart.customizedProduct.product_id);
                    customizeUrl = cart.customizedProduct.url;
                }
            }


            if (cart.customizedProduct.on_sale_id == 0 || (cart.customizedProduct.on_sale > 0 && cart.customizedProduct.on_sale.featured == 1)) {
                totalOfferQty += cart.customizedProduct.quantity;
            }

            // if (this._carts.length - 1 == index) {
            //     // console.log('in cart discount' + cart_discount_total);
            //     const cartD = this.cartDiscounts.filter(item => item.min_value <= cart_discount_total && item.max_value >= cart_discount_total);
            //     if (cartD && cartD.length > 0) {
            //         this.cartDiscount = cartD[0].discount;
            //         this.cartDiscountAmt =  cart_discount_total * cartD[0].discount / 100;
            //     }
            // }
        });

        if ((amount_payable + installation_amount + installation_tax) < this.freeItem?.min_cart_value) {
            this.isFreeItemApplicable = false;
        }

        this.cartAmountEvent.emit({
            total: total,
            total_with_tax: total_with_tax,
            amount_taxable: amount_taxable,
            discount: discount,
            discount_with_tax: discount_with_tax,
            tax: tax,
            amount_payable: (amount_payable + installation_amount + installation_tax),
            max_tax: max_tax,
            product_type: product_type,
            product_type_name: this.product_type_name,
            product_listing: this.product_listing,
            totalOfferQty: totalOfferQty,
            gift_amount: gift_amount,
            gift_tax: gift_tax,
            installation_amount: installation_amount,
            installation_tax: installation_tax,
            trackerSelection: trackerSelection,
            trackerCustomize: customizeUrl
            // cart_discount: this.cartDiscount,
            // cart_discount_amt: this.cartDiscountAmt,

        });
    }

    increment(cart: Cart, index) {
        cart.customizedProduct.quantity++;
        this.refreshCartAmount();
        let promo_id = 0;
        if (this.promo && this.promo.id > 0) {
            promo_id = this.promo.id;
        }
        // if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
        this.cartService.updateCustomizedProductQuantity(cart.customizedProduct.id, cart.customizedProduct.quantity, promo_id).subscribe(res => {
            this._carts = res['data'];
            this.refreshCartAmount();
        });
        // } else {
        //     this._carts[index] = cart;
        //     this.refreshCartAmount();
        //     localStorage.setItem('Mcart', JSON.stringify(this._carts));
        // }
    }

    updateGift($event, cart: Cart) {
        console.log('in gift wrpap');
        cart.customizedProduct.gift_wrap = $event.checked;
        this.cartService.updateGiftWrap(cart.customizedProduct.id, $event.checked).subscribe(res => {
            this._carts = res['data'];
            this.refreshCartAmount();
        });

    }

    validatePIN(pin) {
        return /^(\d{4}|\d{6})$/.test(pin);
    }


    updateInstallation($event, cart: Cart, isremove = 0) {
        // console.log('in gift wrpap');
        // cart.customizedProduct.installation_pincode = this.form.value.pincode;
        const isValid = this.validatePIN(this.form.value.pincode);
        if (isremove || isValid) {
            if (isremove == 1) {
                this.form.value.pincode = '';
            }

            this.cartService.updateInstallation(cart.customizedProduct.id, this.form.value.pincode).subscribe(res => {
                this._carts = res['data'];
                const index = this._carts.findIndex(item => item.id == cart.id);
                // const exist = (this._carts[index].customizedProduct.installation_pincode != null && this._carts[index].customizedProduct.installation_pincode != '');
                const exist = this._carts[index].customizedProduct.isInstall;
                this._carts[index].message = this.form.value.pincode == '' ? '' : (exist ? 'success' : 'error');

                if (this.form.value.pincode != '' && this._carts[index].message == 'success') {
                    this.showTermsDialog();
                }
                this.refreshCartAmount();
            });
        } else if (!isValid) {
            this.snackbar.open('Invalid Pincode', 'x', {
                duration: 2000,
                verticalPosition: 'top',
                panelClass: ['error']
            });
        }

    }

    decrement(cart: Cart, index) {
        if (cart.customizedProduct.quantity > 1) {
            cart.customizedProduct.quantity--;
            this.refreshCartAmount();

            let promo_id = 0;
            if (this.promo && this.promo.id > 0) {
                promo_id = this.promo.id;
            }
            // if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            this.cartService.updateCustomizedProductQuantity(cart.customizedProduct.id, cart.customizedProduct.quantity, promo_id).subscribe(res => {
                this._carts = res['data'];
                this.refreshCartAmount();
            });
            // } else {
            //     this._carts[index] = cart;
            //     this.refreshCartAmount();
            //     localStorage.setItem('Mcart', JSON.stringify(this._carts));
            // }
        }
    }

    //Remove cart_id or wishlist_item_id from url

    public getUpdatedUrl(url: string) {
        const baseURL = 'product-customization';

        let strParams: string = url;
        let arrParams = strParams.split('&');
        let params = new HttpParams();
        arrParams.forEach((arr, index) => {
            let arrParam = arr.split('=');
            if (arrParam[0] != 'cart_id') {
                params = params.set(arrParam[0], arrParam[1]);
            }
            if (arrParam[0] != 'wishlist_item_id') {
                params = params.set(arrParam[0], arrParam[1]);
            }
        });
        return `${baseURL}?${params.toString()}`;
    }

    onGiftMessage(cart: Cart) {
        const dialogRef = this.dialog.open(GiftMessageDialogComponent, {
            data: {
                cart: cart
            },
            disableClose: false,
            autoFocus: false
        });
        const close = dialogRef.componentInstance.onClose.subscribe(data => {
            dialogRef.close();
            this._carts = data['data'];
            this.snackbar.open('Gift Message Updated Successfully', 'x', {
                duration: 2000,
                verticalPosition: 'top',
                panelClass: ['success']
            });
        });

    }

    public showTermsDialog() {
        const dialogRef = this.dialog.open(TermsServiceInstallationComponent, {
            width: '400px'
        });
        dialogRef.afterClosed().subscribe(dialogResult => {
        });
    }

    onCartMoreDetailsDeprecated(cart: Cart) {
        const dialogRef = this.dialog.open(CartDetailsDialogComponent, {
            data: {
                cart: cart
            },
            width: '75vw',
            disableClose: true,
            autoFocus: false
        });
    }

    onCartMoreDetails(cart: Cart) {
        let wallpaperSize = '';
        if (cart.customizedProduct.wallpaper_id > 0) {
            wallpaperSize = this.getQueryParams(cart.customizedProduct.url)['size_type'];
        }
        const dialogRef = this.dialog.open(CustomizedProductDetailsDialogComponent, {
            data: {
                type: 'cart',
                customizedProduct: cart.customizedProduct,
                pricingDetails: cart.customizedProduct.pricing_detail,
                wallpaperSize: wallpaperSize
            },
            // width: cart.customizedProduct.on_sale_id > 0 ? '50vw': '75vw',
            width: '75vw',
            disableClose: true,
            autoFocus: false
        });
    }


    moveToWishlist(cart: Cart) {
        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            let url = this.getUpdatedUrl(cart.customizedProduct.url.split('?')[1]);

            const dialogRef = this.dialog.open(WishlistQuickDialogComponent, {
                data: {
                    customer_address_id: 0
                },
                disableClose: true,
                autoFocus: false
            });
            const close = dialogRef.componentInstance.onClose.subscribe(data => {
                dialogRef.close();
                this.appService.moveCartToWishlistItem(cart.id, data.wishlist_id, url)
                    .pipe(
                        finalize(() => {
                            this.changed.emit();
                            // this.loadingSubject.next(false);
                            // this.form.enable();
                        })
                    )
                    .subscribe(
                        res => {
                            this.getCarts();
                            // this._wishlist_item = new WishlistItem();
                            // this.updateUrl();
                        });

            });
        } else {
            const dialogRef = this.dialog.open(SignInDialogComponent, {
                panelClass: 'sign-up-dialog-container',
                data: {
                    // foil,
                },
                disableClose: true,
                autoFocus: false

            });
            const close = dialogRef.componentInstance.onClose.subscribe(() => {
                dialogRef.close();
            });
        }

    }

    remove(cart: Cart, index) {
        const ids = [];
        ids.push(cart.id);
        const self = this;
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, remove it!',
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            preConfirm() {
                return new Promise<void>((resolve) => {
                    if (self.authenticationService.currentUserValue && self.authenticationService.currentUserValue.id > 0) {
                        self.cartService.delete(JSON.stringify(ids))
                            .pipe(
                                catchError(() => of([])),
                                finalize(() => resolve())
                            )
                            .subscribe(
                                res => {
                                });
                    } else {
                        // self._carts.splice(index, 1);
                        // self.cartService.removeFromCart(index);
                        // resolve();

                        self.cartService.deleteGuest(JSON.stringify(ids), self.cartService.getSessionId())
                            .pipe(
                                catchError(() => of([])),
                                finalize(() => resolve())
                            )
                            .subscribe(
                                res => {
                                });
                    }
                });
            }
        }).then((result) => {
            if (result.value) {
                this.getCarts();
                this.changed.emit();
                // Swal.fire(
                //     'Deleted!',
                //     'Cart item has been removed.',
                //     'success'
                // );
            }
        });
    }

    public getActualUrl(url) {
        return '/' + url.split('?')[0];
    }

    public getQueryParams(url) {
        let urlParams = url.split('?')[1].split('&');
        let params: Params = [];
        urlParams.forEach(function(param) {
            let paramKeyValue = param.split('=');
            params[paramKeyValue[0]] = paramKeyValue[1];
        });
        return params;
    }

    public finalSize(customizedProduct) {
        // let w=0;
        // let h=0;
        // for (let i=0; i< customizedProduct.customizedProductItems.length; i++) {
        //     var arr = customizedProduct.customizedProductItems[i].final_size.split('X');
        //     w = w + +arr[0].trim();
        //     h = h + +arr[1].trim();
        //
        //     if(customizedProduct.customizedProductItems.length-1 == i)
        //     {
        //         return w + "X"+h+"inche"
        //     }
        const array = [];
        array.push.apply(array, customizedProduct.frame_detail);

        array.sort((a, b) => 0 - (a.leftEnd > b.leftEnd ? 1 : -1));
        const leftEnd = array[0].leftEnd;

        array.sort((a, b) => 0 - (a.topEnd > b.topEnd ? 1 : -1));
        const topEnd = array[0].topEnd;
        return leftEnd + '\'\' X ' + topEnd + '\'\'';

    }

    private getPageUrl(product_id: number) {
        if (product_id == 1) {
            return '/wall-art';
        } else if (product_id == 3) {
            return '/art-set';
        } else if (product_id == 4) {
            return '/collage-set';
        } else if (product_id == 5) {
            return '/wallpaper';
        }
        return '/frame-photos';
    }

    onPurchaseTypeChange(value: any) {
        this.purchaseType = value;
        this.purchaseTypeEvent.emit(this.purchaseType);
        this.getCarts();
        this.refreshCartAmount();
    }

    getAreaCovered(wallpaper_id, finalSize) {
        if (this.wallpaper_areas[wallpaper_id]) {
            return this.wallpaper_areas[wallpaper_id] < 10;
        } else {
            const size = finalSize.split('X');
            return (+size[0] * +size[1]) < 10;
        }
    }
}
