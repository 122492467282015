import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './admin/services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // const currentUser = this.authenticationService.currentUserValue;
        // if (currentUser) {
        //     // authorised so return true
        //     return true;
        // }

        let roles = route.data.roles as Array<number>;
        if (!this.authenticationService.isTokenExpired()) {
          let user = this.authenticationService.currentUserValue;

          if(roles !== undefined && !roles.includes(user.role_id)){
            this.router.navigate(['/unauthorized']);
            return false;
          }
          return true;
        }

        // not logged in so redirect to login page with the return url
        if(roles === undefined){
          // roles.includes(7)
          // this.router.navigate(['/access-denied']);
            this.router.navigate(['/access-denied'], { queryParams: {returnUrl: state.url}, queryParamsHandling: 'merge' });
        }
        else {
          this.router.navigate(['/sign-in'], { queryParams: { returnUrl: state.url }});
        }
        return false;
    }
}
