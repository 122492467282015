import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {finalize, map} from 'rxjs/operators';
// import * as jwt_decode from 'jwt-decode';
import jwt_decode from 'jwt-decode';

import {environment} from '../../../environments/environment';

import {Router} from '@angular/router';
import {User} from '../models/user.model';
import {Cart} from '../models/cart.model';

// import { User } from '../users/user.model';

// import { SettingService } from './setting.service';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    private apiUrl = '';

    constructor(private http: HttpClient, private router: Router) {
        this.apiUrl = environment.apiUrlUnauth;
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    getTokenExpirationDate(token: string): Date {
        const decoded: any = jwt_decode(token);

        if (decoded.exp === undefined) {
            return null;
        }

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }

    isTokenExpired(token?: string): boolean {
        if (!this.currentUserSubject.value) {
            return true;
        }
        if (!token) {
            token = this.currentUserSubject.value.token;
        }
        if (!token) {
            return true;
        }

        const date = this.getTokenExpirationDate(token);

        if (date === undefined) {
            return false;
        }

        let flag = !(date.valueOf() > new Date().valueOf());

        return !(date.valueOf() > new Date().valueOf());
    }


    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public check(): boolean {
        return this.currentUserSubject.value != null;
    }

    registerLogin(user: User) {
        localStorage.removeItem('McartSession');
        localStorage.setItem('is_registered_user', '1');
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
    }

    login(email: string, password: string, session_id: string) {
        return this.http.post<any>(`${this.apiUrl}/login`, {email: email, password: password, session_id})
            .pipe(
                map(res => {
                    // login successful if there's a jwt token in the response
                    let user: User = new User();
                    if (res.data) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        user = res.data;
                        localStorage.removeItem('McartSession');
                        localStorage.setItem('is_registered_user', '1');
                        localStorage.setItem('currentUser', JSON.stringify(user));
                        this.currentUserSubject.next(user);
                    }

                    return user;
                })
            );
    }

    loginWithOtp(phone: string, otp: string, session_id: string) {
        return this.http.post<any>(`${this.apiUrl}/loginWithOtp`, {phone: phone, otp: otp, session_id})
            .pipe(
                map(res => {
                    // login successful if there's a jwt token in the response
                    let user: User = new User();
                    if (res.data) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        user = res.data;
                        localStorage.setItem('is_registered_user', '1');
                        localStorage.removeItem('McartSession');
                        localStorage.setItem('currentUser', JSON.stringify(user));
                        this.currentUserSubject.next(user);
                    }

                    return user;
                })
            );
    }

    googleSignIn(firstName: string, lastName: string, authToken: string, profileUrl: string, ipAddress: string, session_id: string, email:string ='') {
        return this.http.post<any>(`${this.apiUrl}/googleSignIn`, {
            firstName: firstName, lastName: lastName, authToken: authToken
            , profileUrl: profileUrl, ipAddress: ipAddress, session_id, email
        })
            .pipe(
                map(res => {
                    // login successful if there's a jwt token in the response
                    let user: User = new User();
                    if (res.data) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        user = res.data;
                        localStorage.removeItem('McartSession');
                        localStorage.setItem('currentUser', JSON.stringify(user));
                        this.currentUserSubject.next(user);
                    }

                    return user;
                })
            );
    }

    logout(): Observable<any> {
        if ('currentUser' in localStorage) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Accept': 'application/json, text/plain'
                })
            };

            if (this.isTokenExpired()) {
                localStorage.removeItem('currentUser');
                this.currentUserSubject.next(null);
            } else {
                return this.http.post<any>(this.apiUrl + '/auth/logout', {}, httpOptions)
                    .pipe(
                        map(res => res),
                        finalize(() => {
                            localStorage.removeItem('currentUser');
                            this.currentUserSubject.next(null);
                            // this.router.navigate(['/sign-in']);
                            this.router.navigate(['/']);
                        })
                    );
            }

        } else {
            this.router.navigate(['/']);
        }
    }

    changePassword(email: string, currentPassword: string, newPassword: string): Observable<any> {
        const element: any = {
            email: email,
            currentPassword: currentPassword,
            newPassword: newPassword
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.post<User>(this.apiUrl + '/changePassword', element, httpOptions)
            .pipe(
                map(res => {
                    return res['data'];
                })
            );
    }


    logoutOri() {
        // remove user from local storage to log user out
        // if(this.currentUserSubject) {
        //   localStorage.removeItem('currentUser');
        //   this.currentUserSubject.next(null);
        //   this.router.navigate(['/auth/login']);
        // }
    }

    public getUtmLink() {
        const data = localStorage.getItem('utmLink');
        if (data != null) {
            return data;
        } else {
            return '';
        }
    }

}
