export class DiscountType {
  id: number;
  name: string;
  slug: string;

  static discountTypes: DiscountType[] = [
    {id: 1, name: 'Amount', slug: 'amount'},
    {id: 2, name: 'Percentage', slug: 'percentage'},
    // {id: 3, name: 'Cashback', slug: 'cashback'}
  ];

  public static getDiscountTypes() {
    return DiscountType.discountTypes;
  }

  public static getDiscountType(id: number) {
    let _discountType: DiscountType;
    for(let discountType of DiscountType.discountTypes) {
      if(discountType.id == id) {
        _discountType = discountType;
        break;
      }
    }
    return _discountType;
  }

  public constructor() {
  }
}
