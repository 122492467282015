import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[alphabet]'
})
export class AlphabetDirective {
    @Input('preventSpaces') preventSpaces: boolean = false;

    private specialKeys = [
      'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'
    ];

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', [ '$event' ])
    onKeyDown(event: KeyboardEvent) {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        if(!this.preventSpaces && event.key == " ") {
            return;
        }
        if (!((event.key >= "a" && event.key <="z") || (event.key >= "A" && event.key <="Z"))) {
           event.preventDefault();
        }

    }
}
