import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Order} from 'src/app/admin/models/order.model';
import {OrderItem} from 'src/app/admin/models/order_item.model';
import {CustomizedProduct} from 'src/app/admin/models/customized_product.model';
import {MatDialog} from '@angular/material/dialog';
import {TestimonialDialogComponent} from '../testimonial-dialog/testimonial-dialog.component';
import {CustomizedProductDetailsDialogComponent} from '../customized-product-details-dialog/customized-product-details-dialog.component';
import {Params} from '@angular/router';
import {AuthenticationService} from '../../admin/services/authentication.service';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {BaseAPIDataSource} from "../../admin/services/base_api.datasource";
import {BaseAPIService} from "../../admin/services/base_api.service";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";

@Component({
    selector: 'app-order-history-order-details',
    templateUrl: './order-details.component.html',
    styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
    @Input() order: Order;
    @Input() showAll: Boolean = false;
    @Input() testimonialVisibility: string;
    @Output() closeEvent = new EventEmitter();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    dataSourceWithPageSize: BaseAPIDataSource<any>;
    pageSize = 10;
    pageSizeOptions: number[] = [5, 10, 20, 50];


    constructor(
        private baseApiService: BaseAPIService,
        private dialog: MatDialog,
        private authenticationService: AuthenticationService
    ) {
        baseApiService.apiEndpointToken = 'order-item';
    }

    ngOnInit() {
        this.dataSourceWithPageSize = new BaseAPIDataSource(this.baseApiService);
        this.getData(0);
    }

    ngOnChanges() {
        this.dataSourceWithPageSize = new BaseAPIDataSource(this.baseApiService);
        this.getData(0);
    }

    getData(pageIndex) {
        if (this.showAll) {
            const filter = [];
            const orderBy = [];

            filter.push(['order.payment_status', 'in', [1, 3]]);
            orderBy.push(['order_item.id', 'asc']);
            this.dataSourceWithPageSize.getData(JSON.stringify(filter), JSON.stringify(orderBy), pageIndex,
                this.pageSize,
                this.pageSizeOptions, 'all_order_item', 'auth');
        } else {
            const filter = [];
            const orderBy = [];
            filter.push(['order_id', '=', this.order.id]);
            orderBy.push(['order_item.id', 'asc']);
            this.dataSourceWithPageSize.getData(JSON.stringify(filter), JSON.stringify(orderBy), pageIndex,
                this.pageSize,
                this.pageSizeOptions, 'order_item', 'auth');
        }
    }


    // public getOrderItems() {
    //     const filter = [];
    //     const orderBy = [];
    //
    //     if (this.order && this.order?.id > 0) {
    //         filter.push(['order_id', '=', this.order.id]);
    //     }
    //     orderBy.push(['id', 'asc']);
    //     this.dataSourceWithPageSize.getData(JSON.stringify(filter), JSON.stringify(orderBy), 0, this.pageSize, this.pageSizeOptions, 'order_item', 'auth');
    // }
    //
    // public getAllOrderItems() {
    //     const filter = [];
    //     const orderBy = [];
    //
    //     orderBy.push(['order_item.id', 'asc']);
    //     this.dataSourceWithPageSize.getData(JSON.stringify(filter), JSON.stringify(orderBy), 0, this.pageSize, this.pageSizeOptions, 'all_order_item', 'auth');
    //     // this.orderItemService.filterAll(JSON.stringify(filter), JSON.stringify(orderBy), 0, 20).subscribe(res => {
    //     //     this._orderItems = res['data'];
    //     // });
    // }

    public getTotalQuantity() {
        let quantity = 0;
        if (this.dataSourceWithPageSize?.data) {
            for (let orderItem of this.dataSourceWithPageSize?.data) {
                quantity += orderItem.customizedProduct.quantity;
            }
        }
        return quantity;
    }

    // orderItem?.customizedProduct.customizedProductItems[0].price
    displayedColumns: string[] = [
        'id'
    ];

    public getTotalPrice() {
        let price = 0;
        if (this.dataSourceWithPageSize?.data) {
            for (let orderItem of this.dataSourceWithPageSize?.data) {
                // price += orderItem.customizedProduct.customizedProductItems[0].price;
                price += orderItem.total_price;

            }
        }
        return price;
    }

    public onClose() {
        this.closeEvent.emit();
    }

    onProductMoreDetails(orderItem: OrderItem) {
        if (orderItem) {
            let customizedProduct: CustomizedProduct = orderItem.customizedProduct;
            // const dialogRef = this.dialog.open(ProductDetailsDialogComponent, {
            //   data: {
            //     customizedProduct: customizedProduct
            //   },
            //   width: '75vw',
            //   disableClose: true,
            //   autoFocus: false
            // });

            // if (orderItem.promo_id > 0) {
            //     customizedProduct.pricing_detail['discount_rate'] = orderItem.promo.discount_amount;
            //     customizedProduct.pricing_detail['discount'] = orderItem.promo_price;          //for single item, other calculation will handle by dialog itself
            //     customizedProduct.pricing_detail['tax'] = orderItem.tax_amount;
            //     customizedProduct.pricing_detail['amount_payable'] = orderItem.total_price;
            // }
            // if (customizedProduct.on_sale_id > 0) {
            //     customizedProduct.on_sale['tax'] = orderItem.tax_amount;
            //     customizedProduct.on_sale['tax_rate'] = orderItem.tax_cgst_rate + orderItem.tax_sgst_rate + orderItem.tax_igst_rate;
            //     customizedProduct.on_sale['amount_payable'] = orderItem.total_price;
            // }

            const dialogRef = this.dialog.open(CustomizedProductDetailsDialogComponent, {
                data: {
                    type: 'order-details',
                    customizedProduct: customizedProduct,
                    pricingDetails: customizedProduct.pricing_detail
                },
                // width: cart.customizedProduct.on_sale_id > 0 ? '50vw': '75vw',
                width: '75vw',
                disableClose: true,
                autoFocus: false
            });
        }
    }

    onTestimonial(orderItem: OrderItem) {
        const dialogRef = this.dialog.open(TestimonialDialogComponent, {
            data: {
                order_item_id: orderItem.id,
                product_id: orderItem.customizedProduct.product_id
            },
            width: '75vw',
            disableClose: true,
            autoFocus: false
        });
        const close = dialogRef.componentInstance.onClose.subscribe(() => {
            dialogRef.close();
        });
    }

    public getQueryParams(url) {
        let urlParams = url.split('?')[1].split('&');
        let params: Params = [];
        urlParams.forEach(function (param) {
            let paramKeyValue = param.split('=');
            if (paramKeyValue[0] != 'cart_id') {
                params[paramKeyValue[0]] = paramKeyValue[1];
            }
        });
        return params;
    }

    public viewGiftMessage(customizeProduct: CustomizedProduct) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: '400px',
            data: {
                title: 'Gift Message',
                message: customizeProduct.gift_message,
                show: false
            }
        });
        dialogRef.afterClosed().subscribe(dialogResult => {
        });
    }

    ngAfterViewInit() {
        // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);


        // merge(this.paginator.page).pipe(
        //     untilDestroyed(this)
        // ).subscribe(() => {
        //     this.getData();
        // });
    }

    onPageFired(event) {
        this.getData(event.pageIndex);
    }
}
