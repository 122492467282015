import { City } from "./city.model";

export class CustomerAddress {
  id: number;
  customer_id: number;
  fullname: string;
  mobile: string;
  email: string;
  address: string;
  zipcode: string;

  city_id: number;
  city: City;

  company_name: string;
  gst: string;
  pan: string;
  user_id: number;

  created_at: string;
  updated_at: string;

  public constructor() {
    this.city = new City();
  }
}
