import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {environment} from "../../../environments/environment";

@Component({
    selector: 'app-sharing-dialog',
    templateUrl: './sharing-dialog.component.html',
    styleUrls: ['./sharing-dialog.component.scss']
})
export class SharingDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<SharingDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        this.data.share.share_link = environment.frontUrl+"/share/" + this.data.share.share_link;
    }


    onDismiss(): void {
        this.dialogRef.close(false);
    }

}
