import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Observable, of, from, BehaviorSubject } from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
// import { TService } from './girdView.service';
import {Meta} from '../models/meta.model';
import {BaseAPIService} from './base_api.service';


export class BaseAPIDataSource<T> implements DataSource<T> {

    private girdViewSubject = new BehaviorSubject<T[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();

    public responseSubject = new BehaviorSubject<any>({});

    // public recordsTotal: number;
    // public recordsFiltered: number;
    // public pageSize: number;
    // public pageSizeOptions: number[];

    public data: T[] = [];
    public meta: Meta = new Meta();
    public res: any;

    constructor(private girdViewService: BaseAPIService) { }

    getData(
                filter: string,
                orderBy: string,
                pageIndex: number,
                pageSize: number,
                pageSizeOptions: number[],
                apiPath= '',
                apiAuth = 'unauth'
              ) {

        this.loadingSubject.next(true);
        this.girdViewService.filter<T>(filter, orderBy, pageIndex, pageSize, apiPath, '', apiAuth)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(
              res =>
              {
                this.data = res["data"];
                this.meta = res["meta"];
                this.res = res;
                this.meta.pageSizeOptions = pageSizeOptions;
                this.girdViewSubject.next(res["data"]);
                this.responseSubject.next(res);
              });
    }

    connect(collectionViewer: CollectionViewer): Observable<T[]> {
        return this.girdViewSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.girdViewSubject.complete();
        this.loadingSubject.complete();
    }

}
