export class WallpaperRequest {
    id?: number = 0;
    public name: string;
    public email: string;
    public contact: string;
    public room_type: string;
    public wallpaper_name: string;
    public remark: string;
    public agree: any;

    public constructor() {
    }
}
