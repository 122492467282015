import {Component, Input, OnInit} from '@angular/core';
import {SwiperConfigInterface, SwiperPaginationInterface} from 'ngx-swiper-wrapper';


@Component({
    selector: 'app-data-not-found',
    templateUrl: './data-not-found.component.html',
    styleUrls: ['./data-not-found.component.scss']
})
export class DataNotFoundComponent implements OnInit {


    constructor() {
    }

    ngOnInit() {

    }


}
