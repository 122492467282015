import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrderService} from 'src/app/admin/services/order.service';
import {Order} from 'src/app/admin/models/order.model';
import {Meta} from 'src/app/admin/models/meta.model';
import {AuthenticationService} from 'src/app/admin/services/authentication.service';

import Swal from 'sweetalert2';
import {catchError, finalize} from 'rxjs/operators';
import {of} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {InvoiceDialogComponent} from 'src/app/shared/invoice-dialog/invoice-dialog.component';
import {ConfirmDialogComponent} from '../../../shared/confirm-dialog/confirm-dialog.component';
import {CancelOrderDialogComponent} from '../cancel-order-dialog/cancel-order-dialog.component';
import {environment} from '../../../../environments/environment';
import {DynamicScriptLoaderService} from '../../../admin/services/dynamic-script-loader.service';
import {AppService} from '../../../app.service';
import {OrderItem} from '../../../admin/models/order_item.model';
import {TestimonialDialogComponent} from '../../../shared/testimonial-dialog/testimonial-dialog.component';

@Component({
    selector: 'app-order-history-orders',
    templateUrl: './order-history-orders.component.html',
    styleUrls: ['./order-history-orders.component.scss']
})
export class OrderHistoryOrdersComponent implements OnInit {
    @Output() orderEvent = new EventEmitter<Order>();
    @Input() session: string;
    @Input() user: number;
    public repaymentValidity = 15;

    constructor(
        private orderService: OrderService,
        private appService: AppService,
        private authenticationService: AuthenticationService,
        private dialog: MatDialog,
        private dynamicScriptLoader: DynamicScriptLoaderService
    ) {
    }

    ngOnInit() {
        this.getOrders();
        this.loadScripts();
    }

    private loadScripts() {
        // You can load multiple scripts by just providing the key as argument into load method of the service
        this.dynamicScriptLoader.load('bolt').then(data => {
            // Script Loaded Successfully

        }).catch(error => console.log('ICTSoft', error));
    }

    private _orders: Order[];
    public get orders() {
        return this._orders;
    }

    private _orderMeta: Meta;
    public get orderMeta() {
        return this._orderMeta;
    }

    public getOrders() {
        this._orderMeta = new Meta();
        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            const filter = [];
            const orderBy = [];

            filter.push(['user_id', '=', this.authenticationService.currentUserValue.id]);
            // filter.push(['payment_status', '=', 1]);
            orderBy.push(['id', 'desc']);

            this.orderService.filter(JSON.stringify(filter), JSON.stringify(orderBy), 0, -1).subscribe(res => {
                this._orders = res['data'];
                this._orderMeta = res['meta'];
            });
        } else {
            const filter = [];
            const orderBy = [];
            this.repaymentValidity = 7;
            filter.push(['session_id', '=', this.session]);
            filter.push(['user_id', '=', this.user]);
            // filter.push(['payment_status', '=', 1]);
            orderBy.push(['id', 'desc']);

            this.orderService.filter(JSON.stringify(filter), JSON.stringify(orderBy), 0, -1).subscribe(res => {
                this._orders = res['data'];
                this._orderMeta = res['meta'];
            });
        }
    }

    public onInvoice(order: Order) {
        const dialogRef = this.dialog.open(InvoiceDialogComponent, {
            data: {
                order_id: order.id,
                generate: 0
            },
            width: '100%',
            disableClose: true,
            autoFocus: false
        });
        const close = dialogRef.componentInstance.onClose.subscribe(() => {
            dialogRef.close();
        });
    }


    public viewGiftMessage(order: Order) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: '400px',
            data: {
                title: 'Gift Message',
                message: order.gift_message,
                show: false
            }
        });
        dialogRef.afterClosed().subscribe(dialogResult => {
        });
    }

    public onOrderDetails(order: Order) {
        this.orderEvent.emit(order);
    }

    public onOrderCancel(order: Order) {
        const dialogRef = this.dialog.open(CancelOrderDialogComponent, {
            data: {
                order_id: order.id
            },
            disableClose: true,
            autoFocus: false
        });
        const close = dialogRef.componentInstance.onClose.subscribe(() => {
            this.getOrders();
            dialogRef.close();
        });
    }

    public onOrderCancelOri(order: Order) {
        const self = this;
        Swal.fire({
            title: 'Are you sure?',
            text: '',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            preConfirm() {
                return new Promise((resolve) => {
                    self.orderService.orderCancel(order.id)
                        .pipe(
                            catchError(() => of([])),
                            finalize(() => resolve())
                        )
                        .subscribe(
                            res => {
                            });
                });
            }
        }).then((result) => {
            if (result.value) {
                this.getOrders();
                Swal.fire(
                    'Order Canceled!',
                    'Order has been canceled.',
                    'success'
                );
            }
        });

    }

    public makePayment(order: Order) {
        this.appService._launchBOLT('eFEkadTg', 'Txn-' + order.order_id, order.grand_total,
            order.billing_name, order.billing_email, order.billing_mobile, order.id + '', 'BOLT_KIT_PHP7',
            environment.apiUrlUnauth + '/paymentHookPending', environment.apiUrlUnauth + '/failedPaymentHook');
    }

    calculateDiff(orderDate) {
        return this.appService.calculateDiff(orderDate);
    }

    onTestimonial(order: Order) {
        const dialogRef = this.dialog.open(TestimonialDialogComponent, {
            data: {
                order_id: order.id,
                // product_id: orderItem.customizedProduct.product_id
            },
            width: '60vw',
            disableClose: true,
            autoFocus: false
        });
        const close = dialogRef.componentInstance.onClose.subscribe(() => {
            dialogRef.close();
            this.getOrders();
        });
    }

}
