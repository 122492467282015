import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {MatTableExporterModule} from 'mat-table-exporter';

import {ColorPickerModule} from 'ngx-color-picker';
import {NgxStarRatingModule} from 'ngx-star-rating';
import {UploadcsvNewDiaogComponent} from './uploadcsv-new-diaog/uploadcsv-new-diaog.component';
import {LayoutCarouselComponent} from './layout-carousel/layout-carousel.component';
import {PipesModule} from '../theme/pipes/pipes.module';
import {RatingComponent} from './rating/rating.component';
import {ControlsComponent} from './controls/controls.component';
import {MainCarouselComponent} from './main-carousel/main-carousel.component';
import {BrandsCarouselComponent} from './brands-carousel/brands-carousel.component';
import {ProductsCarouselComponent} from './products-carousel/products-carousel.component';
import {ProductDialogComponent} from './products-carousel/product-dialog/product-dialog.component';
import {BannersComponent} from './banners/banners.component';
import {CategoryListComponent} from './category-list/category-list.component';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {TestimonialCarouselComponent} from './testimonial-carousel/testimonial-carousel.component';
import {ImageCarouselComponent} from './image-carousel/image-carousel.component';
import {FooterWhymahattaComponent} from './footer-whymahatta/footer-whymahatta.component';
import {SignInDialogComponent} from './sign-in-dialog/sign-in-dialog.component';
import {SignUpDialogComponent} from './sign-up-dialog/sign-up-dialog.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MediaComponent} from './media/media.component';
import {CustomerAddressDialogComponent} from './customer-address-dialog/customer-address-dialog.component';
import {CustomizationComponent} from './customization/customization.component';
import {WishlistQuickDialogComponent} from './wishlist-quick-dialog/wishlist-quick-dialog.component';
import {ProductPreviewComponent} from './product-preview/product-preview.component';
import {OrderDetailsComponent} from './order-details/order-details.component';
import {ProductDetailsDialogComponent} from './product-details-dialog/product-details-dialog.component';
import {TestimonialDialogComponent} from './testimonial-dialog/testimonial-dialog.component';
import {CheckPincodeDialogComponent} from './check-pincode-dialog/check-pincode-dialog.component';
import {InvoiceDialogComponent} from './invoice-dialog/invoice-dialog.component';
import {InvoiceOfflineDialogComponent} from './invoice-offline-dialog/invoice-offline-dialog.component';
import {CustomizedProductDetailsDialogComponent} from './customized-product-details-dialog/customized-product-details-dialog.component';
import {PasswordChangeComponent} from './password-change/password-change.component';
import {WishlistItemCarouselComponent} from './wishlist-item-carousel/wishlist-item-carousel.component';
import {OrderDetailsDialogComponent} from './order-details-dialog/order-details-dialog.component';
import {UploadPhotosComponent} from './upload-photos/upload-photos.component';
import {NgxFileDropModule} from 'ngx-file-drop';
import {NumericDirective} from '../helpers/numeric.directive';
import {AlphabetDirective} from '../helpers/alphabet.directive';
import {AlphanumericDirective} from '../helpers/alphanumeric.directive';
import {ContactDialogComponent} from './contact-dialog/contact-dialog.component';
import {CartComponent} from '../pages/cart-view/cart/cart.component';
import {MatBadgeModule} from '@angular/material/badge';
import {OrderHistoryComponent} from '../pages/order-history/order-history.component';
import {OrdersComponent} from '../pages/account/orders/orders.component';
import {OrderHistoryOrdersComponent} from '../pages/order-history/order-history-orders/order-history-orders.component';
import {CancelOrderDialogComponent} from '../pages/order-history/cancel-order-dialog/cancel-order-dialog.component';
import {OrderDetailDialogComponent} from '../pages/account/older-order/order-detail-dialog/order-detail-dialog.component';
import {ShopInspiredCarouselComponent} from './shop-inspired-carousel/shop-inspired-carousel.component';
import {ArtSetPreviewDialogComponent} from './art-set-preview-dialog/art-set-preview-dialog.component';
import {ProductListingCarouselComponent} from './product-listing-carousel/product-listing-carousel.component';
import {PredefinedItemCarouselComponent} from './predefined-item-carousel/predefined-item-carousel.component';
import {SharingDialogComponent} from './sharing-dialog/sharing-dialog.component';
import {ShareButtonsModule} from 'ngx-sharebuttons/buttons';
import {CollageItemCarouselComponent} from './collage-item-carousel/collage-item-carousel.component';
import {WallPaperRequestComponent} from './wal-paper-request/wall-paper-request.component';
import {WallpaperDialogComponent} from './wallpaper-dialog/wallpaper-dialog.component';
import {PreviewDialogComponent} from './preview-dialog/preview-dialog.component';
import {GiftMessageDialogComponent} from './gift-message-dialog/gift-message-dialog.component';
import {WallPaperCarouselComponent} from './wall-paper-carousel/wall-paper-carousel.component';
import {QueryFormComponent} from './query-form/query-form.component';
import {TextCarouselComponent} from './text-carousel/text-carousel.component';
import {SplitPipe} from '../helpers/pipes/split.pipe';
import {TrackerComponent} from './tracker/tracker.component';
import {FooterSocialLinksComponent} from './footer-social-links/footer-social-links.component';
import {BookConsultationComponent} from "./book-consultation/book-consultation.component";
import {PaymentMarkStatusOfflineDialogComponent} from "../admin/order-history/payment-mark-status-dialog/payment-mark-status-offline-dialog.component";
import {DataNotFoundComponent} from "./data-not-found/data-not-found.component";
import {ProductsHomeCarouselComponent} from "./products-home-carousel/products-home-carousel.component";
import {InvoicePaymentDialogComponent} from "../admin/offline-invoice/invoice-payment-dialog/invoice-payment-dialog.component";
import {InvoicePaymentDetailComponent} from "../admin/offline-invoice/invoice-payment-detail/invoice-payment-detail.component";
import {WallArtCategoryCarouselComponent} from "./wall-art-category-carousel/wall-art-category-carousel.component";
import {TermsServiceInstallationComponent} from './terms-service-installation/terms-service-installation.component';
import {P2fProductComponent} from './p2f-product/p2f-product.component';
import {ShippingDialogComponent} from '../admin/order-history/shipping-dialog/shipping-dialog.component';
import {TrackingDialogComponent} from '../admin/order-history/tracking-dialog/tracking-dialog.component';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { EnvironmentCarouselComponent } from './environment-carousel/environment-carousel.component';
// import {SnowComponent} from './snow/snow.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    wheelPropagation: true,
    suppressScrollX: true
};


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SwiperModule,
        FlexLayoutModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatStepperModule,
        PerfectScrollbarModule,
        PipesModule,
        NgxDaterangepickerMd.forRoot(),
        ReactiveFormsModule,
        NgxSliderModule,
        NgxFileDropModule,
        MatTableExporterModule,
        ColorPickerModule,
        CKEditorModule,
        MatBadgeModule,
        ShareButtonsModule,
        FormsModule
    ],
    exports: [
        RouterModule,
        SwiperModule,
        FlexLayoutModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatStepperModule,
        NgxSliderModule,
        NgxFileDropModule,
        PerfectScrollbarModule,
        PipesModule,
        RatingComponent,
        ControlsComponent,
        MainCarouselComponent,
        BrandsCarouselComponent,
        ProductsCarouselComponent,
        ProductsHomeCarouselComponent,
        ProductDialogComponent,
        BannersComponent,
        CategoryListComponent,
        ConfirmDialogComponent,
        TestimonialCarouselComponent,
        ImageCarouselComponent,
        FooterWhymahattaComponent,
        SignInDialogComponent,
        SignUpDialogComponent,
        ContactDialogComponent,
        ForgotPasswordComponent,
        CustomerAddressDialogComponent,
        CustomizationComponent,
        WishlistQuickDialogComponent,
        MediaComponent,
        ProductPreviewComponent,
        ColorPickerModule,
        NgxStarRatingModule,
        OrderDetailsComponent,
        ProductDetailsDialogComponent,
        TestimonialDialogComponent,
        CheckPincodeDialogComponent,
        InvoiceDialogComponent,
        InvoiceOfflineDialogComponent,
        OrderDetailsDialogComponent,
        CustomizedProductDetailsDialogComponent,
        PasswordChangeComponent,
        WishlistItemCarouselComponent,
        UploadPhotosComponent,
        LayoutCarouselComponent,
        CartComponent,
        AlphanumericDirective,
        ShopInspiredCarouselComponent,
        ProductListingCarouselComponent,
        WallPaperRequestComponent,
        WallpaperDialogComponent,
        PreviewDialogComponent,
        ArtSetPreviewDialogComponent,
        PredefinedItemCarouselComponent,
        SharingDialogComponent,
        CollageItemCarouselComponent,
        GiftMessageDialogComponent,
        WallPaperCarouselComponent,
        QueryFormComponent,
        TextCarouselComponent,
        SplitPipe,
        TrackerComponent,
        P2fProductComponent,
        FooterSocialLinksComponent,
        BookConsultationComponent,
        DataNotFoundComponent,
        WallArtCategoryCarouselComponent,
        NumericDirective,
        TermsServiceInstallationComponent,
        ShippingDialogComponent,
        TrackingDialogComponent,
        EnvironmentCarouselComponent
        // SnowComponent
    ],
    declarations: [
        RatingComponent,
        ControlsComponent,
        MainCarouselComponent,
        BrandsCarouselComponent,
        ProductsCarouselComponent,
        ProductsHomeCarouselComponent,
        ProductDialogComponent,
        BannersComponent,
        CategoryListComponent,
        ConfirmDialogComponent,
        TestimonialCarouselComponent,
        ImageCarouselComponent,
        FooterWhymahattaComponent,
        SignInDialogComponent,
        SignUpDialogComponent,
        ContactDialogComponent,
        ForgotPasswordComponent,
        CustomerAddressDialogComponent,
        CustomizationComponent,
        WishlistQuickDialogComponent,
        MediaComponent,
        ProductPreviewComponent,
        OrderDetailsComponent,
        ProductDetailsDialogComponent,
        TestimonialDialogComponent,
        CheckPincodeDialogComponent,
        InvoiceDialogComponent,
        InvoiceOfflineDialogComponent,
        OrderDetailsDialogComponent,
        PreviewDialogComponent,
        CustomizedProductDetailsDialogComponent,
        PasswordChangeComponent,
        WishlistItemCarouselComponent,
        UploadPhotosComponent,
        NumericDirective,
        AlphabetDirective,
        AlphanumericDirective,
        UploadcsvNewDiaogComponent,
        LayoutCarouselComponent,
        CartComponent,
        OrderHistoryComponent,
        OrdersComponent,
        OrderHistoryOrdersComponent,
        CancelOrderDialogComponent,
        OrderDetailDialogComponent,
        ShopInspiredCarouselComponent,
        ProductListingCarouselComponent,
        WallPaperRequestComponent,
        WallpaperDialogComponent,
        ArtSetPreviewDialogComponent,
        PredefinedItemCarouselComponent,
        CollageItemCarouselComponent,
        SharingDialogComponent,
        GiftMessageDialogComponent,
        WallPaperCarouselComponent,
        QueryFormComponent,
        TextCarouselComponent,
        SplitPipe,
        TrackerComponent,
        P2fProductComponent,
        FooterSocialLinksComponent,
        BookConsultationComponent,
        PaymentMarkStatusOfflineDialogComponent,
        DataNotFoundComponent,
        InvoicePaymentDialogComponent,
        InvoicePaymentDetailComponent,
        WallArtCategoryCarouselComponent,
        TermsServiceInstallationComponent,
        ShippingDialogComponent,
        TrackingDialogComponent,
        EnvironmentCarouselComponent,
        // SnowComponent
    ],
    entryComponents: [
        ProductDialogComponent,
        SignInDialogComponent,
        SignUpDialogComponent,
        ContactDialogComponent,
        ForgotPasswordComponent,
        CustomerAddressDialogComponent,
        WishlistQuickDialogComponent,
        ProductDetailsDialogComponent,
        TestimonialDialogComponent,
        CheckPincodeDialogComponent,
        InvoiceDialogComponent,
        InvoiceOfflineDialogComponent,
        OrderDetailsDialogComponent,
        PreviewDialogComponent,
        CustomizedProductDetailsDialogComponent,
        ShopInspiredCarouselComponent,
        CustomizedProductDetailsDialogComponent,
        ArtSetPreviewDialogComponent,
        SharingDialogComponent,
        GiftMessageDialogComponent,
        TextCarouselComponent,
        BookConsultationComponent,
        PaymentMarkStatusOfflineDialogComponent,
        InvoicePaymentDialogComponent,
        InvoicePaymentDetailComponent
    ],
    providers: [
        {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG}
    ]
})
export class SharedModule {
}
