import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {WishlistItem} from 'src/app/admin/models/wishlist_item.model';
import {AuthenticationService} from 'src/app/admin/services/authentication.service';
import {Params} from '@angular/router';
import {AppService} from 'src/app/app.service';
import {catchError, finalize} from 'rxjs/operators';
import {of} from 'rxjs';
import Swal from 'sweetalert2';
import {CollageLayoutDetail} from "../../admin/models/art_set_master.model";
import {
    CollageLayoutDetailService
} from "../../admin/services/collage_layout_detail.service";
import {ProductImage} from "../../app.models";

@Component({
    selector: 'app-collage-item-carousel',
    templateUrl: './collage-item-carousel.component.html',
    styleUrls: ['./collage-item-carousel.component.scss']
})
export class CollageItemCarouselComponent implements OnInit {
    @Output() changed = new EventEmitter();
    @Input('collageLayoutDetail') collageLayoutDetail: CollageLayoutDetail;
    public config: SwiperConfigInterface = {};
    @Input('collageLayouts')  collageLayouts: CollageLayoutDetail[] = [];
    @Input('showPrice')  showPrice = true;

    constructor(
        public appService: AppService,
        private collageLayoutDetailService: CollageLayoutDetailService) {
    }

    ngOnInit() {
        if(this.collageLayouts.length==0) {
            this.getCollageLayouts();
        }
    }

    public refresh() {
        this.getCollageLayouts();
    }


    public getCollageLayouts() {
            const filter = [];
            // filter.push(['collage_type', '=', this.collageLayoutDetail.collage_type?.id]);
            filter.push(['status', '=', 1]);
            this.collageLayoutDetailService.filter(JSON.stringify(filter), '[]', 0, -1).subscribe(res => {
                this.collageLayouts = res['data'];
            });
    }

    ngAfterViewInit() {
        this.config = {
            observer: true,
            slidesPerView: 4,
            spaceBetween: 16,
            keyboard: true,
            navigation: true,
            pagination: false,
            grabCursor: true,
            loop: true,
            preloadImages: false,
            lazy: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            breakpoints: {
                480: {
                    slidesPerView: 2
                },
                740: {
                    slidesPerView: 2,
                },
                960: {
                    slidesPerView: 3,
                },
                1280: {
                    slidesPerView: 4,
                },
                1500: {
                    slidesPerView: 4,
                }
            }
        };
    }


}
