import {Injectable} from '@angular/core';

@Injectable()
export class TopbarNavbarService {
    menuType: string;
    whatsappType: number;
    mobile: string;
    headerType: number;

    constructor() {
        this.menuType = '';
        this.whatsappType = 2;
        this.headerType = 1;
        this.mobile = '';
    }

    public getMenuType() {
        return this.menuType;
    }

    public getWhatsappType() {
        return this.whatsappType;
    }

    public getHeaderType() {
        return this.headerType;
    }

    public setMenuType(type: number): void {
        if (type === 1) {
            this.menuType = 'wall-art';
            this.whatsappType = 1;
        } else if (type === 2) {
            this.menuType = 'frame-photos';
            this.whatsappType = 2;
        } else if (type === 3) {
            this.menuType = 'wall-paper';
            this.whatsappType = 3;
        } else {
            this.menuType = '';
            this.whatsappType = 4;
        }
    }

    public getMobile() {
        if (this.whatsappType === 1) {
            this.mobile = "";
            // this.mobile = "9599967091";
        } else if (this.whatsappType === 2) {
            this.mobile = "";
            // this.mobile = "9599967091";
        } else if (this.whatsappType === 3) {
            this.mobile = "";
            // this.mobile = "7669068684";
        } else {
            // this.mobile = "9810223204";
        }
        if (window.location.pathname == '/') {
            this.mobile = '';
        }
        // this.mobile = 9810223204;
        return this.mobile;
    }

    public setWhatsAppType(type: number): void {
        this.whatsappType = type;
    }

    public setHeaderType(type: number): void {
        this.headerType = type;
    }
}
