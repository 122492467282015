import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {WishlistItemService} from 'src/app/admin/services/wishlist_item.service';
import {WishlistItem} from 'src/app/admin/models/wishlist_item.model';
import {AuthenticationService} from 'src/app/admin/services/authentication.service';
import {Params} from '@angular/router';
import {AppService} from 'src/app/app.service';
import {catchError, finalize} from 'rxjs/operators';
import {of} from 'rxjs';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-wishlist-item-carousel',
    templateUrl: './wishlist-item-carousel.component.html',
    styleUrls: ['./wishlist-item-carousel.component.scss']
})
export class WishlistItemCarouselComponent implements OnInit {
    @Output() changed = new EventEmitter();

    public config: SwiperConfigInterface = {};

    constructor(
        public appService: AppService,
        private wishlistItemService: WishlistItemService,
        private authenticationService: AuthenticationService,
    ) {
    }

    ngOnInit() {
        this.getWishlistItems();
    }

    public refresh() {
        this.getWishlistItems();
    }

    public _wishlistItems: WishlistItem[];
    public get wishlistItems() {
        return this._wishlistItems;
    }

    public getWishlistItems() {
        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            const filter = [];
            filter.push(['user_id', '=', this.authenticationService.currentUserValue.id]);
            this.wishlistItemService.filter(JSON.stringify(filter), '[]', 0, -1).subscribe(res => {
                this._wishlistItems = res['data'];
            });
        }
    }

    ngAfterViewInit() {
        this.config = {
            observer: true,
            slidesPerView: 5,
            spaceBetween: 16,
            keyboard: true,
            navigation: true,
            pagination: false,
            grabCursor: true,
            loop: false,
            preloadImages: false,
            lazy: true,
            breakpoints: {
                480: {
                    slidesPerView: 2
                },
                740: {
                    slidesPerView: 2,
                },
                960: {
                    slidesPerView: 3,
                },
                1280: {
                    slidesPerView: 4,
                },
                1500: {
                    slidesPerView: 5,
                }
            }
        };
    }

    public getImageCaption(wishlistItem) {
        if (wishlistItem.image_caption) {
            return wishlistItem.image_caption;
        } else {
            if (wishlistItem.on_sale_id > 0) {
                return wishlistItem.onSale.title;
            } else {
                if (wishlistItem.customizedProduct.product.id == 1) {
                    return wishlistItem.customizedProduct.customizedProductItems[0].image_detail.image_caption;
                } else {
                    if (wishlistItem.customizedProduct.product.id == 2) {
                        return 'Print & Frame';
                    } else {
                        return '';
                    }
                }
            }
        }
    }

    public getQueryParams(wishlistItem: WishlistItem) {
        //{product: 'wall_art', product_type: 'framing', image_id: wishlistItem.customizedProduct.customizedProductItems[0].image_detail.image_id}
        // const params = [];
        if (wishlistItem.customized_product_id > 0) {
            let urlParams = wishlistItem.customizedProduct.url.split('?')[1].split('&');
            let params: Params = [];
            urlParams.forEach(function(param) {
                let paramKeyValue = param.split('=');
                params[paramKeyValue[0]] = paramKeyValue[1];
            });
            return params;
            // return {
            //   product: 'wall_art',
            //   product_type: 'framing',
            //   image_id: wishlistItem.customizedProduct.customizedProductItems[0].image_detail.image_id,
            //   wishlist_item_id: wishlistItem.id
            // };
        } else {
            return {
                product: 'wall_art',
                product_type: 'framing',
                image_id: wishlistItem.image_id
            };
        }
    }

    public onMoveWishlistItemToCart(wishlist_item_id) {
        this.appService.moveWishlistItemToCart(wishlist_item_id, '')
            .pipe(
                finalize(() => {
                    this.getWishlistItems();
                    this.changed.emit();
                    // this.updateUrl();
                    // this.loadingSubject.next(false);
                    // this.form.enable();
                })
            )
            .subscribe(
                res => {
                    // this._cart = res['data'];
                    // this._wishlist_item = new WishlistItem();
                    // this.updateUrl();
                });
    }

    public onRemoveFromWishList(wishlist_item_id) {
        const ids = [];
        ids.push(wishlist_item_id);
        const self = this;
        Swal.fire({
            title: 'Are you sure to remove item from wishlist?',
            text: 'You won\'t be able to revert this!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, remove it!',
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            preConfirm() {
                return new Promise((resolve) => {
                    self.wishlistItemService.delete(JSON.stringify(ids))
                        .pipe(
                            catchError(() => of([])),
                            finalize(() => resolve())
                        )
                        .subscribe(
                            res => {
                            });
                });
            }
        }).then((result) => {
            if (result.value) {
                this.getWishlistItems();
                this.changed.emit();
            }
        });

    }

    getPrice(wishlistItem: WishlistItem) {
        let finalPrice = wishlistItem?.pricing_detail?.total ? wishlistItem?.pricing_detail?.total : 0.0;
        if (finalPrice == 0) {
            wishlistItem.customizedProduct.frame_detail.forEach(item => {
                finalPrice = finalPrice + item.priceDetail.amount_payable;
            });
            finalPrice = Number(finalPrice.toFixed(2));
        }
        return finalPrice;
    }

}
