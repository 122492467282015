import {Component, OnInit} from '@angular/core';
import {AppService} from '../../../app.service';
import {AppSettings, Settings} from '../../../app.settings';
import {AuthenticationService} from 'src/app/admin/services/authentication.service';
import {User} from 'src/app/admin/models/user.model';
import {finalize} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {SignInDialogComponent} from 'src/app/shared/sign-in-dialog/sign-in-dialog.component';
import {WishlistItemService} from 'src/app/admin/services/wishlist_item.service';
import {CartService} from 'src/app/admin/services/cart.service';
import {Router} from '@angular/router';
import {SignUpDialogComponent} from '../../../shared/sign-up-dialog/sign-up-dialog.component';
import {TopbarNavbarService} from "../../../topbar-navbar.service";


@Component({
    selector: 'app-top-menu',
    templateUrl: './top-menu.component.html'
})
export class TopMenuComponent implements OnInit {
    public currencies = ['USD', 'EUR'];
    public currency: any;
    public flags = [
        {name: 'English', image: 'assets/images/flags/gb.svg'},
        {name: 'German', image: 'assets/images/flags/de.svg'},
        {name: 'French', image: 'assets/images/flags/fr.svg'},
        {name: 'Russian', image: 'assets/images/flags/ru.svg'},
        {name: 'Turkish', image: 'assets/images/flags/tr.svg'}
    ];
    public flag: any;

    public settings: Settings;
    public user: User = new User();

    constructor(
        private dialog: MatDialog,
        public appSettings: AppSettings,
        public appService: AppService,
        public cartService: CartService,
        public wishlistItemService: WishlistItemService,
        public authenticationService: AuthenticationService,
        private router: Router,
        public topbarNavbarService: TopbarNavbarService,
    ) {
        this.settings = this.appSettings.settings;
    }

    ngOnInit() {
        this.currency = this.currencies[0];
        this.flag = this.flags[0];

        this.user = this.authenticationService.currentUserValue ? this.authenticationService.currentUserValue : new User();

        this.wishlistItemService.refreshCount();
        this.cartService.refreshCount();
    }

    public changeCurrency(currency) {
        this.currency = currency;
    }

    public changeLang(flag) {
        this.flag = flag;
    }

    login(url = '') {
        const dialogRef = this.dialog.open(SignInDialogComponent, {
            panelClass: 'sign-up-dialog-container',
            data: {
                // foil,
            },
            disableClose: true,
            autoFocus: false,
        });
        const close = dialogRef.componentInstance.onClose.subscribe(() => {
            dialogRef.close();
            if (url != '' && this.authenticationService.currentUserValue) {
                this.router.navigate(['/cart-view']);
            }
        });
        // const reset = dialogRef.componentInstance.onReset.subscribe(() => {
        //   this.reset();
        // });

    }

    signUp(url = '') {
        const dialogRef = this.dialog.open(SignUpDialogComponent, {
            panelClass: 'dialog-container-no-padding',
            data: {
                // foil,
            },
            disableClose: true,
            autoFocus: false,
        });
        const close = dialogRef.componentInstance.onClose.subscribe(() => {
            dialogRef.close();
        });

    }

    logout() {
        // this.authenticationService.logout();
        this.authenticationService.logout()
            .pipe(
                finalize(() => {
                })
            )
            .subscribe(
                res => {
                },
                err => {
                }
            );
    }


}
