import { City } from "./city.model";
import {User} from './user.model';
import {Cart} from './cart.model';

export class Customer {
  id: number;
  customer_id: number;
  email: string;
  firstname: string;
  lastname: string;
  gender_id: string;
  city_id: number;
  city: City;

  address: string;
  zipcode: string;
  region_id: number;
  contact: string;
  requirement_id: number;   //i_want_to
  purpose_id: number;
  company_name: string;
  customer_type_id: number;
  registered_from: string;
  client_handled_by: number;
  created_by: number;
  relationship_manager: number;
  user_id: number;
  lead_gen_from: string;
  profession_id: number;
  designation_id: number;
  job_description: string;
  registration_ip: string;
  dob: string;
  anniversary_date: string;
  status: number;
  utm_link : string;
  created_at: string;
  updated_at: string;
  user: User;
  cartList: Cart[];
  session_id: string;
  public constructor() {
    this.city = new City();
  }
}
