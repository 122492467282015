import {Inject, Injectable, Renderer2} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class SchemaService {
    renderer : Renderer2;

    constructor(private title: Title, private meta: Meta, @Inject(DOCUMENT) private _document) {
    }

    addSchema(schemaData: any, isDelete) {
        const script = document.getElementById("json-id");

        if (script) {
            if (isDelete == 1) {
                script.remove();
            } else {
                script.firstChild.nodeValue = schemaData; // Completely replace it
            }
        } else if (isDelete == 0) {
            const scriptTag = this.renderer.createElement('script');
            scriptTag.type = `application/ld+json`;
            scriptTag.text = schemaData;
            scriptTag.id = 'json-id';

            this.renderer.appendChild(this._document.body, scriptTag);
        }
    }

}
