import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SwiperComponent, SwiperConfigInterface, SwiperPaginationInterface} from 'ngx-swiper-wrapper';
import {WallpaperDialogComponent} from '../wallpaper-dialog/wallpaper-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import { Router } from '@angular/router';


@Component({
  selector: 'app-environment-carousel',
  templateUrl: './environment-carousel.component.html',
  styleUrls: ['./environment-carousel.component.scss']
})
export class EnvironmentCarouselComponent implements OnInit {
    @Input('slides') slides: Array<any> = [];
    @Input('images') images: number =2;
    @Input('pattern') pattern: any = 4;
    @Input() changeEnvironments!: (data: any) => void;

    @Input('type') type: string = "new";
    @Input('text-align') textAlignment = "left";
    @ViewChild(SwiperComponent) swiperRef!: SwiperComponent;

    public config: SwiperConfigInterface = {};

    private pagination: SwiperPaginationInterface = {
        el: '.swiper-pagination',
        clickable: true
    };

    constructor(private dialog: MatDialog,private router:Router) {
    }

    ngOnInit() {
    }

    hoveredWallpaper: any = null; // This will store the hovered wallpaper

    // This method is triggered when mouse enters the image area
    onHover(wallpaper: any) {
      this.hoveredWallpaper = wallpaper;
    }
  
    // This method is triggered when mouse leaves the image area
    onLeave() {
      this.hoveredWallpaper = null;
    }


    ngAfterViewInit() {
        this.config = {
            observer: true,
            observeParents: true,
            slidesPerView: 4,
            spaceBetween: 8,
            keyboard: true,
            navigation: true,
            pagination: false,
            grabCursor: true,
            loop: false,
            preloadImages: false,
            lazy: true,
            autoplay: false,
            initialSlide: 0, 
            breakpoints: {
                480: {
                    slidesPerView: 4
                },
                740: {
                    slidesPerView: 4,
                },
                960: {
                    slidesPerView: 4,
                },
                1280: {
                    slidesPerView: 4,
                },
                1500: {
                    slidesPerView: 4
                }
            }
        };

        console.log("Swiper Config:", this.config);

    }

    callParentFunction(wallpaper:any) {
    
        if (typeof this.changeEnvironments === 'function') {
          this.changeEnvironments(wallpaper);
        } else {
          // Build the navigation URL
          const collectionSlug = wallpaper?.collection
            ? wallpaper?.collection.slug
            : wallpaper?.collection_slug;
          
          const route = `/wallpaper/${collectionSlug}/${wallpaper?.slug}/${wallpaper?.max_color}-${wallpaper?.min_color}`;
          
          console.warn("customFunction is not defined! Navigating to:", route);
          this.router.navigate([route]);
        }
      }
}
