import {SidenavMenu} from './sidenav-menu.model';

export const sidenavMenuItems = [
    new SidenavMenu(1, 'Wall Art', '/wall-art', null, null, true, 0),

    new SidenavMenu(2, 'Shop By Theme', null, null, null, true, 1),
    // new SidenavMenu(3, 'Art By Space', null, null, null, true, 1),
    // new SidenavMenu(4, 'Art For Business', '/art-for-business', null, null, false, 1),
    // new SidenavMenu(5, 'Art For Advisory', '/art-for-advisory', null, null, false, 1),

    new SidenavMenu(11, 'All Theme', null, null, null, true, 2),
    // new SidenavMenu (10, 'All Wall Art', '/wall-art/art-by-search/themes', null, null, false, 11),
    new SidenavMenu(10, 'All Wall Art', '/product-all', null, null, false, 11),

    new SidenavMenu(11, 'Birds', '/wall-art/art-by-search/themes/birds', null, null,  false, 11),
new SidenavMenu(12, 'Abstract', '/wall-art/art-by-search/themes/abstract', null, null, false, 11),
new SidenavMenu(13, 'Food', '/wall-art/art-by-search/themes/food', null, null, false, 11),
new SidenavMenu(14, 'Animal', '/wall-art/art-by-search/themes/animal', null, null, false, 11),
new SidenavMenu(15, 'Botanical',  '/wall-art/art-by-search/themes/botanical', null, null, false, 11),
new SidenavMenu(103, 'Still Life', '/wall-art/art-by-search/themes/still-life', null, null, false, 11),
new SidenavMenu(104, 'Astronomy', '/wall-art/art-by-search/themes/astronomy', null, null, false, 11),
// new SidenavMenu(105, 'Fashion',  '/wall-art/art-by-search/themes/fashion', null, null, false, 11),
// new SidenavMenu(106, 'Scenic',  '/wall-art/art-by-search/themes/scenic', null, null, false, 11),
new SidenavMenu(107, 'Cityscapes And Skylines', '/wall-art/art-by-search/themes/places', null, null, false, 11),

new SidenavMenu(108, 'Nature', '/wall-art/art-by-search/themes/nature', null, null, false, 11),
new SidenavMenu(109, 'People and Portrait', '/wall-art/art-by-search/themes/portrait', null, null, false, 11),
new SidenavMenu(110, 'Typography',  '/wall-art/art-by-search/themes/typography', null, null, false, 11),
new SidenavMenu(111, 'Kids', '/wall-art/art-by-search/themes/kids', null, null, false, 11),
new SidenavMenu(112, 'Folk Art',  '/wall-art/art-by-search/themes/folk-art', null, null, false, 11),
new SidenavMenu(113, 'Object',  '/wall-art/art-by-search/themes/object', null, null, false, 11),
new SidenavMenu(114, 'Spiritual',  '/wall-art/art-by-search/themes/spiritual', null, null, false, 11),
new SidenavMenu(115, 'Boho',  '/wall-art/art-by-search/themes/boho', null, null, false, 11),
new SidenavMenu(116, 'Asian', '/wall-art/art-by-search/themes/asian', null, null, false, 11),


// routerLink="/product-customization" [queryParams]="{product:'frame_your_photos', product_type: 'framing'}"
    new SidenavMenu(51, 'Print & Frame', 'frame-photos', null, null, true, 0),
    new SidenavMenu(52, 'Photo Frame', null, '/product-customization?product=frame_your_photos&product_type=framing', null, false, 51),
    new SidenavMenu(53, 'Canvas', null, '/product-customization?product=frame_your_photos&product_type=canvas', null, false, 51),
    new SidenavMenu(54, 'Only Print', null, '/product-customization?product=frame_your_photos&product_type=print_only', null, false, 51),
    new SidenavMenu(55, 'Frame Only', null, '/product-customization?product=frame_your_photos&product_type=frame_only', null, false, 51),
    // new SidenavMenu (56, 'Frame Advisory', '/frame-advisory', null, null, false, 51),

    // new SidenavMenu (57, 'Only Frames', '/only-frames', null, null, false, 0),
    new SidenavMenu(61, 'Wallpaper', '/wall-paper', null, null, true, 0),

    // new SidenavMenu(73, 'Shop By Category', null, null, null, true, 61),
    // new SidenavMenu(74, 'Standard', null, '/wallpaper/shop-by-category/standard', null, false, 73),
    // new SidenavMenu(75, 'Premium', null, '/wallpaper/shop-by-category/premium', null, false, 73),
    // new SidenavMenu(72, 'Exclusive', null, '/wallpaper/shop-by-category/exclusive', null, false, 73),
    // new SidenavMenu(85, 'All Wallpaper', null, 'wallpaper-listing', null, false, 73),

    // new SidenavMenu(68, 'Shop By Space', null, null, null, true, 61),
    // new SidenavMenu(69, 'Bedroom', null, '/wallpaper/shop-by-space/bedroom', null, false, 68),
    // new SidenavMenu(70, 'Living Room', null, '/wallpaper/shop-by-space/living-room', null, false, 68),
    // new SidenavMenu(71, 'Kids Room', null, '/wallpaper/shop-by-space/kids-room', null, false, 68),
    // new SidenavMenu(102, 'Pooja Room', null, '/wallpaper/shop-by-space/pooja-room', null, false, 68),
    // new SidenavMenu(86, 'All Wallpaper', null, 'wallpaper-listing', null, false, 68),

    new SidenavMenu(76, 'Shop By Theme', null, null, null, true, 61),
    new SidenavMenu(78, 'Floral', null, '/wallpaper/shop-by-theme/floral', null, false, 76),
    new SidenavMenu(80, 'Tropical', null, '/wallpaper/shop-by-theme/tropical', null, false, 76),
    new SidenavMenu(81, '3d', null, '/wallpaper/shop-by-theme/3d-floral', null, false, 76),
    new SidenavMenu(83, 'Kids', null, '/wallpaper/shop-by-theme/kids', null, false, 76),
    // new SidenavMenu(92, 'Kids Discover & Dreams', null, '/wallpaper/shop-by-theme/kids-discover-&-dream', null, false, 76),
    new SidenavMenu(93, 'Traditional', null, '/wallpaper/shop-by-theme/traditional', null, false, 76),
    new SidenavMenu(94, 'Rajasthan', null, '/wallpaper/shop-by-theme/rajasthan', null, false, 76),
    new SidenavMenu(95, 'Damask', null, '/wallpaper/shop-by-theme/damask', null, false, 76),
    new SidenavMenu(96, 'Pichwai', null, '/wallpaper/shop-by-theme/pichwai', null, false, 76),
    new SidenavMenu(97, 'Medieval India', null, '/wallpaper/shop-by-theme/medieval-india', null, false, 76),
    new SidenavMenu(98, 'Marble', null, '/wallpaper/shop-by-theme/marble', null, false, 76),
    new SidenavMenu(99, 'Lines and Shapes', null, '/wallpaper/shop-by-theme/lines-and-shapes', null, false, 76),
    new SidenavMenu(100, 'Mandala', null, '/wallpaper/shop-by-theme/mandala', null, false, 76),
    new SidenavMenu(102, 'Artistic', null, '/wallpaper/shop-by-theme/artistic', null, false, 76),
    new SidenavMenu(84, 'All Wallpaper', null, 'wallpaper-listing', null, false, 76),

    new SidenavMenu(88, 'All Wallpaper', '/wallpaper-listing', null, null, false, 61),
    new SidenavMenu(101, 'Custom Wallpaper', '/wallpaper/custom-wallpaper', null, null, false, 61),


    new SidenavMenu(59, 'Featured Frame', '/featured-frames', null, null, false, 0),
    new SidenavMenu(60, 'ArtReady', '/featured-art', null, null, false, 0),
    // new SidenavMenu(58, 'On Sale', '/on-sale', null, null, false, 0),
    // new SidenavMenu(59, 'Art Set', '/art-set', null, null, false, 0),
    new SidenavMenu(60, 'Collage Set', '/collage-set', null, null, false, 0), 
    new SidenavMenu(61, 'Art For Business', '/art-for-business', null, null, false, 0),
    
];
