import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Mount } from '../models/mount.model';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MountService {
  private apiUrlAuth = '';
  private apiUrlUnAuth = '';

  constructor(private http: HttpClient) {
    this.apiUrlAuth = environment.apiUrlauth;
    this.apiUrlUnAuth = environment.apiUrlUnauth;
  }

  filter(filter = '[]', orderBy = '[]', pageNumber = 0, pageSize = 10, type= "list"): Observable<Mount[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('filter', filter);
    httpParams = httpParams.append('type', type);
    if (orderBy || orderBy != '') {
      httpParams = httpParams.append('orderBy', orderBy);
    }
    httpParams = httpParams.append('pageNumber', pageNumber.toString());
    if (pageSize || pageSize > 0) {
      httpParams = httpParams.append('pageSize', pageSize.toString());
    }
    return this.http.get<Mount[]>(this.apiUrlUnAuth +'/mount', {
        params: httpParams
      })
      .pipe(
        map(res =>  res),
        catchError(this.handleError('filter', []))
      );
  }

  find(id = 0): Observable<Mount> {
    let httpParams = new HttpParams();
    return this.http.get<Mount>(this.apiUrlUnAuth +'/mount/' + id, {
        params: httpParams
      })
      .pipe(
        map(res => res),
        catchError(this.handleError('find', null))
      );
  }

  add(element: Mount): Observable<Mount> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Accept: 'application/json, text/plain'
      })
    };

    return this.http.post<Mount>(this.apiUrlAuth +'/mount', element, httpOptions)
      .pipe(
        map(res => res),
        catchError(this.handleError('getMount', element))
      );
  }

  update(element: Mount): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Accept: 'application/json, text/plain'
      })
    };

    return this.http.put<Mount>(this.apiUrlAuth +'/mount', element, httpOptions)
      .pipe(
        map(res => res),
        catchError(this.handleError('getMount', element))
      );
  }

  updateStatus(id: number, status: number): Observable<any> {
    const element: any = {
      id: id,
      status: status,
      form: 'mount'
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Accept: 'application/json, text/plain'
      })
    };

    return this.http.put<Mount>(this.apiUrlAuth +'/status_change', element, httpOptions)
        .pipe(
            map(res => res),
            catchError(this.handleError('getBasicPaint', element))
        );
  }

  delete(ids = '[]'): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ids', ids);
    return this.http.delete<any>(this.apiUrlAuth +'/mount', {
        params: httpParams
      })
      .pipe(
        map(res =>  res),
        catchError(this.handleError('deleteMount', null))
      );
  }

  getMatColors(): Observable<string[]> {
    let httpParams = new HttpParams();
    return this.http.get<string[]>(this.apiUrlUnAuth +'/matcolor', {
        params: httpParams
      })
      .pipe(
        map(res =>  res),
        catchError(this.handleError('filter', []))
      );
  }

  getMatWidths(): Observable<string[]> {
    let httpParams = new HttpParams();
    return this.http.get<string[]>(this.apiUrlUnAuth +'/matwidth', {
        params: httpParams
      })
      .pipe(
        map(res =>  res),
        catchError(this.handleError('filter', []))
      );
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
