import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {finalize} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {AppService} from 'src/app/app.service';
import {AuthenticationService} from 'src/app/admin/services/authentication.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-upload-photos',
    templateUrl: './upload-photos.component.html',
    styleUrls: ['./upload-photos.component.scss']
})
export class UploadPhotosComponent implements OnInit {
    @Output() onClose = new EventEmitter<any>(true);
    @Output() onReset = new EventEmitter<any>(true);

    private _fileUploaded = false;
    public get fileUploaded() {
        return this._fileUploaded;
    }

    private _invalidSize = false;
    public get invalidSize() {
        return this._invalidSize;
    }

    private _files: any[] = [];
    public get files() {
        return this._files;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private activatedRoute: ActivatedRoute,
        private appService: AppService,
        public authenticationService: AuthenticationService,
        private matSnackBar: MatSnackBar
    ) {
    }

    ngOnInit(): void {
    }

    public dropped(files: NgxFileDropEntry[]) {
        for (const droppedFile of files) {
            // Is it a file?
            if (droppedFile.fileEntry.isFile && this.isFileAllowed(droppedFile.fileEntry.name)) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {

                    // Here you can access the real file
                    console.log(droppedFile.relativePath, file);

                    var reader = new FileReader();
                    reader.readAsDataURL(file); // read file as data url
                    reader.onload = (event) => { // called once readAsDataURL is completed
                        const img = new Image();
                        img.src = reader.result as string;
                        img.onload = () => {
                            const height = img.naturalHeight;
                            const width = img.naturalWidth;
                            // console.log('Width and Height', width, height);

                            let invalidSize = false;
                            if (width > height) {
                                if (width < 750 || height < 450) {
                                    invalidSize = true;
                                }
                            } else {
                                if (height > width) {
                                    if (height < 750 || width < 450) {
                                        invalidSize = true;
                                    }
                                } else {
                                    if (height == width) {
                                        if (height < 750) {
                                            invalidSize = true;
                                        }
                                    }
                                }
                            }

                            if (!invalidSize) {
                                let _file: any = {};
                                _file.path = reader.result.toString(); //add source to image
                                _file.image = reader.result;
                                _file.name = file.name;
                                this._files.push(_file);

                                this._fileUploaded = true;
                                // this.image_1_path = reader.result.toString(); //add source to image
                                // this.image_1 = reader.result;
                                // this.filename = file.name;
                            } else {
                                // this._fileUploaded = false;
                                Swal.fire('', 'Sorry your image can’t be uploaded as it’s of very small size. Please upload images Larger Than 750x450 or 450x750 resolution for better Print Quality. Or mail us your Pictures with your details at info@mahattaart.com', 'error');
                            }
                        };

                    };
                });
            } else {
                this.matSnackBar.open('Only files in .jpg, .jpeg format are accepted and directories are not allowed.', 'x', {
                    duration: 2000,
                    verticalPosition: 'top',
                    panelClass: ['error']
                });
            }
        }
    }

    isFileAllowed(fileName: string) {
        let isFileAllowed = false;
        const allowedFiles = ['.jpg', '.jpeg','.JPG','.JPEG'];
        const regex = /(?:\.([^.]+))?$/;
        const extension = regex.exec(fileName);
        if (undefined !== extension && null !== extension) {
            for (const ext of allowedFiles) {
                if (ext === extension[0]) {
                    isFileAllowed = true;
                }
            }
        }
        return isFileAllowed;
    }

    public fileOver(event) {
        console.log(event);
    }

    public fileLeave(event) {
        console.log(event);
    }

    public onUpload() {
        let self = this;
        this._files.forEach(function(file) {
            let param: any = {};
            param.filename = file.name;
            param.created_by = 0;
            param.image = file.image;

            if (self.authenticationService.currentUserValue && self.authenticationService.currentUserValue.id > 0) {
                param.created_by = self.authenticationService.currentUserValue.id;
            }

            self.appService.uploadMedia(param)
                .pipe(
                    finalize(() => {
                        // self.loadingSubject.next(false);
                    })
                )
                .subscribe(
                    res => {
                        // console.log("ICTSoft", res['data']['url']);
                        let _my_photos = [];
                        if (localStorage.getItem('my_photos') !== null) {
                            _my_photos = JSON.parse(localStorage.getItem('my_photos'));
                        }
                        _my_photos.push(res['data']['url']);

                        localStorage.setItem('my_photos', JSON.stringify(_my_photos));
                        self.onClose.emit({url: res['data']['url']});
                    }
                );

        });

    }

    onRemovePhoto($event, i) {
        // $event.stopPropagation();
        this._files.splice(i, 1);
    }
}
