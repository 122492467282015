import {Component, OnInit} from '@angular/core';
import {Contact} from '../../../admin/models/contact.model';
import {finalize} from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {emailValidator} from '../../utils/app-validators';
import {BehaviorSubject, Observable} from "rxjs";
import {MatSnackBar} from '@angular/material/snack-bar';
import {ContactService} from "../../../admin/services/contact.service";
import {Router} from "@angular/router";
import {TopbarNavbarService} from "../../../topbar-navbar.service";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    public lat: number = 40.678178;
    public lng: number = -73.944158;
    public zoom: number = 12;
    subscribeForm: FormGroup;

    private loadingSubject: BehaviorSubject<boolean>;
    public loading$: Observable<boolean>;

    constructor(public formBuilder: FormBuilder, public snackbar: MatSnackBar, public contactService: ContactService,
                public router: Router, public topbarNavbarService: TopbarNavbarService) {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
        this.loading$ = this.loadingSubject.asObservable();
    }

    ngOnInit() {
        this.subscribeForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, emailValidator])],
            subject: ['Footer newsletter form'],
        });

    }

    subscribe() {
        if (this.subscribeForm.invalid || this.subscribeForm.pristine) {
            return;
        }
        this.loadingSubject.next(true);
        this.subscribeForm.disable();

        let element: Contact = new Contact();
        element = Object.assign({}, element, this.subscribeForm.value);
        element.name ='Newsletter';
        element.page_url = this.router.url;


        this.contactService.add(element)
            .pipe(
                // catchError(() => of([])),
                finalize(() => {
                    this.loadingSubject.next(false);
                    this.subscribeForm.enable();
                })
            )
            .subscribe(
                res => {
                    this.snackbar.open('Thank you for contacting us. Our agent will contact you within 24 hours. ', 'Ok', {
                        duration: 10000,
                        verticalPosition: 'top',
                        panelClass: ['success']
                    });
                },
                err => {
                    console.log('error: ', err);
                    // this.errorSubject.next(error);
                    // this.loadingSubject.next(false);
                    this.snackbar.open('There is some error', 'Ok', {
                        duration: 10000,
                        verticalPosition: 'top',
                        panelClass: ['error']
                    });
                });
    }

}
