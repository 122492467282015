import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {WishlistItem} from '../models/wishlist_item.model';
import {environment} from '../../../environments/environment';
import { AppService } from 'src/app/app.service';
import { AuthenticationService } from './authentication.service';
@Injectable({
  providedIn: 'root'
})
export class WishlistItemService {
  private apiUrlAuth = '';
  private apiUrlUnAuth = '';

  private countSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public count$: Observable<number> = this.countSubject.asObservable();

  constructor(private http: HttpClient, public authenticationService: AuthenticationService) {
    this.apiUrlAuth = environment.apiUrlauth;
    this.apiUrlUnAuth = environment.apiUrlUnauth;
  }

  public refreshCount() {
    if(this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
      const filter = [];
      filter.push(['user_id', '=', this.authenticationService.currentUserValue.id]);
      this.filter(JSON.stringify(filter), '[]', 0 , -1).subscribe(res => {});
    }
  }

  public resetCount() {
    this.countSubject.next(0);
  }


  filter(filter = '[]', orderBy = '[]', pageNumber = 0, pageSize = 10): Observable<WishlistItem[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('filter', filter);
    if (orderBy || orderBy != '') {
      httpParams = httpParams.append('orderBy', orderBy);
    }
    httpParams = httpParams.append('pageNumber', pageNumber.toString());
    if (pageSize || pageSize > 0) {
      httpParams = httpParams.append('pageSize', pageSize.toString());
    }
    return this.http.get<WishlistItem[]>(this.apiUrlUnAuth +'/wishlist_item', {
        params: httpParams
      })
      .pipe(
        map(res =>  {this.countSubject.next(res['data'].length); return res;}),
        catchError(this.handleError('filter', []))
      );
  }

  find(id = 0): Observable<WishlistItem> {
    let httpParams = new HttpParams();
    return this.http.get<WishlistItem>(this.apiUrlUnAuth +'/wishlist_item/' + id, {
        params: httpParams
      })
      .pipe(
        map(res => res),
        catchError(this.handleError('find', null))
      );
  }

  add(element: WishlistItem): Observable<WishlistItem> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Accept: 'application/json, text/plain'
      })
    };

    return this.http.post<WishlistItem>(this.apiUrlAuth +'/wishlist_item', element, httpOptions)
      .pipe(
        map(res => { this.refreshCount(); return res;}),
        catchError(this.handleError('getWishlistItem', element))
      );
  }

  update(element: WishlistItem): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Accept: 'application/json, text/plain'
      })
    };

    return this.http.put<WishlistItem>(this.apiUrlAuth +'/wishlist_item', element, httpOptions)
      .pipe(
        map(res => res),
        catchError(this.handleError('getWishlistItem', element))
      );
  }

  delete(ids = '[]'): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ids', ids);
    return this.http.delete<any>(this.apiUrlAuth +'/wishlist_item', {
        params: httpParams
      })
      .pipe(
        map(res => { this.refreshCount(); return res;}),
        catchError(this.handleError('deleteWishlistItem', null))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
