import {
    AfterViewInit,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';

import {SelectionModel} from '@angular/cdk/collections';
import {FormBuilder} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatCheckbox} from '@angular/material/checkbox';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {merge, Observable} from 'rxjs';
import {TableColumn} from './../../interfaces/table-column.interface';
import {CurrencyPipe, DatePipe} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {fadeInUp400ms} from '../../animations/fade-in-up.animation';
import {stagger40ms} from '../../animations/stagger.animation';
import {AuthenticationService} from '../../../authentication.service';
import {BaseAPIDataSource} from '../../services/base_api.datasource';
import {BaseAPIService} from '../../services/base_api.service';
import {InvoicePaymentDialogComponent} from '../invoice-payment-dialog/invoice-payment-dialog.component';
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz';
import {OrderPayment} from '../../models/order_payment.model';

@Component({
    selector: 'app-invoice-payment-detail-history',
    templateUrl: './invoice-payment-detail.component.html',
    styleUrls: ['./invoice-payment-detail.component.scss'],
    animations: [
        fadeInUp400ms,
        stagger40ms
    ],
    providers: [
        CurrencyPipe,
        DatePipe
    ]
})
export class InvoicePaymentDetailComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input()
    columns: TableColumn<OrderPayment>[];
    pageSize = 10;
    pageSizeOptions: number[] = [5, 10, 20, 50];

    dataSource: BaseAPIDataSource<any>;
    selection = new SelectionModel<OrderPayment>(true, []);
    public loading$: Observable<boolean>;
    @Output() onClose = new EventEmitter<any>(true);
    @Output() onReset = new EventEmitter<any>(true);
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild('#checkAllCheckbox', {static: true}) checkAllCheckbox: MatCheckbox;
    public selectedindex = 0;
    private currentUser: any;

    icMoreHoriz = icMoreHoriz;

    constructor(
        private route: Router,
        private baseAPIService: BaseAPIService,
        public dialog: MatDialog,
        public formBuilder: FormBuilder,
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private authenticationService: AuthenticationService
    ) {
        this.currentUser = authenticationService.currentUserValue;
    }

    get visibleColumns() {
        return this.columns.filter(column => column.visible).map(column => column.property);
    }

    getData() {
        this.setColumsData();
        const filter = [];
        const orderBy = [];
        filter.push(['order_id', '=', this.data.order.id]);

        this.dataSource = new BaseAPIDataSource<any>(this.baseAPIService);
        if (this.sort.active && this.sort.direction !== '') {
            orderBy.push([this.sort.active, this.sort.direction]);
        } else {
            orderBy.push(['received_date', 'desc']);
            orderBy.push(['id', 'desc']);
        }
        this.dataSource.getData(
            JSON.stringify(filter),
            JSON.stringify(orderBy),
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.pageSizeOptions,
            this.data.type == 'Offline' ? 'orderOfflinePayment' : 'orderPayment',
            'auth'
        );

        this.selection.clear();
        if (this.checkAllCheckbox) {
            this.checkAllCheckbox.checked = false;
        }
    }


    ngOnInit() {

        this.getData();
    }


    setColumsData() {

        if (this.data.type == 'Offline') {
            this.columns = [
                // { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true },
                {label: 'Invoice No.', property: 'order_offline.invoice_id', type: 'custom', visible: true},
                {label: 'Invoice Date', property: 'order_offline.invoice_date', type: 'custom', visible: true},
                {label: 'Payment Mode', property: 'mode', type: 'text', visible: true},
                {label: 'Cheque Date', property: 'cheque_date', type: 'custom', visible: true},
                {label: 'Cheque No.', property: 'cheque_no', type: 'text', visible: true},
                {label: 'Transaction No.', property: 'txnid', type: 'text', visible: true},
                {label: 'Amount', property: 'amount', type: 'custom', visible: true},
                {label: 'Payment Date', property: 'received_date', type: 'custom', visible: true},
                {label: 'Remarks', property: 'remarks', type: 'text', visible: true},
                {label: 'Actions', property: 'actions', type: 'button', visible: true}];
        } else {
            this.columns = [
                // { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true },
                {label: 'Invoice No.', property: 'order.invoice_id', type: 'custom', visible: true},
                {label: 'Invoice Date', property: 'order.invoice_date', type: 'custom', visible: true},
                {label: 'Payment Mode', property: 'mode', type: 'text', visible: true},
                {label: 'Cheque Date', property: 'cheque_date', type: 'custom', visible: true},
                {label: 'Cheque No.', property: 'cheque_no', type: 'text', visible: true},
                {label: 'Transaction No.', property: 'txnid', type: 'text', visible: true},
                {label: 'Amount', property: 'amount', type: 'custom', visible: true},
                {label: 'Payment Date', property: 'received_date', type: 'custom', visible: true},
                {label: 'Remarks', property: 'remarks', type: 'text', visible: true},
                {label: 'Actions', property: 'actions', type: 'button', visible: true}];
        }

    }

    ngAfterViewInit() {
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        merge(this.sort.sortChange, this.paginator.page).pipe(
            untilDestroyed(this)
        ).subscribe(() => {
            this.getData();
        });
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        // const numRows = this.dataOrderPayments.recordsFiltered;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.dataSource.data.forEach(row => this.selection.select(row));
        }
    }

    trackByProperty<T>(index: number, column: TableColumn<T>) {
        return column.property;
    }

    ngOnDestroy() {
    }

    parse(row, property) {
        switch (property) {
            case 'order_offline.invoice_id':
                return row.order_offline.invoice_id;
            case 'order_offline.invoice_date':

                return row.order_offline.invoice_date ? (this.datePipe.transform(row.order_offline.invoice_date, 'dd/MM/yyyy')) : ('');
            case 'order.invoice_id':
                return row.order.invoice_id;
            case 'order.invoice_date':
                return row.order.invoice_date ? (this.datePipe.transform(row.order.invoice_date, 'dd/MM/yyyy')) : ('');

            case 'invoice_date':

                return row.invoice_date ? (this.datePipe.transform(row.invoice_date, 'dd/MM/yyyy')) : ('');

            case 'received_date':
                return row.received_date ? (this.datePipe.transform(row.received_date, 'dd/MM/yyyy')) : ('');
            case 'cheque_date':
                return row.cheque_date ? (this.datePipe.transform(row.cheque_date, 'dd/MM/yyyy')) : ('');
            case 'total_amount':
                return this.currencyPipe.transform(row.total_amount, 'INR');
            case 'tds_amount':
                return this.currencyPipe.transform(row.tds_amount, 'INR');
            case 'amount':
                return this.currencyPipe.transform(row.amount, 'INR');
            case 'invoice_date':
                return row.invoice_date ? (this.datePipe.transform(row.invoice_date, 'dd/MM/yyyy')) : ('');
            case 'due_date':
                return row.due_date ? (this.datePipe.transform(row.due_date, 'dd/MM/yyyy')) : ('');
            case 'grand_total':
                return this.currencyPipe.transform(row.payable_amount, 'INR');
            case 'payment_received':
                return this.currencyPipe.transform((row.payable_amount - row.payment_received), 'INR');
            case 'region':
                return row.region.name;
            case 'status':
                return row.payable_amount > row.payment_received ? 'Pending' : 'Paid';
        }
        return '-';
    }

    public onSearch() {
        this.getData();
    }

    public reset() {
        this.getData();
    }

    onTabChanged($event) {
        console.log($event);
        this.selectedindex = $event.index;
        this.sort.active = null;
        this.paginator.pageIndex = 0;
        this.dataSource.data = [];
        this.getData();
    }

    public makePayment(orderPayment: OrderPayment) {
        const dialogRef = this.dialog.open(InvoicePaymentDialogComponent, {
            maxWidth: '800px',
            data: {
                orderPayment
            }
        });
        const close = dialogRef.componentInstance.onClose.subscribe(() => {
            dialogRef.close();

        });
        const reset = dialogRef.componentInstance.onReset.subscribe(() => {
            this.reset();
        });
    }
}
