import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Paper} from '../models/paper.model';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PaperService {
    private apiUrlAuth = '';
    private apiUrlUnAuth = '';

    constructor(private http: HttpClient) {
        this.apiUrlAuth = environment.apiUrlauth;
        this.apiUrlUnAuth = environment.apiUrlUnauth;
    }

    filter(filter = '[]', orderBy = '[]', pageNumber = 0, pageSize = 10, $type = 'list'): Observable<Paper[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('filter', filter);
        httpParams = httpParams.append('type', $type);
        if (orderBy || orderBy != '') {
            httpParams = httpParams.append('orderBy', orderBy);
        }
        httpParams = httpParams.append('pageNumber', pageNumber.toString());
        if (pageSize || pageSize > 0) {
            httpParams = httpParams.append('pageSize', pageSize.toString());
        }
        return this.http.get<Paper[]>(this.apiUrlUnAuth + '/paper', {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('filter', []))
            );
    }

    find(id = 0): Observable<Paper> {
        let httpParams = new HttpParams();
        return this.http.get<Paper>(this.apiUrlUnAuth + '/paper/' + id, {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('find', null))
            );
    }

    add(element: Paper): Observable<Paper> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.post<Paper>(this.apiUrlAuth + '/paper', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getPaper', element))
            );
    }

    update(element: Paper): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<Paper>(this.apiUrlAuth + '/paper', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getPaper', element))
            );
    }

    updateStatus(id: number, status: number, fieldName): Observable<any> {
        const element: any = {
            id: id,
            status: status,
            form: 'paper',
            type: fieldName
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<Paper>(this.apiUrlAuth + '/status_change', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getBasicPaint', element))
            );
    }

    delete(ids = '[]'): Observable<any> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('ids', ids);
        return this.http.delete<any>(this.apiUrlAuth + '/paper', {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('deletePaper', null))
            );
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
