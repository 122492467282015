import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {CustomerAddress} from '../models/customer_address.model';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CustomerAddressService {
    private apiUrlAuth = '';
    private apiUrlUnAuth = '';

    constructor(private http: HttpClient) {
        this.apiUrlAuth = environment.apiUrlauth;
        this.apiUrlUnAuth = environment.apiUrlUnauth;
    }

    filter(filter = '[]', orderBy = '[]', pageNumber = 0, pageSize = 10): Observable<CustomerAddress[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('filter', filter);
        if (orderBy || orderBy != '') {
            httpParams = httpParams.append('orderBy', orderBy);
        }
        httpParams = httpParams.append('pageNumber', pageNumber.toString());
        if (pageSize || pageSize > 0) {
            httpParams = httpParams.append('pageSize', pageSize.toString());
        }
        return this.http.get<CustomerAddress[]>(this.apiUrlUnAuth + '/customer_address', {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('filter', []))
            );
    }

    find(id = 0): Observable<CustomerAddress> {
        let httpParams = new HttpParams();
        return this.http.get<CustomerAddress>(this.apiUrlUnAuth + '/customer_address/' + id, {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('find', null))
            );
    }

    add(element: CustomerAddress): Observable<CustomerAddress> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.post<CustomerAddress>(this.apiUrlAuth + '/customer_address', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getCustomerAddress', element))
            );
    }

    addAddress(element: CustomerAddress, role_id): Observable<CustomerAddress> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };
        const apiPath = role_id == 11 ? this.apiUrlAuth + '/' + 'retailer_address' : this.apiUrlUnAuth + '/' + 'customer_address_guest';
        return this.http.post<CustomerAddress>(apiPath, element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getCustomerAddress', element))
            );
    }

    sendOtp(element: CustomerAddress): Observable<CustomerAddress> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.post<CustomerAddress>(this.apiUrlUnAuth + '/sendOtp', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getCustomerAddress', element))
            );
    }


    update(element: CustomerAddress): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain'
            })
        };

        return this.http.put<CustomerAddress>(this.apiUrlAuth + '/customer_address', element, httpOptions)
            .pipe(
                map(res => res),
                catchError(this.handleError('getCustomerAddress', element))
            );
    }

    delete(ids = '[]'): Observable<any> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('ids', ids);
        return this.http.delete<any>(this.apiUrlAuth + '/customer_address', {
            params: httpParams
        })
            .pipe(
                map(res => res),
                catchError(this.handleError('deleteCustomerAddress', null))
            );
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
