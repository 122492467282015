import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-banners',
    templateUrl: './banners.component.html',
    styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {
    @Input('banners') banners: Array<any> = [];
    public isMobile = false;

    constructor() {
    }

    ngOnInit() {
        if (window.innerWidth <= 575) {
            this.isMobile = true;
        }
    }

    public getBanner(index) {
        return this.banners[index];
    }

    public getImageTitle(index) {
        const title = this.banners[index].image_title;
        if (title) {
            return title;
        }
        return '';
    }

    public getBgImage(index) {
        let bgImage = {
            'background-image': index != null ? 'url(' + (this.isMobile ? this.banners[index].mobile_image : this.banners[index].image) + ')' : 'url(https://via.placeholder.com/600x400/ff0000/fff/)'
        };
        return bgImage;
    }

}
