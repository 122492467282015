import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {MatDialog} from '@angular/material/dialog';
import {AppService} from '../../app.service';
import {ProductImage} from '../../app.models';
import {AppSettings, Settings} from '../../app.settings';
import {WishlistQuickDialogComponent} from '../wishlist-quick-dialog/wishlist-quick-dialog.component';
import Swal from 'sweetalert2';
import {catchError, finalize} from 'rxjs/operators';
import {of} from 'rxjs';
import {WishlistItem} from '../../admin/models/wishlist_item.model';
import {AuthenticationService} from '../../authentication.service';
import {WishlistItemService} from '../../admin/services/wishlist_item.service';
import {CartLoginAthentiacationService} from "../../cart-login-athentiacation.service";

@Component({
    selector: 'app-products-carousel',
    templateUrl: './products-carousel.component.html',
    styleUrls: ['./products-carousel.component.scss']
})
export class ProductsCarouselComponent implements OnInit {

    @Input('products') products: Array<ProductImage> = [];
    public config: SwiperConfigInterface = {};
    public settings: Settings;

    constructor(public appSettings: AppSettings, public appService: AppService,
                public wishlistItemService: WishlistItemService,
                public authenticationService: AuthenticationService,
                public cartLoginAthentiacationService: CartLoginAthentiacationService,
                public dialog: MatDialog, private router: Router) {
        this.settings = this.appSettings.settings;
    }

    ngOnInit() {
        console.log('ICTSoft', this.products);
    }

    ngAfterViewInit() {
        this.config = {
            observer: true,
            slidesPerView: 6,
            spaceBetween: 5,
            keyboard: true,
            navigation: true,
            pagination: false,
            grabCursor: true,
            loop: false,
            preloadImages: false,
            lazy: true,
            breakpoints: {
                480: {
                    slidesPerView: 2
                },
                740: {
                    slidesPerView: 2,
                },
                960: {
                    slidesPerView: 3,
                },
                1280: {
                    slidesPerView: 4,
                },
                1500: {
                    slidesPerView: 6,
                }
            }
        };
    }

    // public openProductDialog(product){
    //   let dialogRef = this.dialog.open(ProductDialogComponent, {
    //       data: product,
    //       panelClass: 'product-dialog',
    //       direction: (this.settings.rtl) ? 'rtl' : 'ltr'
    //   });
    //   dialogRef.afterClosed().subscribe(product => {
    //     if(product){
    //       this.router.navigate(['/products', product.id, product.name]);
    //     }
    //   });
    // }

    public onAddToWishList(image_id, image_filename, image_photographer, image_caption) {
        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            const dialogRef = this.dialog.open(WishlistQuickDialogComponent, {
                data: {
                    // customer_address_id: 0
                },
                disableClose: true,
                autoFocus: false
            });
            const close = dialogRef.componentInstance.onClose.subscribe(data => {
                // console.log("ICTSoft onclose", data.wishlist_id);
                dialogRef.close();
                this.addToWishlist(image_id, image_filename, image_caption, image_photographer, data.wishlist_id);

            });
        } else {
            const dialogRef = this.cartLoginAthentiacationService.checkIsRegisteredUser();
            const close = dialogRef.componentInstance.onClose.subscribe(() => {
                dialogRef.close();
            });
        }
    }

    public onRemoveFromWishList(wishlist_item_id) {
        const ids = [];
        ids.push(wishlist_item_id);
        const self = this;
        Swal.fire({
            title: 'Are you sure to remove item from wishlist??',
            text: 'You won\'t be able to revert this!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, remove it!',
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            preConfirm() {
                return new Promise((resolve) => {
                    self.wishlistItemService.delete(JSON.stringify(ids))
                        .pipe(
                            catchError(() => of([])),
                            finalize(() => resolve())
                        )
                        .subscribe(
                            res => {
                            });
                });
            }
        }).then((result) => {
            if (result.value) {
                // this.getData();
            }
        });

    }

    private addToWishlist(image_id, image_filename, image_caption, image_photographer, wishlist_id) {
        let wishlist_item = new WishlistItem();
        wishlist_item.customized_product_id = 0;
        wishlist_item.wishlist_id = wishlist_id;
        wishlist_item.image_id = image_id;
        wishlist_item.image_filename = image_filename;
        wishlist_item.image_caption = image_caption;
        wishlist_item.image_photographer = image_photographer;
        wishlist_item.user_id = this.authenticationService.currentUserValue.id;

        this.wishlistItemService.add(wishlist_item)
            .pipe(
                finalize(() => {
                    // this.getData();
                    // this.loadingSubject.next(false);
                    // this.form.enable();
                })
            )
            .subscribe(
                res => {
                });

    }
}
