import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppService} from './app.service';
import {CartService} from './admin/services/cart.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthenticationService} from './admin/services/authentication.service';
import {Cart} from './admin/models/cart.model';
import {WishlistItemService} from './admin/services/wishlist_item.service';
import {Router} from '@angular/router';

declare let trackEvent: Function;

@Injectable({providedIn: 'root'})
export class GtmService {
    cart: any;
    private ipAddress: string = '';
    private city: string = '';

    constructor(public http: HttpClient, public appService: AppService,
                private cartService: CartService, public authenticationService: AuthenticationService,
                private wishlistItemService: WishlistItemService,
                public dialog: MatDialog, private snackbar: MatSnackBar, private router: Router) {

    }

    public signInGTMCart(event_name:string,data:any){
        console.log("signed in",data)

        const finObject: any = {};

        finObject.url = this.router.url;
        finObject.user_id = String(data);

        this.trackEvents(finObject, event_name);
        // this.trackEvents(data, event_name);
    }

    public signUpGTMCart(event_name:string,data:any){

        const finObject: any = {};

        finObject.url = this.router.url;
        finObject.user_id = String(data.id);
        finObject.name = String(data.name);
        finObject.email = String(data.email);
        finObject.phone_no = String(data.phone_no);
        finObject.looking_for = String(data.looking_for);

        this.trackEvents(finObject, event_name);
        // this.trackEvents(data, event_name);
    }


    public gtmCart(purchaseType = 'customer', promoId = 0, eventName = '', shippingAddress = null) {
        let customer_id = '';
        let guest_session_id = '';
        const filter = [];
        if (promoId > 0) {
            filter.push(['promo_id', '=', promoId]);
        }
        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            customer_id = this.authenticationService.currentUserValue.id + '';
            filter.push(['customer_id', '=', this.authenticationService.currentUserValue.id]);
        } else {
            guest_session_id = this.cartService.getSessionId();

            filter.push(['session_id', '=', guest_session_id]);
        }
        this.cartService.filter(JSON.stringify(filter), '[]', 0, -1, purchaseType).subscribe(res => {
            const carts = res['data'];

            this.gtmCartJson(carts, customer_id, guest_session_id, eventName, shippingAddress);
        });
    }

    private gtmCartJson(carts, customer_id, guest_session_id, event_name, shippingAddress) {
        let total = 0;
        let total_with_tax = 0;
        let amount_taxable = 0;
        let discount = 0;
        let discount_with_tax = 0;
        let tax = 0;
        let gift_amount = 0;
        let gift_tax = 0;
        let installation_amount = 0;
        let installation_tax = 0;
        let amount_payable = 0;
        let max_tax = 12;
        let quantity = 0;
        const cartItemArr = [];
        const finCartObject: any = {};

        carts.forEach((cart: Cart, index) => {
            const cartObj: any = {};
            cartObj.item_id = cart.customizedProduct.product.slug + '_' + cart.customizedProduct.product.id;
            cartObj.item_name = 'customized_' + cart.customizedProduct.product_type.name;

            cartObj.quantity = cart.customizedProduct.quantity;
            const itemDetail = cart.customizedProduct?.customizedProductItems[0];
            if (itemDetail.frame) {
                cartObj.frame = itemDetail.frame?.frame_code;
            }
            if (itemDetail.mount_1) {
                cartObj.mount_1 = itemDetail.mount_1?.code;
            }
            if (itemDetail.mount_2) {
                cartObj.mount_2 = itemDetail.mount_2?.code;
            }
            if (itemDetail.glass) {
                cartObj.glass = itemDetail.glass?.name;
            }
            if (itemDetail.paper) {
                cartObj.paper = itemDetail.paper?.display_name;
            }

            if (cart.customizedProduct.product_type.slug == 'canvas' && itemDetail.canvas_wrap) {
                cartObj.canvas_wrap = itemDetail.canvas_wrap?.name;
            }
            cartObj.final_size = itemDetail.final_size;
            cartObj.print_size = itemDetail.image_size;

            if (cart.customizedProduct.wallpaper) {
                cartObj.item_id = cart.customizedProduct.wallpaper.wallpaper_id;
                cartObj.item_name = cart.customizedProduct.wallpaper.name;
            }

            if (cart.customizedProduct.collage_layout_detail) {

                cartObj.item_id = "collage_set_" + cart.customizedProduct.collage_layout_detail.id;
                cartObj.item_name = cart.customizedProduct.collage_layout_detail.caption;

                cartObj.final_size = cart.customizedProduct.collage_layout_detail.final_size;
                cartObj.print_size = cart.customizedProduct.collage_layout_detail.image_size;
            }


            if (cart.customizedProduct.predefined_layout_detail) {
                cartObj.item_id = 'art_set_' + cart.customizedProduct.predefined_layout_detail.id;
                cartObj.item_name = cart.customizedProduct.predefined_layout_detail.caption;

                cartObj.final_size = cart.customizedProduct.predefined_layout_detail.final_size;
                cartObj.print_size = cart.customizedProduct.predefined_layout_detail.image_size;
            }

            if (cart.customizedProduct.on_sale) {
                // cartObj.featured_slug = cart.customizedProduct.on_sale.slug;
                cartObj.item_id = cart.customizedProduct.on_sale.slug;
                cartObj.item_name = cart.customizedProduct.on_sale.title;

            }
            quantity += cart.customizedProduct.quantity;
            if (cart.customizedProduct.on_sale_id > 0 && cart.customizedProduct.on_sale) {
                total += cart.customizedProduct.on_sale.list_price * cart.customizedProduct.quantity;
                total_with_tax += cart.customizedProduct.on_sale.list_price_tax * cart.customizedProduct.quantity;
                amount_taxable += cart.customizedProduct.on_sale.net_price * cart.customizedProduct.quantity;
                tax += cart.customizedProduct.on_sale.tax * cart.customizedProduct.quantity;
                amount_payable += cart.customizedProduct.on_sale.amount_payable * cart.customizedProduct.quantity;
                discount += cart.customizedProduct.on_sale.discount * cart.customizedProduct.quantity;
                discount_with_tax += cart.customizedProduct.on_sale.discount_with_tax * cart.customizedProduct.quantity;
                if (cart.customizedProduct.on_sale.tax_rate == 18) {
                    max_tax = 18;
                }

                gift_amount += cart.customizedProduct.on_sale.gift_wrap * cart.customizedProduct.quantity;
                gift_tax += cart.customizedProduct.on_sale.gift_tax * cart.customizedProduct.quantity;
                if (cart.customizedProduct.on_sale.installation_amount) {
                    installation_amount += cart.customizedProduct.on_sale.installation_amount;
                    installation_tax += cart.customizedProduct.on_sale.installation_tax;
                }

                cartObj.price = cart.customizedProduct.on_sale.amount_payable;
            } else {
                if (cart.customizedProduct.pricing_detail) {
                    total += cart.customizedProduct.pricing_detail.total * cart.customizedProduct.quantity;
                    total_with_tax += cart.customizedProduct.pricing_detail.total_with_tax * cart.customizedProduct.quantity;
                    // cart_discount_total += cart.customizedProduct.pricing_detail['total'] * cart.customizedProduct.quantity;
                    discount += cart.customizedProduct.pricing_detail.discount * cart.customizedProduct.quantity;
                    discount_with_tax += cart.customizedProduct.pricing_detail.discount_with_tax * cart.customizedProduct.quantity;

                    amount_taxable += (cart.customizedProduct.pricing_detail.amount_taxable - cart.customizedProduct.pricing_detail.gift_wrap) * cart.customizedProduct.quantity;
                    tax += cart.customizedProduct.pricing_detail.tax * cart.customizedProduct.quantity;
                    amount_payable += cart.customizedProduct.pricing_detail.amount_payable * cart.customizedProduct.quantity;
                    if (cart.customizedProduct.pricing_detail.tax_rate == 18) {
                        max_tax = 18;
                    }
                }

                gift_amount += cart.customizedProduct.pricing_detail.gift_wrap * cart.customizedProduct.quantity;
                gift_tax += cart.customizedProduct.pricing_detail.gift_tax * cart.customizedProduct.quantity;

                if (cart.customizedProduct.pricing_detail.installation_amount) {
                    installation_amount += cart.customizedProduct.pricing_detail.installation_amount;
                    installation_tax += cart.customizedProduct.pricing_detail.installation_tax;
                }

                cartObj.price = cart.customizedProduct.pricing_detail.amount_payable;

            }
            cartItemArr.push(cartObj);
        });
        finCartObject.currency = 'INR';
        finCartObject.price = amount_payable;
        finCartObject.quantity = quantity;
        finCartObject.items = cartItemArr;
        finCartObject.customer_id = customer_id;
        finCartObject.guest_session_id = guest_session_id;
        finCartObject.last_url = this.router.url;

        if (shippingAddress) {
            finCartObject.fullname = shippingAddress.fullname;
            finCartObject.mobile = shippingAddress.mobile;
            finCartObject.email = shippingAddress.email;
            finCartObject.address = shippingAddress.address;
            finCartObject.zipcode = shippingAddress.zipcode;
            finCartObject.city = shippingAddress.city?.name;
            // console.log(shippingAddress);
        }


        this.trackEvents(finCartObject, event_name);

    }


    public gtmProductView(customizedProduct, amountPayable) {
        let customer_id = '';
        let guest_session_id = '';
        const finCartObject: any = {};

        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            customer_id = this.authenticationService.currentUserValue.id + '';
        } else {
            guest_session_id = this.cartService.getSessionId();
        }

        const cartItemArr = [];
        cartItemArr.push(this.getCartObj(customizedProduct));

        finCartObject.currency = 'INR';
        finCartObject.price = amountPayable;
        finCartObject.items = cartItemArr;
        finCartObject.customer_id = customer_id;
        finCartObject.guest_session_id = guest_session_id;
        finCartObject.last_url = this.router.url;


        this.trackEvents(finCartObject, 'view_item');
        const cartNew = new Cart();
        cartNew.customizedProduct = customizedProduct;
        this.cartService.productView(cartNew).subscribe(res=>{
            console.log(res);
        });
    }

    private getCartObj(customizedProduct) {
        const cartObj: any = {};

        cartObj.item_id = customizedProduct.product.slug + '_' + customizedProduct.product.id;
        cartObj.item_name = 'customized_' + customizedProduct.product_type.name;
        cartObj.quantity = customizedProduct.quantity;
        const itemDetail = customizedProduct?.customizedProductItems[0];
        if (itemDetail.frame) {
            cartObj.frame = itemDetail.frame?.frame_code;
        }
        if (itemDetail.mount_1) {
            cartObj.mount_1 = itemDetail.mount_1?.code;
        }
        if (itemDetail.mount_2) {
            cartObj.mount_2 = itemDetail.mount_2?.code;
        }
        if (itemDetail.glass) {
            cartObj.glass = itemDetail.glass?.name;
        }
        if (itemDetail.paper) {
            cartObj.paper = itemDetail.paper?.display_name;
        }

        if (customizedProduct.product_type.slug == 'canvas' && itemDetail.canvas_wrap) {
            cartObj.canvas_wrap = itemDetail.canvas_wrap?.name;
        }
        cartObj.final_size = itemDetail.final_size;
        cartObj.print_size = itemDetail.image_size;

        if (customizedProduct.wallpaper) {
            cartObj.item_id = customizedProduct.wallpaper.wallpaper_id;
            cartObj.item_name = customizedProduct.wallpaper.name;
        }

        if (customizedProduct.collage_layout_detail && customizedProduct.collage_layout_detail?.id>0) {

            cartObj.item_id = "collage_set_" + customizedProduct.collage_layout_detail.id;
            cartObj.item_name = customizedProduct.collage_layout_detail.caption;

            cartObj.final_size = customizedProduct.collage_layout_detail.final_size;
            cartObj.print_size = customizedProduct.collage_layout_detail.image_size;
        }


        if (customizedProduct.predefined_layout_detail && customizedProduct.predefined_layout_detail?.id>0) {
            cartObj.item_id = 'art_set_' + customizedProduct.predefined_layout_detail.id;
            cartObj.item_name = customizedProduct.predefined_layout_detail.caption;

            cartObj.final_size = customizedProduct.predefined_layout_detail.final_size;
            cartObj.print_size = customizedProduct.predefined_layout_detail.image_size;
        }

        if (customizedProduct.on_sale) {
            // cartObj.featured_slug = customizedProduct.on_sale.slug;
            cartObj.item_id = customizedProduct.on_sale.slug;
            cartObj.item_name = customizedProduct.on_sale.title;

        }
        return cartObj;
    }

    public gtmSearch(keyword) {

        let customer_id = '';
        let guest_session_id = '';
        const finObject: any = {};

        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            customer_id = this.authenticationService.currentUserValue.id + '';
        } else {
            guest_session_id = this.cartService.getSessionId();
        }

        finObject.customer_id = customer_id;
        finObject.guest_session_id = guest_session_id;
        finObject.last_url = this.router.url;
        finObject.keyword = keyword;


        this.trackEvents(finObject, 'search');
    }

    public gtmFormSubmission(name, email, mobile, formName, product) {

        let customer_id = '';
        let guest_session_id = '';
        const finObject: any = {};

        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            customer_id = this.authenticationService.currentUserValue.id + '';
        } else {
            guest_session_id = this.cartService.getSessionId();
        }

        finObject.customer_id = customer_id;
        finObject.guest_session_id = guest_session_id;
        finObject.last_url = this.router.url;
        finObject.user_name = name;
        finObject.email = email;
        finObject.mobile = mobile;
        finObject.formName = formName;
        finObject.product = product;


        this.trackEvents(finObject, 'sign_up');
    }


    public gtmExitPage(activity_type) {

        let customer_id = '';
        let guest_session_id = '';
        const finObject: any = {};

        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            customer_id = this.authenticationService.currentUserValue.id + '';
        } else {
            guest_session_id = this.cartService.getSessionId();
        }

        finObject.customer_id = customer_id;
        finObject.guest_session_id = guest_session_id;
        finObject.page_location = this.router.url;
        finObject.activity_type = activity_type;
        this.trackEvents(finObject, 'exit_page');
    }

    public gtmEntryPage() {

        const finObject: any = {};


        finObject.url = this.router.url;
        console.log(finObject);

        this.trackEvents(finObject, 'entry_page');
    }

    public gtmPurchase(order) {

        let customer_id = '';
        let guest_session_id = '';
        const finObject: any = {};

        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.id > 0) {
            customer_id = this.authenticationService.currentUserValue.id + '';
        } else {
            guest_session_id = this.cartService.getSessionId();
        }
        finObject.currency = 'INR';

        finObject.customer_id = customer_id;
        finObject.guest_session_id = guest_session_id;

        const cartItemArr = [];
        let quantity = 0;
        const orderItems = order['order_items'];
        orderItems.forEach((item, index) => {
            const cartObj = this.getCartObj(item['customizedProduct']);
            quantity += item['customizedProduct'].quantity;
            cartObj.quantity = item['customizedProduct'].quantity;
            cartObj.price = item['total_price'],
                cartObj.tax = item['tax_amount'],
                cartObj.discount = item['promo_price'],
                cartObj.installation = item['installation_amount'],
                cartObj.installation_tax = item['installation_tax'],
                cartObj.gift = item['gift_amount'],
                cartObj.gift_tax = item['gift_tax'],
                cartObj.discount = item['promo_price'],
                cartItemArr.push(cartObj);
        });

        finObject.items = cartItemArr;
        finObject.quantity = quantity;
        finObject.price = order['total_price'];
        finObject.shipping = order['shipping_amount'];
        finObject.tax = order['tax_amount'];
        finObject.shipping_mobile = order['shipping_mobile'];
        finObject.shipping_email = order['shipping_email'];
        finObject.shipping_address = order['shipping_address'];
        finObject.shipping_name = order['shipping_name'];
        finObject.shipping_city = order['shipping_city'];
        finObject.shipping_state = order['shipping_state'];
        finObject.shipping_pincode = order['shipping_pincode'];
        finObject.promo = order['promo'] ? order['promo'].code : '';
        finObject.transaction_id = order['payment'] ?  order['payment'].txnid : '';
        finObject.mode = order['payment'] ?  order['payment'].mode : '';
        // console.log(finObject);
        this.trackEvents(finObject, 'purchase');
    }


    getIP() {
        return new Promise(resolve => {
            this.appService.getIPAddress().subscribe((res: any) => {
                this.ipAddress = res.ip;
                resolve(true);
            });
        });
    }


    getCity() {
        return new Promise(resolve => {
            this.appService.getCity(this.ipAddress).subscribe((res1: any) => {
                this.city = res1.city;
                resolve(true);
            });
        });
    }

    trackEvents(finObj, event_name) {
        console.log("finObj");
        console.log(finObj);
        this.getIP().then(res => {
            // this.getCity().then(res => {
                finObj.ip_address = this.ipAddress;
                finObj.city = "";
                trackEvent(finObj, event_name);
            // });
        });
    }


}
