export class ShippingMethod {
  id?: number = 0;
  name: string;
  description: string;
  amount: number;
  shipping_type: number;      //1. Delhi  2. NCR  3. Other

  created_at: string;
  updated_at: string;


  public constructor() {
  }
}
