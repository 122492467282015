import {DynamicGrid} from './offline_invoice_item.model';

export class Order {
    id?: number = 0;
    order_id: string;
    customer_type: string;
    product_price: number;
    promo_id: number;
    promo_price: number;
    taxable_price: number;
    tax_amount: number;
    tax_cgst_amount: number;
    tax_igst_amount: number;
    tax_sgst_amount: number;
    total_price: number;
    shipping_method_id: number;
    shipping_amount: number;
    grand_total: number;
    payment_pending: number;
    credit_amt: number;
    cash_point: number;
    payment_status: number;
    payment_mode: number;
    payment_gateway: string;
    payment_comment: string;
    payment_response: string;
    payment_date: string;
    billing_name: string;
    billing_mobile: string;
    billing_email: string;
    billing_address: string;
    billing_city: string;
    billing_state: string;
    billing_pincode: string;
    billing_company_name: string;
    billing_gst: string;
    billing_pan: string;
    shipping_name: string;
    shipping_mobile: string;
    shipping_email: string;
    shipping_address: string;
    shipping_city: string;
    shipping_state: string;
    shipping_pincode: string;
    sales_person_id: number;
    client_serving_id: number;
    invoice_id: string;
    invoice_date: string;
    user_id: number = 0;
    customer_id: number = 0;
    status: number;
    cancel_reason: string;
    gift_message: string;
    items: DynamicGrid;
    is_editable: number;
    enable_payment_link : number;
    created_at: any;
    public constructor() {
    }
}

export class OrderOldModel {
    order_id: number;
    customer_id: string;
    inv_order_id: string;
    customer_name: string;
    customer_address: string;
    customer_city: string;
    customer_state: string;
    customer_country: string;
    customer_pincode: number;
    customer_contact: string;
    customer_email: string;
    delivery_name: string;
    delivery_address: string;
    delivery_city: string;
    delivery_state: string;
    delivery_zip: number;
    delivery_country: string;
    delivery_tel: string;
    courier_company: string;
    tracking_id: string;
    offer_code: string;
    order_amount: number;
    order_tax_amount: number;
    order_tax_rate: number;
    order_tax_type: string;
    nb_order_no: string;
    bank_ref_no: string;
    bank_name: string;
    payment_mode: string;
    order_discount: number;
    order_quantity: number;
    order_date: string;
    order_payment_date: string;
    order_status: number;
    order_comments: string;
    order_update_date: string;
    order_updated_by: string;
    invoice_no: string;
    company_name: string;
    gst_no: string;
    pan_no: string;
    extra_details: string;


    public constructor() {
    }
}

export class OrderDetailOldModel {
    id: number;
    image_size: string;
    final_image_size: string;
    customer_id: string;
    order_id: string;
    customer_type: string;
    created_date: string;
    image_id: string;
    sku_id: string;
    quantity: string;
    product: string;
    product_type: string;
    old_price: number;
    price: number;
    mount_color: string;
    frame_color: string;
    mount_size_width: number;
    surface: string;
    frame_size_width: number;
    glass_name: string;
    updated_status: string;
    imageData: imageData;

    public constructor() {
    }
}

export class imageData {
    imgurl: string;

    public constructor() {
    }
}
