import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {MatDialog} from '@angular/material/dialog';
import {AppService} from '../../app.service';
import {WallArtCategory} from '../../app.models';
import {AppSettings, Settings} from '../../app.settings';
import {AuthenticationService} from '../../authentication.service';
import {WishlistItemService} from '../../admin/services/wishlist_item.service';
import {CartLoginAthentiacationService} from "../../cart-login-athentiacation.service";

@Component({
    selector: 'app-wall-art-category-carousel',
    templateUrl: './wall-art-category-carousel.component.html',
    styleUrls: ['./wall-art-category-carousel.component.scss']
})
export class WallArtCategoryCarouselComponent implements OnInit {

    @Input('products') products: Array<WallArtCategory> = [];
    @Input('url') url = "";
    public config: SwiperConfigInterface = {};
    public settings: Settings;

    constructor(public appSettings: AppSettings, public appService: AppService,
                public wishlistItemService: WishlistItemService,
                public authenticationService: AuthenticationService,
                public cartLoginAthentiacationService: CartLoginAthentiacationService,
                public dialog: MatDialog, private router: Router) {
        this.settings = this.appSettings.settings;
    }

    ngOnInit() {
        console.log('ICTSoft', this.products);
    }

    ngAfterViewInit() {
        this.config = {
            observer: true,
            slidesPerView: 4,
            spaceBetween: 5,
            keyboard: true,
            navigation: true,
            pagination: false,
            grabCursor: true,
            loop: true,
            preloadImages: false,
            lazy: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            breakpoints: {
                480: {
                    slidesPerView: 2
                },
                740: {
                    slidesPerView: 2,
                },
                960: {
                    slidesPerView: 3,
                },
                1280: {
                    slidesPerView: 4,
                },
                1500: {
                    slidesPerView: 4,
                }
            }
        };
    }

}
