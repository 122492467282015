import {Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
// import {ForgotPasswordComponent} from '../../shared/forgot-password/forgot-password.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AuthenticationService} from 'src/app/admin/services/authentication.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

import {environment} from '../../../environments/environment';

import {jsPDF} from 'jspdf';

@Component({
    selector: 'app-invoice-offline-dialog',
    templateUrl: './invoice-offline-dialog.component.html',
    styleUrls: ['./invoice-offline-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class InvoiceOfflineDialogComponent implements OnInit {
    @Output() onClose = new EventEmitter<any>(true);

    private loadingSubject: BehaviorSubject<boolean>;
    public loading$: Observable<boolean>;

    private _invoiceHtml: SafeHtml;
    public get invoiceHtml() {
        return this._invoiceHtml;
    }

    constructor(
        public formBuilder: FormBuilder,
        public router: Router,
        private route: ActivatedRoute,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<InvoiceOfflineDialogComponent>,
        private matSnackBar: MatSnackBar,
        private http: HttpClient,
        private sanitizer: DomSanitizer,
        public authenticationService: AuthenticationService,
        @Inject(MAT_DIALOG_DATA) public data: { order_id: number, generate: number, type: string, customerId: string, showComm: boolean }
    ) {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
        this.loading$ = this.loadingSubject.asObservable();
    }

    public printInvoiceUrl: string;

    ngOnInit() {
        // console.log('customer==' + this.data);
        let invoiceUrl = environment.apiUrlauth + '/invoice_offline?order_id=' + this.data.order_id + '&showComm=' + this.data.showComm + '&generate=' + this.data.generate + '&print=true&token=' + this.authenticationService.currentUserValue.token;
        this.printInvoiceUrl = environment.apiUrlauth + '/invoice_offline?order_id=' + this.data.order_id + '&showComm=' + this.data.showComm +  '&generate=0&print=true&token=' + this.authenticationService.currentUserValue.token;

        this.http.get(invoiceUrl, {responseType: 'text'}).subscribe(res => {
            this._invoiceHtml = this.sanitizer.bypassSecurityTrustHtml(res);
        });
    }

    @ViewChild('invoiceHtmlContent') invoiceHtmlContent: ElementRef;

    public onExport() {
        console.log('ICTSoft', this.invoiceHtmlContent.nativeElement);

        let doc = new jsPDF('landscape');
        doc.html(this.invoiceHtmlContent.nativeElement, {
            callback: function(doc) {
                doc.save();
            }
        });
    }

    public close(): void {
        this.dialogRef.close();
    }
}
